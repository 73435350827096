import React, { useState } from 'react';
import { TravelModeStateType } from './hooks/useServiceArea';
import style from './ArcGisKart.module.css';

type ServiceAreaDistanceFieldsProps = {
  handleSetTravelMode: (arg0: TravelModeStateType) => void;
  travelModeState: TravelModeStateType;
};
const ServiceAreaDistanceFields = ({
  handleSetTravelMode,
  travelModeState
}: ServiceAreaDistanceFieldsProps) => {
  const [
    currentTravelModeState,
    setCurrentTravelModeState
  ] = useState<TravelModeStateType>(travelModeState);

  const handleDriveChange = (e) => {
    setCurrentTravelModeState({
      ...currentTravelModeState,
      driveMinutes: e.target.value
    });
  };
  const handleWalkChange = (e) => {
    setCurrentTravelModeState({
      ...currentTravelModeState,
      walkMeters: e.target.value
    });
  };

  const handleDriveBlur = () => {
    const num = Number(currentTravelModeState.driveMinutes);
    if (typeof num === 'number') {
      handleSetTravelMode({
        driveMinutes: num
      });
    }
  };
  const handleWalkBlur = () => {
    const num = Number(currentTravelModeState.walkMeters);
    if (typeof num === 'number') {
      handleSetTravelMode({
        walkMeters: num
      });
    }
  };

  return (
    <div className={style.serviceAreaDistanceInputWrapper}>
      <label className={style.serviceAreaDistanceField} htmlFor={'walkMeters'}>
        <span>Gåavstand (1 - 1500 m):</span>
        <input
          type="number"
          max={1500}
          min={1}
          value={currentTravelModeState.walkMeters}
          onChange={handleWalkChange}
          onBlur={handleWalkBlur}
          id={'walkMeters'}
        />
      </label>
      <label
        className={style.serviceAreaDistanceField}
        htmlFor={'driveMinutes'}
      >
        <span>Kjøreavstand (1 - 15 min):</span>
        <input
          type="number"
          max={15}
          min={1}
          value={currentTravelModeState.driveMinutes}
          onChange={handleDriveChange}
          onBlur={handleDriveBlur}
          id={'driveMinutes'}
        />
      </label>
    </div>
  );
};

export default ServiceAreaDistanceFields;
