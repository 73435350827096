import { useRef, useEffect } from 'react';
export default function useMutableRef(value: Record<string, any>) {
  const valueRef = useRef(value);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef;
}
