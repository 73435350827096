import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import { parseISO8601String } from 'js/date-helper';
import { usePostback } from 'js/hooks';
import React, { FC, useState } from 'react';
import Panel from 'components/Panel/Panel';
import { Prosjekt } from 'types/Prosjekt';
import { Publisering } from 'types/Publisering';
import ProsjektRedigerContainer from '../ProsjektRedigerContainer';

export interface PubliseringRedigerSide {
  readonly headerText: string;
  readonly prosjektData: Prosjekt;
  readonly publiseringData: Publisering;
  readonly publiseringdataLagreApi: string;
  readonly avsluttUrl: string;
  readonly forrigeUrl: string;
  readonly deleteUrl?: string;
  readonly slettProsjektBekreftTekst: string;
  readonly archiveProsjektUrl?: string;
}

const PubliseringRedigerSide: FC<PubliseringRedigerSide> = ({
  headerText,
  publiseringData: data,
  publiseringdataLagreApi,
  avsluttUrl,
  forrigeUrl,
  deleteUrl,
  slettProsjektBekreftTekst,
  archiveProsjektUrl
}) => {
  const [sokePeriodeFraDatoUtc, setSokePeriodeFraDatoUtc] = useState(parseISO8601String(data.sokePeriodeFraDatoUtc));
  const [sokePeriodeTilDatoUtc, setSokePeriodeTilDatoUtc] = useState(parseISO8601String(data.sokePeriodeTilDatoUtc));
  const [annonseringFraDatoUtc, setAnnonseringFraDatoUtc] = useState(parseISO8601String(data.annonseringFraDatoUtc));
  const [annonseringTilDatoUtc, setAnnonseringTilDatoUtc] = useState(parseISO8601String(data.annonseringTilDatoUtc));

  const { postback, isDirty, isBusy, validation } = usePostback(publiseringdataLagreApi, {
    sokePeriodeFraDatoUtc,
    sokePeriodeTilDatoUtc,
    annonseringFraDatoUtc,
    annonseringTilDatoUtc
  });

  return (
    <ProsjektRedigerContainer
      headerText={headerText}
      isDirty={isDirty}
      isBusy={isBusy}
      currentStep={5}
      quitUrl={avsluttUrl}
      previousPageUrl={forrigeUrl}
      save={postback}
      deleteUrl={deleteUrl}
      slettProsjektBekreftTekst={slettProsjektBekreftTekst}
      archiveProsjektUrl={archiveProsjektUrl}
    >
      <Panel heading="Publisering" className="PubliseringRedigerSide">
        <DateRangePicker
          label="Søkeperiode (fra, til og med)*"
          fromDateInitialValue={sokePeriodeFraDatoUtc}
          toDateInitialValue={sokePeriodeTilDatoUtc}
          onFromDateChanged={setSokePeriodeFraDatoUtc}
          onToDateChanged={setSokePeriodeTilDatoUtc}
          fromDateValidation={validation.sokePeriodeFraDatoUtc}
          toDateValidation={validation.sokePeriodeTilDatoUtc}
        />

        <DateRangePicker
          label="Annonsering (fra, til og med)*"
          fromDateInitialValue={annonseringFraDatoUtc}
          toDateInitialValue={annonseringTilDatoUtc}
          onFromDateChanged={setAnnonseringFraDatoUtc}
          onToDateChanged={setAnnonseringTilDatoUtc}
          fromDateValidation={validation.annonseringFraDatoUtc}
          toDateValidation={validation.annonseringTilDatoUtc}
        />
      </Panel>
    </ProsjektRedigerContainer>
  );
};

export default PubliseringRedigerSide;
