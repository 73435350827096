import {
  AcceptedFileTypes,
  DeleteFile,
  File,
  UploadFile
} from 'components/UploadField/UploadField';
import React, { useCallback, useEffect, useState } from 'react';
// import styles from './UploadMultipleField.module.css';
export interface Props {
  readonly initialFiles: File[];
  readonly fileType: AcceptedFileTypes;
  readonly destinationUrl: string;
  readonly deleteUrl: string;
  readonly onChangeGetFiles?: (files: File[]) => void
}

export default function UploadMultipleField({
  initialFiles,
  fileType,
  destinationUrl,
  deleteUrl,
  onChangeGetFiles
}: Props) {
  const [files, setFiles] = useState(initialFiles);

  const deleteFile = useCallback(({ id }) => {
    setFiles((files) => files.filter((f) => f.id !== id));
  }, []);

  const addFile = useCallback((file) => {
    setFiles((files) => [...files, file]);
  }, []);

  useEffect(() => {
    if(onChangeGetFiles){
      onChangeGetFiles(files)
    }
  },[files])

  return (
    <>
      <UploadFile
        multiple
        key={'upload'}
        destinationUrl={destinationUrl}
        fileType={fileType}
        onUpload={addFile}
      />
      {files.map((file) => (
        <DeleteFile
          multiple
          file={file}
          key={file.id}
          deleteUrl={deleteUrl}
          onDelete={deleteFile}
        />
      ))}
    </>
  );
}
