import React, { FC } from 'react';
import Annonse, {
  AnnonseBlock
} from '../../components/AnnonseBlock/AnnonseBlock';

export interface AnnonseBlokkSide {
  readonly annonse: AnnonseBlock;
}

// Denne siden brukes til annonser med /blokk i URL og skal fungere som embedded i eksterne sider
const AnnonseBlokkSide: FC<AnnonseBlokkSide> = ({ annonse }) => {
  return <Annonse {...annonse} />;
};

export default AnnonseBlokkSide;
