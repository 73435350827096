import useArrayState from '@clave/use-array-state';
import AdvancedFileSelector from 'components/AdvancedFileSelector/AdvancedFileSelector';
import AnchoredErrorMessage, { ErrorProps } from 'components/AnchoredErrorMessage/AnchoredErrorMessage';
import DynamicFileList, { SortColumns } from 'components/DynamicFileList/DynamicFileList';
import { cleanPost, post } from 'js/api-helper';
import { filterAttachmentsById } from 'js/document-filter-helper';
import React, { useEffect, useState } from 'react';
import { KonkurranseGrunnlagFil } from 'types/KonkurranseGrunnlagFil';

interface AndreDokumentSkjemaProps {
  readonly andreDokumenter: KonkurranseGrunnlagFil[];
  readonly lastOppAndreDokumenterUrl: string;
  readonly slettVedleggUrl: string;
  readonly uploadField: boolean;
  readonly overrideHeading?: React.ReactNode;
  readonly readOnly?: boolean;
  readonly fileListCols?: Array<Object>;
  readonly disableUpload?: boolean;
  readonly disableDocRemoval?: boolean;
  readonly oppdaterFilStatusUrl: string;
}

const AndreDokumentSkjema: React.FC<AndreDokumentSkjemaProps> = (props) => {
  const [otherDocs, setOtherDocs] = useArrayState<KonkurranseGrunnlagFil>(props.andreDokumenter);
  const [error, setError] = useState<ErrorProps | undefined>();

  const handleOtherDocs = async (newDocs: FileList | null) => {
    const fileList = Array.from(newDocs ?? []);

    const data = new FormData();
    data.append('svarFilType', 'AD' ?? '');
    fileList?.forEach((file) => data.append('file', file));

    try {
      const res = await cleanPost(props.lastOppAndreDokumenterUrl, data);
      res.slettbar = true;
      setOtherDocs.append(res);
    } catch (error) {
      setError({
        msg: error.message
      });
    }
  };

  const handleRemoveAttachment = async (id: string) => {
    try {
      await post(`${props.slettVedleggUrl}?filId=${id}`);
      const copy = filterAttachmentsById(otherDocs, id);
      if (copy) setOtherDocs(copy);
    } catch (error) {
      console.log(error);
    }
  };

  //make all files not deletable if the offer has been made.
  // shallow copy is needed to forward reactivity
  useEffect(() => {
    if (props.disableDocRemoval) {
      let clone = [...otherDocs];
      clone.forEach((doc) => (doc.slettbar = false));
      setOtherDocs(clone);
    }
  }, [props.disableDocRemoval]);

  return (
    <div className="otherDocs">
      {error && <AnchoredErrorMessage {...error} percentageTopPos={13} />}

      {props.overrideHeading ? props.overrideHeading : <h3 className="-heading">Andre dokumenter</h3>}
      {props.uploadField && (
        <AdvancedFileSelector
          disabled={props.disableUpload}
          name={'Andre Dokumenter'}
          onChange={(newDoc) => {
            handleOtherDocs(newDoc?.target?.files);
          }}
        />
      )}

      {otherDocs.length > 0 && (
        <DynamicFileList
          oppdaterFilStatusUrl={props.oppdaterFilStatusUrl}
          files={otherDocs}
          onRemove={(id: string) => {
            handleRemoveAttachment(id);
          }}
          readOnly={props.readOnly}
          columns={props.fileListCols ?? [SortColumns.date, SortColumns.document, SortColumns.status]}
        />
      )}
    </div>
  );
};

export default AndreDokumentSkjema;
