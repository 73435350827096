import React, { FC, ReactElement, useState } from 'react';
import cn from 'classnames';

export interface ExpandableItem{
    readonly itemHeader: React.ReactChild;
    readonly itemContent? : React.ReactChild;
    readonly expandable? : boolean;
}

const ExpandableItem: FC<ExpandableItem> = ({itemHeader, itemContent, expandable = true}) =>{

    const [isOpen, setIsOpen] = useState(false);
    
    if(!expandable){
        return(
            <div className={cn('item-header')}>{itemHeader}</div> 
        );
    }
    return (
    <>
        <div className={cn('item-header', {
            '-isOpen' : isOpen
        })}
            onClick={() => setIsOpen(prevState => !prevState)}>
                {itemHeader}
        </div>
        
        <div className={cn('item-content', {
            '-isOpen' : isOpen
        })}>
            {itemContent}
        </div>
    </>
    )
}

export default ExpandableItem;