import React from 'react';
import Text, { VariantTypes } from 'components/Text/Text';

export interface Props {
  readonly children?: string;
  readonly className?: string;
  readonly textVariant?: VariantTypes;
}

export default function Paragraphs({ children, className, textVariant }: Props) {
  return (
    <>
      {children
        ?.split('\n')
        .filter(Boolean)
        .map((t, i) => (
          <Text variant={textVariant || 'paragraph'} key={i} className={className}>
            {t}
          </Text>
        ))}
    </>
  );
}
