import { useRef, useEffect, DependencyList, useMemo } from 'react';
export default function useAction<T>(func: () => T, deps: DependencyList): () => T {
  const funcRef = useRef(func);

  useEffect(() => {
    funcRef.current = func;
  }, [deps]);

  return useMemo(() => () => funcRef.current(), []);
}
