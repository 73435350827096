import React, { useState, useRef, useEffect } from 'react';
import style from './HamburgerButton.module.css';
import cn from 'classnames';
import { ifWindow } from 'js/checkWindow';
import { LinkItem } from 'types/LinkItem';
import { IoMdLogIn } from 'react-icons/io';
import { MdOpenInNew } from 'react-icons/md';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import Text from 'components/Text/Text';

interface Props {
  readonly largeSidebarItems: LinkItem[];
  readonly smallSidebarItems: LinkItem[];
}

export const HamburgerButton = ({ largeSidebarItems, smallSidebarItems }: Props) => {
  const [display, setDisplay] = useState(false);
  const [open, setOpen] = useState(false);
  const [showText, setShowText] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [buttonFromLeft, setButtonFromLeft] = useState(0);

  const handleResize = () => {
    if (buttonRef?.current) {
      const fromLeft = buttonRef.current.offsetLeft;
      setButtonFromLeft(fromLeft);
    }
  };
  useEffect(() => {
    handleResize();
    ifWindow(() => window.addEventListener('resize', handleResize));
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => ifWindow(() => window.removeEventListener('resize', handleResize));
  }, [buttonRef?.current]);

  const toggleMenu = () => {
    setDisableButton(true);
    setTimeout(() => setDisableButton(false), 600);
    if (open) {
      setShowText(false);
      setTimeout(() => {
        setOpen(false);
        setTimeout(() => {
          setDisplay(false);
        }, 400);
      }, 200);
    } else {
      setDisplay(true);
      setTimeout(() => {
        setOpen(true);
        setTimeout(() => {
          setShowText(true);
        }, 400);
      }, 200);
    }
  };

  return (
    <>
      <button
        ref={buttonRef}
        disabled={disableButton}
        onClick={toggleMenu}
        className={cn(style.menuButton, {
          [style.open]: open
        })}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div
        style={{ paddingLeft: buttonFromLeft, opacity: open ? 1 : 0 }}
        className={cn(style.slideInMenu, {
          [style.slideInMenuOpen]: open,
          [style.removeDiv]: !display
        })}
      >
        <div
          style={{
            transition: 'opacity 200ms ease-in-out',
            opacity: showText ? 1 : 0
          }}
        >
          {largeSidebarItems?.map(({ text, url, isSelected }, i) => (
            <Text
              component="a"
              variant={'menu-main'}
              key={text}
              className={cn(style.menuNavigationItem, {
                [style.menuNavSelected]: isSelected,
                [style.menuNavLast]: largeSidebarItems.length - 1 === i
              })}
              href={url}
            >
              {text}
            </Text>
          ))}

          {smallSidebarItems?.map(({ text, url, isSelected }) => (
            <Text
              component="a"
              variant={'menu-sub'}
              key={text}
              className={cn(style.menuNavigationItemSmall, {
                [style.menuNavSelected]: isSelected
              })}
              href={url}
            >
              {text} <IconReactIcons size={1} type={text?.includes('log') ? IconTypes.login : IconTypes.openInNew} />
            </Text>
          ))}
        </div>
      </div>
    </>
  );
};
