import { IArcGisKart } from 'components/ArcGisKart/ArcGis';
import { getMarker } from 'components/ArcGisKart/arcGis.helper';
import ArcGisLoader from 'components/ArcGisKart/ArcGisLoader';
import Popup from 'components/Popup/Popup';
import Text from 'components/Text/Text';
import { shortDateString } from 'js/date-helper';
import { numberWithSpaces } from 'js/format-helper';
import React, { FC, useCallback } from 'react';
import { BiCommentDots } from 'react-icons/bi';
import { ArcgisServiceArea } from 'types/ArcgisServiceArea';
import { ArcgisSketch } from 'types/ArcgisSketch';
import { SvarTomteListItem } from 'types/SvarTomteListItem';
import InnkomneItem from 'components/InnkomneItem/InnkomneItem';
import SvarInnkomneHeader from '../components/SvarInnkomneHeader';
import SvarInnkomneTitle from '../components/SvarInnkomneTitle';
import SvarPopup from '../components/SvarPopup';
import style from '../SvarInnkomne.module.css';
import useSvarInnkomneListOperations from '../useSvarInnkomneListOperations';

export interface SvarTomteInnkomneSide {
  readonly title: string;
  readonly innkomneSvar: SvarTomteListItem[];
  readonly annonseUrl: string;
  readonly lastNedZipUrl?: string;
  readonly sketches?: ArcgisSketch[];
  readonly serviceAreas?: ArcgisServiceArea[];
}

const SvarTomteInnkomneSide: FC<SvarTomteInnkomneSide> = ({
  title,
  innkomneSvar,
  annonseUrl,
  lastNedZipUrl,
  sketches,
  serviceAreas
}: SvarTomteInnkomneSide) => {
  const { sortedSvar, now, mapPopup, setMapPopup, viewMode, setViewMode, toggleBareTilBefaring, setCurrentInnkomneSvar } =
    useSvarInnkomneListOperations<SvarTomteListItem>(innkomneSvar);

  const getDetailsList = useCallback(
    (svar) => [
      {
        label: 'Pris: ',
        value: numberWithSpaces(svar.prisKvmTilbudtAreal) + ' kr'
      },
      {
        label: 'Gjennomføringstid: ',
        value: svar.gjennomforingstidTilByggeklar
      },
      {
        label: 'Tilbudt areal: ',
        value: numberWithSpaces(svar.tilbudtAreal) + ' kvm'
      },
      {
        label: 'Ledig fra: ',
        value: shortDateString(svar.ledigFraDato)
      }
    ],
    []
  );

  const getSubtitle = useCallback(
    (svar) => ({
      label: 'Næringsmegler: ',
      value: svar.eier || svar.naeringsmegler
    }),
    []
  );

  const getMapsProps = (): IArcGisKart => ({
    readOnly: true,
    zoom: 8,
    center: sortedSvar[0] ? [Number(sortedSvar[0]?.location?.longitude), Number(sortedSvar[0]?.location?.latitude)] : undefined,
    sketches: sketches,
    serviceAreas: serviceAreas,
    markers: sortedSvar.map(({ location, overstyrtScoreHelhetsvurdering, ...svar }) => {
      return {
        latitude: Number(location?.latitude),
        longitude: Number(location?.longitude),
        markerColor: getMarker(overstyrtScoreHelhetsvurdering),
        popupTemplate: {
          title: svar.address,
          element: (
            <SvarPopup
              heading={
                <>
                  {svar.address}
                  {svar.internKommentar && <BiCommentDots />}
                </>
              }
              url={svar.url}
              scores={svar.scores}
              subtitle={getSubtitle(svar)}
              detailsList={getDetailsList(svar)}
              location={location}
            />
          )
        }
      };
    })
  });

  return (
    <div className={style.SvarInnkomneSide}>
      <SvarInnkomneTitle title={title} annonseUrl={annonseUrl} lastNedZipUrl={lastNedZipUrl} />
      <SvarInnkomneHeader viewMode={viewMode} setViewMode={setViewMode} toggleBareTilBefaring={toggleBareTilBefaring} />

      {viewMode === 'table' &&
        (sortedSvar?.length === 0 ? (
          <Text variant={'tag-bold'} className={style.empty}>
            Ingen innkomne svar
          </Text>
        ) : (
          sortedSvar.map((svar) => (
            <InnkomneItem
              heading={
                <>
                  {svar.address}
                  {svar.internKommentar && <BiCommentDots />}
                </>
              }
              subtitle={getSubtitle(svar)}
              detailsList={getDetailsList(svar)}
              id={svar.id}
              url={svar.url}
              image={svar.image}
              key={svar.id}
              scores={svar.scores}
              befaring={svar.befaring}
              overstyrtScoreHelhetsvurdering={svar.overstyrtScoreHelhetsvurdering}
              setCurrentInnkomneSvar={setCurrentInnkomneSvar}
            />
          ))
        ))}

      {viewMode === 'map' && (
        <div className={style.map}>
          <Popup close={() => setMapPopup(false)} isOpen={mapPopup}>
            <ArcGisLoader toggleFullscreen={() => setMapPopup(!mapPopup)} {...getMapsProps()} />
          </Popup>
          {ArcGisLoader !== null && mapPopup ? (
            <div style={{ height: 600 }} />
          ) : (
            <ArcGisLoader toggleFullscreen={() => setMapPopup(!mapPopup)} {...getMapsProps()} />
          )}
        </div>
      )}
      <div className={style.printDate}>{now}</div>
    </div>
  );
};

export default SvarTomteInnkomneSide;
