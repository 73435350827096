import React from 'react';
import Text from 'components/Text/Text';

export default function Personvernerklaring() {
  return (
    <div className="Personvernerklaring">
      <Text variant="paragraph">
        Denne personvernerklæringen gjelder ved besøk på og bruk av nettsidene statensinnleie.no og statenstomtesøk.no. Statsbygg
        (org. no. 971 278 374) er behandlingsansvarlig for personopplysninger som samles inn på statensinnleie.no og
        statenstomtesøk.no. Spørsmål du måtte ha om behandling av dine personopplysninger i statensinnleie.no og
        statenstomtesøk.no vil Statsbygg besvare på vegne av oss. Du kan kontakte Statsbygg på
        <a href="mailto:personvern@statsbygg.no"> personvern@statsbygg.no</a>
      </Text>

      <Text variant="paragraph-header" component="h3">
        Personvern
      </Text>

      <Text variant="paragraph">
        Personvern er retten til privatliv og retten til å bestemme over egne personopplysninger. Personopplysninger er
        opplysninger og vurderinger som kan knyttes til en enkeltperson. Vi tar ditt personvern på alvor og vi behandler kun
        opplysninger i den grad det er nødvendig og vi har et gyldig rettslig grunnlag (jf. personvernforordningen art. 6 nr. 1
        bokstav f ). Vi etterstreber videre å sørge for at opplysningene vi behandler er korrekte, at de ikke kommer på avveie og
        at de er tilgjengelige for de av våre ansatte som har behov for tilgang. Du kan reservere deg mot at vi registrerer
        personopplysninger, men det kan medføre at vi er forhindret fra å gi deg tilgang til ønsket informasjon eller tjenester.
      </Text>

      <Text variant="paragraph-header" component="h3">
        Utlevering av personopplysninger
      </Text>

      <Text variant="paragraph">
        Personopplysningene deles ikke med utenforstående tredjeparter. Nettsiden driftes av Knowit AS. Tjenesten Statens innleie
        og Statens tomtesøk er skybasert og er plassert i skyen hos Microsoft. For å måle bruk av sidene brukes et script og
        informasjonskapsel (cookie) fra analyseverktøyet Google Analytics. Mottatte opplysninger er underlagt Google sine
        retningslinjer for personvern.
      </Text>

      <Text variant="paragraph-header" component="h3">
        Dine rettigheter
      </Text>

      <Text variant="paragraph">
        Som bruker av nettsidene våre har du rett til å kreve innsyn i personopplysningene som vi behandler om deg og hvordan de
        behandles. Du kan også kreve retting, sletting og begrensninger i behandlingen av personopplysninger i henhold til
        personopplysningsloven. Dersom du mener at Statsbygg ikke har overholdt dine rettigheter i henhold til
        personopplysningsloven, har du rett til å klage til den aktuelle tilsynsmyndigheten. Dette gjøres ved å sende klage til
        <a href="http://www.datatilsynet.no" target="_blank" rel="noopener noreferrer">
          {' '}
          Datatilsynet
        </a>
        .
      </Text>

      <Text variant="paragraph-header" component="h3">
        Formål og behandling av personopplysninger
      </Text>

      <Text variant="paragraph">
        Formålet med databehandlingen er at Databehandleren skal kunne styre tilgangen til detaljert informasjon i
        statensinnleie.no og statenstomtesøk.no, og sikre at registrerte brukere har riktig tilgang til sin virksomhets data. Alle
        øvrige brukere som ikke blir tilknyttet en virksomhet defineres som «publikum». Statensinnleie.no og statenstomtesøk.no må
        aksesseres med innlogging via ID-porten som er en felles innloggingstjeneste for offentlige tjenester.
        Digitaliseringsdirektoratet er behandlingsansvarlig for personopplysninger som blir håndtert i felles innlogging til
        offentlige tjenester. Bruk av ID-porten til innlogging minimerer datatilgang og sikrer at kun personer med norsk
        personnummer og e-ID får tilgang til nettløsningen. Utover tilgangsstyring, rollestyring og sikkerhet brukes opplysningene
        til analyse og sammenstilling av bruksmønster for å videreutvikle nettsidene og utbedre brukeropplevelsen.
      </Text>

      <Text variant="paragraph-header" component="h3">
        Behandlingens art og hensikt
      </Text>

      <Text variant="paragraph">
        Personopplysninger samles gjennom innlogging via ID-porten og lagres i en skybasert plattform hos Microsoft. I tillegg
        samles anonymiserte personopplysninger via innsamling av informasjonskapsler.
      </Text>

      <Text variant="paragraph-header" component="h3">
        Varighet
      </Text>

      <Text variant="paragraph">
        For alle slettes personopplysninger automatisk etter 5 dager med inaktivitet. For øvrige brukere slettes
        personopplysninger ved endringer av relasjonen til aktuell virksomhet eller ved utmelding fra Statens innleie/Statens
        tomtesøk av andre årsaker.
      </Text>

      <Text variant="paragraph-header" component="h3">
        Type personopplysninger
      </Text>

      <Text variant="paragraph">
        Følgende opplysninger knyttet til person lagres i løsningen: Navn, fødselsnummer, telefonnummer, epostadresse (jobb),
        bilde. Opplysningene lagres for å tilby funksjonalitet i løsningen for Statens innleie/Statens tomtesøk.
      </Text>
      <Text variant="paragraph-header" component="h2">
        TEKNISKE OG ORGANISATORISKE SIKKERHETSTILTAK
      </Text>

      <Text variant="paragraph-header" component="h3">
        Pseudonymiseringstiltak{' '}
      </Text>

      <Text variant="paragraph">
        For å måle bruk av sidene brukes et script og informasjonskapsel (cookie) fra analyseverktøyet Google Analytics.
        IP-adresse samles inn for å rapportere på geolokasjonsdata. IP-adressene anonymiseres slik at geolokasjonsdata ikke spores
        på bynivå.
      </Text>

      <Text variant="paragraph-header" component="h3">
        Krypteringstiltak{' '}
      </Text>

      <Text variant="paragraph">
        Alt av kommunikasjon både inn til statensinnleie.no, statenstomtesøk.no og ut til eksterne tjenester er kryptert via
        https. Tilgang til serverne i Microsoft Azure skjer kun via krypterte kanaler. Det samme gjelder for serverne som kjører
        de forskjellige applikasjonene som ikke er tilgjengelige utenfra.
      </Text>

      <Text variant="paragraph-header" component="h3">
        Tiltak for å sikre personopplysningenes fortrolighet{' '}
      </Text>

      <Text variant="paragraph">
        Tilgang til personopplysninger er begrenset til systemforvaltere av statensinnleie.no og statenstomtesøk.no. Hos
        underdatabehandleren er dette begrenset til 2 personer og hos databehandler skal det til enhver tid aldri være flere enn 5
        systemforvaltere.
      </Text>

      <Text variant="paragraph-header" component="h3">
        Andre datasikkerhetstiltak{' '}
      </Text>

      <Text variant="paragraph">Datatilgang er minimert via innlogging og ved at tilgang til data kun gjøres via API.</Text>

      <Text variant="paragraph-header" component="h3">
        Bruk av data om leieobjektet til analyse{' '}
      </Text>

      <Text variant="paragraph">
        Statsbygg vil i tillegg til å bruke opplysningene om leieobjekter som legges inn til å vurdere svaret også kunne bruke
        opplysningene til å analysere leiemarkedet med hensyn på prisutvikling og andre analyser.
      </Text>
    </div>
  );
}
