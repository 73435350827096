import { IArcGisKart } from 'components/ArcGisKart/ArcGis';
import { getMarker } from 'components/ArcGisKart/arcGis.helper';
import ArcGisLoader from 'components/ArcGisKart/ArcGisLoader';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import Popup from 'components/Popup/Popup';
import React, { FC, useState } from 'react';
import SvarInnkomneTitle from '../../Svar/SvarInnkomne/components/SvarInnkomneTitle';
import { ArcgisServiceArea } from 'types/ArcgisServiceArea';
import { ArcgisSketch } from 'types/ArcgisSketch';
import { BefaringListItem } from 'types/BefaringListItem';
import { LinkItem } from 'types/LinkItem';
import style from './BefaringInnkomneKartSide.module.css';
import BefaringPopup from './BefaringPopup';

export interface BefaringInnkomneKartSide {
  readonly title: string;
  readonly lastNedZipUrl?: string;
  readonly annonseUrl?: string;
  readonly alleBefaringerListe: BefaringListItem[];
  readonly sketches?: ArcgisSketch[];
  readonly serviceAreas?: ArcgisServiceArea[];
  readonly pageLinks?: LinkItem[];
}

const BefaringInnkomneKartSide: FC<BefaringInnkomneKartSide> = ({
  title,
  lastNedZipUrl,
  annonseUrl,
  alleBefaringerListe,
  sketches,
  serviceAreas,
  pageLinks
}: BefaringInnkomneKartSide) => {
  const [mapPopup, setMapPopup] = useState(false);

  const getMapsProps = (): IArcGisKart => ({
    readOnly: true,
    zoom: 8,
    center: alleBefaringerListe[0]
      ? [
          Number(alleBefaringerListe[0].longitude),
          Number(alleBefaringerListe[0].latitude)
        ]
      : undefined,
    sketches: sketches,
    serviceAreas: serviceAreas,
    markers: alleBefaringerListe.map(
      ({
        latitude,
        longitude,
        overstyrtScoreHelhetsvurdering,
        ...befaring
      }) => {
        return {
          latitude: Number(latitude),
          longitude: Number(longitude),
          markerColor: getMarker(overstyrtScoreHelhetsvurdering),
          popupTemplate: {
            title: befaring.address,
            element: (
              <BefaringPopup
                {...befaring}
                latitude={latitude}
                longitude={longitude}
              />
            )
          }
        };
      }
    )
  });

  return (
    <div className={style.befaringInnkomneKart}>
      <SvarInnkomneTitle
        title={title}
        lastNedZipUrl={lastNedZipUrl}
        annonseUrl={annonseUrl ?? ""}
      />
      {pageLinks && pageLinks?.length > 0 && (
        <PageLinkMenu pageLinks={pageLinks} styles='-top-bot-margin' />
      )}
      <div className={style.map}>
        <Popup close={() => setMapPopup(false)} isOpen={mapPopup}>
          <ArcGisLoader
            toggleFullscreen={() => setMapPopup(!mapPopup)}
            {...getMapsProps()}
          />
        </Popup>
        {ArcGisLoader !== null && mapPopup ? (
          <div style={{ height: 600 }} />
        ) : (
          <ArcGisLoader
            toggleFullscreen={() => setMapPopup(!mapPopup)}
            {...getMapsProps()}
          />
        )}
      </div>
    </div>
  );
};

export default BefaringInnkomneKartSide;
