import KontaktpersonPanel from 'components/Kontaktperson/Kontaktperson';
import Popup from 'components/Popup/Popup';
import { post } from 'js/api-helper';
import React, { PropsWithChildren, useCallback, useState } from 'react';
import { AiOutlineSync } from 'react-icons/ai';
import Button from 'components/Button/Button';
import Panel from 'components/Panel/Panel';
import { Kontaktperson } from 'types/Kontaktperson';
import style from './SvarTomteRedigerSide.module.css';
import { FaExternalLinkAlt } from 'react-icons/fa';
import Text from 'components/Text/Text';

interface SaveResponse {
  readonly redigerSvarUrl: string;
  readonly svarSideUrl: string;
}

export interface Props {
  readonly headline?: string;
  readonly subtitle?: string;
  readonly submitText?: string;
  readonly kontaktperson: Kontaktperson;
  readonly showOpenRelevanteSvar?: boolean;
  openRelevanteSvar(): void;
  submitDraft(): Promise<SaveResponse>;
  submitFinal(): Promise<SaveResponse>;
  readonly deleteUrl?: string;
  readonly slettSvarBekreftTekst?: string;
  readonly isBusy?: boolean;
  readonly canSubmit?: boolean;
}

export default function SvarTomteRedigerContainer({
  headline,
  submitText,
  subtitle,
  kontaktperson,
  openRelevanteSvar,
  showOpenRelevanteSvar,
  submitDraft,
  submitFinal,
  deleteUrl,
  slettSvarBekreftTekst,
  isBusy = false,
  canSubmit = false,
  children
}: PropsWithChildren<Props>) {
  const saveDraft = useCallback(async () => {
    const { redigerSvarUrl, svarSideUrl } = await submitDraft();
    history.replaceState(null, '', redigerSvarUrl);
    document.location.href = svarSideUrl;
  }, [submitDraft]);

  const saveFinal = useCallback(async () => {
    const { redigerSvarUrl, svarSideUrl } = await submitFinal();
    history.replaceState(null, '', redigerSvarUrl);
    document.location.href = svarSideUrl;
  }, [submitFinal]);

  const deleteSvar = useCallback(async () => {
    if (deleteUrl) {
      await post(deleteUrl);
      window.location.href = '/';
    }
  }, [deleteUrl]);

  const [popup, setPopup] = useState<'delete' | undefined>();
  const hidePopup = useCallback(() => setPopup(undefined), []);

  return (
    <div className={style.SvarTomteRedigerContainer}>
      <div className={style.titles}>
        <Text variant="project-h1">{headline}</Text>
        <Text variant="tag">
          Svarfrist: <b>{subtitle}</b>
        </Text>
        <div className={style.toolbar}>
          {showOpenRelevanteSvar && (
            <div>
              <label style={{ marginRight: 10 }} htmlFor={'openRelevantAnswers'}>
                Mine objekter
              </label>
              <Button id={'openRelevantAnswers'} onClick={openRelevanteSvar}>
                <FaExternalLinkAlt />
              </Button>
            </div>
          )}
        </div>
      </div>

      <KontaktpersonPanel {...kontaktperson} />

      {children}

      <Popup isOpen={popup === 'delete'} close={hidePopup}>
        <Panel>
          <h3>{slettSvarBekreftTekst}</h3>
          <div className="BrukerSide__popup-buttons">
            <Button secondary onClick={hidePopup}>
              Avbryt
            </Button>
            <Button onClick={deleteSvar}>Slett</Button>
          </div>
        </Panel>
      </Popup>

      <div className={style.buttons}>
        <div className={style.buttonRow}>
          {deleteUrl && (
            <Button secondary onClick={() => setPopup('delete')}>
              Slett
            </Button>
          )}

          <Button secondary onClick={saveDraft} disabled={!canSubmit}>
            Lagre kladd
          </Button>
          <Button secondary onClick={saveDraft} disabled={!canSubmit}>
            Lagre og avslutt
          </Button>
          <Button onClick={saveFinal}>{submitText || 'Levere svar'}</Button>
          {isBusy && <AiOutlineSync className="spin" />}
        </div>
      </div>
    </div>
  );
}
