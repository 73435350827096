import React, {
  FC,
  MouseEvent,
  PropsWithChildren,
  useEffect,
  useMemo
} from 'react';
import { createPortal } from 'react-dom';
import style from './Popup.module.css';

export interface Props {
  close(): void;

  isOpen?: boolean;
  clickOutsideToClose?: boolean;
}

export default function Popup({
  isOpen = false,
  close,
  clickOutsideToClose = true,
  children
}: PropsWithChildren<Props>) {
  const closeModal = (e: MouseEvent<HTMLDivElement>) => {
    if (clickOutsideToClose && e.target === e.currentTarget) {
      close();
    }
  };

  return isOpen ? (
    <Portal>
      <div onClick={closeModal} className={style.overlay}>
        {children}
      </div>
    </Portal>
  ) : (
    <></>
  );
}

const Portal: FC = ({ children }) => {
  const el = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    document.body.appendChild(el);
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.removeChild(el);
      document.body.style.overflow = 'auto';
    };
  }, []);

  return createPortal(children, el);
};
