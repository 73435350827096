import cn from 'classnames';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/DropdownMenu';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import React, { useState } from 'react';
import { LinkItem } from 'types/LinkItem';
import { LinkItemChild } from 'types/LinkItemChild';

export interface PageLinkMenu {
  readonly pageLinks: LinkItem[];
  readonly styles?: string;
}

const RenderLinkItemChild = (child: LinkItemChild) => {
  if (!child.url && child.text) {
    return (
      <DropdownMenuItem asChild disabled>
        <div style={{ textDecoration: 'none' }}>{child.text}</div>
      </DropdownMenuItem>
    );
  }
  return (
    <DropdownMenuItem asChild>
      <a href={child.url} style={{ textDecoration: 'none' }}>
        {child.text}
      </a>
    </DropdownMenuItem>
  );
};

function RenderLinkItem(item: LinkItem) {
  if ((item.children?.length ?? 0) > 0) {
    const [open, setIsOpen] = useState(false);
    return (
      <DropdownMenu open={open} onOpenChange={(state) => setIsOpen(state)}>
        <DropdownMenuTrigger className={cn('link dropdown', item.isSelected && '-active')}>
          {item.children?.find((c) => c.isSelected)?.text ?? item.text}
          <IconReactIcons type={open ? IconTypes.arrowUp : IconTypes.arrowDown} />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {item.children?.map((child) => (
            <RenderLinkItemChild {...child} />
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }

  if (!item.url || typeof item.url !== 'string') {
    return (
      <span aria-disabled="true" className="link -unavailable">
        {item.text}
      </span>
    );
  }

  return (
    <a className={cn('link', item.isSelected && '-active')} href={item.url}>
      {item.text}
    </a>
  );
}

const PageLinkMenu = ({ pageLinks, styles }: PageLinkMenu) => (
  <div role="navigation" className={cn('pageLinkMenu', styles)}>
    {pageLinks.map((item) => RenderLinkItem(item))}
  </div>
);

const Styles = {
  onGrid: 'on-grid'
};

export { Styles };

export default PageLinkMenu;
