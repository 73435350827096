import Heading from 'components/Heading/Heading';
import React, { FC } from 'react';
import Panel from 'components/Panel/Panel';
import { HjelpetekstListItem } from 'types/HjelpetekstListItem';
import Hjelpetekst from './Hjelpetekst';
import style from './HjelpeteksterSide.module.css';

export interface HjelpeteksterSide {
  readonly tekster: HjelpetekstListItem[];
}

const HjelpeteksterSide: FC<HjelpeteksterSide> = ({ tekster }) => {
  return (
    <div className="HjelpeteksterSide">
      <Heading>Hjelpetekster</Heading>
      <Panel className={style.hjelpetekster}>
        {tekster.map((tekst) => (
          <Hjelpetekst key={tekst.id} {...tekst} />
        ))}
      </Panel>
    </div>
  );
};

export default HjelpeteksterSide;
