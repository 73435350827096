import cn from 'classnames';
import { shortDateString } from 'js/date-helper';
import { numberWithSpaces } from 'js/format-helper';
import React from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { BiCommentDots } from 'react-icons/bi';
import { BefaringListItem } from 'types/BefaringListItem';
import style from '../BefaringInnkomneSide/BefaringInnkomneSide.module.css';

export default function BefaringPopup({
  address,
  url,
  ...befaring
}: BefaringListItem) {
  return (
    <div className={style.popup}>
      <h3>
        {address} {befaring.internKommentar && <BiCommentDots />}
      </h3>
      <a href={url} className={style.link}>
        Detaljer <FiChevronRight />
      </a>
      <div>
        <div>
          Utleier: <strong>{befaring.utleier}</strong>
        </div>

        <div>
          Pris per kvm med tiltak:{' '}
          <strong>{numberWithSpaces(befaring.prisKvmMedTiltak)} kr</strong>
        </div>

        <div>
          Pris per kvm uten tiltak:{' '}
          <strong>{numberWithSpaces(befaring.prisKvmUtenTiltak)} kr</strong>
        </div>

        <div>
          Felleskostnader:{' '}
          <strong>{numberWithSpaces(befaring.felleskostKvm)} kr</strong>
        </div>

        <div>
          Mva-kompensasjon per kvm:{' '}
          <strong>{numberWithSpaces(befaring.mvaKompensasjonKvm)} kr</strong>
        </div>

        <div>
          Ledig areal:{' '}
          <strong>{numberWithSpaces(befaring.ledigAreal)} kvm</strong>
        </div>

        <div>
          Ledig fra: <strong>{shortDateString(befaring.ledigFraDato)}</strong>
        </div>
      </div>
      <div className={style.score}>
        {befaring?.scores?.map((score) => (
          <>
            <strong>{score.type}</strong>
            <Dot
              kalkulert={score.kalkulertScore}
              overstyrt={score.overstyrtScore}
            />
          </>
        ))}
      </div>
    </div>
  );
}

function Dot({
  kalkulert,
  overstyrt
}: {
  kalkulert: number;
  overstyrt: number;
}) {
  const scoreClass = getScore(overstyrt, kalkulert);

  return <div className={cn(style.dot, scoreClass)}></div>;
}
function getScore(overstyrt: number, kalkulert: number) {
  switch (overstyrt || kalkulert) {
    case 1:
      return style.dotBad;
    case 2:
      return style.dotMiddle;
    case 3:
      return style.dotGood;
  }
}
export function bygningstandToCSSClass(item: string) {
  switch (item) {
    case 'Ikke oppført':
      return style.ikkeoppfort;
    case 'Eksisterende':
      return style.eksisterende;
    case 'Total rehabilitering':
      return style.totalRehab;
  }
}
