/* eslint-disable @typescript-eslint/no-var-requires */
import { useEffect, useState } from 'react';
import * as api from 'js/api-helper';
import { ifWindow } from 'js/checkWindow';

const getGeoDataToken = async () => {
  const fetchedToken = await api
    .get('/api/v1/kart/arcgistoken')
    .then((res) => res)
    .catch(() => {
      if (process.env.GEODATA_FETCH) {
        return fetch(process.env.GEODATA_FETCH).then((res) => res.text());
      } else return '';
    });
  return fetchedToken;
};
export const useLoadModules = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [loadedModules, setLoadedModules] = useState<any>(null);

  async function asyncLoadModules() {
    ifWindow(() => {
      const { loadModules, loadCss } = require('esri-loader');
      loadModules([
        'esri/Map',
        'esri/views/MapView',
        'esri/tasks/ServiceAreaTask',
        'esri/tasks/support/ServiceAreaParameters',
        'esri/tasks/support/FeatureSet',
        'esri/geometry/Point',
        'esri/Graphic',
        'esri/layers/GraphicsLayer',
        'esri/layers/FeatureLayer',
        'esri/Color',
        'esri/widgets/Sketch',
        'esri/config',
        'esri/identity/IdentityManager',
        'esri/Basemap',
        'esri/widgets/BasemapGallery'
      ]).then(
        async ([
          Map,
          MapView,
          ServiceAreaTask,
          ServiceAreaParameters,
          FeatureSet,
          Point,
          Graphic,
          GraphicsLayer,
          FeatureLayer,
          Color,
          Sketch,
          config,
          IdentityManager,
          Basemap,
          BasemapGallery
        ]) => {
          loadCss();
          config.apiKey =
            'AAPK739b017085164638a8419571aaf9574chgiwcRHXbmAqIBCDJ4gkyKfmfoOOhgJVnmlcXSET6wSwEU5MwxzSTx4chCN22RNd';
          config.assetsPath = '/assets';

          const token = await getGeoDataToken();

          if (token) {
            sessionStorage.setItem('geodata_token', token);
            IdentityManager.registerToken({
              server: 'https://services.geodataonline.no/arcgis/rest/services',
              token: token
            });
          } else {
            sessionStorage.removeItem('geodata_token');
            IdentityManager.registerToken({
              server: 'https://services.geodataonline.no/arcgis/rest/services',
              token: 'token'
            });
          }

          setLoadedModules({
            Map,
            MapView,
            ServiceAreaTask,
            ServiceAreaParameters,
            FeatureSet,
            Point,
            Graphic,
            GraphicsLayer,
            FeatureLayer,
            Color,
            Sketch,
            config,
            Basemap,
            BasemapGallery
          });
        }
      );
    });
  }

  useEffect(() => {
    if (!loadedModules && typeof window !== 'undefined') {
      asyncLoadModules();
    }
  }, []);
  return loadedModules;
};
