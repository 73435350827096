import agenda from '../../assets/icons/agenda.svg';
import alertFilled from '../../assets/icons/alert-filled.svg';
import alert from '../../assets/icons/alert.svg';
import angleDoubleLeft from '../../assets/icons/angle-double-left.svg';
import angleDoubleRight from '../../assets/icons/angle-double-right.svg';
import angleDown from '../../assets/icons/angle-down.svg';
import angleUp from '../../assets/icons/angle-up.svg';
import arrowDown from '../../assets/icons/arrow-down.svg';
import arrowLeft from '../../assets/icons/arrow-left.svg';
import arrowRight from '../../assets/icons/arrow-right.svg';
import arrowUp from '../../assets/icons/arrow-up.svg';
import bell from '../../assets/icons/bell.svg';
import checkBox from '../../assets/icons/check-box.svg';
import checkSuccess from '../../assets/icons/check-success.svg';
import check from '../../assets/icons/check.svg';
import close from '../../assets/icons/close.svg';
import customDetaljer from '../../assets/icons/custom-detaljer.svg';
import customEiendommerWhite from '../../assets/icons/custom-eiendommer-white.svg';
import customVirksomhet from '../../assets/icons/custom-virksomhet.svg';
import directionAlt from '../../assets/icons/direction-alt.svg';
import email from '../../assets/icons/email.svg';
import eye from '../../assets/icons/eye.svg';
import files from '../../assets/icons/files.svg';
import fullscreen from '../../assets/icons/fullscreen.svg';
import helpAlt from '../../assets/icons/help-alt.svg';
import home from '../../assets/icons/home.svg';
import infoAlt from '../../assets/icons/info-alt.svg';
import listOl from '../../assets/icons/list-ol.svg';
import list from '../../assets/icons/list.svg';
import locationPinFilled from '../../assets/icons/location-pin-filled.svg';
import locationPin from '../../assets/icons/location-pin.svg';
import map from '../../assets/icons/map.svg';
import pencil from '../../assets/icons/pencil.svg';
import profil from '../../assets/icons/profil.svg';
import reload from '../../assets/icons/reload.svg';
import save from '../../assets/icons/save.svg';
import search from '../../assets/icons/search.svg';
import settings from '../../assets/icons/settings.svg';
import trash from '../../assets/icons/trash.svg';
import write from '../../assets/icons/write.svg';
import mapPinBlueFilled from '../../assets/icons/map-pin-blue-filled.png';
import mapPinBlue from '../../assets/icons/map-pin-blue.png';
import mapPinOrangeFilled from '../../assets/icons/map-pin-orange-filled.png';
import mapPinOrange from '../../assets/icons/map-pin-orange.png';
import bookmark from '../../assets/icons/bookmark.svg';

export default {
  agenda: agenda,
  'alert-filled': alertFilled,
  alert: alert,
  'angle-double-left': angleDoubleLeft,
  'angle-double-right': angleDoubleRight,
  'angle-down': angleDown,
  'angle-up': angleUp,
  'arrow-down': arrowDown,
  'arrow-left': arrowLeft,
  'arrow-right': arrowRight,
  'arrow-up': arrowUp,
  bell: bell,
  'check-box': checkBox,
  'check-success': checkSuccess,
  check: check,
  close: close,
  'custom-detaljer': customDetaljer,
  'custom-eiendommer-white': customEiendommerWhite,
  'custom-virksomhet': customVirksomhet,
  'direction-alt': directionAlt,
  email: email,
  eye: eye,
  files: files,
  fullscreen: fullscreen,
  'help-alt': helpAlt,
  home: home,
  'info-alt': infoAlt,
  'list-ol': listOl,
  list: list,
  'location-pin-filled': locationPinFilled,
  'location-pin': locationPin,
  map: map,
  pencil: pencil,
  profil: profil,
  reload: reload,
  save: save,
  search: search,
  settings: settings,
  trash: trash,
  write: write,
  'map-pin-blue-filled': mapPinBlueFilled,
  'map-pin-blue': mapPinBlue,
  'map-pin-orange-filled': mapPinOrangeFilled,
  'map-pin-orange': mapPinOrange,
  bookmark: bookmark
};
