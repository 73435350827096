import React, { useEffect, useState } from 'react';

type CustomIconButtonProps = {
  isToggled?: boolean;
  noToggle?: boolean;
  onClick: () => void;
  title?: string;
  children?: JSX.Element | JSX.Element[] | string | null;
  icon?: JSX.Element | string | null;
};
const CustomIconButton = ({
  isToggled,
  noToggle,
  onClick,
  icon,
  title,
  children
}: CustomIconButtonProps) => {
  const [showToggled, setShowToggled] = useState(isToggled);

  const handleClick = () => {
    onClick();
    if (!noToggle) {
      setShowToggled(!showToggled);
    }
  };
  useEffect(() => {
    setShowToggled(isToggled);
  }, [isToggled]);

  return (
    <button
      title={title}
      className={
        showToggled ? 'esri-custom-button toggled' : 'esri-custom-button'
      }
      onClick={handleClick}
    >
      {icon && 
      // eslint-disable-next-line react/no-unknown-property
      <img url={icon} height={20} width={20} />}
      {children}
    </button>
  );
};

export default CustomIconButton;
