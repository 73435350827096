import React from 'react';
import classnames from 'classnames';
import styles from './WizardButton.module.css';

interface IWizardButton {
  active: boolean;
  disabled?: boolean;
  finished?: boolean;
  number: string;
  onClick?: () => void;
  label: string;
}
const WizardButton = ({
  active,
  disabled,
  finished,
  number,
  onClick,
  label
}: IWizardButton) => (
  <div className={styles.buttonWrapper} onClick={onClick}>
    <button
      disabled={disabled || onClick === null}
      name={number}
      onClick={onClick}
      className={classnames(styles.button, {
        [styles.active]: active,
        [styles.finished]: finished
      })}
    />
    <div className={styles.label}>{label}</div>
  </div>
);

export default WizardButton;
