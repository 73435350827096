import React, { FC, useEffect, useState, useRef } from 'react';
import type { LinkItem } from 'types/LinkItem';
import type { DokumentSkjemadel } from 'types/DokumentSkjemadel';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import Text from 'components/Text/Text';
import useArrayState from '@clave/use-array-state';
import SvarDokumentSkjema from 'components/SvarDokumentSkjema/SvarDokumentSkjema';
import FormSubmitBox from 'components/FormSubmitBox/FormSubmitBox';
import Modal from 'components/Modal/Modal';
import Checkbox from 'components/Checkbox/Checkbox';
import { cleanPost, post } from 'js/api-helper';
import { KonkurranseGrunnlagFil } from 'types/KonkurranseGrunnlagFil';
import AndreDokumentSkjema from 'components/AndreDokumentSkjema/AndreDokumentSkjema';
import { shortDateString } from 'js/date-helper';
import DownloadAllFiles from 'components/DownloadAllFiles/DownloadAllFiles';
import { ErrorProps } from 'components/AnchoredErrorMessage/AnchoredErrorMessage';
import RecindOfferBox from 'components/RecindOfferBox/RecindOfferBox';
import { InvalidDocStructure } from 'types/InvalidDocStructure';
import { SortColumns } from 'components/DynamicFileList/DynamicFileList';
import { disableRemovableDoc, getInvalidDocStructure } from 'js/document-list-helper';

export interface ForhandlingerSvarAktorSide {
  readonly tittel: string;
  readonly sideLenker?: LinkItem[];

  readonly sendTilbudUrl: string;
  readonly angreTilbudUrl?: string;
  readonly trekkeTilbudUrl?: string;
  readonly slettVedleggUrl: string;
  readonly oppdaterFilStatusUrl: string;
  readonly lastOppAndreDokumenterUrl: string;
  readonly lastNedAlleDokumenterUrl: string;

  readonly oppdragsGiverNavn: string;
  readonly innFlytteDatoUtc: string;
  readonly leveranseFristDatoUtc: string;

  readonly tilbudSendt: boolean;
  readonly tilbudTrukket: boolean;

  readonly andreDokumenter: KonkurranseGrunnlagFil[];
  readonly skjemaDeler: DokumentSkjemadel[];
}

const ForhandlingerSvarAktorSide: FC<ForhandlingerSvarAktorSide> = (props: ForhandlingerSvarAktorSide) => {
  const [dynamicDocs, setDynamicDocs] = useArrayState<DokumentSkjemadel>(props.skjemaDeler);
  const [invalidDocs, setInvalidDocs] = useArrayState<InvalidDocStructure>([]);
  const [error, setError] = useState<ErrorProps | undefined>();
  const [hasBeenRead, setHasBeenRead] = useState(props.tilbudSendt);
  const [offerMade, setOfferMade] = useState(props.tilbudSendt);
  const [offerModal, setOfferModal] = useState(false);
  const [offerRecinded, setOfferRecinded] = useState(props.tilbudTrukket);
  const [recindModal, setRecindModal] = useState(false);
  const isMounted = useRef(false);

  const sendOffer = async (url) => {
    const InvalidDocStructure = getInvalidDocStructure(dynamicDocs);

    if (InvalidDocStructure.length == 0) {
      try {
        const res = await cleanPost(url);
        if (res) {
          setOfferMade(true);
          setOfferModal(true);
        }
      } catch (error) {
        setError({
          msg: error.message
        });
      }
    } else {
      setInvalidDocs(InvalidDocStructure);
      setError({
        msg: 'Ufullstendig skjema: du må fylle ut alle obligatoriske felter'
      });
    }
  };

  const regretOffer = async (url) => {
    try {
      await cleanPost(url);
      setOfferMade(false);
      setHasBeenRead(false);
    } catch (error) {
      setError({
        msg: error.message
      });
    }
  };

  const recindOffer = async (url) => {
    try {
      const res = await post(url);
      if (res) {
        //make all files not deletable if the offer is recinded
        disableRemovableDoc(dynamicDocs);
        setOfferRecinded(true);
        setHasBeenRead(false);
        setRecindModal(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Unset error if form changes AND Remove red outline if invalidDocs holds reference to the
  // field just uploaded to.
  useEffect(() => {
    setError(undefined);
    if (isMounted.current) {
      setInvalidDocs(getInvalidDocStructure(dynamicDocs));
    } else {
      isMounted.current = true;
    }
  }, [dynamicDocs]);

  //make all files not deletable if the offer has been made
  useEffect(() => {
    if (offerMade) {
      disableRemovableDoc(dynamicDocs);
    }
  }, [offerMade]);

  return (
    <div className={'ForhandlingerSvarAktorSide'}>
      {props.leveranseFristDatoUtc && (
        <span className="document-delivery-page-deadline">
          <b>Leveransefrist:</b> {shortDateString(props.leveranseFristDatoUtc)}
        </span>
      )}
      {props.tittel && (
        <div className="document-delivery-page-title-wrapper">
          <Text component="h1" variant="list-title">
            {props.tittel}
          </Text>
          {offerRecinded && <span className="recind-label">Trukket</span>}
        </div>
      )}
      {props.sideLenker && <PageLinkMenu pageLinks={props.sideLenker} styles="-bottom-margin" />}

      <SvarDokumentSkjema
        oppdaterFilStatusUrl={props.oppdaterFilStatusUrl}
        offerRecinded={offerRecinded}
        skjemaDeler={dynamicDocs}
        setDynamicDocs={setDynamicDocs}
        slettVedleggUrl={props.slettVedleggUrl}
        editVedleggUrl={'asd'}
        invalidDocs={invalidDocs}
        supportMultipleVedlegg={true}
        fileListCols={[SortColumns.date, SortColumns.document, SortColumns.status, SortColumns.version, SortColumns.required]}
      />
      <AndreDokumentSkjema
        oppdaterFilStatusUrl={props.oppdaterFilStatusUrl}
        andreDokumenter={props.andreDokumenter}
        lastOppAndreDokumenterUrl={props.lastOppAndreDokumenterUrl}
        slettVedleggUrl={props.slettVedleggUrl}
        uploadField={true}
        readOnly={true}
        disableDocRemoval={offerRecinded || offerMade}
        disableUpload={offerRecinded}
      />
      <Modal title={'Tilbud innsendt'} clickOutsideToClose={true} open={offerModal} onClose={() => setOfferModal(false)}>
        <p>
          Tilbudet er nå sendt inn. <br></br>Du kan angre innsendelse for å gjøre endringer inntil leveranse fristen er passert.
        </p>
      </Modal>
      <DownloadAllFiles downloadUrl={props.lastNedAlleDokumenterUrl} />
      <FormSubmitBox
        heading="Send inn tilbud"
        bodyText="Tilbudet vil bli sendt til Statsbygg for videre behandling."
        buttonText={'Send inn tilbud'}
        regretButtonText={'Angre'}
        dokumenterPublisert={offerMade}
        disabled={!hasBeenRead || (!props.angreTilbudUrl && offerMade == true) || offerRecinded}
        submitUrl={!offerMade ? props.sendTilbudUrl : props.angreTilbudUrl}
        submitFunc={(url) => {
          sendOffer(url);
        }}
        regretFunc={(url) => {
          regretOffer(url);
        }}
        error={error}
      >
        <Text variant="mono-16">For å gå videre med ditt tilbud må du godta følgende:</Text>
        <Checkbox
          name="godkjent"
          label="Vi bekrefter at vi har lest og forstått dokumentene i konkurransegrunnlaget og leverer tilbud iht. til dette."
          checked={hasBeenRead || props.tilbudSendt}
          disabled={offerMade || offerRecinded}
          onChange={(e) => {
            setHasBeenRead(!hasBeenRead);
          }}
        />
      </FormSubmitBox>
      <RecindOfferBox
        prohibitRecind={!props.trekkeTilbudUrl || offerRecinded}
        recindOffer={() => recindOffer(props.trekkeTilbudUrl)}
      />
    </div>
  );
};

export default ForhandlingerSvarAktorSide;
