export type OrgNrSearchResponse = {
  readonly organisasjonResponse: OrgDTO[];
  readonly status: string;
};

export type OrgDTO = {
  readonly organisasjonsnummer: string;
  readonly navn: string;
};

export function getProffLink(orgNr?: string) {
  return (orgNr?.length ?? 0) > 0
    ? 'https://proff.no/bransjes%C3%B8k?q=' + orgNr
    : '';
}

export function getBrregLink(orgNr?: string) {
  return (orgNr?.length ?? 0) > 0
    ? 'https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=' + orgNr
    : '';
}
