import ErrorMessage, { IErrorMessage } from 'components/ErrorMessage/ErrorMessage';
import React from 'react';

export interface ErrorProps {
  msg: string;
  targetKey?: string;
  percentageTopPos?: number;
  percentageRightPos?: number;
}

const AnchoredErrorMessage = (props: ErrorProps) => {
  return (
    <div
      style={{
        top: props.percentageTopPos + '%',
        right: props.percentageRightPos + '%'
      }}
      className="error-msg"
    >
      <ErrorMessage feedback={props.msg} feedbackMargin={true} />
    </div>
  );
};

export default AnchoredErrorMessage;
