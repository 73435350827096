import Modal from 'components/Modal/Modal';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import React, { FC, useState } from 'react';

interface RecindOfferBox {
  prohibitRecind: boolean;
  recindOffer: () => Promise<void>;
}

const RecindOfferBox: FC<RecindOfferBox> = ({ prohibitRecind, recindOffer }) => {
  const [recindModal, setRecindModal] = useState(false);

  return (
    <div className={`recind-offer ${prohibitRecind && '-disabled'}`}>
      <div className="recind-offer--text">
        <div className="font-paragraph-header">Ønsker du å trekke tilbudet?</div>
        <p className="font-paragraph">
          I løpet av prosessen vil det alltid være mulig å trekke tilbudet fra konkurransen. Da vil tilbudet formelt trekkes, men
          det vil bli lagret for innsyn og ettersyn i fremtiden.
        </p>
      </div>
      <button
        disabled={prohibitRecind}
        className="recind-offer--button -warning"
        onClick={() => {
          setRecindModal(true);
        }}
      >
        <IconReactIcons type={IconTypes.close}></IconReactIcons>
        Trekk tilbud
      </button>

      <Modal title={'Vil du trekke tilbudet ditt?'} open={recindModal} onClose={() => setRecindModal(false)}>
        <p>Trekker du tilbudet ditt kan du ikke angre deg, og tilbudet vil bli utelatt fra videre evaluering.</p>

        <div className="recind-offer--buttons">
          <button
            className="recind-offer--button -primary"
            onClick={async () => {
              await recindOffer();
              setRecindModal(false);
            }}
          >
            Trekk tilbud
          </button>

          <button
            className="recind-offer--button -secondary"
            onClick={() => {
              setRecindModal(false);
            }}
          >
            Avbryt
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default RecindOfferBox;
