import React, { useState } from 'react';
import Text from 'components/Text/Text';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import * as api from 'js/api-helper';
import Field from 'components/Field/Field';
import { EierDataOrganisasjon } from 'types/EierDataOrganisasjon';
import { getBrregLink, getProffLink, OrgDTO, OrgNrSearchResponse } from './common';
import type { Matrikkel } from 'types/Matrikkel';
import type { LinkItem } from 'types/LinkItem';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';

interface EierDataBlockProps {
  onSetOrg: (val: EierDataOrganisasjon) => void;
  matrikkel?: Matrikkel;
  kartverketLink?: LinkItem;
  oppdaterEierUrl?: string;
  organisasjon?: EierDataOrganisasjon;
}

const EierDataBlock: React.FC<EierDataBlockProps> = ({ ...props }) => {
  const [searching, setSearching] = useState<boolean>(false);
  const [orgIndex, setOrgIndex] = useState<Array<OrgDTO>>([]);
  const [org, setOrg] = useState<EierDataOrganisasjon>({
    ...props.organisasjon
  });

  async function handleSearch(value: string) {
    if (!value || value.length < 3) {
      setSearching(false);
      return [];
    }

    try {
      setSearching(true);
      const response: OrgNrSearchResponse = await api.get(`/api/v1/data/OrganisasjonsvelgerSok/${value}?withOrgnrInNavn=false`);
      setSearching(false);
      if (!response.organisasjonResponse) {
        setOrgIndex([]);
        return [];
      }
      setOrgIndex(response.organisasjonResponse);
      return response.organisasjonResponse.map((e) => ({
        label: e.navn,
        value: e.organisasjonsnummer
      }));
    } catch (e) {
      return [];
    }
  }

  function handleChange(orgNr?: string) {
    if (!orgNr) {
      setOrg({});
      props.onSetOrg({});
      return;
    }
    const org = orgIndex.find((p) => p.organisasjonsnummer == orgNr);
    if (!org) return;
    const newValue = {
      orgNr: org.organisasjonsnummer,
      navn: org.navn
    } as EierDataOrganisasjon;
    setOrg(newValue);
    props.onSetOrg(newValue);
  }

  return (
    <div className="EierDataBlock">
      <Text className="EierLabel" variant="mono-16">
        Matrikkelinfo
      </Text>
      <div className="Org-data">
        <Text component="p" variant="paragraph-header">
          {props.matrikkel?.matrikkelIdentitet}
        </Text>
        <Text component="p">Adresse: {props.matrikkel?.adressetekst}</Text>
      </div>
      <div className="Org-links">
        <Text
          component="a"
          variant="download-link"
          target={'_blank'}
          title={'Åpne hjemmelshavers kartverket.no-side i en ny fane'}
          href={props.kartverketLink?.url}
        >
          {props.kartverketLink?.text} <IconReactIcons type={IconTypes.openInNew} size={1} />
        </Text>
      </div>
      <div className="Org-edit">
        <AutoCompleteField
          label="Organisasjonsnummer:"
          validationState={searching ? 'loading' : null}
          onSearch={handleSearch}
          onChange={handleChange}
          placeholder={'Søk på org.nr. eller navn'}
          initialValue={org?.orgNr ?? ''}
          formatOptionLabel={(opt, context) => {
            return context.context === 'menu'
              ? `${opt.label} (${opt.value})`
              : orgIndex.find((o) => o.navn === opt.value)?.organisasjonsnummer ?? '';
          }}
        />
        <Field label="Hjemmelshaver:" readOnly={true} value={org?.navn ?? '-'} />
      </div>
      {(org?.orgNr?.length ?? 0) > 0 && (
        <div className="Org-links">
          <Text component="a" variant="download-link" target={'_blank'} href={getBrregLink(org.orgNr)}>
            Les mer på brreg.no <IconReactIcons type={IconTypes.openInNew} size={1} />
          </Text>
          <Text component="a" variant="download-link" target={'_blank'} href={getProffLink(org.orgNr)}>
            Les mer på proff.no <IconReactIcons type={IconTypes.openInNew} size={1} />
          </Text>
        </div>
      )}
    </div>
  );
};

export default EierDataBlock;
