import { upload, ValidationError } from 'js/api-helper';
import React, { useCallback, useState } from 'react';
import { IconTypes, getIcon } from 'components/Icon/IconReactIcons';

const acceptedFileTypes = {
  document: '.pdf, .docx',
  documentAndImages: '.pdf, .docx, .png, .jpg, .jpeg',
  image: '.png, .jpg, .jpeg'
};

export type AcceptedFileTypes = keyof typeof acceptedFileTypes;

export interface File {
  readonly id: string;
  readonly nedlastingslenke: string;
  readonly visningsnavn: string;
}

export interface Props {
  readonly fileType: keyof typeof acceptedFileTypes;
  readonly destinationUrl: string;
  readonly onUploadComplete?: (file: File) => void;
  readonly multiple?: boolean;
  readonly styles?: string;
}

export function fileTypes(fileType: AcceptedFileTypes): string {
  return acceptedFileTypes[fileType];
}

export const Styles = {
  ButtonAsLink: 'buttonAsLink'
};

export default function UploadButton({ fileType, destinationUrl, onUploadComplete, multiple = false, styles }: Props) {
  const [isBusy, setBusy] = useState(false);
  const [uploadFailedMessage, setUploadFailedMessage] = useState<string>();

  const handleUpload = useCallback(
    async (files: globalThis.File[]) => {
      setBusy(true);
      setUploadFailedMessage(undefined);
      try {
        for (const file of files) {
          const data = new FormData();
          data.append('file', file);
          try {
            const result = await upload(destinationUrl, data);
            onUploadComplete?.(result);
          } catch (error) {
            if (error instanceof ValidationError) {
              setUploadFailedMessage(error['file']);
              return;
            }
          }
        }
      } finally {
        // There is a warning in the devtools because of this line; ignore it.
        // If this component is unmounted because of the onUploadComplete call above,
        // then it's too late to update the state here. It warns that it's a memory leak,
        // but it's not really, so nothing to worry about.
        setBusy(false);
      }
    },
    [destinationUrl, onUploadComplete]
  );
  if (styles !== Styles.ButtonAsLink) {
    return (
      <label className="UploadButton">
        {uploadFailedMessage && (
          <div className="UploadButton-failed">
            <span>{uploadFailedMessage}</span>
          </div>
        )}
        {isBusy ? (
          getIcon(IconTypes.spinner).Component
        ) : (
          <>
            {getIcon(IconTypes.plus).Component}
            <input
              multiple={multiple}
              type="file"
              accept={fileTypes(fileType)}
              onChange={(e) => handleUpload(Array.from(e.target.files ?? []))}
            />
          </>
        )}
      </label>
    );
  } else {
    return (
      <label className={styles}>
        {getIcon(IconTypes.upload).Component}
        <input
          multiple={multiple}
          type="file"
          accept={fileTypes(fileType)}
          onChange={(e) => handleUpload(Array.from(e.target.files ?? []))}
        />
        Last opp fil
      </label>
    );
  }
}
