import React from 'react';
import classnames from 'classnames';
import styles from './Text.module.css';

export type VariantTypes =
  | 'menu-main'
  | 'menu-sub'
  | 'tag'
  | 'tag-bold'
  | 'article-h1'
  | 'article-h2'
  | 'article-h3'
  | 'article-ingress-1-2'
  | 'article-ingress-3-4'
  | 'project-h1'
  | 'byline'
  | 'byline-bold'
  | 'paragraph-header'
  | 'paragraph'
  | 'list-title'
  | 'list-item'
  | 'list-item-bold'
  | 'table-header'
  | 'alt-text'
  | 'alt-text-bold'
  | 'download-link'
  | 'button'
  | 'contact-title'
  | 'publication-title'
  | 'article-title'
  | 'article-ingress'
  | 'article-quote'
  | 'article-author'
  | 'news-title'
  | 'news-ingress'
  | 'news-paragraph'
  | 'property-title'
  | 'property-ingress'
  | 'paragraph-mono'
  | 'mono-16'
  | 'panel-heading'
  | 'annonse-grid-title'
  | 'overskrift-mengdetekst'
  | 'meny-hovedpunkt';

interface IText {
  variant: VariantTypes;
  className?: string;
  noMargin?: boolean;
  center?: boolean;
  component?: string;
  children?: React.ReactNode;
}

const Component = (props: IText): JSX.Element => {
  const { component, variant, children } = props;
  if (component) return React.createElement(component, props, children);
  else {
    switch (variant) {
      case 'article-h1':
      case 'contact-title':
      case 'article-title':
      case 'news-title':
      case 'property-title': {
        return React.createElement('h1', props, children);
      }
      case 'article-h2': {
        return React.createElement('h2', props, children);
      }
      case 'article-h3': {
        return React.createElement('h3', props, children);
      }
      default: {
        return React.createElement('p', props, children);
      }
    }
  }
};

const Text = ({
  component,
  variant,
  center,
  className,
  noMargin,
  children,
  ...rest
}: IText) => {
  return (
    <Component
      component={component}
      variant={variant}
      className={classnames(
        className,
        styles[`font-${variant}`],
        styles.general,
        {
          [styles.center]: center,
          [styles.noMargin]: noMargin
        }
      )}
      {...rest}
    >
      {children}
    </Component>
  );
};

Text.defaultProps = {
  variant: 'paragraph'
};

export default Text;
