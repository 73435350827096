import Heading from 'components/Heading/Heading';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import Checkbox from 'components/Checkbox/Checkbox';
import { post } from 'js/api-helper';
import { useForm } from 'js/hooks/useForm';
import React, { FC, useState } from 'react';
import { EierData } from 'types/EierData';
import { LinkItem } from 'types/LinkItem';
import Text from 'components/Text/Text';
import EierDataBlock from 'components/EierDataBlock';
import { EierDataOrganisasjon } from 'types/EierDataOrganisasjon';
import Modal from 'components/Modal/Modal';

export interface OkonomiSide {
  readonly title: string;
  readonly pageLinks: LinkItem[];
  readonly lagreEvalueringUrl: string;
  readonly eierData: EierData;
  readonly evaluering: {
    okonomi: boolean;
    eierskap: boolean;
    kommentar: string;
  };
  readonly erTrukket: boolean;
}

const OkonomiSide: FC<OkonomiSide> = ({ ...props }) => {
  const { onChange, onSubmit, values } = useForm(formSubmitCallback, props.evaluering);
  const [loading, setLoading] = useState(false);
  const [formSubmitResultModalText, setFormSubmitResultModalText] = useState('');
  const [org, setOrg] = useState<EierDataOrganisasjon>({
    ...props.eierData.organisasjon
  });

  // a submit function that will execute upon form submission.
  // attaches active form to payload.
  async function formSubmitCallback() {
    setFormSubmitResultModalText('');
    if (!props?.eierData?.oppdaterEierUrl) {
      console.error('props.eierData.oppdaterEierUrl is empty');
      return;
    }
    if (!props?.lagreEvalueringUrl) {
      console.error('props.lagreEvalueringUrl is empty');
      return;
    }
    setLoading(true);
    try {
      await post(props.lagreEvalueringUrl, values);
      await post(props.eierData.oppdaterEierUrl, org);
      setFormSubmitResultModalText('Evalueringen er nå lagret.');
    } catch (error) {
      console.error(error);
      setFormSubmitResultModalText('En feil oppstod ved lagring av evalueringen.');
    }
    setLoading(false);
  }

  return (
    <>
      <Heading>
        {props.title}
        {props.erTrukket && <span className="recindLabel">Trukket</span>}
      </Heading>
      <PageLinkMenu pageLinks={props.pageLinks} />
      <EierDataBlock {...props.eierData} onSetOrg={(org) => setOrg(org)} />
      <form className="OkonomiEvaluering" onSubmit={onSubmit}>
        <h2>Evaluering og eierskap</h2>
        <Text variant="mono-16">Vurdering</Text>
        <Checkbox
          className="Okonomi-row font-paragraph"
          name="okonomi"
          label="Økonomi"
          checked={values.okonomi}
          onChange={() => {
            onChange({ okonomi: !values.okonomi });
          }}
        ></Checkbox>
        <Checkbox
          className="Okonomi-row font-paragraph"
          name="eierskap"
          label="Eierskap"
          checked={values.eierskap}
          onChange={() => {
            onChange({ eierskap: !values.eierskap });
          }}
        ></Checkbox>
        <Field
          maxCharacters={500}
          className="CommentArea"
          label="Kommentar"
          type="textarea"
          value={values.kommentar ?? ''}
          onChange={(e) => onChange({ kommentar: e.target.value })}
        />
        <p className="max-char-number">
          {values.kommentar?.length ?? 0}/{500}
        </p>
      </form>
      <div className="OkonomiEvaluering__footer">
        <Button submit={true} onClick={onSubmit} loading={loading}>
          Lagre evaluering
        </Button>
      </div>
      <Modal
        open={(formSubmitResultModalText?.length ?? 0) > 1}
        title={'økonomi og eierskap'}
        onClose={() => setFormSubmitResultModalText('')}
      >
        <p>{formSubmitResultModalText}</p>
      </Modal>
    </>
  );
};

export default OkonomiSide;
