/* eslint-disable react/display-name */
import Panel from 'components/Panel/Panel';
import Text from 'components/Text/Text';
import React from 'react';
import style from '../SvarDetalj.module.css';

interface ISvarDetaljUtleierPanel {
  readonly utleierNavn: string;
  readonly utleier: string;
  readonly utleiemeglerNavn: string;
  readonly utleiemegler: string;
}
export default function SvarDetaljUtleierPanel({
  utleierNavn,
  utleier,
  utleiemeglerNavn,
  utleiemegler
}: ISvarDetaljUtleierPanel) {
  return (
    <Panel type="border" className={style.Utleier}>
      <Text variant="annonse-grid-title" component="h2">
        Utleier
      </Text>
      <Text variant="download-link">{utleierNavn}</Text>
      <Text variant="download-link">{utleier}</Text>
      <Text variant="annonse-grid-title" component="h2">
        Utleiemegler
      </Text>
      <Text variant="download-link">{utleiemeglerNavn}</Text>
      <Text variant="download-link">{utleiemegler}</Text>
    </Panel>
  );
}
