import Popup from 'components/Popup/Popup';
import Panel from 'components/Panel/Panel';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import React, { FC, useState } from 'react';
import { LogEntryUrls } from 'source/pages/HendelsesLoggSide/HendelsesLoggSide';
import { FaseHendelse } from 'types/FaseHendelse';
import { MailHendelse } from 'types/MailHendelse';
import { Hendelse } from 'types/Hendelse';
import LogEntryForm from './LogEntryForm/LogEntryForm';
import LoggedEventRow from './LoggedEventRow/LoggedEventRow';
import useArrayState from '@clave/use-array-state';

export interface EventLog {
  readonly hendelser: (Hendelse | MailHendelse | FaseHendelse)[];
  readonly nyeOppforingerUrlListe: LogEntryUrls;
  readonly leggTilVedleggUrl: string;
  readonly slettFilUrl: string;
  readonly viskUtHendelseUrl: string;
  readonly id: string;
  readonly rolle: string;
}

const sortByUtc = (events: any[]) => {
  return events.sort((a, b) => {
    return a.handlingUtc < b.handlingUtc ? 1 : -1;
  });
};

const EventLog: FC<EventLog> = ({
  hendelser,
  nyeOppforingerUrlListe,
  leggTilVedleggUrl,
  slettFilUrl,
  viskUtHendelseUrl,
  id,
  rolle
}) => {
  const [hendelsesListe, setHendelser] = useArrayState(sortByUtc(hendelser));
  const [modal, setModal] = useState(false);

  const appendData = (data) => {
    const newData = sortByUtc([...hendelsesListe, data]);
    setHendelser(newData);
    setModal((prevState) => !prevState);
  };

  const updateStrikeThrough = (eventId, newVal) => {
    const idxToUpdate = hendelsesListe.findIndex((x) => x.eventId === eventId);
    const target = hendelsesListe[idxToUpdate];
    target.strikeThrough = newVal;
    setHendelser.replaceAt(idxToUpdate, target);
  };

  return (
    <>
      {/* Change this with modal component when 558 is merged to develop */}
      <Popup isOpen={modal} clickOutsideToClose={true} close={() => setModal((prevState) => !prevState)}>
        <Panel>
          <LogEntryForm
            onSubmit={(data) => appendData(data)}
            defaultChoice={{ label: 'Generell', value: 1 }}
            choices={[
              { label: 'Generell', value: 1 },
              { label: 'Epost', value: 2 },
              { label: 'Start fase', value: 3 },
              { label: 'Avslutt fase', value: 4 }
            ]}
            vedlegg={[]}
            nyeOppforingerUrlListe={nyeOppforingerUrlListe}
            leggTilVedleggUrl={leggTilVedleggUrl}
            slettFilUrl={slettFilUrl}
            id={id}
          />
        </Panel>
      </Popup>
      <div className="EventLogHeader">
        <p>Opprettet</p>
        <p>Hendelse</p>
        <p>Kjennetegn</p>
        <p>Aktør</p>
      </div>
      {rolle == 'PL' && (
        <button
          className="AddLoggedEvent"
          onClick={() => {
            setModal((prevState) => !prevState);
          }}
        >
          <p>Legg til loggføring</p>
          <div className="add-icon">
            <IconReactIcons type={IconTypes.plus} />
          </div>
        </button>
      )}
      <ul className="EventLog">
        {hendelsesListe &&
          hendelsesListe.map((event) => (
            <LoggedEventRow
              updateStrikeThrough={(id, newVal) => updateStrikeThrough(id, newVal)}
              viskUtHendelseUrl={viskUtHendelseUrl}
              key={event.eventId}
              {...event}
            />
          ))}
      </ul>
    </>
  );
};

export default EventLog;
