import React, { useEffect, useMemo, useRef, useState } from 'react';
import Button from 'components/Button/Button';
import { IArcGisKart } from './ArcGis';
import ArcGisCustomToolbar from './ArcGisCustomToolbar';
import style from './ArcGisKart.module.css';
import ArcGisLayerToolbar from './ArcGisLayerToolbar';
import ArcGisMapSelectToolbar from './ArcGisMapSelectToolbar';
import ArcGisSearchToolbar from './ArcGisSearchToolbar';
import ArcGisServiceAreaToolbar from './ArcGisServiceAreaToolbar';
import { useArcGisStorage } from './hooks/useArcGisStorage';
import { useGetLayers } from './hooks/useGetLayers';
import { useMapLock } from './hooks/useMapLock';
import { useParentSize } from './hooks/useParentSize';
import { useServiceArea } from './hooks/useServiceArea';
import { useSketch } from './hooks/useSketch';
import ServiceAreaDistanceFields from './ServiceAreaDistanceFields';
import { FaBus, FaEye, FaTrain, FaTram } from 'react-icons/fa';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { AiOutlineSync } from 'react-icons/ai';
import CustomIconButton from './CustomIconButton';
import { getWalkDriveIcon } from './arcGis.helper';
import useBeforeUnload from 'js/hooks/use-before-unload';

// { latestWkid: 3857, wkid: 102100 }
export const spatialReferenceWGS84 = { wkt: 'WGS84', wkid: 4326 };

const ArcGisKart = ({
  readOnly,
  noPadding,
  markers,
  serviceAreas,
  sketches,
  center,
  zoom,
  saveEndpoint,
  toggleFullscreen,
  hideCustomToolbar = false,
  initialZoomDisabled = false,
  lib
}: IArcGisKart) => {
  const token = sessionStorage.getItem('geodata_token');
  const selectMapRef = useRef<HTMLButtonElement>(null);
  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    message: ''
  });
  const [isDirty, setIsDirty] = useState(false);

  const {
    setServiceAreaPoint,
    deleteAllServiceAreas,
    travelModeState,
    handleSetTravelMode,
    undoLastServiceArea,
    redoLastServiceArea
  } = useServiceArea(lib);

  const {
    allLayers,
    sketchGraphicLayer,
    markersGraphicLayer,
    walkServiceAreaLayer,
    driveServiceAreaLayer,
    togLayer,
    trikkLayer,
    busLayer,
    markersServiceAreaWalkLayer,
    markersServiceAreaDriveLayer
  } = useGetLayers({
    initialMarkers: markers,
    initialServiceAreas: serviceAreas,
    initialSketches: sketches,
    setServiceAreaPoint,
    lib
  });

  useBeforeUnload(isDirty);

  const mapRef = useRef<HTMLDivElement>(null);

  const layerToolbarRef = useRef<HTMLDivElement>(null);
  const sketchToolbarRef = useRef<HTMLDivElement>(null);
  const serviceAreaToolbarRef = useRef<HTMLDivElement>(null);
  const customToolbarRef = useRef<HTMLDivElement>(null);

  const { view } = useMemo(() => {
    if (mapRef?.current) {
      // eslint-disable-next-line
      // @ts-expect-error
      const map = new lib.Map({
        basemap: 'arcgis-light-gray',
        layers: [...allLayers.filter((l) => l)] // remove null values
      });
      // eslint-disable-next-line
      // @ts-expect-error
      const view = new lib.MapView({
        map,
        constraints: {
          rotationEnabled: false
        },
        center: typeof center === 'string' ? JSON.parse(center) : center, //Longitude, latitude
        zoom: zoom, // Zoom level
        // component: mapRef,
        container: 'viewDiv',
        ui: {
          components: ['attribution'] // empty the UI, except for attribution
        }
      });

      return { view, map };
    } else return { view: null, map: null };
  }, [center, zoom, mapRef?.current, sketchGraphicLayer, markersGraphicLayer, walkServiceAreaLayer, driveServiceAreaLayer]);

  useSketch({ sketchGraphicLayer, view, readOnly, sketchToolbarRef, lib });

  const { saveData } = useArcGisStorage({
    setIsDirty,
    setSaveStatus,
    view,
    walkServiceAreaLayer,
    driveServiceAreaLayer,
    sketchGraphicLayer,
    saveEndpoint
  });

  const [mapIsLocked, toggleMapLock] = useMapLock(view, initialZoomDisabled);

  const { parentRef, sizes } = useParentSize();

  const handleClick = async (event) => {
    // console.log(event);
    setIsDirty(true);
    if (
      travelModeState.type !== 0 &&
      typeof travelModeState.type !== 'undefined' &&
      typeof travelModeState.walkMeters !== 'undefined' &&
      typeof travelModeState.driveMinutes !== 'undefined'
    ) {
      setServiceAreaPoint({
        point: event.mapPoint,
        travelMode: {
          type: travelModeState.type,
          distance: travelModeState.type === 1 ? travelModeState.driveMinutes : travelModeState.walkMeters
        },
        layer: travelModeState.type === 1 ? driveServiceAreaLayer : walkServiceAreaLayer
      });
    }
  };

  // trigger this update on click handler everytime some important state changes
  useEffect(() => {
    let clickHandler;
    if (view && !readOnly) {
      clickHandler = view.on('click', handleClick);
    }
    return () => {
      clickHandler && clickHandler.remove();
    };
  }, [view, readOnly, travelModeState]);

  const [viewLoaded, setViewLoaded] = useState(false);
  // Set up map after view loads
  React.useEffect(() => {
    let bottomLeftPanel;
    if (view) {
      view
        .when(() => {
          setViewLoaded(true);
          if (!readOnly && sketchToolbarRef.current) {
            // setSketch(newSketch);
            view.ui.add(sketchToolbarRef.current, 'top-right');

            if (sketchToolbarRef.current) {
              setTimeout(() => {
                const sketchSections = sketchToolbarRef.current?.childNodes[0];
                if (sketchSections) {
                  const sketchSection = sketchSections.childNodes[3];
                  if (sketchSection) {
                    const button = document.getElementById('toggle-sketch-button');
                    if (button) {
                      button.style.display = 'block';
                      sketchSection.appendChild(button);
                    }
                  }
                }
              }, 2000);
            }
          }
          if (selectMapRef?.current) {
            view.ui.add(selectMapRef.current, 'top-left');
          }
          if (!hideCustomToolbar) {
            if (customToolbarRef?.current) {
              view.ui.add(customToolbarRef.current, 'bottom-right');
            }
            if (serviceAreaToolbarRef?.current) {
              view.ui.add(serviceAreaToolbarRef.current, 'top-right');
            }
            if (layerToolbarRef?.current) {
              view.ui.add(layerToolbarRef.current, 'bottom-left');
            }
          }
        })
        .catch(function (error) {
          if (error.name.includes('webgl')) {
            if (mapRef?.current) {
              mapRef.current.innerHTML = error.message;

              if (customToolbarRef?.current) {
                customToolbarRef.current.style.display = 'none';
              }
            }
          }
        });
    }
    return () => {
      bottomLeftPanel && bottomLeftPanel.remove();
    };
  }, [view]);

  useEffect(() => {
    if (!sketchToolbarRef.current) return;
    setTimeout(() => {
      const sketchSections = sketchToolbarRef.current?.childNodes[0];
      if (sketchSections) {
        const sketchSection = sketchSections.childNodes[3];
        if (sketchSection) {
          const button = document.getElementById('toggle-sketch-button');
          if (button) {
            button.style.display = 'block';
            sketchSection.appendChild(button);
          }
        }
      }
    }, 2000);
  }, [sketchToolbarRef]);

  return (
    <div ref={parentRef} className={style.wrapper} style={noPadding ? { padding: 0 } : {}}>
      {toggleFullscreen && (
        <button className={style.expandButton} onClick={toggleFullscreen}>
          <BsBoxArrowUpRight className={style.expandIcon} />
        </button>
      )}
      {!readOnly && viewLoaded && token && (
        <div className={style.topBar}>
          <div>
            <ArcGisSearchToolbar markersGraphicLayer={markersGraphicLayer} lib={lib} view={view} />
          </div>
          <div>
            <ServiceAreaDistanceFields handleSetTravelMode={handleSetTravelMode} travelModeState={travelModeState} />
          </div>
        </div>
      )}

      <div id="toggle-sketch-button" style={{ display: 'none' }}>
        <div className={'esri-custom-section'}>
          <CustomIconButton
            title="Se/skjul søkeområde"
            onClick={() => (sketchGraphicLayer.visible = !sketchGraphicLayer.visible)}
            isToggled={sketchGraphicLayer.visible}
          >
            <FaEye />
          </CustomIconButton>
        </div>
      </div>

      <div
        style={
          travelModeState.type !== 0
            ? {
                cursor: `url(${getWalkDriveIcon(travelModeState.type || 0)}), auto`,
                ...sizes
              }
            : { ...sizes }
        }
        id={'viewDiv'}
        ref={mapRef}
      />
      <div id="webglNotSupportedDiv" />

      <ArcGisMapSelectToolbar lib={lib} ref={selectMapRef} view={view} />

      {!hideCustomToolbar && viewLoaded && (
        <>
          {token && (
            <>
              <ArcGisLayerToolbar
                ref={layerToolbarRef}
                layers={[
                  {
                    title: 'Vis/skjul tog',
                    layer: togLayer,
                    icon: <FaTrain />
                  },
                  {
                    title: 'Vis/skjul trikk',
                    layer: trikkLayer,
                    icon: <FaTram />
                  },
                  { title: 'Vis/skjul buss', layer: busLayer, icon: <FaBus /> }
                ]}
              />
              <ArcGisServiceAreaToolbar
                markers={markers}
                readOnly={!!readOnly}
                markersServiceAreaWalkLayer={markersServiceAreaWalkLayer}
                markersServiceAreaDriveLayer={markersServiceAreaDriveLayer}
                undoLastServiceArea={undoLastServiceArea}
                redoLastServiceArea={redoLastServiceArea}
                walkServiceAreaLayer={walkServiceAreaLayer}
                driveServiceAreaLayer={driveServiceAreaLayer}
                ref={serviceAreaToolbarRef}
                view={view}
                handleSetTravelMode={handleSetTravelMode}
                deleteAllServiceAreas={() => {
                  deleteAllServiceAreas(walkServiceAreaLayer);
                  deleteAllServiceAreas(driveServiceAreaLayer);
                }}
                travelModeState={travelModeState}
              />
            </>
          )}
          <ArcGisCustomToolbar
            ref={customToolbarRef}
            view={view}
            center={center}
            toggleMapLock={toggleMapLock}
            mapIsLocked={mapIsLocked}
          />
        </>
      )}
      <div id={'sketch-wrapper'} ref={sketchToolbarRef} />
      {!readOnly && viewLoaded && (
        <>
          <div className={style.bottomBar}>
            <div className={style.saveMapWrapper}>
              <Button primary disabled={saveStatus?.loading} onClick={saveData}>
                Lagre kart
              </Button>
            </div>
            <div>
              {!!saveStatus?.message && !saveStatus?.loading && <p className={style.saveStatus}>{saveStatus.message}</p>}
              {!!saveStatus?.loading && <AiOutlineSync className="spin" />}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

ArcGisKart.defaultProps = {
  readOnly: false,
  center: '[10.74663, 59.91592]', // [59.9, 10.7],
  zoom: 12
};

export default ArcGisKart;
