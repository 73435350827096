import React from 'react';
import classnames from 'classnames';
import styles from './ErrorMessage.module.css';
import Icon from '../Icon/Icon';

/**
 * Error-melding og error-icon
 */

export interface IErrorMessage {
  feedback?: React.ReactNode;
  feedbackMargin?: boolean;
}

const ErrorMessage = ({ feedback, feedbackMargin }: IErrorMessage) => {
  return (
    <div className={classnames(styles.error, !feedbackMargin)}>
      {feedback && <Icon icon={'alert'} red={true} size="2" />}
      <span className={classnames(styles.feedback)}>{feedback}</span>
    </div>
  );
};

export default ErrorMessage;
