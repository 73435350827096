import Paragraphs from 'components/Paragraphs/Paragraphs';
import Button from 'components/Button/Button';
import TextField from 'components/TextField/TextField';
import { post } from 'js/api-helper';
import React, { FC, useCallback, useState } from 'react';
import style from '../../pages/Svar/SvarDetalj/SvarDetalj.module.css';

export interface CommentBox {
  readonly visKommentar?: boolean;
  readonly visRedigerKommentar?: boolean;
  readonly kommenterUrl?: string;
  readonly overstyrtScoreHelhetsvurdering?: number;
  readonly internKommentar?: string;
}

const CommentBox: FC<CommentBox> = ({ internKommentar, visKommentar, visRedigerKommentar, kommenterUrl }) => {
  const [kommentar, setKommentar] = useState(internKommentar);

  const lagreKommentar = useCallback(async () => {
    await post(kommenterUrl as string, { kommentar });
  }, [kommentar]);

  return (
    <>
      {visKommentar && (
        <div className={style.kommentarInputWrapper}>
          <h2>Kommentar til svar</h2>
          <Paragraphs textVariant="paragraph">{kommentar}</Paragraphs>
        </div>
      )}
      {!!visRedigerKommentar && !!kommenterUrl && (
        <div className={style.kommentarInputWrapper}>
          <h2>Kommentar til svar</h2>
          <TextField
            className={style.Kommentarfelt}
            placeholder="Skriv her"
            large={true}
            type="textarea"
            rows={7}
            value={kommentar}
            maxCharacters={1000}
            onChange={(e) => setKommentar(e.target.value)}
          />
          <Button disabled={!kommentar?.length} onClick={lagreKommentar}>
            Lagre kommentar
          </Button>
        </div>
      )}
    </>
  );
};

export default CommentBox;
