import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import './DynamicFileList.scss';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import { Fildata } from 'types/Fildata';
import { shortDateTimeString } from 'js/date-helper';
import { KonkurranseGrunnlagFil } from 'types/KonkurranseGrunnlagFil';
import {
  DropdownMenuTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator
} from 'components/DropdownMenu';
import { cleanPost } from 'js/api-helper';

interface DynamicFileListProps {
  files: KonkurranseGrunnlagFil[];
  onRemove: (id: string) => void;
  onEdit?: (id: string) => void;
  readOnly?: boolean;
  columns: Array<object>;
  bottomMargin?: boolean;
  readonly oppdaterFilStatusUrl: string;
}

enum sortOptions {
  dateAscending = 'dateAsc',
  dateDescending = 'dateDesc',
  documentAscending = 'documentAsc',
  documentDescending = 'documentDesc',
  statusAscending = 'statusAsc',
  statusDescending = 'statusDesc',
  versionAscending = 'versionAsc',
  versionDescending = 'versionDesc',
  requiredAscending = 'requiredAsc',
  requiredDescending = 'requiredDesc'
}

export const SortColumns = {
  date: ['Opprettet', sortOptions.dateAscending, sortOptions.dateDescending],
  document: ['Dokument', sortOptions.documentAscending, sortOptions.documentDescending],
  status: ['Status', sortOptions.statusAscending, sortOptions.statusDescending],
  version: ['Versjon', sortOptions.versionAscending, sortOptions.versionDescending],
  required: ['Obligatorisk', sortOptions.requiredAscending, sortOptions.requiredDescending]
};

const DynamicFileList = ({
  files,
  onRemove,
  onEdit,
  readOnly,
  columns,
  bottomMargin,
  oppdaterFilStatusUrl
}: DynamicFileListProps) => {
  const [sortOption, setSortOption] = useState('');
  const [orderedFiles, setOrderedFiles] = useState<KonkurranseGrunnlagFil[]>(files);

  const dateSort = (a: Fildata, b: Fildata) => {
    const dateA = new Date(a.opplastetDato ?? '');
    const dateB = new Date(b.opplastetDato ?? '');

    if (sortOption == sortOptions.dateDescending) {
      return dateA > dateB ? 1 : -1;
    } else if (sortOption == sortOptions.dateAscending) {
      return dateB > dateA ? 1 : -1;
    }
    return 0;
  };

  useEffect(() => {
    setOrderedFiles((prevState) => files);
  }, [files]);

  useEffect(() => {
    switch (sortOption) {
      case sortOptions.dateAscending:
        setOrderedFiles([...orderedFiles.sort(dateSort)]);
        break;
      case sortOptions.dateDescending:
        setOrderedFiles([...orderedFiles.sort(dateSort)]);
        break;
      case sortOptions.documentAscending:
        setOrderedFiles([
          ...orderedFiles.sort((a, b) => {
            return ('' + a.visningsnavn).localeCompare(b.visningsnavn);
          })
        ]);
        break;
      case sortOptions.documentDescending:
        setOrderedFiles([
          ...orderedFiles.sort((a, b) => {
            return ('' + b.visningsnavn).localeCompare(a.visningsnavn);
          })
        ]);
        break;
      case sortOptions.statusAscending:
        setOrderedFiles([...orderedFiles.sort((a, b) => ('' + a.status).localeCompare(b.status))]);
        break;
      case sortOptions.statusDescending:
        setOrderedFiles([...orderedFiles.sort((a, b) => ('' + b.status).localeCompare(a.status))]);
        break;
      case sortOptions.versionAscending:
        setOrderedFiles([...orderedFiles.sort((a, b) => a.version.localeCompare(b.visningsnavn))]);
        break;
      case sortOptions.versionDescending:
        setOrderedFiles([...orderedFiles.sort((a, b) => b.version.localeCompare(a.visningsnavn))]);
        break;
      case sortOptions.requiredAscending:
        setOrderedFiles([...orderedFiles.sort((a, b) => (a.required ? 1 : 0) - (b.required ? 1 : 0))]);
        break;
      case sortOptions.requiredDescending:
        setOrderedFiles([...orderedFiles.sort((a, b) => (b.required ? 1 : 0) - (a.required ? 1 : 0))]);
        break;
    }
  }, [sortOption]);

  async function renderLink(file: KonkurranseGrunnlagFil): Promise<void> {
    const a = document.createElement('a');
    a.href = file.nedlastingslenke;
    a.download;
    document.body.appendChild(a);
    a.click();
    a.remove();
    const targetfile = orderedFiles.find((x) => x.id === file.id);
    const successFullNotication =  await sendNotification(oppdaterFilStatusUrl, file);
    
    if (targetfile && successFullNotication) {
      targetfile.status = 'Nedlastet';

      setOrderedFiles((prevState) => [...prevState]);
      
    }
  }

  async function sendNotification(oppdaterFilStatusUrl: string, file: KonkurranseGrunnlagFil): Promise<boolean> {

    if(!oppdaterFilStatusUrl){
      return false
    }

    try {
      const res = await cleanPost(`${oppdaterFilStatusUrl}?filId=${file.id}`);

      if (res) {
        return true
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  const SortColumnsInclude = (key, Component) => {
    if (columns.find((x) => x[0] == key)) return Component;
  };

  if (!columns.find((x) => x[0] == SortColumns.date[0])) throw new Error('[Date: Array] is a required param for DynamicFileList');

  return (
    <div className={cn('dynamic-file-list', bottomMargin && '-bottom-margin')}>
      <div className="dynamic-file-list__filters">
        {columns.map((x) => (
          <SortOption cols={x} sortOption={sortOption} setSortOption={setSortOption} />
        ))}
      </div>
      <ul>
        {orderedFiles?.map((file) => (
          <li key={file.id}>
            {file.opplastetDato &&
              SortColumnsInclude(
                SortColumns.date[0],
                <div className="dynamic-file-list__date">{shortDateTimeString(file.opplastetDato)}</div>
              )}
            {file.visningsnavn &&
              SortColumnsInclude(
                SortColumns.document[0],
                <div className="dynamic-file-list__displayName">
                  <a onClick={() => renderLink(file)}>{file.visningsnavn}</a>
                </div>
              )}
            {SortColumnsInclude(SortColumns.status[0], <p>{file.status}</p>)}
            {SortColumnsInclude(SortColumns.version[0], <p>{file.version}</p>)}
            {SortColumnsInclude(SortColumns.required[0], <p>{file.required ? 'Ja' : 'Nei'}</p>)}
            {
              <DropdownMenu>
                <DropdownMenuTrigger iconType={IconTypes.dotsVertical} />
                <DropdownMenuContent>
                  <DropdownMenuItem onClick={() => renderLink(file)}>
                    <IconReactIcons type={IconTypes.download} />
                    Last ned
                  </DropdownMenuItem>
                  {!readOnly && (
                    <DropdownMenuItem onClick={() => onEdit?.(file.id)}>
                      <IconReactIcons type={IconTypes.edit} />
                      Rediger
                    </DropdownMenuItem>
                  )}
                  <DropdownMenuSeparator />
                  {file.slettbar && (
                    <DropdownMenuItem onClick={() => onRemove(file.id)}>
                      <IconReactIcons type={IconTypes.trash} />
                      Slett dokument
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            }
          </li>
        ))}
      </ul>
    </div>
  );
};

const SortOption = ({ cols, sortOption, setSortOption }) => {
  const colName = cols[0];
  const colAsc = cols[1];
  const colDesc = cols[2];

  return (
    <div>
      <button
        onClick={() => {
          if (sortOption === colDesc) {
            setSortOption(colAsc);
          } else {
            setSortOption(colDesc);
          }
        }}
      >
        <SortOptionLabel title={colName} activeOption={sortOption} conditionLabels={[colAsc, colDesc]} />
      </button>
    </div>
  );
};

const SortOptionLabel = ({ title, activeOption, conditionLabels }) => {
  const optionA = conditionLabels[0];
  const optionB = conditionLabels[1];

  return (
    <>
      {title}
      {activeOption === optionB && <IconReactIcons type={IconTypes.sortDown}></IconReactIcons>}
      {activeOption === optionA && <IconReactIcons type={IconTypes.sortUp}></IconReactIcons>}
      {activeOption !== optionB && activeOption !== optionA && <IconReactIcons type={IconTypes.unsorted}></IconReactIcons>}
    </>
  );
};

export default DynamicFileList;
