import { useEffect } from 'react';

// https://developers.arcgis.com/javascript/latest/api-reference/esri-widgets-Sketch.html
export const useSketch = ({
  view,
  sketchGraphicLayer,
  readOnly,
  sketchToolbarRef,
  lib
}) => {
  useEffect(() => {
    if (view && sketchToolbarRef?.current) {
      view.when(() => {
        if (!readOnly) {
          new lib.Sketch({
            layer: sketchGraphicLayer,
            availableCreateTools: ['point', 'polyline', 'polygon'],
            visibleElements: {
              createTools: {
                point: false,
                circle: false,
                polyline: false
              },
              selectionTools: {
                'lasso-selection': false
              }
            },
            view: view,
            // graphic will be selected as soon as it is created
            creationMode: 'update',
            pointSymbol: {
              // viewModel
              type: 'simple-marker',
              color: 'white',
              size: 8
            },
            container: sketchToolbarRef?.current || 'sketch-wrapper'
          });
        }
      });
    }
  }, [view, sketchGraphicLayer, readOnly, sketchToolbarRef]);
};
