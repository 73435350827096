import React, { FC } from 'react';
import Text from 'components/Text/Text';
import type { TilbudtObjekt } from 'types/TilbudtObjekt';
import TriPie from 'components/TriPie/TriPie';

const mapColors = (p) => {
  if (p.erVurdert) {
    if (p.erAnbefalt && !p.erTrukket) {
      return 'green';
    } else if (p.erAnbefaltMedAnmerkning && !p.erTrukket) {
      return 'orange';
    } else {
      return 'red';
    }
  }

  if (p.erTrukket) return 'red';
};

const TilbudtObjektCard: FC<TilbudtObjekt> = (p) => {
  return (
    <a href={p.url} className={`TilbudtObjekt ${mapColors(p)} `}>
      <div className="TilbudtObjekt--image-wrapper">
        <img src={p.bildeKilde} />
        <div className="tongue">{p.bildeTekst}</div>
      </div>
      <div className="TilbudtObjekt--content">
        <Text className="TilbudtObjekt--content--top" variant={'annonse-grid-title'} component="h3">
          {p.tittel}
        </Text>
        <div className="TilbudtObjekt--content--bottom">
          <ul className="info">
            <Text variant="list-item" component="li">
              <span>Tilbyder:</span> <b>{p.tilbyder}</b>
            </Text>
            <Text variant="list-item" component="li">
              <span>Pris per kvm:</span> <b>{p.kvmPris}</b>
            </Text>
            <Text variant="list-item" component="li">
              <span>Felleskostnader:</span> <b>{p.felleskostnader}</b>
            </Text>
            <Text variant="list-item" component="li">
              <span>Ledig areal:</span> <b>{p.ledigAreal}</b>
            </Text>
            <Text variant="list-item" component="li">
              <span>Ledig fra:</span> <b>{p.ledigFraDato}</b>
            </Text>
          </ul>

          <div className="scores">
            {p.scores?.map(({ kalkulertScore, overstyrtScore, type }) => (
              <div key={type} className="scores-item">
                <TriPie kalkulert={kalkulertScore} overstyrt={overstyrtScore} />
                <Text variant={'list-item'}>{type}</Text>
              </div>
            ))}
          </div>
          {p.dokumentasjonInnsendt && <span className="status-tag font-tag-bold">Innkommet Svar</span>}
        </div>
      </div>
    </a>
  );
};

export default TilbudtObjektCard;
