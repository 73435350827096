import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import KollektivTransport from 'components/KollektivTransport/KollektivTransport';
import LabeledField from 'components/LabeledField/LabeledField';
import NumberField from 'components/NumberField/NumberField';
import NumberPicker from 'components/NumberPicker/NumberPicker';
import SelectField from 'components/SelectField/SelectField';
import Panel from 'components/Panel/Panel';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import TextField from 'components/TextField/TextField';
import UploadField from 'components/UploadField/UploadField';
import UploadMultipleField from 'components/UploadMultipleField/UploadMultipleField';
import { parseISO8601String as parseDate } from 'js/date-helper';
import { usePostback } from 'js/hooks';
import { toCamelCase, toFile } from 'js/upload-files';
import React, { FC, useMemo, useState, useEffect } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { Prosjekt } from 'types/Prosjekt';
import { TomtesokGrunnlagsdata } from 'types/TomtesokGrunnlagsdata';
import ProsjektRedigerContainer from '../ProsjektRedigerContainer';
import { mapFylker, mapKommuner, mapPersoner } from '../utils';
import styles from './TomtesokGrunnlagsdataRedigerSide.module.css';

export interface TomtesokGrunnlagsdataRedigerSide {
  readonly headerText: string;
  readonly prosjektData: Prosjekt;
  readonly tomtesokGrunnlagsdata: TomtesokGrunnlagsdata;
  readonly fylkeListe: {
    readonly id: string;
    readonly navn: string;
  }[];
  readonly kommuneListe: {
    readonly id: string;
    readonly fylkeId: string;
    readonly navn: string;
  }[];
  readonly kontaktpersonListe: {
    readonly id: string;
    readonly fulltNavn: string;
  }[];

  readonly kollektivTransportTyperListe: string[];
  readonly grunnlagsdataLagreApi: string;
  readonly lastOppEkstraDokumentUrl: string;
  readonly lastOppKartSokeomradeBildeUrl: string;
  readonly lastOppKartSokeomradeDokumentUrl: string;
  readonly lastOppKundelogoUrl: string;
  readonly slettFilUrl: string;
  readonly avsluttUrl: string;
  readonly forrigeUrl: string;
  readonly nesteUrl: string;
  readonly deleteUrl?: string;
  readonly slettProsjektBekreftTekst: string;
  readonly archiveProsjektUrl?: string;
}

const GrunnlagsdataRedigerSide: FC<TomtesokGrunnlagsdataRedigerSide> = ({
  headerText,
  tomtesokGrunnlagsdata: data,
  kollektivTransportTyperListe,
  kontaktpersonListe,
  fylkeListe,
  kommuneListe,
  lastOppEkstraDokumentUrl,
  lastOppKundelogoUrl,
  slettFilUrl,
  grunnlagsdataLagreApi,
  avsluttUrl,
  forrigeUrl,
  nesteUrl,
  deleteUrl,
  slettProsjektBekreftTekst,
  archiveProsjektUrl
}) => {
  const [onsketOvertakelseFraUtc, setOnsketOvertakelseFraUtc] = useState(() => parseDate(data.onsketOvertakelseFraUtc));
  const [onsketOvertakelseTilUtc, setOnsketOvertakelseTilUtc] = useState(() => parseDate(data.onsketOvertakelseTilUtc));
  const [arealFra, setArealFra] = useState(data.arealFra || undefined);
  const [arealTil, setArealTil] = useState(data.arealTil || undefined);

  const [utbyggingspotensialeFra, setUtbyggingspotensialeFra] = useState(data.utbyggingspotensialeFra || undefined);
  const [utbyggingspotensialeTil, setUtbyggingspotensialeTil] = useState(data.utbyggingspotensialeTil || undefined);

  const [tomteprisBasiskostnad, setTomteprisBasiskostnad] = useState(data.tomteprisBasiskostnad || undefined);
  const [kontaktperson, setKontaktperson] = useState(data.kontaktperson);

  const [opsjonsperiode, setOpsjonsperiode] = useState(data.opsjonsperiode || undefined);
  const [sarskilteForhold, setSarskilteForhold] = useState(data.sarskilteForhold || undefined);
  // const [fraPrisKvmUtenTiltak, setFraPrisKvmUtenTiltak] = useState(
  //   data.fraPrisKvmUtenTiltak || undefined
  // );
  // const [tilPrisKvmUtenTiltak, setTilPrisKvmUtenTiltak] = useState(
  //   data.tilPrisKvmUtenTiltak || undefined
  // );

  const [avstandKollektivtransport, setAvstandKollektivTransport] = useState(
    data.avstandKollektivTransport ? data.avstandKollektivTransport : []
  );

  const [fylkeKommuner, setFylkeKommuner] = useState(data.fylkeKommuner ? data.fylkeKommuner : []);

  const prosjektledere = useMemo(() => mapPersoner(kontaktpersonListe), []);

  const [stateFylke, setStateFylke] = useState('');
  const [stateKommune, setStateKommune] = useState('');

  const alleFylker = useMemo(
    () => mapFylker(fylkeListe.filter(({ id }) => !fylkeKommuner.some((fK) => fK.fylkeId === id))),
    [fylkeKommuner]
  );

  const alleKommuner = useMemo(() => mapKommuner(stateFylke, kommuneListe), [stateFylke, fylkeKommuner]);

  const setChosenFylkeKommune = () => {
    // setChosenFylker([...chosenFylker, fylke]);
    // setChosenKommuner([...chosenKommuner, kommune]);
    const currentFylke = alleFylker.find((p) => p.value === stateFylke);
    const currentKommune = alleKommuner.find((p) => p.value === stateKommune);

    setFylkeKommuner([
      ...fylkeKommuner,
      {
        fylkeId: stateFylke,
        fylke: currentFylke?.label || '',
        kommuneId: stateKommune,
        kommune: currentKommune?.label || ''
      }
    ]);
    setStateFylke('');
    setStateKommune('');
  };

  // Automatically set the add the first entry to the list
  useEffect(() => {
    if (stateFylke && stateKommune && fylkeKommuner.length === 0) {
      setChosenFylkeKommune();
    }
  }, [stateFylke, stateKommune]);

  const removeChosenFylkeKommune = (kommuneFylke: string) => {
    setFylkeKommuner((_c) => _c.filter((item) => item.kommune + item.fylke !== kommuneFylke));
  };
  const { postback, isDirty, isBusy, validation } = usePostback(grunnlagsdataLagreApi, {
    fylkeKommuner,
    onsketOvertakelseFraUtc,
    onsketOvertakelseTilUtc,
    arealFra,
    arealTil,
    kontaktperson,
    avstandKollektivtransport,
    utbyggingspotensialeFra,
    utbyggingspotensialeTil,
    sarskilteForhold,
    tomteprisBasiskostnad,
    opsjonsperiode
  });

  return (
    <ProsjektRedigerContainer
      headerText={headerText}
      isDirty={isDirty}
      isBusy={isBusy}
      currentStep={2}
      quitUrl={avsluttUrl}
      nextPageUrl={nesteUrl}
      previousPageUrl={forrigeUrl}
      save={postback}
      slettProsjektBekreftTekst={slettProsjektBekreftTekst}
      deleteUrl={deleteUrl}
      archiveProsjektUrl={archiveProsjektUrl}
    >
      <div className={styles.GrunnlagsdataRedigerSide}>
        <Panel heading={'Generell informasjon'} className={styles.GenerellArea}>
          <div className={styles.MultipleFylkeKommune}>
            <SelectField
              label="Fylke*"
              placeholder={'Velg fylke'}
              choices={alleFylker}
              selectedValue={
                alleFylker.find((p) => p.value === stateFylke) || {
                  value: '',
                  label: ''
                }
              }
              onChange={({ value }) => setStateFylke(value)}
              {...validation.fylkeKommuner}
            />

            <SelectField
              label="Kommune*"
              placeholder={'Velg kommune'}
              choices={alleKommuner}
              selectedValue={
                alleKommuner.find((p) => p.value === stateKommune) || {
                  value: '',
                  label: ''
                }
              }
              onChange={({ value }) => setStateKommune(value)}
            />
            <Button disabled={!stateFylke || !stateKommune} icon onClick={setChosenFylkeKommune}>
              <IconReactIcons type={IconTypes.plus} />
            </Button>
            {fylkeKommuner.map(({ kommune, fylke }) => {
              return (
                <React.Fragment key={kommune + fylke}>
                  <Field disabled value={fylke} />
                  <Field disabled value={kommune} />
                  <Button icon onClick={() => removeChosenFylkeKommune(kommune + fylke)}>
                    <AiOutlineDelete />
                  </Button>
                </React.Fragment>
              );
            })}
          </div>
          <DateRangePicker
            label="Ønsket overtakelse*"
            fromDateInitialValue={onsketOvertakelseFraUtc}
            toDateInitialValue={onsketOvertakelseTilUtc}
            onFromDateChanged={setOnsketOvertakelseFraUtc}
            onToDateChanged={setOnsketOvertakelseTilUtc}
            toDateValidation={validation.onsketOvertakelseTilUtc}
            fromDateValidation={validation.onsketOvertakelseFraUtc}
          />

          <LabeledField label="Areal*">
            <NumberField
              placeholder="Fra"
              suffix="kvm"
              type="number"
              min="0"
              value={arealFra}
              onChange={setArealFra}
              {...validation.arealFra}
            />
            <NumberField
              placeholder="Til"
              suffix="kvm"
              type="number"
              min="0"
              value={arealTil}
              onChange={setArealTil}
              {...validation.arealTil}
            />
          </LabeledField>

          <SelectField
            label="Kontaktperson*"
            choices={prosjektledere}
            selectedValue={prosjektledere.find((p) => p.value === kontaktperson)}
            onChange={(e) => setKontaktperson(e.value)}
            {...validation.kontaktperson}
          />

          <LabeledField label="Utbyggingspotensiale*">
            <NumberField
              placeholder="Fra"
              suffix="kvm"
              type="number"
              min="0"
              value={utbyggingspotensialeFra}
              onChange={setUtbyggingspotensialeFra}
              {...validation.utbyggingspotensialeFra}
            />
            <NumberField
              placeholder="Til"
              suffix="kvm"
              type="number"
              min="0"
              value={utbyggingspotensialeTil}
              onChange={setUtbyggingspotensialeTil}
              {...validation.utbyggingspotensialeTil}
            />
            {/* TODO: Utbyggingspotensiale BTA */}
          </LabeledField>

          <LabeledField label="Ekstra Dokumenter">
            <UploadMultipleField
              initialFiles={data.ekstraDokumenter?.map(toCamelCase) ?? []}
              destinationUrl={lastOppEkstraDokumentUrl}
              deleteUrl={slettFilUrl}
              fileType="document"
            />
          </LabeledField>

          <LabeledField label="Legg til kundens logo">
            <UploadField
              fileType="image"
              destinationUrl={lastOppKundelogoUrl}
              deleteUrl={slettFilUrl}
              initialFile={toFile(data.kundelogo)}
            />
          </LabeledField>
          {/* <LabeledField label="Legg til kart over søkeområde">
            <UploadField
              initialFile={toFile(data.kartSokeomradeBilde)}
              fileType="image"
              destinationUrl={lastOppKartSokeomradeBildeUrl}
              deleteUrl={slettFilUrl}
            />
            <UploadField
              initialFile={toFile(data.kartSokeomradeDokument)}
              fileType="document"
              destinationUrl={lastOppKartSokeomradeDokumentUrl}
              deleteUrl={slettFilUrl}
            />
          </LabeledField> */}
        </Panel>

        <Panel heading="Tomtepris" className={styles.PrisArea}>
          <NumberField
            label="Kun til intern vurdering *"
            placeholder="Angitt i basiskostnad i KVN/KVU"
            type="number"
            suffix={'kr'}
            min="0"
            value={tomteprisBasiskostnad}
            onChange={setTomteprisBasiskostnad}
            {...validation.tomteprisBasiskostnad}
          />
        </Panel>

        <Panel heading="Transport" className={styles.TransportArea}>
          <KollektivTransport
            typerKollektivtransport={kollektivTransportTyperListe}
            avstandKollektivtransport={avstandKollektivtransport}
            onChange={setAvstandKollektivTransport}
          />
        </Panel>

        <Panel heading="Annet" className={styles.AnnetArea}>
          <NumberPicker
            label="Opsjonsperiode"
            unit="år"
            placeholder="Velg (1-10 år)"
            selectedValue={opsjonsperiode}
            choices={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            onChange={(e) => setOpsjonsperiode(e.value)}
            {...validation.opsjonsperiode}
          />
          <TextField
            label="Særskilte forhold"
            placeholder="Skriv her"
            type="textarea"
            maxCharacters={350}
            value={sarskilteForhold}
            onChange={(e) => setSarskilteForhold(e.target.value)}
            {...validation.sarskilteForhold}
          />
        </Panel>
      </div>
    </ProsjektRedigerContainer>
  );
};

export default GrunnlagsdataRedigerSide;
