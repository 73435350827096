import { TilbudtObjektListe } from 'types/TilbudtObjektListe';
import { TilbudtObjekt } from 'types/TilbudtObjekt';

export const SortLeieObjekter = (entries: TilbudtObjekt[]): TilbudtObjektListe => {
  return {
    NotEvaluated: entries?.filter((x) => !x.erVurdert && !x.erTrukket),
    Recommended: entries?.filter((x) => x.erVurdert && (x.erAnbefalt || x.erAnbefaltMedAnmerkning) && !x.erTrukket),
    Rejected: entries?.filter((x) => x.erVurdert && !x.erAnbefalt && !x.erAnbefaltMedAnmerkning && !x.erTrukket),
    Canceled: entries?.filter((x) => x.erTrukket)
  };
};

export const recommendationFlagsToEnumValue = (erVurdert, erAnbefalt, erAnbefaltMedAnmerkning, erTrukket = false) => {
  return erTrukket
    ? SetObjektVurdering.ER_TRUKKET
    : !erVurdert
    ? SetObjektVurdering.UNSET
    : erVurdert && erAnbefalt
    ? SetObjektVurdering.ANBEFALES
    : erVurdert && erAnbefaltMedAnmerkning
    ? SetObjektVurdering.ANBEFALES_MED_ANMERKNING
    : SetObjektVurdering.ANBEFALES_IKKE;
};

export enum SetObjektVurdering {
  UNSET = -1,
  ANBEFALES = 0,
  ANBEFALES_MED_ANMERKNING = 1,
  ANBEFALES_IKKE = 2,
  ER_TRUKKET = 3
}
