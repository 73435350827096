import cn from 'classnames';
import Panel from 'components/Panel/Panel';
import React from 'react';
import { FaList, FaMapMarkerAlt } from 'react-icons/fa';
import style from '../SvarInnkomne.module.css';
import Text from 'components/Text/Text';
import { BiClipboard } from 'react-icons/bi';

interface ISvarInnkomneHeader {
  viewMode: string;
  withKriterier?: boolean;
  setViewMode: (string) => void;
  toggleBareTilBefaring?: () => void;
}

const SvarInnkomneHeader = ({ setViewMode, viewMode, toggleBareTilBefaring, withKriterier = false }: ISvarInnkomneHeader) => {
  return (
    <Panel className={style.toolbar}>
      <div className={style.visningBar}>
        <Text style={{ marginRight: 20 }} noMargin variant="tag">
          Visning:
        </Text>

        <button onClick={() => setViewMode('table')} className={cn({ [style.active]: viewMode === 'table' })}>
          <FaList />
          Tabell
        </button>
        <button onClick={() => setViewMode('map')} className={cn({ [style.active]: viewMode === 'map' })}>
          <FaMapMarkerAlt />
          Kart
        </button>

        {withKriterier && (
          <button onClick={() => setViewMode('kriterier')} className={cn({ [style.active]: viewMode === 'kriterier' })}>
            <BiClipboard />
            Kriterier
          </button>
        )}
      </div>

      {typeof toggleBareTilBefaring !== 'undefined' && (
        <Text variant="paragraph" component="label" htmlFor={'tilBefaringFilter'}>
          <input
            style={{ marginRight: 16 }}
            id="tilBefaringFilter"
            type="checkbox"
            autoComplete="off"
            onChange={toggleBareTilBefaring}
          />
          Vis bare svar til befaring
        </Text>
      )}
    </Panel>
  );
};

export default SvarInnkomneHeader;
