import React, { FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { BsCalendar, BsClock } from 'react-icons/bs';
import cn from 'classnames';

export interface TimePicker {
  onChange: (time: string) => void;
}

const TimePicker: FC<TimePicker> = ({ onChange }: TimePicker) => {
  const maxHours = 23;
  const maxMin = 59;

  const [time, setTime] = useState({
    hh: '',
    mm: ''
  });

  const [validInput, setValidInput] = useState<SetStateAction<boolean>>(true);

  const hhInput = useRef<HTMLInputElement>(null);
  const mmInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const hour = parseInt(time.hh);
    const min = parseInt(time.mm);
    onChange('');

    if (hour > maxHours && hhInput.current) {
      setTime({
        ...time,
        hh: ''
      });
      setValidInput(false);
    } else if (min > maxMin && mmInput.current) {
      setTime({
        ...time,
        mm: ''
      });
      setValidInput(false);
    } else if (hour <= maxHours && min <= maxMin) {
      setValidInput(true);
      onChange(time.hh.concat(':', time.mm));
    }
  }, [time]);

  return (
    <div className={cn('TimePicker', !validInput ? '-error' : '')}>
      <input
        type="text"
        className="TimePicker--hh"
        min="0"
        max={maxHours}
        placeholder="hh"
        pattern="[0-9]*"
        maxLength={2}
        ref={hhInput}
        onChange={(e) =>
          setTime({
            ...time,
            hh: e.target.value.replace(/\D/g, '')
          })
        }
        value={time.hh}
      />
      :
      <input
        type="text"
        className="TimePicker--mm"
        min="0"
        max={maxMin}
        placeholder="mm"
        pattern="[0-9]*"
        maxLength={2}
        ref={mmInput}
        onChange={(e) =>
          setTime({
            ...time,
            mm: e.target.value.replace(/\D/g, '')
          })
        }
        value={time.mm}
      />
      <BsClock />
    </div>
  );
};

export default TimePicker;
