/* eslint-disable react/display-name */
import Panel from 'components/Panel/Panel';
import Text from 'components/Text/Text';
import React from 'react';
import style from '../SvarDetalj.module.css';
import { Fildata } from 'types/Fildata';
import FileList from '../../../Annonse/components/FileList';

interface ISvarDetaljDokumenterPanel {
  readonly ekstraDokumenter?: Fildata[];
}

export default function SvarDetaljDokumenterPanel({ ekstraDokumenter }: ISvarDetaljDokumenterPanel) {
  return (
    <>
      {!!ekstraDokumenter?.length && (
        <Panel type="border" className={style.Files}>
          <Text variant="annonse-grid-title" component="h2">
            Tilleggsinformasjon
          </Text>
          <FileList files={ekstraDokumenter} />
        </Panel>
      )}
    </>
  );
}
