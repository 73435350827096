/* eslint-disable react/display-name */
import Popup from 'components/Popup/Popup';
import TriPie from 'components/TriPie/TriPie';
import { post } from 'js/api-helper';
import { useOnChange } from 'js/hooks';
import React, { useCallback, useState, useMemo } from 'react';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { Score as ScoreType } from 'types/Score';
import style from './Score.module.css';
import VurderingsPopup from './Popups/VurderingsPopup';
import Text from 'components/Text/Text';

interface IScore {
  visVurdering: boolean;
  hideExpand?: boolean;
  title: string;
  score: ScoreType;
  children: JSX.Element | null;
  readonly overstyrVurderingUrl?: string;
}

const ScoreUnit = ({ visVurdering, hideExpand, children, title, score, overstyrVurderingUrl }: IScore) => {
  const [popupOpen, setPopupOpen] = useState<boolean>(false);

  const overstyrVurdering = useCallback(
    async (type: string, vurdering: number) => {
      await post(overstyrVurderingUrl as string, { type, vurdering });
    },
    [overstyrVurderingUrl]
  );

  const [overstyrtScore, setOverstyrtScore] = useState(score.overstyrtScore);

  useOnChange(overstyrVurdering, [score.type, overstyrtScore]);

  const childrenWithProps = useMemo(
    () =>
      React.Children.map(children, (child) => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { onChange: setOverstyrtScore });
        }
        return child;
      }),
    [children, setOverstyrtScore]
  );

  return (
    <>
      <div
        className={style.ScoreUnit}
        style={{ cursor: hideExpand ? 'auto' : 'pointer' }}
        onClick={() => setPopupOpen(!hideExpand && true)}
      >
        {!hideExpand && <BsBoxArrowUpRight className={style.expandIcon} />}
        <Text noMargin variant="button">
          {title}
        </Text>
        <Text variant="list-item" className={style.justertText}>
          {overstyrtScore !== 0 && overstyrtScore !== score.kalkulertScore ? 'Justert' : ' '}
        </Text>
        <div className={style.TriPieWrapper}>
          {visVurdering && <TriPie className={style.triPieSvg} kalkulert={score.kalkulertScore} overstyrt={overstyrtScore} />}
        </div>
      </div>

      <Popup isOpen={popupOpen} close={() => setPopupOpen(false)}>
        <VurderingsPopup
          visVurdering={visVurdering}
          overstyrVurdering={!!overstyrVurderingUrl}
          kalkulert={score.kalkulertScore}
          overstyrt={overstyrtScore}
          onChange={setOverstyrtScore}
        >
          {childrenWithProps}
        </VurderingsPopup>
      </Popup>
    </>
  );
};

export default ScoreUnit;
