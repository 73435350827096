import { upload, post, ValidationError } from 'js/api-helper';
import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineSync, AiOutlineUpload } from 'react-icons/ai';
import style from './ImageUploadGrid.module.css';
import { Fildata } from 'types/Fildata';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import Popup from 'components/Popup/Popup';
import cn from 'classnames';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';

export interface Props {
  readonly destinationUrl: string;
  readonly bildeFil: Fildata;
  readonly onUploadComplete?: (file: Fildata, index: number) => void;
  readOnly?: boolean;
  index: number;
  handleDeleteImg: (id: string) => void;
}

export default function ImageUploadBox({ destinationUrl, onUploadComplete, bildeFil, readOnly, index, handleDeleteImg }: Props) {
  const [isBusy, setBusy] = useState(false);
  const [uploadFailedMessage, setUploadFailedMessage] = useState<string>();
  const [imageUrl, setImageUrl] = useState(bildeFil?.nedlastingslenke || '');
  const [popupImage, setPopupImage] = useState(false);

  useEffect(() => {
    setImageUrl(bildeFil?.nedlastingslenke || '');
  }, [bildeFil]);

  const handleUpload = useCallback(
    async (files: globalThis.File[]) => {
      setBusy(true);
      setUploadFailedMessage(undefined);
      try {
        for (const file of files) {
          const data = new FormData();
          data.append('file', file);
          try {
            console.log({ data, file });
            const newImg = URL.createObjectURL(file);
            setImageUrl(newImg);
            const result = await upload(destinationUrl, data);
            onUploadComplete?.(result, index);
          } catch (error) {
            if (error instanceof ValidationError) {
              setUploadFailedMessage(error['file']);
              return;
            }
          }
        }
      } finally {
        // There is a warning in the devtools because of this line; ignore it.
        // If this component is unmounted because of the onUploadComplete call above,
        // then it's too late to update the state here. It warns that it's a memory leak,
        // but it's not really, so nothing to worry about.
        setBusy(false);
      }
    },
    [destinationUrl, onUploadComplete]
  );

  const handleDeleteImgIfId = () => {
    if (bildeFil.id) {
      handleDeleteImg(bildeFil.id);
    } else {
      setImageUrl('');
    }
  };

  return (
    <>
      {uploadFailedMessage && (
        <div className="UploadButton-failed">
          <span>{uploadFailedMessage}</span>
        </div>
      )}

      <section
        className={cn(style.imageItem, {
          [style.imageItemReadOnly]: readOnly,
          [style.imageItemEmpty]: !imageUrl
        })}
      >
        {imageUrl && (
          <>
            <img className={style.image} src={imageUrl} alt={bildeFil?.visningsnavn || 'Brukeropplastet bilde'} />
            <Popup close={() => setPopupImage(false)} clickOutsideToClose isOpen={popupImage}>
              <img className={style.image} style={{ width: '90vw', height: '90vh' }} src={imageUrl} alt={''} />
            </Popup>
            <button className={cn(style.imageButton, style.expandButton)} onClick={() => setPopupImage(true)}>
              <BsBoxArrowUpRight />
            </button>
            {!readOnly && (
              <button className={cn(style.imageButton, style.deleteButton)} onClick={() => handleDeleteImgIfId()}>
                <IconReactIcons type={IconTypes.trash}></IconReactIcons>
              </button>
            )}
          </>
        )}
        {isBusy && <AiOutlineSync className="spin" />}
        {!imageUrl && !readOnly && (
          <>
            <AiOutlineUpload />
            <p>Last opp bilde</p>
          </>
        )}
        {!readOnly && (
          <input
            className={style.imageFileUploadInput}
            type="file"
            accept={'.png, .jpg, .jpeg'}
            onChange={(e) => handleUpload(Array.from(e.target.files ?? []))}
          />
        )}
      </section>
    </>
  );
}
