import SelectField, {
  Props as SelectFieldProps
} from 'components/SelectField/SelectField';
import { useOnChange } from 'js/hooks';
import React, { useCallback, useMemo, useState } from 'react';

export interface Props
  extends Omit<Omit<SelectFieldProps<number>, 'selectedValue'>, 'choices'> {
  readonly unit: string;
  readonly selectedValue?: number;
  readonly choices?: number[];
}

export default function NumberPicker({
  unit,
  onChange,
  selectedValue,
  choices = [5, 10, 15, 20, 25],
  ...props
}: Props) {
  const [value, setValue] = useState(
    selectedValue !== undefined ? choice(selectedValue, unit) : undefined
  );
  const [input, setInput] = useState<number>();

  useOnChange((v) => v && onChange(v), [value]);

  const onInputChange = useCallback((input: string) => {
    const val = Number(input);
    if (!isNaN(val)) setInput(val);
  }, []);

  const availableChoices = useMemo(
    () => (input ? [input] : choices).map((v) => choice(v, unit)),
    [input, unit]
  );

  return (
    <SelectField
      {...props}
      selectedValue={value}
      choices={availableChoices}
      onInputChange={onInputChange}
      onChange={setValue}
      noOptionsMessage={() => 'Skriv et tall'}
    />
  );
}

function choice(input: number, unit: string) {
  return { value: input, label: `${input} ${unit}` };
}
