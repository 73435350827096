import Text from 'components/Text/Text';
import React, { FC } from 'react';
import Annonse, { AnnonseBlock } from '../../components/AnnonseBlock/AnnonseBlock';
import styles from './ForsideInnleieSide.module.css';

export interface ForsideInnleieSide {
  readonly annonser: AnnonseBlock[];
}

const ForsideInnleieSide: FC<ForsideInnleieSide> = ({ annonser }) => {
  return (
    <div className={styles.ForsideInnleieSide}>
      <Text center variant={'article-h1'}>
        Statensinnleie
      </Text>
      <Text center variant={'article-ingress-1-2'}>
        Statsbygg bistår statlige virksomheter ved søk av leielokaler over hele Norge. På disse sidene har du løpende oversikt
        over aktive søk som du kan svare på.
      </Text>

      <div className={styles.Grid}>
        {annonser.map((a, index) => {
          return (
            <div key={index} className={styles.GridItem}>
              <Annonse {...a} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ForsideInnleieSide;
