import Field from 'components/Field/Field';
import FormSubmitBox from '../../../components/FormSubmitBox/FormSubmitBox';
import Modal from 'components/Modal/Modal';
import Checkbox from 'components/Checkbox/Checkbox';
import { post } from 'js/api-helper';
import React, { FC } from 'react';
import Text from 'components/Text/Text';
import { useState } from 'react';
import { KonkurransegrunnlagSide } from '../KonkurransegrunnlagSide';

interface CommissionerArea extends KonkurransegrunnlagSide {}

const CommissionerArea: FC<CommissionerArea> = ({
  sendTilGodkjenningUrl,
  godkjennUrl,
  publiserGrunnlagUrl,
  anbefalingerSendt,
  anbefalingerGodkjent,
  grunnlagPublisert,
  oppdragsGiverKommentar
}) => {
  const [hasBeenRead, setHasBeenRead] = useState(anbefalingerGodkjent);
  const [approved, setApproved] = useState(anbefalingerGodkjent);
  const [comments, setcomments] = useState<string>(oppdragsGiverKommentar);
  const [modal, setModal] = useState(false);

  const submitDocs = async (url: string) => {
    try {
      const res = await post(url, { kommentar: comments });
      if (res) {
        setApproved(true);
      }
    } catch (error) {
      console.log('error!');
    }
  };

  enum HeaderVariations {
    'Godkjenn og send til prosjektleder',
    'Godkjent grunnlag'
  }

  return (
    <FormSubmitBox
      heading={HeaderVariations[approved ? 1 : 0]}
      bodyText="For å gå videre med konkurransen må du som oppdragsgiver bekrefte at du har lest og godkjent konkurransegrunnlaget."
      buttonText={'Godkjenn'}
      dokumenterPublisert={false}
      submitUrl={godkjennUrl}
      submitFunc={(url) => {
        submitDocs(url);
        setModal(true);
      }}
      regretFunc={() => {}}
      disabled={!hasBeenRead || approved}
    >
      <Modal
        title={'Oppdragsgivers godkjennelse er mottatt'}
        clickOutsideToClose={true}
        open={modal}
        onClose={() => setModal(false)}
      >
        <p>
          Konkurransegrunnlaget vil nå bli distribuert til aktuelle aktører og prosessen vil gå videre til innhenting av formelle
          tilbud.
        </p>
      </Modal>

      <div className="row">
        <Field
          maxCharacters={500}
          className="CommentArea"
          label="Oppdragsgivers vurdering"
          type="textarea"
          name="anbefalingerGodkjentKommentar"
          value={comments}
          disabled={approved}
          onChange={(e) => setcomments(e.target.value)}
        />
      </div>
      <div className="row">
        <Text variant="mono-16">Oppdragsgivers godkjenning</Text>
        <Checkbox
          name="godkjent"
          label="Oppsummeringer og anbefalinger er lest og godkjent"
          checked={hasBeenRead || approved}
          onChange={(e) => {
            setHasBeenRead(!hasBeenRead);
          }}
        ></Checkbox>
      </div>
    </FormSubmitBox>
  );
};

export default CommissionerArea;
