import cn from 'classnames';
import Paragraphs from 'components/Paragraphs/Paragraphs';
import useBooleanState from 'js/hooks/useBooleanState';
import React, { useCallback, useState } from 'react';
import { BsPencil } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import EditHjelpetekst from '../../pages/HjelpeteksterSide/EditHjelpetekst';
import style from './Hjelpetekst.module.css';

export interface Props {
  readonly id: string;
  readonly show: boolean;
  readonly sidenavn: string;
  readonly tittel?: string;
  readonly innhold?: string;
  readonly lagreUrl?: string;
  readonly slettUrl?: string;
  close(): void;
}

export default function Hjelpetekst({
  show,
  lagreUrl,
  close,
  ...tekst
}: Props) {
  const [editing, , toggleEditing] = useBooleanState(false);

  const [slettUrl, setSlettUrl] = useState(tekst.slettUrl);
  const [tittel, setTittel] = useState(tekst.tittel || tekst.sidenavn);
  const [innhold, setInnhold] = useState(tekst.innhold);

  const onSave = useCallback(
    (tittel: string, innhold: string, slettUrl: string) => {
      setTittel(tittel);
      setInnhold(innhold);
      setSlettUrl(slettUrl);
      toggleEditing();
    },
    []
  );

  const onDelete = useCallback(() => {
    setTittel(tekst.sidenavn);
    setInnhold(undefined);
    setSlettUrl(undefined);
    toggleEditing();
  }, []);

  return (
    <div className={cn(style.help, { [style.active]: show })}>
      <button onClick={close} className={style.close}>
        <MdClose />
      </button>

      {lagreUrl ? (
        editing ? (
          <EditHjelpetekst
            initialTittel={tittel}
            initialInnhold={innhold}
            lagreUrl={lagreUrl}
            slettUrl={slettUrl}
            onDelete={onDelete}
            onSave={onSave}
            stopEditing={toggleEditing}
          />
        ) : innhold ? (
          <>
            <h1>{tittel}</h1>
            <Paragraphs>{innhold}</Paragraphs>
            <button className={style.icon} onClick={toggleEditing}>
              <BsPencil /> Rediger hjelpetekst
            </button>
          </>
        ) : (
          <div className={style.noText}>
            <p>
              <i>Det finnes ingen hjelpetekst for denne siden</i>
            </p>
            <button className={style.icon} onClick={toggleEditing}>
              <BsPencil /> Legg til hjelpetekst
            </button>
          </div>
        )
      ) : (
        <>
          <h1>{tittel}</h1>
          <Paragraphs>{innhold}</Paragraphs>
        </>
      )}
    </div>
  );
}
