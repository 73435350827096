import React, { FC, InputHTMLAttributes } from 'react';

import './RadioBox.scss';

export interface RadioBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
}

const RadioBox: FC<RadioBoxProps> = ({ label, name, ...rest }) => {
  return (
    <div className="RadioBox">
      <label>
        <input name={name} {...rest} type="checkbox" />
        <div className="RadioBox__check">
          <div className="RadioBox__check--inner"></div>
        </div>
        {label}
      </label>
    </div>
  );
};

export default RadioBox;
