import React, { ReactNode, FC, useState } from 'react';
import cn from 'classnames';
import './Accordion.scss';

import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';

export interface AccordionProps {
  title: string;
  children: ReactNode;
  defaultOpen?: boolean;
  borders?: boolean;
}

const Accordion: FC<AccordionProps> = ({ title, children, defaultOpen = false, borders = false }) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <div className={cn('accordion', borders ? '-with-borders' : '')}>
      <button
        onClick={() => {
          setOpen(!open);
        }}
        className="accordion__trigger"
      >
        {title}
        {open && <IconReactIcons type={IconTypes.arrowUp} size={1} />}
        {!open && <IconReactIcons type={IconTypes.arrowDown} size={1} />}
      </button>
      {open && <div className="accordion__content">{children}</div>}
    </div>
  );
};

export default Accordion;
