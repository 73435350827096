import React from 'react';
import Icon from '../Icon/Icon';
import classnames from 'classnames';
import styles from './Alert.module.css';

/**
 * Fremhevet tekstområde for tilbakemelding til bruker.
 */

interface IAlert {
  kind: 'success' | 'warning' | 'danger' | 'info';
  children: React.ReactNode;
}

const Alert = ({ kind, children }: IAlert) => {
  const classes = classnames(styles.default, {
    [styles.success]: kind === 'success',
    [styles.warning]: kind === 'warning',
    [styles.danger]: kind === 'danger',
    [styles.info]: kind === 'info'
  });
  const alertIcon = () => {
    if (kind === 'success') {
      return 'check-box';
    } else if (kind === 'warning') {
      return 'alert';
    } else if (kind === 'danger') {
      return 'close';
    }
    return 'info-alt';
  };

  const alertIconColor = () => {
    if (kind === 'success') {
      return { white: true };
    } else if (kind === 'warning') {
      return { warning: true };
    } else if (kind === 'danger') {
      return { white: true };
    }
    return { info: true };
  };

  return (
    <div className={classes}>
      <Icon size="1" icon={alertIcon()} {...alertIconColor()} />
      <span className={styles.children}>{children}</span>
    </div>
  );
};

Alert.defaultProps = {
  kind: 'info',
  children: ''
};

export default Alert;
