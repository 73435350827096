import Personvernerklaring from 'components/Personvernerklaring/Personvernerklaring';
import * as api from 'js/api-helper';
import React, { FC, useCallback } from 'react';
import Button from 'components/Button/Button';
import Panel from 'components/Panel/Panel';
import Text from 'components/Text/Text';

export interface PersonvernerklaringAkseptSide {
  readonly aksepterUrl: string;
  readonly videresendUrl: string;
}

const PersonvernerklaringAkseptSide: FC<PersonvernerklaringAkseptSide> = ({
  aksepterUrl: AksepterUrl,
  videresendUrl: VideresendUrl
}) => {
  const clickAccecptButton = useCallback(async () => {
    await api.post(AksepterUrl, {});
    window.location.href = VideresendUrl;
  }, []);

  return (
    <div className="PersonvernerklaringAkseptSide">
      <Text variant="paragraph-header" component="h1">
        Personvernerklæring for statensinnleie.no
      </Text>
      <Panel>
        <Text variant="paragraph" component="h2">
          <b>Du må lese og akseptere personvernerklæringen før du kan bruke tjenesten:</b>
        </Text>
        <Personvernerklaring />
        <Button onClick={clickAccecptButton}>Jeg har lest og aksepterer personvernerklæringen</Button>
      </Panel>
    </div>
  );
};

export default PersonvernerklaringAkseptSide;
