import useArrayState from '@clave/use-array-state';
import SingleDatePicker from 'components/DateRangePicker/SingleDatePicker';
import DoublePanel from 'components/DoublePanel/DoublePanel';
import LabeledField from 'components/LabeledField/LabeledField';
import NumberField from 'components/NumberField/NumberField';
import Organisasjonsvelger from 'components/Organisasjonsvelger/Organisasjonsvelger';
import SelectField from 'components/SelectField/SelectField';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Field from 'components/Field/Field';
import Panel from 'components/Panel/Panel';
import TextField from 'components/TextField/TextField';
import UploadField from 'components/UploadField/UploadField';
import UploadMultipleField from 'components/UploadMultipleField/UploadMultipleField';
import { parseISO8601String as parseDate, shortDateString } from 'js/date-helper';
import { toCamelCase } from 'js/upload-files';
import React, { FC, useMemo, useState } from 'react';
import { Kontaktperson } from 'types/Kontaktperson';
import { Matrikkel } from 'types/Matrikkel';
import { SingelKollektivTransportEntry } from 'types/SingelKollektivTransportEntry';
import { SvarTomtesok } from 'types/SvarTomtesok';
import { mapAlternativerToLabelValue } from '../../utils';
import { KopierSvar } from '../components/KopierSvar';
import PresenterFeil from '../components/PresenterFeil';
import RedigerMatrikler from '../components/RedigerMatrikkeler';
import { useDraftAndFinalPostback } from '../useDraftAndFinalPostback';
import SvarTomteRedigerContainer from './SvarTomteRedigerContainer';
import style from './SvarTomteRedigerSide.module.css';
import useBeforeUnload from 'js/hooks/use-before-unload';

export interface SvarTomteRedigerSide {
  readonly svarData: SvarTomtesok;
  readonly annonseinformasjon: {
    readonly tittel: string;
    readonly svarFrist: string;
    readonly onsketOvertakelseFraUtc: string;
    readonly onsketOvertakelseTilUtc: string;
    readonly avstandKollektivTransport: SingelKollektivTransportEntry[];
    readonly arealFra: number;
    readonly arealTil: number;
    readonly kommune: string;
  };
  readonly kontaktperson: Kontaktperson;
  readonly bygningensStandAlternativer: {
    readonly alternativ: string;
  }[];
  readonly miljosertifisertAlternativer: {
    readonly alternativ: string;
  }[];
  readonly universellUtformingAlternativer: {
    readonly alternativ: string;
  }[];
  readonly gjennomforingstidTilByggeklarAlternativer: {
    readonly alternativ: string;
  }[];
  readonly bebygdStatusAlternativer: {
    readonly alternativ: string;
  }[];
  readonly lastOppEkstraDokumentUrl: string;
  readonly lastOppOvrigeBildeEnUrl: string;
  readonly lastOppOvrigeBildeToUrl: string;
  readonly lastOppKartutsnittUrl: string;
  readonly slettFilUrl: string;
  readonly kollektivTransportTyperListe: string[];
  readonly svarLagreKladdApi: string;
  readonly svarLeverTilbudApi: string;
  readonly relevanteTidligereSvar: {
    readonly url: string;
    readonly tittel: string;
    readonly erKladd?: boolean;
    readonly erIProsjekt?: boolean;
  }[];
  readonly slettSvarBekreftTekst?: string;
  readonly deleteUrl?: string;
  readonly lagreBildeUrl: string;
}

const SvarTomteRedigerSide: FC<SvarTomteRedigerSide> = ({
  svarData: data,
  annonseinformasjon,
  kontaktperson,
  gjennomforingstidTilByggeklarAlternativer,
  bebygdStatusAlternativer,
  kollektivTransportTyperListe,
  lastOppEkstraDokumentUrl,
  lastOppOvrigeBildeEnUrl,
  lastOppOvrigeBildeToUrl,
  lastOppKartutsnittUrl,
  slettFilUrl,
  svarLagreKladdApi,
  svarLeverTilbudApi,
  relevanteTidligereSvar = [],
  slettSvarBekreftTekst,
  deleteUrl
}) => {
  const [naeringsmegler, setNaeringsmegler] = useState(data.naeringsmegler);

  const [hovedMatrikkelIdentitet, setHovedMatrikkelIdentitet] = useState(data?.matrikkelInfo?.hovedMatrikkel?.matrikkelIdentitet);
  const [hovedMatrikkelAdressetekst, setHovedMatrikkelAdressetekst] = useState(data?.matrikkelInfo?.hovedMatrikkel?.adressetekst);

  const [tilleggsMatrikler, setTilleggsMatrikler] = useArrayState<Matrikkel>(data?.matrikkelInfo?.tilleggsMatrikler ?? []);

  const [ledigFraUtc, setLedigFraUtc] = useState(() => (data.ledigFraUtc ? parseDate(data.ledigFraUtc) : undefined));

  const [avstandKollektivTransport, setAvstandKollektivTransport] = useState(
    data.avstandKollektivTransport?.length
      ? data.avstandKollektivTransport
      : kollektivTransportTyperListe.map((kollektivtype) => ({
          avstandIMeter: undefined as unknown as number,
          kollektivtype
        }))
  );

  const [reguleringsstatus, setReguleringsstatus] = useState(data.reguleringsstatus);
  const [kommuneplanStatus, setKommuneplanStatus] = useState(data.kommuneplanStatus);
  const [gjennomforingstidTilByggeklar, setGjennomforingstidTilByggeklar] = useState(data.gjennomforingstidTilByggeklar);
  const [bebygdStatus, setBebygdStatus] = useState(data.bebygdStatus);
  const [beliggenhet, setBeliggenhet] = useState(data.beliggenhet);
  const [grunnforhold, setGrunnforhold] = useState(data.grunnforhold);
  const [infrastruktur, setInfrastruktur] = useState(data.infrastruktur);

  const [prisKvmTilbudtAreal, setPrisKvmTilbudtAreal] = useState(data.prisKvmTilbudtAreal);

  const [tilbudtAreal, setTilbudtAreal] = useState(data.tilbudtAreal);
  const [utbyggingspotensialeBTA, setUtbyggingspotensialeBTA] = useState(data.utbyggingspotensialeBTA);

  const [kommentarer, setKommentarer] = useState(data.kommentarer);

  const gjennomforingstidTilByggeklarChoices = useMemo(
    () => mapAlternativerToLabelValue(gjennomforingstidTilByggeklarAlternativer),
    [gjennomforingstidTilByggeklarAlternativer]
  );
  const bebygdStatusChoices = useMemo(() => mapAlternativerToLabelValue(bebygdStatusAlternativer), [bebygdStatusAlternativer]);

  const [kartutsnittField, setKartutsnittField] = useState(data?.bildeData?.kartutsnittUrl);

  const { saveDraft, saveFinal, isBusy, isDirty, validation, canSubmit } = useDraftAndFinalPostback(
    svarLagreKladdApi,
    svarLeverTilbudApi,
    {
      naeringsmegler,
      kommuneplanStatus,
      beliggenhet,
      grunnforhold,
      infrastruktur,
      gjennomforingstidTilByggeklar,
      bebygdStatus,
      hovedMatrikkelIdentitet,
      hovedMatrikkelAdressetekst,
      tilbudtAreal,
      utbyggingspotensialeBTA,
      prisKvmTilbudtAreal,
      tilleggsMatrikler,
      ledigFraUtc,
      reguleringsstatus,
      avstandKollektivTransport,
      kommentarer,
      kartutsnittField: kartutsnittField?.id
    }
  );

  useBeforeUnload(isDirty);

  const [popupOpen, setPopupOpen] = useState(0);
  const { hasRelevanteSvar, hasRelevanteSvarWithoutKladd } = React.useMemo(() => {
    return {
      hasRelevanteSvar: !!relevanteTidligereSvar?.length,
      hasRelevanteSvarWithoutKladd: !!relevanteTidligereSvar?.filter((svar) => !svar.erKladd).length
    };
  }, [relevanteTidligereSvar]);

  React.useEffect(() => {
    if (hasRelevanteSvar) {
      setPopupOpen(1);
    }
  }, [hasRelevanteSvar]);

  return (
    <>
      <SvarTomteRedigerContainer
        headline={annonseinformasjon.tittel}
        subtitle={shortDateString(annonseinformasjon.svarFrist)}
        kontaktperson={kontaktperson}
        showOpenRelevanteSvar={hasRelevanteSvarWithoutKladd}
        openRelevanteSvar={() => setPopupOpen(2)}
        submitDraft={saveDraft}
        submitFinal={saveFinal}
        slettSvarBekreftTekst={slettSvarBekreftTekst}
        deleteUrl={deleteUrl}
        isBusy={isBusy}
        canSubmit={canSubmit}
      >
        {!!relevanteTidligereSvar?.length && !isDirty && (
          <KopierSvar
            setPopupOpen={setPopupOpen}
            popupOpen={popupOpen}
            kommune={annonseinformasjon.kommune}
            relevanteTidligereSvar={relevanteTidligereSvar}
          />
        )}
        <DoublePanel label="Kontaktinformasjon" className={style.kontaktinfo}>
          <Organisasjonsvelger
            label="Næringsmegler/Eier/Holdingselskap"
            initialValue={data.naeringsmeglerNavn}
            onChange={(value) => setNaeringsmegler(value)}
            placeholder={'Org.nr eller søk etter navn'}
            {...validation.naeringsmegler}
          />
        </DoublePanel>
        <RedigerMatrikler
          hovedMatrikkelAdressetekst={hovedMatrikkelAdressetekst}
          hovedMatrikkelIdentitet={hovedMatrikkelIdentitet}
          validation={validation}
          setHovedMatrikkelAdressetekst={setHovedMatrikkelAdressetekst}
          setHovedMatrikkelIdentitet={setHovedMatrikkelIdentitet}
          matrikler={tilleggsMatrikler}
          addMatrikkel={setTilleggsMatrikler.append}
          removeMatrikkel={setTilleggsMatrikler.removeAt}
          editMatrikkel={setTilleggsMatrikler.editAt}
        />
        <DoublePanel label="Generell informasjon" className={style.generell}>
          <Field
            label={'Kommuneplanstatus*'}
            placeholder={'Skriv inn her'}
            type="text"
            defaultValue={data.kommuneplanStatus}
            onChange={(e) => setKommuneplanStatus(e.target.value)}
            {...validation.kommuneplanStatus}
          />
          <Field
            label={'Reguleringsstatus*'}
            placeholder={'Skriv inn her'}
            type="text"
            defaultValue={data.reguleringsstatus}
            onChange={(e) => setReguleringsstatus(e.target.value)}
            {...validation.reguleringsstatus}
          />
          <TextField
            label="Beliggenhet*"
            placeholder="Skriv om beliggenheten her"
            large={true}
            type="textarea"
            rows={10}
            value={beliggenhet}
            maxCharacters={500}
            onChange={(e) => setBeliggenhet(e.target.value)}
            {...validation.beliggenhet}
          />

          <TextField
            label="Grunnforhold*"
            placeholder="Skriv om grunnforholdene til utleieobjektet her"
            large={true}
            type="textarea"
            rows={10}
            value={grunnforhold}
            maxCharacters={500}
            onChange={(e) => setGrunnforhold(e.target.value)}
            {...validation.grunnforhold}
          />
          <TextField
            label="Infrastruktur*"
            placeholder="Skriv om infrastrukturen her"
            large={true}
            type="textarea"
            rows={10}
            value={infrastruktur}
            maxCharacters={500}
            onChange={(e) => setInfrastruktur(e.target.value)}
            {...validation.infrastruktur}
          />
          <div className={style.threewayGrid}>
            <SingleDatePicker
              label="Ledig fra*"
              tooltip={
                <>
                  <h4>Ønsket overtakelse</h4>
                  <p>
                    {shortDateString(annonseinformasjon.onsketOvertakelseFraUtc)} -{' '}
                    {shortDateString(annonseinformasjon.onsketOvertakelseTilUtc)}
                  </p>
                </>
              }
              placeholder="Velg tidligste ledig dato"
              initialDate={ledigFraUtc}
              required
              onDateChange={(value) => setLedigFraUtc(value)}
              {...validation.ledigFraUtc}
            />
            <SelectField
              label="Gjennomføringstid til tomt er byggeklar"
              placeholder="Er tomten byggeklar?"
              choices={gjennomforingstidTilByggeklarChoices}
              selectedValue={gjennomforingstidTilByggeklarChoices.find((p) => p.value === gjennomforingstidTilByggeklar)}
              onChange={(e) => setGjennomforingstidTilByggeklar(e.value as string)}
              {...validation.gjennomforingstidTilByggeklar}
            />
            <SelectField
              label="Bebygd/Ubebygd*"
              placeholder="Velg bygningens stand"
              choices={bebygdStatusChoices}
              selectedValue={bebygdStatusChoices.find((p) => p.value === bebygdStatus)}
              onChange={(e) => setBebygdStatus(e.value as string)}
              {...validation.bebygdStatus}
            />
          </div>
        </DoublePanel>
        {!!avstandKollektivTransport?.length && (
          <DoublePanel className={style.transport} label="Avstand til kollektivtransport">
            {avstandKollektivTransport.map((k, i) => (
              <NumberField
                placeholder="Avstand i meter"
                tooltip={
                  <>
                    <h4>Ønsket avstand til {k.kollektivtype}</h4>
                    <p>
                      {
                        annonseinformasjon.avstandKollektivTransport.find((a) => a.kollektivtype === k.kollektivtype)
                          ?.avstandIMeter
                      }
                      m
                    </p>
                  </>
                }
                key={i}
                label={k.kollektivtype}
                suffix="m"
                value={k.avstandIMeter}
                onChange={(avstandIMeter) =>
                  setAvstandKollektivTransport((liste) => liste.map((o) => (o === k ? { ...o, avstandIMeter } : o)))
                }
              />
            ))}
            {validation.avstandKollektivTransport.feedback &&
              validation.avstandKollektivTransport.validationState === 'error' && (
                <ErrorMessage feedback={validation.avstandKollektivTransport.feedback} feedbackMargin />
              )}
          </DoublePanel>
        )}
        <Panel heading={'Størrelse'} className={style.size}>
          <NumberField
            label="Størrelse på tilbudt tomteareal*"
            suffix="kvm"
            placeholder="Størrelse i KVM"
            value={tilbudtAreal}
            onChange={(val) => setTilbudtAreal(val)}
            {...validation.tilbudtAreal}
          />
          <NumberField
            label="Utbyggingspotensiale - oppgi tall i BTA*"
            suffix="BTA"
            placeholder="Antall i BTA"
            value={utbyggingspotensialeBTA}
            onChange={(val) => setUtbyggingspotensialeBTA(val)}
            {...validation.utbyggingspotensialeBTA}
          />
        </Panel>

        <Panel heading="Pris per kvm tomt*" className={style.pris}>
          <NumberField
            placeholder="Pris"
            suffix="kr"
            required
            label="Estimert pris for tilbudt areal"
            type="number"
            min="0"
            value={prisKvmTilbudtAreal}
            onChange={setPrisKvmTilbudtAreal}
            {...validation.prisKvmTilbudtAreal}
          />
        </Panel>

        <Panel heading={'Bilder'} className={style.bilde}>
          <LabeledField label="Kartutsnitt med tomtegrense*">
            <UploadField
              initialFile={data.bildeData?.kartutsnittUrl}
              fileType="image"
              destinationUrl={lastOppKartutsnittUrl}
              deleteUrl={slettFilUrl}
              onChange={(x) => setKartutsnittField(x)}
              {...validation.kartutsnittField}
            />
          </LabeledField>

          <LabeledField label="Øvrige bilder">
            <UploadField
              initialFile={data.bildeData?.bildeEnUrl}
              fileType="image"
              destinationUrl={lastOppOvrigeBildeEnUrl}
              deleteUrl={slettFilUrl}
            />
          </LabeledField>

          <LabeledField label="Øvrige bilder">
            <UploadField
              initialFile={data.bildeData?.bildeToUrl}
              fileType="image"
              destinationUrl={lastOppOvrigeBildeToUrl}
              deleteUrl={slettFilUrl}
            />
          </LabeledField>
          <LabeledField label="Tilleggsinformasjon">
            <UploadMultipleField
              initialFiles={data.ekstraDokumenter?.map(toCamelCase) ?? []}
              destinationUrl={lastOppEkstraDokumentUrl}
              deleteUrl={slettFilUrl}
              fileType="document"
            />
          </LabeledField>
        </Panel>
        <Panel heading={'Tilleggskommentarer'} className={style.kommentar}>
          <TextField
            placeholder="Skriv her"
            large={true}
            type="textarea"
            rows={7}
            value={kommentarer}
            maxCharacters={500}
            onChange={(e) => setKommentarer(e.target.value)}
            {...validation.kommentarer}
          />
        </Panel>
        <PresenterFeil validation={validation} />
      </SvarTomteRedigerContainer>
    </>
  );
};

export default SvarTomteRedigerSide;
