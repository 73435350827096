import { DokumentSkjemadel } from "types/DokumentSkjemadel";
import { KonkurranseGrunnlagFil } from "types/KonkurranseGrunnlagFil";


export const filterDocById = (docs: DokumentSkjemadel[], id: string) => {
    const copy = structuredClone(docs);
    copy.forEach((skjema) => {
          skjema.underDeler.forEach((del) => {
            del.vedlegg = (del.vedlegg ?? []).filter((v) => v.id !== id);
          });
        });  
    return copy
}

export const filterAttachmentsById = (docs: KonkurranseGrunnlagFil[], id: string) => {
  return docs.filter(x => x.id !== id)
}

export const findAttachmentById = (docs: DokumentSkjemadel[], id: string): any => {
  
    let target = {};
  
    docs.forEach((skjema) => {
      skjema.underDeler.forEach((del) => {
        (del.vedlegg ?? []).forEach(vedlegg => {
          if(vedlegg.id == id){
            target = vedlegg
          }
        })
      });
    });  
    return target
}