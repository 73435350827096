import React from 'react';

import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import { KategoriskDokument } from 'types/KategoriskDokument';

export interface UploadedFileLabelProps {
  file: KategoriskDokument;
  icon: JSX.Element;
  onRemove?: (id?: string) => void;
}

const UploadedFileLabel = ({ file, icon, onRemove }: UploadedFileLabelProps) => {
  return (
    <div className="UploadedFileLabel">
      <span className="UploadedFileLabel--file">
        <IconReactIcons type={IconTypes.file} size={3} />
      </span>
      <div className="UploadedFileLabel--meta">
        <span>{file.visningsnavn}</span>
        <div className="UploadedFileLabel--meta--bottom">
          {file.opplastetDato && <span>Lastet opp: {file.opplastetDato}</span>}
          {file.filstorrelse && <span>Størrelse: {file.filstorrelse}</span>}
        </div>
      </div>

      {onRemove ? (
        <button
          className="UploadedFileLabel--trash"
          onClick={() => {
            onRemove(file.id);
          }}
        >
          {icon}
        </button>
      ) : (
        <a href={file.nedlastingslenke} className="UploadedFileLabel--download">
          {icon}
        </a>
      )}
    </div>
  );
};

export default UploadedFileLabel;
