import React from 'react';
import ReactSelect from 'react-select';
import classnames from 'classnames';
import styles from './Select.module.css';
import { Choice } from 'components/SelectField/SelectField';

interface ISelect<T> {
  readonly choices: Choice<T>[];
  readonly selectedValue?: Choice<T>;
  noResultsText?: string;
  placeholder?: string;
  name?: string;
  selectGroup?: boolean;
  keepInput?: boolean;
  onSelectChange?: () => void;
  onKeyPress?: () => void;
  loadOptions?: () => void;
  matchProp?: 'any' | 'label' | 'value';
  wide?: boolean;
  formatOptionLabel?: (opt, context) => string;

  onInputChange?(selection: Choice<T>): void;
}

function Select<T>({
  choices,
  selectGroup,
  keepInput,
  selectedValue,
  onKeyPress,
  wide,
  ...props
}: ISelect<T>) {
  const classes = classnames(styles.select, {
    [styles.selectGroup]: selectGroup,
    [styles.wide]: wide
  });
  // Options to not reset typed input
  const keepInputProps = keepInput
    ? { onBlurResetsInput: false, onCloseResetsInput: false }
    : '';
  const getFontStyles = (provided) => ({
    ...provided,
    fontFamily: 'GT America Mono'
  });
  const getOptionsStyles = (provided, state) => ({
    ...provided,
    background: state.isSelected && 'var(--primary_01)',
    '&:hover': {
      background: 'var(--primary_01)'
    }
  });
  return (
    <ReactSelect
      styles={{
        option: getOptionsStyles,
        singleValue: getFontStyles,
        placeholder: getFontStyles,
        noOptionsMessage: getFontStyles
      }}
      {...keepInputProps}
      theme={(theme) => ({
        ...theme,
        text: 'orangered',
        primary25: 'hotpink',
        primary: 'black'
      })}
      className={classes}
      ignoreAccents={false}
      options={choices}
      id={props.name}
      value={selectedValue}
      onInputKeyDown={onKeyPress}
      {...props}
    />
  );
}

export default Select;
