import { useState } from 'react';
import useAction from './useAction';

export default function useDirtyTracking(fields: Record<string, any>): [boolean, () => void] {
  const [original, setOriginal] = useState(() => Object.values(fields));

  const isDirty = Object.values(fields)
    .map(zip(original))
    .some(isDifferent);

  const resetDirty = useAction(() => {
    setOriginal(Object.values(fields));
  }, [fields]);

  return [isDirty, resetDirty];
}

const zip = (other: any[]) => (value: any, index: number) => [value, other[index]] as [any, any];
const isDifferent = ([a, b]: [any, any]) => !Object.is(a, b);