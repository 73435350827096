import React, { Children, FC } from 'react';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import style from './LabeledField.module.css';
import Text from 'components/Text/Text';
import cn from 'classnames';

export interface Props {
  readonly label: string;
  readonly feedback?: string;
  readonly className?: string;
  readonly validationState?: 'error' | null;
  readonly feedbackMargin?: boolean;
  readonly vertical?: boolean;
}

const LabeledField: FC<Props> = ({ label, children, feedback, feedbackMargin, validationState, vertical, className }) => (
  <div
    className={cn(style.LabeledField, className)}
    style={
      vertical
        ? { gridTemplateRows: `repeat(${Children.count(children)}, auto)` }
        : {
            gridTemplateColumns: `repeat(${Children.count(children)}, minmax(max-content, 1fr))`
          }
    }
  >
    <Text variant="mono-16" component="label" className={style.label}>
      {label}
    </Text>
    {children}
    {feedback && validationState === 'error' && (
      <div className={style.ErrorMessage}>
        <ErrorMessage feedback={feedback} feedbackMargin={feedbackMargin} />
      </div>
    )}
  </div>
);

export default LabeledField;
