import SelectField, { Choice } from 'components/SelectField/SelectField';
import { useDebounce, useOnChange } from 'js/hooks';
import React, { useCallback, useState } from 'react';
import { components } from 'react-select';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';

export interface Props {
  readonly label?: string;
  readonly initialValue?: string;
  readonly onChange?: (value: string, label: string) => void;
  readonly onSearch: (value: string) => Promise<Choice<string>[]>;
  readonly validationState?: null | 'error' | 'loading';
  readonly feedback?: string;
  readonly feedbackMargin?: boolean;
  readonly placeholder?: string;
  readonly formatOptionLabel?: (opt, context) => string;
}

const DropdownIndicator = (props: object) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconReactIcons type={IconTypes.search} />
    </components.DropdownIndicator>
  );
};

export default function AutoCompleteField({
  label,
  initialValue,
  onChange = () => {},
  onSearch,
  validationState,
  feedback,
  feedbackMargin,
  formatOptionLabel,
  placeholder
}: Props) {
  const [value, setValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [choices, setChoices] = useState<Choice<string>[]>([]);
  const [searchInput, setSearchInput] = useState('');

  const debouncedSearchInput = useDebounce(searchInput, 500);

  const search = useCallback(async (value: string) => {
    if (!value) return;
    setLoading(true);
    setChoices([]);
    const result = await onSearch(value);
    setLoading(false);
    setChoices(result);
  }, []);

  useOnChange(search, [debouncedSearchInput]);

  return (
    <SelectField
      label={label}
      selectedValue={value ? { label: value, value: value } : undefined}
      choices={choices}
      noOptionsMessage={() => (loading ? 'Laster' : 'Fant ikke noe på søk')}
      onInputChange={setSearchInput}
      onChange={(e) => {
        setValue(e.label);
        onChange(e.value, e.label);
      }}
      validationState={validationState}
      feedback={feedback}
      feedbackMargin={feedbackMargin}
      components={{ DropdownIndicator }}
      placeholder={placeholder}
      formatOptionLabel={formatOptionLabel}
    />
  );
}
