import React, { FC, useState, ChangeEvent } from 'react';

import { post, upload } from 'js/api-helper';

import Heading from '../../../components/Heading/Heading';
import Accordion from '../../../components/Accordion/Accordion';

import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import { LinkItem } from 'types/LinkItem';

import FormSubmitBox from '../../../components/FormSubmitBox/FormSubmitBox';
import Modal from '../../../components/Modal/Modal';
import ReccomendationLabel, { SeriositetsLabels } from '../../../components/ReccomendationLabel/ReccomendationLabel';
import Text from 'components/Text/Text';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import Checkbox from 'components/Checkbox/Checkbox';
import Alert from 'components/Alert/Alert';
import { ObjektVurdering } from 'types/ObjektVurdering';
import RapportVisning from 'types/no-gen/RapportVisning';

export interface SeriositetsRapportSide {
  title: string;
  summary: { title: string; text: string; anbefaling: ObjektVurdering }[];
  seriositetsSjekkFullfort: boolean;

  visning: number;
  pageLinks?: LinkItem[];
  sendAnbefalingerUrl?: string; // url hvor PL kan sende anbefaling
  angreAnbefalingerUrl?: string; // url hvor PL kan angre anbefalinger

  anbefalingerGodkjentKommentar?: string; //evt. kommentarer OG har gitt
  anbefalingerGodkjennUrl?: string;
  anbefalingerGodkjentDato?: string;
}

interface ModalData {
  open: boolean;
  title: string;
  body: string;
}

const SeriositetsRapportSide: FC<SeriositetsRapportSide> = ({
  title,
  pageLinks,
  summary,
  seriositetsSjekkFullfort,

  sendAnbefalingerUrl,
  angreAnbefalingerUrl,
  anbefalingerGodkjentKommentar,
  anbefalingerGodkjennUrl,
  visning
}) => {
  const [formdata, setFormdata] = useState({
    anbefalingerGodkjentKommentar: anbefalingerGodkjentKommentar ?? '',
    betingelserGodtatt: visning == RapportVisning.godkjentRapportView ?? false
  });

  const [view, setView] = useState<RapportVisning>(visning);

  const [modal, setModal] = useState<ModalData>({ open: false, title: '', body: '' });

  const [error, setError] = useState<string>();

  const submitReccomendations = async (url) => {
    try {
      const res = await post(url, { anbefalingerSendt: true });
      if (res) {
        setModal({
          open: true,
          title: 'Evalueringer og anbefalinger sendt til oppdragsgiver for godkjennelse',
          body: 'Oppdragsgiver vil nå varsles om at seriøsitetsjekken er evaluert med anbefalninger. Du vil få en epost når Oppdragsgiver har gitt sin godkjennelse.'
        });
        setView(RapportVisning.levertRapportView);
      }
    } catch (error) {
      setError('oops! noe gikk galt!');
    }
  };

  const acceptReccomendations = async (url) => {
    //post submit, submit relevant comments if any
    try {
      const res = await post(url, { kommentar: formdata.anbefalingerGodkjentKommentar });
      if (res) {
        setModal({
          open: true,
          title: 'Oppdragsgivers godkjennelse er mottatt',
          body: 'Seriøsitetssjekken er nå fullført og prosessen vil gå videre til konkurranse.'
        });
        setView(RapportVisning.godkjentRapportView);
      }
    } catch (error) {
      setError('oops! noe gikk galt!');
    }
  };

  const regretReccomendations = async (undoUrl) => {
    try {
      const res = await post(undoUrl);
      if (res) {
        setView(RapportVisning.redigerRapportView);
      }
    } catch (error) {
      console.log(error);
      setError('oops! noe gikk galt!');
    }
  };

  return (
    <div className="rapport">
      <Heading>{title}</Heading>
      {pageLinks && pageLinks?.length > 0 && <PageLinkMenu pageLinks={pageLinks} />}
      <Accordion title="Seriøsitetsvurdering og kommentarer" defaultOpen={summary.length > 0}>
        <div className="summary">
          {summary &&
            seriositetsSjekkFullfort &&
            summary.map((item) => {
              return (
                <div className="summary__item" key={item.title}>
                  <div>
                    <h3>{item.title}</h3>
                    <ReccomendationLabel anbefalingsEnum={SeriositetsLabels} vurdering={item.anbefaling} />
                  </div>
                  <p>{item.text}</p>
                </div>
              );
            })}
        </div>
      </Accordion>
      <Modal
        title={modal.title}
        open={modal.open}
        onClose={() =>
          setModal({
            ...modal,
            open: false
          })
        }
      >
        <p>{modal.body}</p>
      </Modal>
      {/* {!formdata.anbefalingerSendt && !formdata.anbefalingerGodkjent && allowEditRoles.includes(rolle) && ( */}
      {view === RapportVisning.redigerRapportView && (
        <>
          <FormSubmitBox
            heading={'Send til oppdragsgiver for godkjennelse'}
            bodyText={'Først når alle objekter er evaluert og anbefalninger er gitt kan du be om oppdragsgivers godkjennelse.'}
            disabled={!seriositetsSjekkFullfort}
            buttonText={'Send til Oppdragsgiver'}
            submitUrl={sendAnbefalingerUrl ?? ''}
            submitFunc={(url) => {
              submitReccomendations(url);
            }}
          />
        </>
      )}
      {view === RapportVisning.levertRapportView && (
        <>
          <div className="complete">
            <div className="font-paragraph-header">Anbefalinger har blitt levert til oppdragsgiver</div>
            <div className="complete__bottom-row">
              <p className="font-paragraph text">Prosessen avventer nå godkjenning.</p>
              <Button
                className="undo"
                submit={true}
                secondary={true}
                disabled={false}
                onClick={() => regretReccomendations(angreAnbefalingerUrl)}
              >
                Angre
              </Button>
            </div>
          </div>
        </>
      )}
      {view === RapportVisning.lesRapportView && (
        <form className="SeriositetEvaluering">
          <h2>Godkjenn og send til prosjektleder</h2>

          <>
            <Text variant="mono-16">Oppdragsgivers vurdering</Text>
            <Field
              maxCharacters={500}
              className="CommentArea"
              label="Kommentar"
              type="textarea"
              name="anbefalingerGodkjentKommentar"
              value={formdata.anbefalingerGodkjentKommentar}
              onChange={(e) =>
                setFormdata({
                  ...formdata,
                  [e.currentTarget.name]: e.currentTarget.value
                })
              }
            />
          </>

          <p className="max-char-number">
            ({formdata.anbefalingerGodkjentKommentar.length}/{500})
          </p>

          <Text variant="mono-16">Oppdragsgivers godkjenning</Text>
          <div className="font-paragraph">
            <Checkbox
              name="godkjent"
              label="Oppsummeringer og anbefalinger er lest og godkjent"
              checked={formdata.betingelserGodtatt}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  betingelserGodtatt: e.currentTarget.checked
                });
              }}
            ></Checkbox>
          </div>
          <div className="SeriositetEvaluering--bottom">
            <p>
              For å gå videre til konkurransen må du som oppdragsgiver bekrefte at du har lest og godkjent seriøsitetssjekken fra
              Statensinnleie.
            </p>

            <Button
              disabled={!formdata.betingelserGodtatt}
              onClick={() => {
                acceptReccomendations(anbefalingerGodkjennUrl);
              }}
            >
              Godkjenn
            </Button>
          </div>
        </form>
      )}
      {view === RapportVisning.godkjentRapportView && (
        <div className="SeriositetEvaluering">
          <div className="font-paragraph-header">Oppdragsgiver har godkjent</div>
          {formdata?.anbefalingerGodkjentKommentar && (
            <>
              <Text variant="mono-16">Oppdragsgivers vurdering</Text>
              <p className="approved-text">{formdata?.anbefalingerGodkjentKommentar}</p>
            </>
          )}
          <div className="font-paragraph">
            <Text variant="mono-16">Oppdragsgivers godkjenning</Text>
          </div>
          <div className="font-paragraph">
            <Checkbox name="godkjent" label="Oppsummeringer og anbefalinger er lest og godkjent" checked={true}></Checkbox>
          </div>
        </div>
      )}
      {error && <Alert kind="danger">{error}</Alert>}
    </div>
  );
};

export default SeriositetsRapportSide;
