import ArcGisLoader from 'components/ArcGisKart/ArcGisLoader';
import IconButton from 'components/IconButton/IconButton';
import Popup from 'components/Popup/Popup';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { Prosjekt } from 'types/Prosjekt';
import ProsjektRedigerContainer from '../ProsjektRedigerContainer';
import React, { FC, useRef, useState } from 'react';
import useOnClickOutside from 'js/hooks/useOnClickOutside';
import { ArcgisSketch } from 'types/ArcgisSketch';
import { ArcgisServiceArea } from 'types/ArcgisServiceArea';

export interface KartRedigerSide {
  readonly headerText: string;
  readonly prosjektData: Prosjekt;
  readonly avsluttUrl: string;
  readonly forrigeUrl: string;
  readonly nesteUrl: string;
  readonly deleteUrl?: string;
  readonly slettProsjektBekreftTekst: string;
  readonly archiveProsjektUrl?: string;
  readonly redigerKartUrl: string;

  readonly saveEndpoint?: string;
  readonly readOnly: boolean; // false på rediger kart side
  readonly center: string; // [10.77, 60.0]
  readonly zoom: number; // 12
  // readonly svarList?: SvarListItem[]; // oppretter markers i ArcGis basert på svar
  readonly sketches?: ArcgisSketch[]; // Omrisset fra redigeringen må lagres i backend
  readonly serviceAreas?: ArcgisServiceArea[]; // Reisedistanse
}

const KartRedigerSide: FC<KartRedigerSide> = ({
  headerText,
  avsluttUrl,
  forrigeUrl,
  nesteUrl,
  deleteUrl,
  slettProsjektBekreftTekst,
  archiveProsjektUrl,

  sketches,
  serviceAreas,
  saveEndpoint,
  readOnly,
  center,
  zoom
}) => {
  const [displayHelpImage, setDisplayHelpImage] = useState(false);
  const tooltipRef = useRef(null);
  useOnClickOutside(tooltipRef, () => setDisplayHelpImage(false));
  const postback = async () => '';

  return (
    <ProsjektRedigerContainer
      hasAbsoluteButtonRow
      headerText={headerText}
      hideRequiredText
      isDirty={false}
      isBusy={false}
      currentStep={3}
      canGoToNextPage
      disableSave
      quitUrl={avsluttUrl}
      nextPageUrl={nesteUrl}
      previousPageUrl={forrigeUrl}
      save={postback}
      slettProsjektBekreftTekst={slettProsjektBekreftTekst}
      deleteUrl={deleteUrl}
      archiveProsjektUrl={archiveProsjektUrl}
    >
      <div
        className="KartRedigerSide"
        style={{
          minHeight: '100vh',
          height: '100vh',
          maxHeight: '100vh'
        }}
      >
        <div className={'KartRedigerSide__toolbar'}>
          <div className={'KartRedigerSide__tooltip-icon'}>
            <IconButton
              onClick={() => setDisplayHelpImage(!displayHelpImage)}
              type="noBorder"
              size="large"
              title="Hjelpebilde"
            >
              <AiFillQuestionCircle />
            </IconButton>
          </div>
        </div>
        <Popup
          isOpen={displayHelpImage}
          close={() => setDisplayHelpImage(false)}
        >
          <div
            ref={tooltipRef}
            role={'image'}
            className={'KartRedigerSide__help-image'}
          />
        </Popup>
        <div className={'KartRedigerSide__image-container'}>
          <ArcGisLoader
            sketches={sketches}
            serviceAreas={serviceAreas}
            saveEndpoint={saveEndpoint}
            readOnly={readOnly}
            center={center}
            zoom={zoom}
          />
        </div>
      </div>
    </ProsjektRedigerContainer>
  );
};

export default KartRedigerSide;
