import { useMemo, useState } from 'react';
import useBooleanState from 'js/hooks/useBooleanState';

export default function useSvarInnkomneListOperations<
  T extends {
    befaring?: {
      tilBefaring?: boolean;
    };
  }
>(innkomneSvar: T[]) {
  const [mapPopup, setMapPopup] = useState(false);
  const [bareTilBefaring, , toggleBareTilBefaring] = useBooleanState(false);
  const [viewMode, setViewMode] = useState<'table' | 'map'>('table');

  const now = useMemo(() => {
    const d = new Date();
    return `${d.getUTCDate()}/${d.getUTCMonth() + 1}/${d.getUTCFullYear()}`;
  }, []);

  const [currentInnkomneSvar, setCurrentInnkomneSvar] = useState<T[]>(
    innkomneSvar
  );

  const sortList = (list): T[] => {
    function compareBefaring(a, b): number {
      return a?.befaring?.tilBefaring === b?.befaring?.tilBefaring
        ? 0
        : a?.befaring?.tilBefaring
        ? -1
        : 1;
    }

    function compareDate(a, b): number {
      const aMs = new Date(a.levertSvarDato).getTime();
      const bMs = new Date(b.levertSvarDato).getTime();
      if (aMs === bMs) return 0;

      return bMs - aMs;
    }

    function compareScore(a, b): number {
      if (
        a.overstyrtScoreHelhetsvurdering === b.overstyrtScoreHelhetsvurdering
      ) {
        return 0;
      }
      return (
        (b.overstyrtScoreHelhetsvurdering || 0) -
        (a.overstyrtScoreHelhetsvurdering || 0)
      );
    }

    return list.sort((a, b) => {
      return compareBefaring(a, b) || compareScore(a, b) || compareDate(a, b);
    }) as T[];
  };

  const sortedSvar: T[] = useMemo(() => {
    if (bareTilBefaring) {
      return sortList(
        currentInnkomneSvar.filter(
          (_svar) => !bareTilBefaring || _svar?.befaring?.tilBefaring
        )
      );
    } else return currentInnkomneSvar;
  }, [currentInnkomneSvar, bareTilBefaring]);

  return {
    sortedSvar,
    now,
    mapPopup,
    setMapPopup,
    viewMode,
    setViewMode,
    toggleBareTilBefaring,
    setCurrentInnkomneSvar
  };
}
