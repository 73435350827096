import {
  BiLogIn,
  BiUserCircle,
  BiSearch,
  BiTrash,
  BiMinus
} from 'react-icons/bi';
import {
  HiOutlineDotsVertical,
  HiOutlineExclamationCircle,
  HiExclamation
} from 'react-icons/hi';
import {
  TiArrowSortedUp,
  TiArrowSortedDown,
  TiArrowUnsorted
} from 'react-icons/ti';
import { MdOpenInNew, MdArchive } from 'react-icons/md';
import React, { useMemo } from 'react';
import classnames from 'classnames';
import styles from './IconReactIcons.module.css';
import {
  AiOutlineClose,
  AiOutlinePrinter,
  AiOutlinePlus,
  AiOutlineSync,
  AiOutlineReload,
  AiOutlineFilePdf
} from 'react-icons/ai';
import {
  IoMdHelp,
  IoMdHelpCircleOutline,
  IoMdInformation,
  IoMdInformationCircleOutline
} from 'react-icons/io';
import { FaListOl } from 'react-icons/fa';
import { ImEmbed } from 'react-icons/im';
import {
  FiChevronsLeft,
  FiChevronLeft,
  FiChevronUp,
  FiChevronRight,
  FiChevronsRight,
  FiChevronDown,
  FiUpload,
  FiDownload,
  FiEdit,
  FiArchive
} from 'react-icons/fi';

import { ImFileText2, ImCheckmark } from 'react-icons/im';

export enum IconTypes {
  openInNew,
  login,
  userCircle,
  helpCircle,
  help,
  close,
  fiArchive,
  printer,
  plus,
  search,
  remove,
  spinner,
  arrowUp,
  arrowDown,
  arrowLeft,
  arrowRight,
  doubleArrowRight,
  doubleArrowLeft,
  trash,
  information,
  informationCircleOutline,
  archive,
  listOl,
  embed,
  reload,
  dotsVertical,
  filePdf,
  upload,
  download,
  sortUp,
  sortDown,
  unsorted,
  file,
  checkMark,
  exclamationCircle,
  exclamationTriangle,
  edit
}

interface Props {
  type: IconTypes;
  size?: number;
}

export const getIcon = (
  type: IconTypes
): { alt: string; Component: React.ReactNode } => {
  switch (type) {
    case IconTypes.openInNew:
      return { Component: <MdOpenInNew />, alt: 'Åpne i ny fane' };
    case IconTypes.login:
      return { Component: <BiLogIn />, alt: 'Logg inn' };
    case IconTypes.userCircle:
      return { Component: <BiUserCircle />, alt: 'Bruker' };
    case IconTypes.helpCircle:
      return { Component: <IoMdHelpCircleOutline />, alt: 'Hjelp' };
    case IconTypes.help:
      return { Component: <IoMdHelp />, alt: 'Hjelp' };
    case IconTypes.close:
      return { Component: <AiOutlineClose />, alt: 'Lukk' };
    case IconTypes.printer:
      return { Component: <AiOutlinePrinter />, alt: 'Print' };
    case IconTypes.plus:
      return { Component: <AiOutlinePlus />, alt: 'Legg til' };
    case IconTypes.search:
      return { Component: <BiSearch />, alt: 'Søk' };
    case IconTypes.remove:
      return { Component: <BiMinus />, alt: 'Fjern' };
    case IconTypes.spinner:
      return { Component: <AiOutlineSync className="spin" />, alt: 'Laster' };
    case IconTypes.listOl:
      return { Component: <FaListOl />, alt: 'Liste' };
    case IconTypes.embed:
      return { Component: <ImEmbed />, alt: 'Embedded' };
    case IconTypes.reload:
      return { Component: <AiOutlineReload />, alt: 'reload' };
    case IconTypes.dotsVertical:
      return { Component: <HiOutlineDotsVertical style={{color: '#005763'}} />, alt: 'Mer' };
    case IconTypes.filePdf:
      return { Component: <AiOutlineFilePdf />, alt: 'fil' };

    case IconTypes.upload:
      return { Component: <FiUpload />, alt: 'Last opp' };
    case IconTypes.download:
      return { Component: <FiDownload />, alt: 'Last ned' };
    case IconTypes.arrowUp:
      return { Component: <FiChevronUp />, alt: 'Opp' };
    case IconTypes.arrowDown:
      return { Component: <FiChevronDown />, alt: 'Ned' };
    case IconTypes.arrowLeft:
      return { Component: <FiChevronLeft />, alt: 'Venstre' };
    case IconTypes.arrowRight:
      return { Component: <FiChevronRight />, alt: 'Høyre' };
    case IconTypes.doubleArrowRight:
      return { Component: <FiChevronsRight />, alt: 'Høyre' };
    case IconTypes.doubleArrowLeft:
      return { Component: <FiChevronsLeft />, alt: 'Venstre' };
    case IconTypes.trash:
      return { Component: <BiTrash />, alt: 'Søppelkanne' };
    case IconTypes.information:
      return { Component: <IoMdInformation />, alt: 'Informasjon' };
    case IconTypes.informationCircleOutline:
      return {
        Component: <IoMdInformationCircleOutline />,
        alt: 'Information'
      };
    case IconTypes.archive:
      return { Component: <MdArchive />, alt: 'Arkiv' };
    case IconTypes.fiArchive:
      return { Component: <FiArchive />, alt: 'Arkiv' };
    case IconTypes.sortUp:
      return { Component: <TiArrowSortedUp />, alt: 'Sorter opp' };
    case IconTypes.sortDown:
      return { Component: <TiArrowSortedDown />, alt: 'Sorter ned' };
    case IconTypes.unsorted:
      return { Component: <TiArrowUnsorted />, alt: 'Usortert' };
    case IconTypes.file:
      return { Component: <ImFileText2 />, alt: 'Fil' };
    case IconTypes.checkMark:
      return {
        Component: <ImCheckmark style={{ fill: 'green' }} />,
        alt: 'Hake'
      };
    case IconTypes.exclamationCircle:
      return { Component: <HiOutlineExclamationCircle />, alt: 'Informasjon' };
    case IconTypes.exclamationTriangle:
      return { Component: <HiExclamation />, alt: 'Utelukke' };
    case IconTypes.edit:
      return { Component: <FiEdit />, alt: 'Rediger' };
    default:
      return { Component: null, alt: '' };
  }
};

const IconReactIcons = ({ type, size = 2 }: Props) => {
  const iconClasses = classnames(styles.defaultSize, {
    [styles.sizex1]: size === 1,
    [styles.sizex2]: size === 2,
    [styles.sizex3]: size === 3,
    [styles.sizex4]: size === 4,
    [styles.sizex5]: size === 5
  });

  const { Component, alt } = useMemo(() => getIcon(type), [type]);

  return (
    <span alt={alt} className={iconClasses}>
      {Component}
    </span>
  );
};

export default IconReactIcons;
