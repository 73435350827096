// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useMemo } from 'react';
import ReactDOM from 'react-dom';
import { MarkerT } from '../ArcGis';
import { getIconUrl } from '../arcGis.helper';
import { spatialReferenceWGS84 } from '../ArcGisKart';

/**
 *
 * @param id 1 = buss, 2 = tbane, 3 = trikk, 4 = ferge, 5 = tog, 6 = flyplass, 7 = ladestasjon
 */
const getSamferdselUrl = (id) => {
  return `https://services.geodataonline.no/arcgis/rest/services/Geomap_UTM33_EUREF89/GeomapSamferdsel/FeatureServer/${id}&spatialReference=${spatialReferenceWGS84.wkid}`;
};

export const useGetLayers = ({
  initialMarkers,
  initialServiceAreas,
  initialSketches,
  setServiceAreaPoint,
  lib
}) => {
  const token = sessionStorage.getItem('geodata_token');
  const { Point, Graphic, GraphicsLayer, FeatureLayer, Color } = lib;
  const {
    markersServiceAreaWalkLayer,
    markersServiceAreaDriveLayer
  } = useMemo(() => {
    const markersServiceAreaWalkLayer = new GraphicsLayer({
      spatialReference: spatialReferenceWGS84,

      listMode: Array.isArray(initialMarkers) ? 'show' : 'hide',
      visible: false,
      title: 'Markers Service Area layer'
    });
    const markersServiceAreaDriveLayer = new GraphicsLayer({
      spatialReference: spatialReferenceWGS84,

      listMode: Array.isArray(initialMarkers) ? 'show' : 'hide',
      visible: false,
      title: 'Markers Service Area layer'
    });
    if (Array.isArray(initialMarkers)) {
      initialMarkers.forEach(({ latitude, longitude }: MarkerT) => {
        setServiceAreaPoint({
          hideMarker: true,
          point: {
            latitude,
            longitude
          },
          travelMode: {
            type: 3,
            distance: 1000
          },
          layer: markersServiceAreaWalkLayer
        });
        setServiceAreaPoint({
          hideMarker: true,
          point: {
            latitude,
            longitude
          },
          travelMode: {
            type: 1,
            distance: 10
          },
          layer: markersServiceAreaDriveLayer
        });
      });
    }
    return { markersServiceAreaWalkLayer, markersServiceAreaDriveLayer };
  }, []);

  const markersGraphicLayer = useMemo(() => {
    const markersGraphiclayer = new GraphicsLayer({
      spatialReference: spatialReferenceWGS84,

      listMode: 'show',
      title: 'Markers layer'
    });
    if (Array.isArray(initialMarkers)) {
      initialMarkers.forEach(
        ({ latitude, longitude, popupTemplate, markerColor }: MarkerT) => {
          const point = new Point({ latitude, longitude });

          let symbolData = {};
          symbolData = {
            ...symbolData,
            url: getIconUrl(markerColor),
            type: 'picture-marker',
            width: 18,
            height: 18
          };
          const graphicData = {
            geometry: point,
            symbol: symbolData
          };
          if (popupTemplate?.element) {
            const div = document.createElement('div');
            ReactDOM.render(popupTemplate.element, div);
            graphicData.popupTemplate = {
              content: div,
              title: popupTemplate.title
            };
          } else if (popupTemplate?.content) {
            graphicData.popupTemplate = popupTemplate;
          }

          const graphic = new Graphic(graphicData);

          markersGraphiclayer.add(graphic);
        }
      );
    }
    return markersGraphiclayer;
  }, []);

  const sketchGraphicLayer = useMemo(() => {
    const layerObject: {
      listMode: string;
      visible: boolean;
      title: string;
    } = {
      listMode: 'show',
      visible: true,
      title: 'Sketch layer'
    };
    if (Array.isArray(initialSketches)) {
      const processedParsedSketchGraphics = initialSketches.map(
        ({ symbol, geometry }) => {
          return {
            symbol: {
              color: new Color({
                a: 0.2,
                r: 150,
                g: 150,
                b: 150
              }),
              style: symbol.style,
              type: symbol.type
            },
            geometry: {
              type: geometry.type,
              rings:
                typeof geometry.rings === 'string'
                  ? JSON.parse(geometry.rings)
                  : geometry.rings,
              spatialReference: {
                latestWkid: 3857,
                wkid: 102100
              }
            }
          };
        }
      );
      layerObject.graphics = processedParsedSketchGraphics;
    }
    return new GraphicsLayer(layerObject);
  }, []);

  const { walkServiceAreaLayer, driveServiceAreaLayer } = useMemo(() => {
    const walkServiceAreaLayer = new GraphicsLayer({
      spatialReference: spatialReferenceWGS84,

      listMode: 'show',
      title: 'Walk Area layer'
    });
    const driveServiceAreaLayer = new GraphicsLayer({
      spatialReference: spatialReferenceWGS84,

      listMode: 'show',
      title: 'Drive Area layer'
    });

    if (Array.isArray(initialServiceAreas)) {
      initialServiceAreas.forEach(
        ({ type, longitude, latitude, travelMode }) => {
          if (type === 'point') {
            setServiceAreaPoint({
              point: {
                type,
                latitude:
                  typeof latitude === 'string'
                    ? JSON.parse(latitude)
                    : latitude,
                longitude:
                  typeof longitude === 'string'
                    ? JSON.parse(longitude)
                    : longitude,
                spatialReference: {
                  latestWkid: 3857,
                  wkid: 102100
                }
              },
              layer:
                travelMode?.type === 1
                  ? driveServiceAreaLayer
                  : walkServiceAreaLayer,
              travelMode: travelMode
            });
          }
        }
      );
    }

    return { walkServiceAreaLayer, driveServiceAreaLayer };
  }, []);

  // create geojson layer from usgs earthquakes geojson feed
  const busLayer = useMemo(() => {
    if (!token) return null;
    return new FeatureLayer({
      spatialReference: spatialReferenceWGS84,
      visible: false,
      title: 'Buss',
      url: getSamferdselUrl(1)
    });
  }, []);

  const tbaneLayer = useMemo(() => {
    if (!token) return;
    return new FeatureLayer({
      spatialReference: spatialReferenceWGS84,
      visible: false,
      title: 'T-Bane',
      url: getSamferdselUrl(2)
    });
  }, []);

  const trikkLayer = useMemo(() => {
    if (!token) return;
    return new FeatureLayer({
      spatialReference: spatialReferenceWGS84,
      visible: false,
      title: 'Trikk',
      url: getSamferdselUrl(2)
    });
  }, []);

  // const mallsLayer = useMemo(() => {
  //   return new FeatureLayer({
  //     visible: false,
  //     title: 'Handlesenter',
  //     url: `https://services.geodataonline.no/arcgis/rest/services/Geomap_UTM33_EUREF89/GeomapPOI/FeatureServer/3?spatialReference=${spatialReferenceWGS84.wkid}`
  //   });
  // }, []);

  const togLayer = useMemo(() => {
    if (!token) return;
    return new FeatureLayer({
      spatialReference: spatialReferenceWGS84,
      visible: false,
      title: 'Tog',
      url: getSamferdselUrl(5)
    });
  }, []);

  const tokenLayers = [togLayer, trikkLayer, tbaneLayer, busLayer];
  const allLayers = [
    markersServiceAreaWalkLayer,
    markersServiceAreaDriveLayer,
    walkServiceAreaLayer,
    driveServiceAreaLayer,
    ...tokenLayers,
    sketchGraphicLayer, // The further down here, the more click prioritization does it get on the map
    markersGraphicLayer
  ];

  return {
    markersGraphicLayer,
    markersServiceAreaWalkLayer,
    markersServiceAreaDriveLayer,
    sketchGraphicLayer,
    walkServiceAreaLayer,
    driveServiceAreaLayer,
    togLayer,
    trikkLayer,
    tbaneLayer,
    busLayer,
    allLayers
  };
};
