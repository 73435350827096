import cn from 'classnames';
import TriPie from 'components/TriPie/TriPie';
import { post } from 'js/api-helper';
import { useOnChange } from 'js/hooks';
import React, { useCallback } from 'react';
import { Score } from 'types/Score';
import { SvarListItem } from 'types/SvarListItem';
import styles from './InnkomneItem.module.css';
import Text from 'components/Text/Text';
import dragAndDropIconUrl from '../../assets/svg/dragndrop.svg';

interface Props {
  setCurrentInnkomneSvar?: React.Dispatch<React.SetStateAction<unknown[]>>;
  readonly detailsList: {
    value: string;
    label: string;
  }[];
  readonly heading: React.ReactNode;
  readonly scores: Score[];
  readonly id: string;
  readonly url: string;
  readonly image: string;
  readonly bygningensStand?: string;
  readonly overstyrtScoreHelhetsvurdering?: number;
  readonly befaring?: {
    readonly settEllerFjernTilBefaringUrl?: string;
    readonly tilBefaring: boolean;
  };
}

const bygningTotalScoreToCSSClass = (score?: number): string => {
  switch (score) {
    case 1:
      return styles.totalScoreBad;
    case 2:
      return styles.totalScoreMiddle;
    case 3:
      return styles.totalScoreGood;
    default:
      return styles.totalScoreNone;
  }
};

export const DragAndDropBefaringItem = ({ title, overstyrtScoreHelhetsvurdering }) => {
  return (
    <div className={styles.DragAndDropItem}>
      <h2>{title}</h2>
      <div
        style={{
          background: `url(${dragAndDropIconUrl}) no-repeat`,
          height: 10,
          width: 40
        }}
      />
      <svg
        className={cn(styles.totalScore, bygningTotalScoreToCSSClass(overstyrtScoreHelhetsvurdering))}
        preserveAspectRatio="none"
        viewBox="0 0 10 100"
      >
        <rect width="10" height="100" />
      </svg>
    </div>
  );
};

export default function InnkomneItem({
  url,
  id,
  image,
  heading,
  detailsList,
  befaring,
  scores,
  bygningensStand,
  overstyrtScoreHelhetsvurdering,
  setCurrentInnkomneSvar
}: Props) {
  const bygningstandToCSSClass = (item?: 'Ikke oppført' | 'Total rehabilitering' | 'Eksisterende' | string): string => {
    switch (item) {
      case 'Ikke oppført':
        return styles.ikkeoppfort;
      case 'Eksisterende':
        return styles.eksisterende;
      case 'Total rehabilitering':
        return styles.totalRehab;
      default:
        return styles.ingenStand;
    }
  };

  const bygningTotalScoreToCSSClass = (score?: number): string => {
    switch (score) {
      case 1:
        return styles.totalScoreBad;
      case 2:
        return styles.totalScoreMiddle;
      case 3:
        return styles.totalScoreGood;
      default:
        return styles.totalScoreNone;
    }
  };

  const settEllerFjernTilBefaring = useCallback(
    async (value: boolean) => {
      await post(befaring?.settEllerFjernTilBefaringUrl as string, { value });
    },
    [befaring?.settEllerFjernTilBefaringUrl]
  );

  const handleSetTilBefaring = async (e) => {
    e.stopPropagation();
    if (setCurrentInnkomneSvar) {
      setCurrentInnkomneSvar((_currentInnkomneSvar: SvarListItem[]) => {
        const update = _currentInnkomneSvar.map((innkomneSvar) => {
          const tilBefaring = innkomneSvar.id === id ? !innkomneSvar?.befaring?.tilBefaring : innkomneSvar?.befaring?.tilBefaring;
          const settEllerFjernTilBefaringUrl = innkomneSvar?.befaring?.settEllerFjernTilBefaringUrl;

          return {
            ...innkomneSvar,
            befaring: {
              settEllerFjernTilBefaringUrl,
              tilBefaring
            }
          };
        });
        return update;
      });
    }
  };

  useOnChange(settEllerFjernTilBefaring, [befaring?.tilBefaring]);

  return (
    <a className={styles.svar} href={url}>
      <span className={cn(styles.totalScore, bygningTotalScoreToCSSClass(overstyrtScoreHelhetsvurdering))} />
      <div className={styles.svarPicture}>
        <img src={`${image}?maxWidth=200&maxHeight=300`} />
        <div className={cn(styles.svarStand, bygningstandToCSSClass(bygningensStand))}>
          <p>{bygningensStand}</p>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.heading}>
          <Text variant={'annonse-grid-title'} component="h3">
            {heading}
          </Text>
          {befaring && setCurrentInnkomneSvar && (
            <Text variant="paragraph" component="label" className={styles.tilbefaringLabel} htmlFor={id}>
              <input id={id} type="checkbox" autoComplete="off" onChange={handleSetTilBefaring} checked={befaring?.tilBefaring} />
              Til befaring?
            </Text>
          )}
        </div>
        <div className={styles.details}>
          {detailsList.map((detail) => (
            <div key={detail.value}>
              <Text variant="list-item">
                {detail.label} <b className={styles.blueColor}>{detail.value}</b>
              </Text>
            </div>
          ))}
        </div>

        <div className={styles.rating}>
          {scores?.map(({ kalkulertScore, overstyrtScore, type }) => (
            <div key={type} className={styles.Scoreitem}>
              <TriPie kalkulert={kalkulertScore} overstyrt={overstyrtScore} />
              <Text variant={'list-item'}>{type}</Text>
            </div>
          ))}
        </div>
      </div>
    </a>
  );
}
