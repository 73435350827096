import { useEffect } from 'react';
import { ifWindow } from 'js/checkWindow';

export default function useBeforeUnload(preventDefault) {
  useEffect(() => {
    if (!preventDefault) {
      ifWindow(() => {
        window.onhashchange = null;
        window.onbeforeunload = null;
      });

      return;
    }

    ifWindow(() => {
      window.addEventListener('hashchange', onhashChange);
      window.onbeforeunload = confirmExit;
    });

    function confirmExit() {
      return 'show warning';
    }
    function onhashChange(e) {
      e.preventDefault();
    }

    return () => {
      ifWindow(() => {
        window.onhashchange = null;
        window.onbeforeunload = null;
      });
    };
  }, [preventDefault]);
}
