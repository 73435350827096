import Text from 'components/Text/Text';
import React from 'react';
import { Fildata } from 'types/Fildata';
import styles from './FileList.module.css';
export interface Props {
  readonly files: Fildata[];
}

export default function FileList({ files }: Props) {
  if (files.length === 0) return null;

  return (
    <ul className={styles.FileList}>
      {files.map((e) => (
        <li key={e.id}>
          <Text variant="download-link" component="a" href={e.nedlastingslenke}>
            {e.visningsnavn}
          </Text>
        </li>
      ))}
    </ul>
  );
}
