import React, { useEffect, useState } from 'react';

import './FileListWithSort.scss';

import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import { Fildata } from 'types/Fildata';
import { shortDateTimeString } from 'js/date-helper';

interface FileListWithSortProps {
  files: Fildata[];
  onRemove: (id: string) => void;
  readOnly: boolean;
  interactionDisabled: boolean;
}

enum sortOptions {
  dateAscending = 'dateAsc',
  dateDescending = 'dateDesc',
  documentAscending = 'documentAsc',
  documentDescending = 'documentDesc'
}

const FilelistWithSort = ({ files, onRemove, readOnly, interactionDisabled }: FileListWithSortProps) => {
  const [sortOption, setSortOption] = useState('');
  const [orderedFiles, setOrderedFiles] = useState<Fildata[]>(files);

  const dateSort = (a: Fildata, b: Fildata) => {
    const dateA = new Date(a.opplastetDato ?? '');
    const dateB = new Date(b.opplastetDato ?? '');

    if (sortOption == sortOptions.dateDescending) {
      return dateA > dateB ? 1 : -1;
    } else if (sortOption == sortOptions.dateAscending) {
      return dateB > dateA ? 1 : -1;
    }
    return 0;
  };

  useEffect(() => {
    setOrderedFiles((prevState) => files);
  }, [files]);

  useEffect(() => {
    switch (sortOption) {
      case sortOptions.dateAscending:
        setOrderedFiles([...orderedFiles.sort(dateSort)]);
        break;
      case sortOptions.dateDescending:
        setOrderedFiles([...orderedFiles.sort(dateSort)]);
        break;
      case sortOptions.documentAscending:
        setOrderedFiles([
          ...orderedFiles.sort((a, b) => {
            return ('' + a.visningsnavn).localeCompare(b.visningsnavn);
          })
        ]);
        break;
      case sortOptions.documentDescending:
        setOrderedFiles([
          ...orderedFiles.sort((a, b) => {
            return ('' + b.visningsnavn).localeCompare(a.visningsnavn);
          })
        ]);
        break;
    }
  }, [sortOption]);

  return (
    <div className="filelist-with-sort">
      <div className="filelist-with-sort__filters">
        <div>
          <button
            onClick={() => {
              if (sortOption === sortOptions.dateDescending) {
                setSortOption(sortOptions.dateAscending);
              } else {
                setSortOption(sortOptions.dateDescending);
              }
            }}
          >
            <SortOptionLabel
              title="Opprettet"
              activeOption={sortOption}
              conditionLabels={[sortOptions.dateAscending, sortOptions.dateDescending]}
            />
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              if (sortOption === sortOptions.documentDescending) {
                setSortOption(sortOptions.documentAscending);
              } else {
                setSortOption(sortOptions.documentDescending);
              }
            }}
          >
            <SortOptionLabel
              title="Dokument"
              activeOption={sortOption}
              conditionLabels={[sortOptions.documentAscending, sortOptions.documentDescending]}
            />
          </button>
        </div>
      </div>
      <ul>
        {orderedFiles?.map((file) => (
          <li key={file.id} className={`filelist-with-sort${interactionDisabled && ' -disabled'}`}>
            <div className="filelist-with-sort__date">{file.opplastetDato && shortDateTimeString(file.opplastetDato)}</div>
            <div className="filelist-with-sort__displayName">
              <a href={file.nedlastingslenke}>{file.visningsnavn}</a>
            </div>
            {!readOnly && (
              <button
                disabled={interactionDisabled}
                onClick={() => {
                  if (!interactionDisabled) onRemove(file.id);
                }}
              >
                <IconReactIcons type={IconTypes.trash} size={3} />
              </button>
            )}
            {readOnly && (
              <a className="download-file" href={file.nedlastingslenke}>
                <IconReactIcons type={IconTypes.download} size={2} />
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const SortOptionLabel = ({ title, activeOption, conditionLabels }) => {
  const optionA = conditionLabels[0];
  const optionB = conditionLabels[1];

  return (
    <>
      {title}
      {activeOption === optionB && <IconReactIcons type={IconTypes.sortDown}></IconReactIcons>}
      {activeOption === optionA && <IconReactIcons type={IconTypes.sortUp}></IconReactIcons>}
      {activeOption !== optionB && activeOption !== optionA && <IconReactIcons type={IconTypes.unsorted}></IconReactIcons>}
    </>
  );
};

export default FilelistWithSort;
