import React, { useState } from 'react';

// useForm functional componen
export const useForm = (callback: any, initialState = {}) => {
  const [values, setValues] = useState<any>(initialState);

  // onChange
  const onChange = (keyVal: any) => {
    setValues({ ...values, ...keyVal });
  };

  // onSubmit
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await callback(); // triggering the callback
  };

  // return values
  return {
    onChange,
    onSubmit,
    values
  };
};
