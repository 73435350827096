import cn from 'classnames';
import React from 'react';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Select from 'components/Select/Select';
import Text from 'components/Text/Text';

export interface Choice<T> {
  readonly label: string;
  readonly value: T;
}

export interface Props<T> {
  readonly label?: string;
  readonly choices: Choice<T>[];
  readonly selectedValue?: Choice<T>;
  readonly feedback?: string;
  readonly validationState?: 'error' | 'loading' | null;
  readonly feedbackMargin?: boolean;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly formatOptionLabel?: (opt, context) => string;

  onChange(selection: Choice<T>): void;
}

export default function SelectField<T>({
  label,
  choices,
  feedback,
  validationState,
  feedbackMargin,
  disabled,
  ...props
}: Props<T>) {
  const showError = feedback && validationState === 'error';
  return (
    <div
      className={cn({
        SelectField: true,
        Loading: validationState === 'loading'
      })}
    >
      {label && (
        <Text variant="mono-16" component="label">
          {label}
        </Text>
      )}
      <Select {...props} isDisabled={disabled} choices={choices} className={cn({ SelectField__ErrorBorder: showError })} />
      {showError && <ErrorMessage feedback={feedback} feedbackMargin={feedbackMargin} />}
    </div>
  );
}
