export function mapBygningsStandAlternativer(list: { alternativ: string }[]) {
  return list?.map((e) => ({ label: e.alternativ, value: e.alternativ })) ?? [];
}

export function mapAlternativerToLabelValue(list: { alternativ: string }[]) {
  return list?.map((e) => ({ label: e.alternativ, value: e.alternativ })) ?? [];
}

export function mapEnergimerkeAlternativer(list: { alternativ: string }[]) {
  return list?.map((e) => ({ label: e.alternativ, value: e.alternativ })) ?? [];
}

export function mapMiljosertifisertAlternativer(
  list: { alternativ: string }[]
) {
  return list?.map((e) => ({ label: e.alternativ, value: e.alternativ })) ?? [];
}

export function mapUniversellUtformingAlternativer(
  list: { alternativ: string }[]
) {
  return list?.map((e) => ({ label: e.alternativ, value: e.alternativ })) ?? [];
}
