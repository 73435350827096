import * as api from 'js/api-helper';
import { ArcgisServiceArea } from 'types/ArcgisServiceArea';
import { ifWindow } from 'js/checkWindow';

export const useArcGisStorage = ({
  setIsDirty,
  walkServiceAreaLayer,
  driveServiceAreaLayer,
  sketchGraphicLayer,
  saveEndpoint,
  view,
  setSaveStatus
}) => {
  const saveData = async () => {
    setSaveStatus({
      message: '',
      loading: true
    });
    const sketchGraphicsList = sketchGraphicLayer?.graphics?.items?.map(
      (graphicItem) => {
        return {
          geometry: {
            type: graphicItem.get('geometry.type'),
            rings: JSON.stringify(graphicItem.get('geometry.rings'))
          },
          symbol: {
            type: graphicItem.get('symbol.type'),
            style: graphicItem.get('symbol.style')
          }
        };
        //   };
      }
    );

    const walkLayer = Array.isArray(walkServiceAreaLayer?.graphics?.items)
      ? walkServiceAreaLayer?.graphics?.items
      : [];
    const driveLayer = Array.isArray(driveServiceAreaLayer?.graphics?.items)
      ? driveServiceAreaLayer?.graphics?.items
      : [];
    const allServiceAreaGraphicItems = walkLayer.concat(driveLayer);
    const serviceAreaGeometryList: ArcgisServiceArea[] = allServiceAreaGraphicItems
      .filter((graphicItem) => graphicItem.geometry.type === 'point')
      .map((graphicItem) => {
        // const { r, g, b, a } = graphicItem.symbol.color;
        const travelMode = JSON.parse(
          graphicItem.id.substr(0, graphicItem.id.indexOf('//'))
        );
        // Dette er objektet som lagres i DB. Må også støtte travelMode og distances
        return {
          latitude: JSON.stringify(graphicItem.get('geometry.latitude')),
          longitude: JSON.stringify(graphicItem.get('geometry.longitude')),
          // spatialReference: graphicItem.get('geometry.spatialReference'),
          type: graphicItem.get('geometry.type'),
          travelMode: travelMode
          //   color: `rgba(${r},${g},${b},${a})`
        };
      });

    let urlKartIdString: string[] = [];
    ifWindow(() => {
      urlKartIdString = window?.location?.pathname.split('/');
    });

    if (saveEndpoint && urlKartIdString?.length) {
      const kartId = urlKartIdString[urlKartIdString.length - 1];

      try {
        const centerLatitude = view.get('center.latitude');
        const centerLongitude = view.get('center.longitude');
        const center = `[${centerLongitude}, ${centerLatitude}]`;
        const zoom = view.get('zoom');
        await api.post(saveEndpoint, {
          kartId,
          serviceAreaGeometryList,
          sketchGraphicsList,
          center,
          zoom
        });
        setIsDirty(false);
        setSaveStatus({
          message: 'Kartet er lagret',
          loading: false
        });
        return;
      } catch (err) {
        setSaveStatus({
          message: 'Lagring av kart feilet',
          loading: false
        });
        return;
      }
    } else {
      setSaveStatus({
        message: 'Lagring av kart feilet',
        loading: false
      });
      return;
    }
  };

  return { saveData };
};
