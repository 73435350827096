// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState, useEffect } from 'react';
import style from './ArcGisKart.module.css';
import { useDebounce } from 'js/hooks';
import useOnClickOutside from 'js/hooks/useOnClickOutside';
import { MdMyLocation } from 'react-icons/md';
import CustomIconButton from './CustomIconButton';
type ArcGisSearchToolbarProps = {
  view: any;
  lib: any;
  markersGraphicLayer: any;
};
type GeoSearchCandidatesType = {
  location: {
    x: number;
    y: number;
  };
  address: string;
  extent: {
    xmin: number;
  };
};

type FetchResponseType = {
  candidates?: GeoSearchCandidatesType[];
};

const getGeoCodeByPostalOrAddress = async (
  searchQuery: string
): Promise<FetchResponseType> => {
  const token = sessionStorage.getItem('geodata_token');
  if (!token) return;
  const queryOptions = `token=${token}&f=json&SingleLine=${searchQuery}&maxLocations=${5}&sourceCountry=${'Norway'}&outSR=${'4326'}`;

  const geoCodeUrl = `https://services.geodataonline.no/arcgis/rest/services/Geosok/GeosokLokasjon2/GeocodeServer/findAddressCandidates?${queryOptions}`;
  const geoCodeRes = await fetch(geoCodeUrl, {
    credentials: 'same-origin'
  }).then((res) => res.json());
  return geoCodeRes;
};

const ArcGisSearchToolbar = ({
  markersGraphicLayer,
  view,
  lib
}: ArcGisSearchToolbarProps) => {
  const { Point } = lib;

  const searchBarRef = useRef<HTMLDivElement>(null);
  const searchResultRef = useRef<HTMLDivElement>(null);
  const [geoSearchInput, setGeoSearchInput] = useState('');
  const [geoSearchCandidates, setGeoSearchCandidates] = useState<
    GeoSearchCandidatesType[]
  >([]);
  const debouncedGeoSearchInput = useDebounce(geoSearchInput, 500);
  const [searchGraphic, setSearchGraphic] = useState(null);
  useOnClickOutside(searchResultRef, () => setGeoSearchCandidates([]));

  const handleGeoSearchInput = (e) => {
    setGeoSearchInput(e.target.value);
  };

  const setViewCenter = ({ address, xLong, yLat }) => {
    if (view) {
      if (searchGraphic) {
        markersGraphicLayer.remove(searchGraphic);
      }
      const newCenter = new Point({
        latitude: yLat,
        longitude: xLong
      });
      view.center = newCenter;
      view.zoom = 16;

      const paragraph = document.createElement('p');
      paragraph.innerText = address;
      const graphic = new lib.Graphic({
        geometry: newCenter,
        popupTemplate: {
          title: address
        },
        symbol: {
          size: 12,
          type: 'simple-marker',
          color: 'white'
        }
      });

      markersGraphicLayer.add(graphic);
      setSearchGraphic(graphic);

      setGeoSearchCandidates([]);
    }
  };

  useEffect(() => {
    async function handleSearchGeo() {
      try {
        const res = await getGeoCodeByPostalOrAddress(debouncedGeoSearchInput);
        // const { x, y } = res.candidates[0].location;
        // setViewCenter(x, y);
        if (res?.candidates?.length) {
          setGeoSearchCandidates(res.candidates);
        } else {
          throw new Error('No candidates');
        }
      } catch (err) {
        if (searchBarRef?.current) {
          searchBarRef.current.style.border = '1px solid red';
        }
      }
    }
    if (debouncedGeoSearchInput?.length) {
      handleSearchGeo();
    }
  }, [debouncedGeoSearchInput]);

  return (
    <div ref={searchBarRef}>
      <div className={style.searchToolbar}>
        <label htmlFor={'kart-sok'}>Søk i kart: </label>
        <input
          autoComplete="off"
          id={'kart-sok'}
          placeholder={'adresse, stedsnavn, postnummer, poststed, kommune'}
          onChange={handleGeoSearchInput}
        />
        {!!geoSearchCandidates.length && (
          <CustomIconButton
            title="Gå til første resultat"
            noToggle={true}
            onClick={() =>
              setViewCenter({
                address: geoSearchCandidates[0].address,
                xLong: geoSearchCandidates[0].location.x,
                yLat: geoSearchCandidates[0].location.y
              })
            }
          >
            <MdMyLocation />
          </CustomIconButton>
        )}
        {!!geoSearchCandidates.length && (
          <ul ref={searchResultRef} className={style.searchResultList}>
            {geoSearchCandidates.map(
              (candidate: GeoSearchCandidatesType, i) => {
                return (
                  <li
                    className={style.searchResult}
                    key={`${candidate.location.x + candidate.location.y}${i}`}
                  >
                    <button
                      onClick={() =>
                        setViewCenter({
                          address: candidate.address,
                          xLong: candidate.location.x,
                          yLat: candidate.location.y
                        })
                      }
                    >
                      {candidate?.address}
                    </button>
                  </li>
                );
              }
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

ArcGisSearchToolbar.displayName = 'ArcGisLayerToolbar';

export default ArcGisSearchToolbar;
