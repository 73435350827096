import cn from 'classnames';
import DotsMenu from 'components/DotsMenu/DotsMenu';
import ExpandableItem from 'components/ExpandableItem/ExpandableItem';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import { shortDateString, shortDateTimeString } from 'js/date-helper';
import React, { FC, useState } from 'react';
import { BiStrikethrough } from 'react-icons/bi';
import { FaseHendelse, FaseHendelseMedEkstraFelter } from 'types/FaseHendelse';
import { Hendelse, HendelseMedEkstraFelter } from 'types/Hendelse';
import { MailHendelse, MailHendelseMedEkstraFelter } from 'types/MailHendelse';
import PhaseRow from './PhaseRow';

export enum EventRowType {
  General = 'Generell',
  Email = 'Epost',
  StartPhase = 'Start fase',
  EndPhase = 'Avslutt fase',
  Action = 'Handling'
}
const baseClassName = 'LoggedEventRow';

const colorCodes = {
  Prosjektleder: '#EFD5E8',
  Oppdragsgiver: '#D1D9E5',
  Fagressurs: '#F5D4B5',
  Megler: '#AED4F8',
  Markedsaktør: '#E3CEC1'
};

const LoggedEventRow: FC<Hendelse | MailHendelse | FaseHendelse> = ({ ...event }) => {
  return (
    <>
      {(event.eventType == EventRowType.General || event.eventType == EventRowType.Action) && (
        <GenericEventRow {...(event as HendelseMedEkstraFelter)} />
      )}
      {event.eventType == EventRowType.Email && <EmailEventRow {...(event as MailHendelseMedEkstraFelter)} />}
      {(event.eventType == EventRowType.StartPhase || event.eventType == EventRowType.EndPhase) && (
        <PhaseRow {...(event as FaseHendelseMedEkstraFelter)} />
      )}
    </>
  );
};

const GenericEventRow = ({ ...event }: HendelseMedEkstraFelter) => {
  return (
    <ExpandableItem
      itemHeader={
        <li
          className={cn(baseClassName, {
            '-generic': true,
            '-isStriked': event.strikeThrough
          })}
        >
          <p className="row--date">{shortDateString(event.handlingUtc)}</p>
          <p className="row--title">{event.handlingTittel}</p>
          <p className="row--type">{event.eventText}</p>
          <p className="row--role">
            <span
              className="row--role--icon"
              style={{ backgroundColor: colorCodes[event.utfortAvBrukerKontekstRollenavn] }}
            ></span>
            <span>{event.utfortAvBrukerKontekstRollenavn}</span>
          </p>
          <div className="row--icon">
            {event.strikeable && (
              <DotsMenu
                eventId={event.eventId}
                strikeUrl={event?.viskUtHendelseUrl}
                updateStrikeThrough={(flag) => event.updateStrikeThrough(event.eventId, flag)}
                isStrikedThrough={!!event.strikeThrough}
              />
            )}
            <div className="expandable-btn">
              <IconReactIcons type={IconTypes.arrowDown} />
            </div>
          </div>
        </li>
      }
      itemContent={
        <div className={cn('expanded-content', { '-isStriked': event.strikeThrough })}>
          <div className="expanded-content-col1"></div>

          <div className="expanded-content-col2">
            <p className="wrapped-field">
              <span className="wrapped-field--header">Innhold:</span>
              <span className="wrapped-field--content">{event.handlingTekst}</span>
            </p>
            <ul className="attachments">
              {event.vedlegg?.map((attachment) => (
                <li key={attachment.id}>
                  <a href={attachment.nedlastingslenke}>
                    <IconReactIcons type={IconTypes.filePdf} size={2} />
                    <span>{attachment.visningsnavn}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="expanded-content-col3">
            {event.utfortAvBrukerKontekstFulltNavn && (
              <p className="wrapped-field">
                <span className="wrapped-field--header">Loggført av:</span>
                <span>{event.utfortAvBrukerKontekstFulltNavn}</span>
              </p>
            )}
            <p>{shortDateTimeString(event.opprettetUtc)}</p>
          </div>
          <div className="expanded-content-col4"></div>
        </div>
      }
    />
  );
};

const EmailEventRow = ({ ...event }: MailHendelseMedEkstraFelter) => {
  return (
    <ExpandableItem
      itemHeader={
        <li
          className={cn(baseClassName, {
            '-email': true,
            '-isStriked': event.strikeThrough
          })}
        >
          <p className="row--date">{shortDateString(event.handlingUtc)}</p>
          <p className="row--title">{event.handlingTittel}</p>
          <p className="row--type">{event.eventText}</p>
          <p className="row--role">
            <span
              className="row--role--icon"
              style={{ backgroundColor: colorCodes[event.utfortAvBrukerKontekstRollenavn] }}
            ></span>
            <span>{event.utfortAvBrukerKontekstRollenavn}</span>
          </p>
          <div className="row--icon">
            {event.strikeable && (
              <DotsMenu
                eventId={event.eventId}
                strikeUrl={event?.viskUtHendelseUrl}
                updateStrikeThrough={(flag) => event.updateStrikeThrough(event.eventId, flag)}
                isStrikedThrough={!!event.strikeThrough}
              />
            )}

            <div className="expandable-btn">
              <IconReactIcons type={IconTypes.arrowDown} />
            </div>
          </div>
        </li>
      }
      itemContent={
        <div className={cn('expanded-content', { '-isStriked': event.strikeThrough })}>
          <div className="expanded-content-col1"></div>

          <div className="expanded-content-col2">
            {event.epostAvsender && (
              <p className="noWrap-field">
                <span className="noWrap-field--header">Avsender:</span>
                <span>{event.epostAvsender}</span>
              </p>
            )}
            {event.epostMottaker && (
              <p className="noWrap-field">
                <span className="noWrap-field--header">Mottaker: </span>
                <span>{event.epostMottaker}</span>
              </p>
            )}
            {event.epostSendtUtc && (
              <p className="noWrap-field">
                <span className="noWrap-field--header">Dato Sendt:</span>
                <span>{shortDateString(event.epostSendtUtc)}</span>
              </p>
            )}
            {event.epostMottattUtc && (
              <p className="noWrap-field">
                <span className="noWrap-field--header">Dato mottatt:</span>
                <span>{shortDateString(event.epostMottattUtc)}</span>
              </p>
            )}
            <br></br>
            {event.epostTittel && (
              <p className="wrapped-field">
                <span className="wrapped-field--header">Tittel:</span> <span>{event.epostTittel}</span>
              </p>
            )}
            {event.epostTekst && (
              <p className="wrapped-field">
                <span className="wrapped-field--header">Innhold:</span>
                <span className="wrapped-field--content">{event.epostTekst}</span>
              </p>
            )}
            <ul className="attachments">
              {event.vedlegg?.map((attachment) => (
                <li key={attachment.id}>
                  <a href={attachment.nedlastingslenke}>
                    <IconReactIcons type={IconTypes.filePdf} size={2} />
                    <span>{attachment.visningsnavn}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="expanded-content-col3">
            {event.utfortAvBrukerKontekstFulltNavn && (
              <p className="wrapped-field">
                <span className="wrapped-field--header">Loggført av:</span>
                <span>{event.utfortAvBrukerKontekstFulltNavn}</span>
              </p>
            )}
            <p>{shortDateTimeString(event.opprettetUtc)}</p>
          </div>
        </div>
      }
    />
  );
};

export default LoggedEventRow;
