// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef, useMemo, useEffect, useState } from 'react';

const ArcGisMapSelectToolbar = forwardRef(({ view, lib }, ref) => {
  const { Basemap, BasemapGallery } = lib;
  const [displayMapSelect, setDisplayMapSelect] = useState(false);

  const basemapGallery = useMemo(() => {
    if (view) {
      const basemapGallery = new BasemapGallery({
        container: 'map-selector',
        source: [
          Basemap.fromId('arcgis-light-gray'),
          Basemap.fromId('arcgis-streets'),
          Basemap.fromId('arcgis-navigation'),
          Basemap.fromId('satellite')
        ],
        view: view // The view that provides access to the map's "streets-vector" basemap
      });

      return basemapGallery;
    } else return null;
  }, [view]);

  const closeBasemapGallery = () => {
    if (view) {
      basemapGallery && view.ui.remove(basemapGallery);
      setDisplayMapSelect(!displayMapSelect);
    }
  };
  useEffect(() => {
    if (view && basemapGallery) {
      if (displayMapSelect) {
        view.ui.add(basemapGallery, 'top-left');
      } else {
        basemapGallery && view.ui.remove(basemapGallery);
      }
    }
  }, [view, displayMapSelect, basemapGallery]);

  return (
    <div ref={ref}>
      {displayMapSelect && (
        <div id={'map-selector'} onClick={closeBasemapGallery} />
      )}

      {basemapGallery && (
        <button
          style={{
            background: `url(${basemapGallery?.activeBasemap?.thumbnailUrl}) no-repeat`,
            border: '2px solid white',
            width: 25,
            height: 25
          }}
          onClick={() => setDisplayMapSelect(!displayMapSelect)}
        ></button>
      )}
    </div>
  );
});

ArcGisMapSelectToolbar.displayName = 'ArcGisLayerToolbar';

export default ArcGisMapSelectToolbar;
