import SingleDatePicker from 'components/DateRangePicker/SingleDatePicker';
import SelectField, { Choice } from 'components/SelectField/SelectField';
import Button from 'components/Button/Button';
import Label from 'components/Label/Label';
import { post, ValidationError } from 'js/api-helper';
import { shortDateTimeString } from 'js/date-helper';
import { useValidation } from 'js/hooks';
import { useForm } from 'js/hooks/useForm';
import { Validations } from 'js/hooks/useValidation';
import React, { FC, useEffect, useState } from 'react';
import { LogEntryUrls } from 'source/pages/HendelsesLoggSide/HendelsesLoggSide';
import { Fildata } from 'types/Fildata';
import { Hendelse } from 'types/Hendelse';
import { EventRowType } from '../LoggedEventRow/LoggedEventRow';
import EmailForm from './FormTypes/EmailForm';
import GenericForm from './FormTypes/GenericForm';

export interface LogEntryForm {
  defaultChoice: Choice<number>;
  choices: Choice<number>[];
  vedlegg: Fildata[];
  nyeOppforingerUrlListe: LogEntryUrls;
  leggTilVedleggUrl: string;
  slettFilUrl: string;
  id: string;
  onSubmit: (hendelse: Hendelse) => void;
}

interface PhaseForm extends LogEntryForm {
  validation: Validations<any>;
  activeChoice: Choice<number>;
  setActiveChoice: (choice: Choice<number>) => void;
  onChange: (event: any) => void;
}

const appendDateTimeIfExists = (event) => {
  // TODO- nullDate?
  // if(!event.handlingUtc){
  //     event.handlingUtc = new Date()
  // }
  if (event.handlingKlokkeslett) {
    const HoursAndMin = event.handlingKlokkeslett.split(':').map((x) => Number(parseInt(x)));
    const calculatedUtc = new Date(event.handlingUtc).setHours(HoursAndMin[0], HoursAndMin[1]) ?? '00:00';
    event.handlingUtc = new Date(calculatedUtc).toISOString();
  }
  return event;
};

const LogEntryForm: FC<LogEntryForm> = ({ ...props }) => {
  const [activeChoice, setActiveChoice] = useState(props.defaultChoice);

  const initialState = {
    id: props.id,
    handlingUtc: '',
    opprettetUtc: '',
    handlingKlokkeslett: '',
    handlingTittel: '',
    handlingTekst: '',
    eventType: '',
    faseHandling: '',
    epostTittel: '',
    epostTekst: '',
    epostAvsender: '',
    epostMottaker: '',
    epostSendtUtc: '',
    epostMottattUtc: '',
    vedlegg: []
  };

  const FieldsToValidate = {
    Generell: ['handlingUtc', 'handlingTittel', 'handlingTekst'],
    Epost: ['handlingUtc', 'handlingTittel'],
    'Start fase': ['faseHandling', 'handlingUtc'],
    'Avslutt fase': ['faseHandling', 'handlingUtc']
  };

  const { onChange, onSubmit, values } = useForm(formSubmitCallback, initialState);

  const [validation, validate, resetValidation] = useValidation(values);

  // a submit function that will execute upon form submission.
  // attatches active form to payload.
  async function formSubmitCallback() {
    let invalidFields = 0;
    //validation
    resetValidation();
    FieldsToValidate[activeChoice.label]?.forEach((key) => {
      if (values[key] == '') {
        invalidFields += 1;
        validate(
          new ValidationError({
            [key]: ['Feltet kan ikke være tomt']
          })
        );
      }
    });

    //append time to date
    const dataWithCalculatedUtc = appendDateTimeIfExists(values);

    //map correct url to active eventType in dropdown
    const idx = Object.keys(EventRowType)[activeChoice.value - 1];
    const targetUrl = props.nyeOppforingerUrlListe[idx];

    if (invalidFields <= 0) {
      try {
        const res = await post(targetUrl, dataWithCalculatedUtc);

        if (res) {
          props.onSubmit(res);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  //reset formdata when active choice changes
  useEffect(() => {
    resetValidation();
    onChange({
      ...initialState,
      eventType: activeChoice.label,
      opprettetUtc: new Date().toISOString()
    });
  }, [activeChoice]);

  return (
    <form className="LogEntryForm" onSubmit={onSubmit}>
      {activeChoice.label == EventRowType.General && (
        <GenericForm
          {...props}
          validation={validation}
          activeChoice={activeChoice}
          setActiveChoice={setActiveChoice}
          onChange={onChange}
        />
      )}
      {activeChoice.label == EventRowType.Email && (
        <EmailForm
          {...props}
          validation={validation}
          activeChoice={activeChoice}
          setActiveChoice={setActiveChoice}
          onChange={onChange}
        />
      )}
      {activeChoice.label == EventRowType.StartPhase && (
        <StartPhaseForm
          {...props}
          validation={validation}
          activeChoice={activeChoice}
          setActiveChoice={setActiveChoice}
          onChange={onChange}
        />
      )}
      {activeChoice.label == EventRowType.EndPhase && (
        <EndPhaseForm
          {...props}
          validation={validation}
          activeChoice={activeChoice}
          setActiveChoice={setActiveChoice}
          onChange={onChange}
        />
      )}

      <Button className="LogEntryForm--button" type="submit">
        Legg til loggføring
      </Button>
      <p>{shortDateTimeString(new Date().toISOString())}</p>
    </form>
  );
};

const StartPhaseForm: FC<PhaseForm> = ({ ...props }) => {
  const phaseActionChoices = [
    { label: 'Søk opprettet', value: 1 },
    { label: 'Befaring påbegynt', value: 2 },
    { label: 'Seriøsitetssjekk startet', value: 3 },
    { label: 'Konkurranse påbegynt', value: 4 }
  ];
  const [formValidation, setFormValidation] = useState(props.validation);

  useEffect(() => {
    setFormValidation(props.validation);
  }, [props.validation]);

  return (
    <div className="LogEntryForm--generic">
      <div className="span-half">
        <Label>Type loggføring</Label>
        <SelectField
          onChange={(value) => {
            props.setActiveChoice(value);
          }}
          className="picker"
          choices={props.choices}
          selectedValue={props.activeChoice}
        />
      </div>
      <div className="span-whole">
        <Label>Fase</Label>
        <SelectField
          onChange={(option) => {
            props.onChange({ faseHandling: option.label });
            setFormValidation({
              ...formValidation,
              faseHandling: {
                validationState: null,
                feedback: '',
                feedbackMargin: false
              }
            });
          }}
          className="picker"
          choices={phaseActionChoices}
          {...formValidation.faseHandling}
        />
      </div>
      <div className="span-whole">
        <SingleDatePicker
          className="picker"
          label={'Dato for hendelse'}
          placeholder={'Velg dato'}
          styleException={'half-width'}
          onDateChange={(val) => {
            props.onChange({ handlingUtc: val });
            setFormValidation({
              ...formValidation,
              handlingUtc: {
                validationState: null,
                feedback: '',
                feedbackMargin: false
              }
            });
          }}
          {...formValidation.handlingUtc}
        />
      </div>
    </div>
  );
};

const EndPhaseForm: FC<PhaseForm> = ({ ...props }) => {
  const phaseActionChoices = [
    { label: 'Søk avsluttet', value: 1 },
    { label: 'Befaring avsluttet', value: 2 },
    { label: 'Seriøsitetssjekk avsluttet', value: 3 },
    { label: 'Konkurranse avsluttet', value: 4 }
  ];

  const [phaseAction, setPhaseAction] = useState(phaseActionChoices[0]);
  const [formValidation, setFormValidation] = useState(props.validation);

  useEffect(() => {
    setFormValidation(props.validation);
  }, [props.validation]);

  return (
    <div className="LogEntryForm--generic">
      <div className="span-half">
        <Label>Type loggføring</Label>
        <SelectField
          onChange={(value) => {
            props.setActiveChoice(value);
          }}
          className="picker"
          choices={props.choices}
          selectedValue={props.activeChoice}
        />
      </div>
      <div className="span-whole">
        <Label>Fase</Label>
        <SelectField
          onChange={(option) => {
            props.onChange({ faseHandling: option.label });
            setFormValidation({
              ...formValidation,
              faseHandling: {
                validationState: null,
                feedback: '',
                feedbackMargin: false
              }
            });
          }}
          className="picker"
          choices={phaseActionChoices}
          {...formValidation.faseHandling}
        />
      </div>
      <div className="span-whole">
        <SingleDatePicker
          className="picker"
          label={'Dato for hendelse'}
          placeholder={'Velg dato'}
          styleException={'half-width'}
          onDateChange={(val) => {
            props.onChange({ handlingUtc: val });
            setFormValidation({
              ...formValidation,
              handlingUtc: {
                validationState: null,
                feedback: '',
                feedbackMargin: false
              }
            });
          }}
          {...formValidation.handlingUtc}
        />
      </div>
    </div>
  );
};

export default LogEntryForm;
