// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef } from 'react';
import {
  AiOutlineLock,
  AiOutlineUnlock,
  AiOutlineCamera
} from 'react-icons/ai';
import { MdMyLocation } from 'react-icons/md';
import { handleTakeScreenshot } from './arcGis.helper';
import CustomIconButton from './CustomIconButton';

const ArcGisCustomToolbar = forwardRef(
  ({ view, toggleMapLock, mapIsLocked, center }, ref) => {
    const setViewCenter = () => {
      if (view) {
        view.center = typeof center === 'string' ? JSON.parse(center) : center;
      }
    };
    return (
      <div ref={ref} className={'esri-custom-toolbar'}>
        <div className={'esri-custom-panel'}>
          <div className={'esri-custom-section'}>
            <CustomIconButton
              title="Sentrer kartet"
              onClick={setViewCenter}
              noToggle
            >
              <MdMyLocation />
            </CustomIconButton>
            <CustomIconButton
              title="Lås kartnavigasjon"
              onClick={toggleMapLock}
              isToggled={mapIsLocked}
            >
              {mapIsLocked ? <AiOutlineLock /> : <AiOutlineUnlock />}
            </CustomIconButton>

            {/* <CustomIconButton onClick={saveData} noToggle>
              <AiOutlineSave />
            </CustomIconButton> */}

            <CustomIconButton
              title="Lagre bilde av kartet"
              onClick={() => handleTakeScreenshot(view)}
              noToggle
            >
              {<AiOutlineCamera />}
            </CustomIconButton>
          </div>
        </div>
      </div>
    );
  }
);
ArcGisCustomToolbar.displayName = 'ArcGisCustomToolbar';
export default ArcGisCustomToolbar;
