/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiRedo, BiUndo } from 'react-icons/bi';
import { FaCarSide, FaEye, FaWalking } from 'react-icons/fa';
import CustomIconButton from './CustomIconButton';
import { TravelModeStateType } from './hooks/useServiceArea';
import { AiFillCar } from 'react-icons/ai';
import { MdLocationOn } from 'react-icons/md';
import { MarkerT } from './ArcGis';

type ArcGisServiceAreaToolbarType = {
  deleteAllServiceAreas: () => void;
  handleSetTravelMode: (arg0: TravelModeStateType) => void;
  travelModeState: TravelModeStateType;
  walkServiceAreaLayer: any;
  driveServiceAreaLayer: any;
  markersServiceAreaWalkLayer: any;
  markersServiceAreaDriveLayer: any;
  undoLastServiceArea: () => void;
  redoLastServiceArea: () => void;
  readOnly: boolean;
  markers?: MarkerT[];
};
const ArcGisServiceAreaToolbar = forwardRef<
  HTMLDivElement,
  ArcGisServiceAreaToolbarType
>(
  (
    {
      deleteAllServiceAreas,
      handleSetTravelMode,
      travelModeState,
      walkServiceAreaLayer,
      driveServiceAreaLayer,
      markersServiceAreaWalkLayer,
      markersServiceAreaDriveLayer,
      undoLastServiceArea,
      redoLastServiceArea,
      readOnly,
      markers
    },
    ref
  ) => {
    const handleChangeTravelMode = (newTravelMode) => {
      // Clicked an active travel mode, turn off both
      if (newTravelMode === travelModeState.type) {
        handleSetTravelMode({
          type: 0
        });
      } else {
        handleSetTravelMode({ type: newTravelMode });
      }
    };

    const showDriveVisibilityToggle = Boolean(
      !readOnly || driveServiceAreaLayer?.graphics?.items?.length
    );
    const showWalkVisibilityToggle = Boolean(
      !readOnly || walkServiceAreaLayer?.graphics?.items?.length
    );
    const showMarkersDistanceSection = Boolean(markers?.length);

    const showToolbar =
      sessionStorage.getItem('geodata_token') &&
      (showMarkersDistanceSection ||
        showDriveVisibilityToggle ||
        showWalkVisibilityToggle ||
        !readOnly);
    return (
      <div ref={ref} className={'esri-custom-toolbar'}>
        {showToolbar && (
          <div className={'esri-custom-panel'}>
            {!readOnly && (
              <div className={'esri-custom-section'}>
                <CustomIconButton
                  title="Marker gådistanse"
                  onClick={() => handleChangeTravelMode(3)}
                  isToggled={travelModeState.type === 3}
                >
                  <FaWalking />
                </CustomIconButton>

                <CustomIconButton
                  title="Marker kjøredistanse"
                  onClick={() => handleChangeTravelMode(1)}
                  isToggled={travelModeState.type === 1}
                >
                  <FaCarSide />
                </CustomIconButton>
                <CustomIconButton
                  title="Slett alle distanser"
                  onClick={deleteAllServiceAreas}
                  noToggle
                >
                  <AiOutlineDelete />
                </CustomIconButton>
                <CustomIconButton
                  title="Angre distanseendring"
                  noToggle
                  onClick={undoLastServiceArea}
                >
                  <BiUndo />
                </CustomIconButton>
                <CustomIconButton
                  title="Tilbakefør distanseendring"
                  noToggle
                  onClick={redoLastServiceArea}
                >
                  <BiRedo />
                </CustomIconButton>
              </div>
            )}

            {showMarkersDistanceSection && (
              <div className={'esri-custom-section'}>
                <CustomIconButton
                  title="Vis gåavstand fra markører"
                  onClick={() =>
                    (markersServiceAreaWalkLayer.visible = !markersServiceAreaWalkLayer.visible)
                  }
                  isToggled={markersServiceAreaWalkLayer.visible}
                >
                  <MdLocationOn
                    style={{
                      position: 'absolute',
                      left: 3
                    }}
                  />
                  <FaWalking
                    style={{
                      position: 'absolute',
                      right: 3
                    }}
                  />
                </CustomIconButton>

                <CustomIconButton
                  title="Vise kjøreavstand fra markører"
                  onClick={() =>
                    (markersServiceAreaDriveLayer.visible = !markersServiceAreaDriveLayer.visible)
                  }
                  isToggled={markersServiceAreaDriveLayer.visible}
                >
                  <MdLocationOn
                    style={{
                      position: 'absolute',
                      left: 3
                    }}
                  />
                  <AiFillCar
                    style={{
                      position: 'absolute',
                      right: 3
                    }}
                  />
                </CustomIconButton>
              </div>
            )}

            {(showWalkVisibilityToggle || showDriveVisibilityToggle) && (
              <div className={'esri-custom-section'}>
                {showWalkVisibilityToggle && (
                  <CustomIconButton
                    title="Vis/skjul gådistanser"
                    onClick={() =>
                      (walkServiceAreaLayer.visible = !walkServiceAreaLayer.visible)
                    }
                    isToggled={walkServiceAreaLayer.visible}
                  >
                    <FaEye
                      style={{
                        position: 'absolute',
                        left: 3,
                        top: 2
                      }}
                    />

                    <FaWalking
                      style={{
                        position: 'absolute',
                        right: 3
                      }}
                    />
                  </CustomIconButton>
                )}
                {showDriveVisibilityToggle && (
                  <CustomIconButton
                    title="Vis/skjul kjøredistanser"
                    onClick={() =>
                      (driveServiceAreaLayer.visible = !driveServiceAreaLayer.visible)
                    }
                    isToggled={driveServiceAreaLayer.visible}
                  >
                    <FaEye
                      style={{
                        position: 'absolute',
                        left: 2,
                        top: 2
                      }}
                    />

                    <FaCarSide
                      style={{
                        position: 'absolute',
                        right: 2
                      }}
                    />
                  </CustomIconButton>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);
ArcGisServiceAreaToolbar.displayName = 'ArcGisCustomToolbar';
export default ArcGisServiceAreaToolbar;
