import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import React, { ReactNode } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import Text from 'components/Text/Text';

export interface Props {
  readonly label?: string;
  readonly tooltip?: ReactNode;
}

export default function InfoLabel({ label, tooltip }: Props) {
  if (!label) return null;

  return (
    <Text variant="mono-16" component="label">
      {label}
      {tooltip && (
        <>
          <Tooltip overlay={tooltip} placement="top" trigger="click">
            <AiOutlineInfoCircle data-tip data-for={label} />
          </Tooltip>
        </>
      )}
    </Text>
  );
}
