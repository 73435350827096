import ExpandableItem from "components/ExpandableItem/ExpandableItem";
import { FaseHendelseMedEkstraFelter } from "types/FaseHendelse";
import React, { FC } from 'react';
import cn from "classnames";
import { shortDateString } from "js/date-helper";
import DotsMenu from "components/DotsMenu/DotsMenu";

const PhaseRow =({...event}: FaseHendelseMedEkstraFelter) => {
    return (
      <ExpandableItem
        expandable={false}
        itemHeader={
          <li className={cn("LoggedEventRow", {
            '-phase': true,
            '-isStriked': event.strikeThrough
          })}>
            <p className='phase-action'> {event.faseHandling}: {shortDateString(event.handlingUtc)}</p>
            <div className='phase-row--icon'>
            {event.strikeable &&   
                <DotsMenu
                eventId={event.eventId}
                strikeUrl={event?.viskUtHendelseUrl}
                updateStrikeThrough={(flag) => event.updateStrikeThrough(event.eventId, flag)}
                isStrikedThrough={!!event.strikeThrough}
                />
              }
              </div>
          </li>
        }
      />
    )
  }

  export default PhaseRow;