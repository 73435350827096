import cn from 'classnames';
import Paragraphs from 'components/Paragraphs/Paragraphs';
import useBooleanState from 'js/hooks/useBooleanState';
import React, { useCallback, useState } from 'react';
import { BsPencil } from 'react-icons/bs';
import { HjelpetekstListItem } from 'types/HjelpetekstListItem';
import EditHjelpetekst from './EditHjelpetekst';
import style from './HjelpeteksterSide.module.css';

export default function Hjelpetekst(tekst: HjelpetekstListItem) {
  const [editing, , toggleEditing] = useBooleanState(false);

  const [slettUrl, setSlettUrl] = useState(tekst.slettUrl);
  const [tittel, setTittel] = useState(tekst.tittel || tekst.sidenavn);
  const [innhold, setInnhold] = useState(tekst.innhold);

  const onSave = useCallback(
    (tittel: string, innhold: string, slettUrl: string) => {
      setTittel(tittel);
      setInnhold(innhold);
      setSlettUrl(slettUrl);
      toggleEditing();
    },
    []
  );

  const onDelete = useCallback(() => {
    setTittel(tekst.sidenavn);
    setInnhold(undefined);
    setSlettUrl(undefined);
    toggleEditing();
  }, []);

  return (
    <details>
      <summary>
        <span className={cn({ [style.missing]: !innhold })}>{tittel}</span>
        <small>{tekst.sidenavn}</small>
      </summary>
      {editing ? (
        <EditHjelpetekst
          initialTittel={tittel}
          initialInnhold={innhold}
          lagreUrl={tekst.lagreUrl}
          slettUrl={slettUrl}
          stopEditing={toggleEditing}
          onSave={onSave}
          onDelete={onDelete}
        />
      ) : innhold ? (
        <>
          <Paragraphs>{innhold}</Paragraphs>

          <div className={style.footer}>
            <button className={style.icon} onClick={toggleEditing}>
              <BsPencil /> Rediger hjelpetekst
            </button>
          </div>
        </>
      ) : (
        <div className={style.noText}>
          <p>
            <i>Det finnes ingen hjelpetekst for denne siden</i>
          </p>
          <button className={style.icon} onClick={toggleEditing}>
            <BsPencil /> Legg til hjelpetekst
          </button>
        </div>
      )}
    </details>
  );
}
