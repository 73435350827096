import React, { FC, useState } from 'react';
import type { LinkItem } from 'types/LinkItem';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import type { DokumentSkjemadel } from 'types/DokumentSkjemadel';
import Text from 'components/Text/Text';
import DokumentSkjema from 'components/DokumentSkjema/DokumentSkjema';
import FormSubmitBox from '../../components/FormSubmitBox/FormSubmitBox';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import RadioBox from 'components/RadioBox/RadioBox';
import { cleanPost } from 'js/api-helper';
import DownloadAllFiles from '../../components/DownloadAllFiles/DownloadAllFiles';
import Modal from '../../components/Modal/Modal';
import { KonkurranseGrunnlagFil } from 'types/KonkurranseGrunnlagFil';
import AndreDokumentSkjema from 'components/AndreDokumentSkjema/AndreDokumentSkjema';
import { ErrorProps } from '../../components/AnchoredErrorMessage/AnchoredErrorMessage';

export interface InnkommetKonkurransegrunnlagSide {
  readonly tittel: string;
  readonly sideLenker?: LinkItem[];
  readonly editVedleggUrl: string;
  readonly slettVedleggUrl: string;
  readonly oppdaterFilStatusUrl: string;
  readonly rolle: string;
  readonly skjemaDeler: DokumentSkjemadel[];
  readonly andreDokumenter?: KonkurranseGrunnlagFil[];
  readonly lagreEvalueringUrl: string;
  readonly kommentar?: string;
  readonly vurdering?: number;
  readonly lastnedAltUrl: string;
  readonly erTrukket: boolean;
}

enum Vurdering {
  UNSET = -1,
  ANBEFALES = 0,
  VIDERE = 1,
  ANBEFALES_IKKE = 2
}

type SubmitionData = {
  kommentar: string;
  vurdering: number;
};

const InnkommetKonkurransegrunnlagSide: FC<InnkommetKonkurransegrunnlagSide> = (props: InnkommetKonkurransegrunnlagSide) => {
  const [submitionData, setSubmitionData] = useState<SubmitionData>({
    kommentar: props.kommentar ?? '',
    vurdering: props.vurdering ?? Vurdering.UNSET
  });
  const [submitted, setSubmitted] = useState(false);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState<ErrorProps | undefined>();

  async function submit(url: string) {
    if (!submitionData) return;
    try {
      await cleanPost(url, {
        erAnbefalt: submitionData.vurdering == Vurdering.ANBEFALES,
        ErAnbefaltMedAnmerkning: submitionData.vurdering == Vurdering.VIDERE,
        kommentar: submitionData.kommentar
      });
      setSubmitted(true);
      setModal(true);
    } catch (error) {
      setError({
        msg: error.message,
        percentageTopPos: -149,
        percentageRightPos: 53
      });
    }
  }

  return (
    <div className={'InnkommetKonkurransegrunnlagSide'}>
      <Modal
        title={'Evalueringen er lagret'}
        clickOutsideToClose={true}
        open={modal}
        onClose={() => {
          setModal(false);
        }}
      >
        <p>Dette er nå tilgjeneglig i rapporten</p>
        <Button
          onClick={() => {
            setModal(false);
          }}
        >
          Lukk
        </Button>
      </Modal>
      <div className="title-wrapper">
        <Text component="h1" variant="list-title">
          {props.tittel}
        </Text>
        {props.erTrukket && <span className="recind-label">Trukket</span>}
      </div>
      {props.sideLenker && <PageLinkMenu pageLinks={props.sideLenker} />}
      <DokumentSkjema
        skjemaDeler={props.skjemaDeler}
        slettVedleggUrl={props.slettVedleggUrl}
        oppdaterFilStatusUrl={props.oppdaterFilStatusUrl}
        editVedleggUrl={props.editVedleggUrl}
        readonly={true}
      />
      {props.andreDokumenter && props.andreDokumenter.length > 0 && (
        <AndreDokumentSkjema
          disableDocRemoval={true}
          andreDokumenter={props.andreDokumenter}
          lastOppAndreDokumenterUrl={''}
          slettVedleggUrl={props.slettVedleggUrl}
          oppdaterFilStatusUrl={props.oppdaterFilStatusUrl}
          uploadField={false}
          readOnly={true}
        />
      )}
      <DownloadAllFiles downloadUrl={props.lastnedAltUrl} />
      <FormSubmitBox
        heading={'Evaluering og anbefaling'}
        buttonText={'Lagre evaluering'}
        submitUrl={props.lagreEvalueringUrl}
        submitFunc={submit}
        disabled={submitionData && submitionData.vurdering == Vurdering.UNSET}
        error={error}
      >
        <div className="vurdering">
          <Text variant="mono-16">Vurdering *</Text>
          <div className="radios">
            <RadioBox
              label={'Anbefales til forhandlinger'}
              name={Vurdering.ANBEFALES.toString()}
              onChange={() => {
                setSubmitionData((p) => ({ ...p, vurdering: Vurdering.ANBEFALES }));
              }}
              checked={submitionData.vurdering === Vurdering.ANBEFALES}
            ></RadioBox>
            <RadioBox
              label={'Oppdragsgiver ønsker å ta med til forhandlinger'}
              name={Vurdering.VIDERE.toString()}
              onChange={() => {
                setSubmitionData((p) => ({ ...p, vurdering: Vurdering.VIDERE }));
              }}
              checked={submitionData.vurdering === Vurdering.VIDERE}
            ></RadioBox>
            <RadioBox
              label={'Anbefales ikke til forhandlinger'}
              name={Vurdering.ANBEFALES_IKKE.toString()}
              onChange={() => {
                setSubmitionData((p) => ({ ...p, vurdering: Vurdering.ANBEFALES_IKKE }));
              }}
              checked={submitionData.vurdering === Vurdering.ANBEFALES_IKKE}
            ></RadioBox>
          </div>
        </div>
        <Field
          maxCharacters={400}
          className="CommentArea"
          label="Kommentar"
          type="textarea"
          name="comment"
          disabled={props.rolle === 'OG'}
          showCharCount={true}
          value={submitionData.kommentar}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSubmitionData((p) => ({ ...p, kommentar: event.target.value }));
          }}
        />
      </FormSubmitBox>
    </div>
  );
};

export default InnkommetKonkurransegrunnlagSide;
