import Personvernerklaring from 'components/Personvernerklaring/Personvernerklaring';
import React, { FC } from 'react';
import Panel from 'components/Panel/Panel';
import Text from 'components/Text/Text';

// eslint-disable-next-line @typescript-eslint/ban-types
const PersonvernerklaringSide: FC<{}> = () => {
  return (
    <div className="PersonvernerklaringSide">
      <Text variant="paragraph-header" component="h1">
        Personvernerklæring for statensinnleie.no og statenstomtesøk.no
      </Text>
      <Panel>
        <Personvernerklaring />
      </Panel>
    </div>
  );
};

export default PersonvernerklaringSide;
