import React, { useRef, useMemo, createRef } from 'react';
import WizardButton from 'components/WizardButton/WizardButton';
import Heading from 'components/Heading/Heading';
import styles from './WizardSteps.module.css';

interface IWizardSteps {
  currentStep: number;
  steps: number;
  onClick?: () => void | undefined;
  labels: Array<string>;
  box?: boolean;
  boxLabel?: string;
}

const WizardSteps = ({ currentStep, onClick, steps, labels, box, boxLabel }: IWizardSteps) => {
  const { lineSteps, wizButtons } = useMemo(() => {
    const lineSteps: Array<React.ReactNode> = [];
    const wizButtons: Array<React.ReactNode> = [];

    for (let i = 1; i <= steps; i++) {
      wizButtons.push(
        <WizardButton
          label={Array.isArray(labels) && labels.length >= i ? labels[i - 1] : ''}
          onClick={onClick}
          key={i}
          active={i === currentStep}
          finished={currentStep >= i}
          disabled={currentStep <= i}
          number={i.toString()}
        />
      );
      const lineWidth = i >= steps ? 0 : `calc(100% / ${steps - 1})`;
      lineSteps.push(
        <div
          key={i}
          style={{ maxWidth: lineWidth, minWidth: lineWidth }}
          className={i < currentStep ? styles.activeLine : styles.inactiveLine}
        />
      );
    }
    return { lineSteps, wizButtons };
  }, [labels]);

  const getContent = () => {
    if (box)
      return (
        <div className={styles.boxWrapper}>
          <Heading level={3} green bottomMargin className={styles.boxLabel}>
            {boxLabel}
          </Heading>
          <div className={styles.line}>
            {lineSteps}
            <div className={styles.buttonContainer}>{wizButtons}</div>
          </div>
        </div>
      );
    return (
      <div className={styles.wrapper}>
        <div className={styles.line}>
          {lineSteps}
          <div className={styles.buttonContainer}>{wizButtons}</div>
        </div>
      </div>
    );
  };
  return <div>{getContent()}</div>;
};

WizardSteps.defaultProps = {
  labels: []
};

export default WizardSteps;
