import DoublePanel from 'components/DoublePanel/DoublePanel';
import SelectField, { Choice } from 'components/SelectField/SelectField';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import { getIcon, IconTypes } from 'components/Icon/IconReactIcons';
import * as api from 'js/api-helper';
import { useDebounce, useOnChange } from 'js/hooks';
import { Validations } from 'js/hooks/usePostback';
import React, { useCallback, useState } from 'react';
import style from '../SvarRediger.module.css';

interface Fields {
  readonly matrikkelIdentitet: string;
  readonly adressetekst: string;
}

export interface Props extends Fields {
  readonly validation: Validations<Fields>;
  readonly setMatrikkelIdentitet?: (value: string) => void;
  readonly setAdressetekst?: (value: string) => void;
  readonly removeMe?: () => void;
  readonly label: string;
}

interface Response {
  readonly matrikkelResponse: {
    readonly matrikkelIdentitet: string;
    readonly adressetekst: string;
  }[];

  readonly status: string;
}

async function handleSearch(value: string) {
  const response = await api.get(`/api/v1/data/Matrikkelsok/${value}`).catch(() => ({} as Response));

  return {
    result:
      response?.matrikkelResponse?.map((e) => ({
        label: e.adressetekst,
        value: e.matrikkelIdentitet
      })) ?? [],
    status: response?.status ?? 'En feil har skjedd'
  };
}

export default function MatrikkelPanel({
  matrikkelIdentitet,
  setMatrikkelIdentitet,
  adressetekst,
  setAdressetekst,
  validation,
  removeMe,
  label
}: Props) {
  const [loading, setLoading] = useState(false);
  const [choices, setChoices] = useState<Choice<string>[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [validationMessage, setValidationMessage] = useState('OK');

  const debouncedSearchInput = useDebounce(searchInput, 500);

  const search = useCallback(async (value: string) => {
    setValidationMessage('OK');
    if (!value || value.length < 8) return;

    setLoading(true);
    setChoices([]);
    const { result, status } = await handleSearch(value);
    setLoading(false);
    setChoices(result);
    setAdressetekst?.(result[0]?.label);
    setValidationMessage(status);
  }, []);

  useOnChange(search, [debouncedSearchInput]);
  useOnChange(setMatrikkelIdentitet, [searchInput]);

  return (
    <DoublePanel label={label} className={style.matrikkelpanel}>
      <Field
        label={'Kommunenr - Gårdsnr - Bruksnr*'}
        placeholder="Tast inn matrikkel-id på formen 0000-00-00"
        type="text"
        defaultValue={matrikkelIdentitet}
        onChange={(e) => setSearchInput(e.target.value)}
        validationState={validationMessage !== 'OK' ? 'error' : validation.matrikkelIdentitet.validationState}
        feedback={validationMessage !== 'OK' ? validationMessage : validation.matrikkelIdentitet.feedback}
        feedbackMargin={validationMessage !== 'OK' ? true : validation.matrikkelIdentitet.feedbackMargin}
      />
      <SelectField
        disabled={choices.length === 0}
        label="Adresse*"
        selectedValue={{ label: adressetekst, value: adressetekst }}
        choices={choices}
        placeholder="Velg addresse"
        noOptionsMessage={() => (loading ? 'Laster' : 'Fant ikke noe på søk')}
        onChange={(c) => setAdressetekst?.(c.label)}
        {...validation.adressetekst}
      />
      {removeMe && (
        <Button title={getIcon(IconTypes.remove).alt} onClick={removeMe}>
          {getIcon(IconTypes.remove).Component}
        </Button>
      )}
    </DoublePanel>
  );
}
