import Table from 'components/Table/Table';
import React, { FC } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { BsPencil } from 'react-icons/bs';
import Panel from 'components/Panel/Panel';
import { SvarTomteTableItem } from 'types/SvarTomteTableItem';
import Text from 'components/Text/Text';

export interface SvarTomteListeSide {
  readonly aktiveSvar: SvarTomteTableItem[];
  readonly paabegynteSvar: SvarTomteTableItem[];
  readonly historiskeSvar: SvarTomteTableItem[];
}

const AktivtSvarIkoner: FC<SvarTomteTableItem> = ({ redigerUrl, detaljUrl }) => (
  <>
    {redigerUrl && (
      <a title="Rediger svaret" href={redigerUrl}>
        <BsPencil />
      </a>
    )}
    <a title="Se svaret" href={detaljUrl}>
      <AiOutlineArrowRight />
    </a>
  </>
);

const PaabegyntSvarIkoner: FC<SvarTomteTableItem> = ({ redigerUrl, detaljUrl }) => (
  <>
    {redigerUrl && (
      <a title="Rediger svaret" href={redigerUrl}>
        <BsPencil />
      </a>
    )}
    <a title="Se svaret" href={detaljUrl}>
      <AiOutlineArrowRight />
    </a>
  </>
);

const HistoriskSvarIkoner: FC<SvarTomteTableItem> = ({ detaljUrl }) => (
  <>
    <a title="Se svaret" href={detaljUrl}>
      <AiOutlineArrowRight />
    </a>
  </>
);

const SvarTomteListeSide: FC<SvarTomteListeSide> = ({ aktiveSvar, paabegynteSvar, historiskeSvar }) => {
  return (
    <div className="SvarListeSide">
      <Text component="h1" variant="article-h2">
        Aktive
      </Text>
      <Panel>
        <Table
          paging
          data={aktiveSvar}
          iconsComponent={AktivtSvarIkoner}
          columns={{
            ['Tittel']: 'tittel',
            ['Eier']: 'eier',
            ['Status']: 'status',
            ['Levert']: (x) => new Date(x.levertUtc).getTime(),
            ['Frist']: (x) => new Date(x.fristUtc).getTime()
          }}
          noItemsLabel="Ingen aktive svar"
        />
      </Panel>
      <Text component="h1" variant="article-h2">
        Påbegynte
      </Text>
      <Panel>
        <Table
          paging
          data={paabegynteSvar}
          iconsComponent={PaabegyntSvarIkoner}
          columns={{
            ['Tittel']: 'tittel',
            ['Tilbudt objekt']: 'tilbudtObjekt',
            ['Eier']: 'eier',
            ['Status']: 'status',
            ['Lagret']: (x) => new Date(x.sistOppdatertUtc).getTime(),
            ['Frist']: (x) => new Date(x.fristUtc).getTime()
          }}
          noItemsLabel="Ingen påbegynte svar"
        />
      </Panel>
      <Text component="h1" variant="article-h2">
        Historiske
      </Text>
      <Panel>
        <Table
          paging
          data={historiskeSvar}
          iconsComponent={HistoriskSvarIkoner}
          columns={{
            ['Tittel']: 'tittel',
            ['Tilbudt objekt']: 'tilbudtObjekt',
            ['Eier']: 'eier',
            ['Status']: 'status',
            ['Levert']: (x) => new Date(x.levertUtc).getTime(),
            ['Frist']: (x) => new Date(x.fristUtc).getTime()
          }}
          noItemsLabel="Ingen historiske svar"
        />
      </Panel>
    </div>
  );
};

export default SvarTomteListeSide;
