import React, { FC } from 'react';
import EventLog from 'components/EventLog/EventLog';
import { LinkItem } from 'types/LinkItem';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import Heading from 'components/Heading/Heading';
import { Hendelse } from 'types/Hendelse';

export interface HendelsesLoggSide {
  readonly hendelser: Hendelse[];
  readonly pageLinks?: LinkItem[];
  readonly heading: string;
  readonly id: string;
  readonly nyGenerellLoggoppforingUrl: string;
  readonly nyEpostLoggoppforingUrl: string;
  readonly nyFaseStartLoggoppforingUrl: string;
  readonly nyFaseSluttLoggoppforingUrl: string;
  readonly leggTilVedleggUrl: string;
  readonly slettFilUrl: string;
  readonly viskUtHendelseUrl: string;
  readonly rolle: string;
}

export interface LogEntryUrls {
  General: string;
  Email: string;
  StartPhase: string;
  EndPhase: string;
}

const HendelsesLoggSide: FC<HendelsesLoggSide> = ({
  hendelser,
  pageLinks,
  heading,
  id,
  nyGenerellLoggoppforingUrl,
  nyEpostLoggoppforingUrl,
  nyFaseStartLoggoppforingUrl,
  nyFaseSluttLoggoppforingUrl,
  leggTilVedleggUrl,
  slettFilUrl,
  rolle,
  viskUtHendelseUrl
}) => {
  const oppforingUrlListe: LogEntryUrls = {
    General: nyGenerellLoggoppforingUrl,
    Email: nyEpostLoggoppforingUrl,
    StartPhase: nyFaseStartLoggoppforingUrl,
    EndPhase: nyFaseSluttLoggoppforingUrl
  };

  return (
    <>
      <Heading>{heading}</Heading>
      {pageLinks && pageLinks?.length > 0 && <PageLinkMenu pageLinks={pageLinks} />}
      <EventLog
        hendelser={hendelser}
        rolle={rolle}
        nyeOppforingerUrlListe={oppforingUrlListe}
        leggTilVedleggUrl={leggTilVedleggUrl}
        slettFilUrl={slettFilUrl}
        viskUtHendelseUrl={viskUtHendelseUrl}
        id={id}
      />
    </>
  );
};

export default HendelsesLoggSide;
