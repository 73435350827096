import SelectField, { Choice } from 'components/SelectField/SelectField';
import React from 'react';
import { components } from 'react-select';
import style from './Energimerke.module.css';

const colorLookup = {
  red: '#c7262b',
  orange: '#f67821',
  yellow: '#e8d056',
  chartreuse: '#7fc241',
  green: '#039149'
};

const colors = ['red', 'orange', 'yellow', 'chartreuse', 'green'] as const;
const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G'] as const;

type EnergiMerkeValueLabel = { value: EnergiLetterColor; label: string };
export type Letter = typeof letters[number];
export type Color = keyof typeof colorLookup;

export interface EnergiLetterColor {
  readonly letter: Letter;
  readonly color: Color;
}

const options: EnergiMerkeValueLabel[] = [];

for (let i = 0; i < 7; i++) {
  for (let c = 0; c < 5; c++) {
    options.push({
      value: { letter: letters[i], color: colors[c] },
      label: i + '-' + c
    });
  }
}

const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      <div className={style.menuList}>{props.children}</div>
    </components.MenuList>
  );
};

export const Merke = ({ color, letter }: EnergiLetterColor) => (
  <div
    className={style.merke}
    style={{
      background: `
    linear-gradient(45deg, ${colorLookup[color]} 50%, transparent 50%) 15px 0,
    linear-gradient(135deg, transparent 50%, ${colorLookup[color]} 50%) 15px 0
  `
    }}
  >
    {letter}
  </div>
);

const Option = (props) => (
  <div className={style.option} onClick={() => props.setValue(props.value)}>
    <Merke {...props.value} />
  </div>
);

const SingleValue = (props) => <Merke {...props.data.value} />;

export interface Props {
  value?: EnergiLetterColor;
  onChange(value?: EnergiLetterColor): void;
  isClearable?: boolean;
  readonly feedback?: string;
  readonly validationState?: 'error' | null;
  readonly feedbackMargin?: boolean;
}

export default function Energimerke({
  onChange,
  value,
  isClearable,
  ...props
}: Props) {
  return (
    <div className={style.energimerke}>
      <SelectField
        selectedValue={value && { value, label: '' }}
        onChange={
          (onChange as unknown) as (s: Choice<EnergiLetterColor>) => void
        }
        choices={options}
        components={{
          MenuList,
          Option,
          SingleValue
        }}
        isSearchable={false}
        isClearable={isClearable}
        {...props}
      />
    </div>
  );
}
