import useArrayState from '@clave/use-array-state';
import UploadedFileLabel from '../../../components/UploadedFileLabel/UploadedFileLabel';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import React, { FC, useState } from 'react';
import { KategoriskDokument } from 'types/KategoriskDokument';
import { LinkItem } from 'types/LinkItem';
import Text from 'components/Text/Text';
import EierDataBlockDisplayOnly from 'components/EierDataBlock/DisplayOnly';
import RadioBox from 'components/RadioBox/RadioBox';
import { Choice } from 'components/SelectField/SelectField';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import { cleanPost } from 'js/api-helper';
import type { EierDataOrganisasjon } from 'types/EierDataOrganisasjon';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import AnchoredErrorMessage, { ErrorProps } from '../../../components/AnchoredErrorMessage/AnchoredErrorMessage';

export interface EvaluerSeriositetsSjekkSide {
  readonly title: string;
  readonly pageLinks?: LinkItem[];
  readonly dokumentListe: KategoriskDokument[];
  readonly lagreEvalueringUrl: string;
  readonly erVurdert: boolean;
  readonly erAnbefalt: boolean;
  readonly erAnbefaltMedAnmerkning: boolean;
  readonly eierData: EierDataOrganisasjon;
  readonly nyEierData?: EierDataOrganisasjon;

  readonly vurderingKommentar?: string;
  readonly tilbakeHref?: LinkItem;
  readonly erTrukket: boolean;
}

export enum SeriositetsVurdering {
  UNSET = -1,
  ANBEFALES = 0,
  ANBEFALES_MED_ANMERKNING = 1,
  ANBEFALES_IKKE = 2
}

const EvaluerSeriositetsSjekkSide: FC<EvaluerSeriositetsSjekkSide> = ({
  title,
  pageLinks,
  dokumentListe,
  lagreEvalueringUrl,
  erVurdert,
  erAnbefalt,
  erAnbefaltMedAnmerkning,
  eierData,
  vurderingKommentar,
  tilbakeHref,
  nyEierData,
  erTrukket
}) => {
  const [dynamicDocs, setDynamicDocs] = useArrayState<KategoriskDokument>(dokumentListe);

  const [vurdering, setVurdering] = useState(
    !erVurdert
      ? SeriositetsVurdering.UNSET
      : erVurdert && erAnbefalt
      ? SeriositetsVurdering.ANBEFALES
      : erVurdert && erAnbefaltMedAnmerkning
      ? SeriositetsVurdering.ANBEFALES_MED_ANMERKNING
      : SeriositetsVurdering.ANBEFALES_IKKE
  );

  const [isEval, setIsEval] = useState(erVurdert);
  const [kommentar, setKommentar] = useState<string>(vurderingKommentar || '');
  const [error, setError] = useState<ErrorProps | undefined>();

  const doEval = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await cleanPost(lagreEvalueringUrl, {
        erVurdert: true,
        erAnbefalt: vurdering == SeriositetsVurdering.ANBEFALES,
        erAnbefaltMedAnmerkning: vurdering == SeriositetsVurdering.ANBEFALES_MED_ANMERKNING,
        kommentar: kommentar
      });
      setIsEval(true);
    } catch (error) {
      setError({
        msg: error.message,
        percentageTopPos: 73,
        percentageRightPos: 54
      });
    }
  };

  return (
    <div className="document-delivery-page">
      <div className="document-delivery-page-title-wrapper">
        <Text component="h1" variant="list-title">
          {title}
        </Text>
        {erTrukket && <span className="recind-label">Trukket</span>}
      </div>
      {pageLinks && <PageLinkMenu pageLinks={pageLinks} styles="-bottom-margin" />}
      {tilbakeHref && (
        <Text component={'a'} variant={'download-link'} href={tilbakeHref.url}>
          {tilbakeHref.text}
        </Text>
      )}
      <EierDataBlockDisplayOnly organisasjon={eierData} nyOrganisasjon={nyEierData} />
      <div className="document-delivery-page-list">
        {dynamicDocs.map((x) => (
          <div key={x.kategorinavn} className="dokument">
            <Text className="dokument-title" variant="meny-hovedpunkt" component="h3">
              {x.kategorinavn}
            </Text>
            <UploadedFileLabel file={x} icon={<IconReactIcons type={IconTypes.download} size={4} />} />
          </div>
        ))}
      </div>
      <form className="Evaluation" onSubmit={doEval}>
        <h2>Seriøsitetssjekk</h2>
        <Text variant="mono-16">Vurdering</Text>
        <div className="radios">
          <RadioBox
            label={'Anbefales til videre konkurranse'}
            name={SeriositetsVurdering.ANBEFALES.toString()}
            onChange={() => {
              setVurdering(SeriositetsVurdering.ANBEFALES);
            }}
            checked={vurdering === SeriositetsVurdering.ANBEFALES}
            disabled={isEval}
          ></RadioBox>
          <RadioBox
            label={'Anbefales med anmerkning'}
            name={SeriositetsVurdering.ANBEFALES_MED_ANMERKNING.toString()}
            onChange={() => {
              setVurdering(SeriositetsVurdering.ANBEFALES_MED_ANMERKNING);
            }}
            checked={vurdering === SeriositetsVurdering.ANBEFALES_MED_ANMERKNING}
            disabled={isEval}
          ></RadioBox>
          <RadioBox
            label={'Utelukk objektet'}
            name={SeriositetsVurdering.ANBEFALES_IKKE.toString()}
            onChange={() => {
              setVurdering(SeriositetsVurdering.ANBEFALES_IKKE);
            }}
            checked={vurdering === SeriositetsVurdering.ANBEFALES_IKKE}
            disabled={isEval}
          ></RadioBox>
        </div>
        <div className="Evaluation-comment">
          <Field
            disabled={isEval}
            maxCharacters={500}
            className="CommentArea"
            label="Kommentar"
            type="textarea"
            value={kommentar ?? ''}
            onChange={(e) => setKommentar(e.target.value)}
          />
          <p className="max-char-number">
            ({kommentar.length}/{500})
          </p>
          {error && <AnchoredErrorMessage {...error} />}
        </div>
        <Button disabled={isEval} submit={true}>
          {isEval ? 'Lagret' : 'Lagre evaluering'}
        </Button>
      </form>
    </div>
  );
};

export default EvaluerSeriositetsSjekkSide;
