import CustomDateRangePickerInput from 'components/DateRangePicker/CustomDateRangePickerInput';
import InfoLabel from 'components/InfoLabel/InfoLabel';
import noNb from 'date-fns/locale/nb';
import { useOnChange } from 'js/hooks';
import React, { ReactNode, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import cn from 'classnames';
registerLocale('no-nb', noNb);

export interface Props {
  readonly label: string;
  readonly placeholder: string;
  readonly initialDate?: Date;
  readonly tooltip?: ReactNode;
  onDateChange?: (date: Date) => void;
  readonly validationState?: null | 'error';
  readonly feedback?: string;
  readonly feedbackMargin?: boolean;
  readonly styleException?: string;
  readonly disabled?: boolean;
}

export default function SingleDatePicker({
  label,
  initialDate,
  tooltip,
  onDateChange,
  placeholder,
  validationState,
  feedback,
  styleException,
  feedbackMargin,
  disabled
}: Props) {
  const [date, setDate] = useState(initialDate);
  const showError = feedback && validationState === 'error';

  useOnChange(onDateChange, [date]);

  return (
    <div className={cn(`DatePicker${styleException ?? ''}`)}>
      <InfoLabel label={label} tooltip={tooltip} />
      <div className="DatePicker__CustomInput">
        <DatePicker
          disabled={disabled}
          locale="no-nb"
          selected={date}
          onChange={(date) => setDate((date as Date) ?? undefined)}
          customInput={<CustomDateRangePickerInput validationState={validationState} placeholder={placeholder ?? 'Dato'} />}
          showYearDropdown
          showMonthDropdown
          month
          dateFormat="dd-MM-yyyy"
          placeholderText={placeholder ?? 'Dato'}
        />
        {showError && <ErrorMessage feedback={feedback} feedbackMargin={feedbackMargin} />}
      </div>
    </div>
  );
}
