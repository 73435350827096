import useArrayState from '@clave/use-array-state';
import SingleDatePicker from 'components/DateRangePicker/SingleDatePicker';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import AdvancedFileSelector, { acceptedFileTypes } from 'components/AdvancedFileSelector/AdvancedFileSelector';
import FormSubmitBox from 'components/FormSubmitBox/FormSubmitBox';
import Modal from 'components/Modal/Modal';
import UploadedFileLabel from 'components/UploadedFileLabel/UploadedFileLabel';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import { post, upload } from 'js/api-helper';
import React, { ChangeEvent, FC, useState } from 'react';
import { KategoriskDokument } from 'types/KategoriskDokument';
import { LinkItem } from 'types/LinkItem';
import Text from 'components/Text/Text';
import Button from 'components/Button/Button';

export interface DokumentAvklaringSide {
  readonly title: string;
  readonly pageLinks?: LinkItem[];

  readonly slettVedleggUrl?: string;
  readonly publiserDokumenterUrl?: string;
  readonly varsleMarkedsAktorUrl?: string;
  readonly angrePubliserDokumenterUrl?: string;
  readonly dokumentListe: KategoriskDokument[];
  readonly rolle: string; //enten OG eller PL

  readonly dokumenterPublisert: boolean;
  readonly leveranseFristUtc?: string;
}

interface KategoriskDokumentAdapter {
  kategoriNavn: string;
  kategoriIndex: number;
  lastOppVedleggUrl: string;
  dokument: KategoriskDokument;
}

//1: create an indexed list of all documents, with respective category name
//2: whenever a request is sent - the contextual index is kept within function scope
//3: replace array item at the contextual index

function documentListTransformer(dokumentListe: KategoriskDokument[]): KategoriskDokumentAdapter[] {
  return dokumentListe.map((x, idx) => {
    return {
      kategoriNavn: x.kategorinavn,
      kategoriIndex: idx,
      lastOppVedleggUrl: x.lastOppVedleggUrl ?? '/error',
      dokument: x
    };
  });
}

const DokumentAvklaringSide: FC<DokumentAvklaringSide> = ({
  title,
  pageLinks,
  dokumentListe,
  slettVedleggUrl,
  publiserDokumenterUrl,
  angrePubliserDokumenterUrl,
  varsleMarkedsAktorUrl,
  rolle,
  dokumenterPublisert,
  leveranseFristUtc
}) => {
  const [dynamicDocs, setDynamicDocs] = useArrayState<KategoriskDokumentAdapter>(documentListTransformer(dokumentListe));
  const [dokumenterErPublisert, setDokumenterErPublisert] = useState<boolean>(dokumenterPublisert);
  const [date, setDate] = useState<Date | undefined>(leveranseFristUtc ? new Date(leveranseFristUtc) : undefined);
  const [error, setError] = useState<string>();
  const [showDocsPublishedModal, setShowDocsPublishedModal] = useState(false);
  const [invalidDocs, setInvalidDocs] = useArrayState<number>([]);

  function validateDocuments(docs: KategoriskDokumentAdapter[]) {
    const indexesOfNotUploadedDocuments = docs.filter((x) => !x.dokument.id).map((y) => y.kategoriIndex);
    setInvalidDocs(indexesOfNotUploadedDocuments);
    return !indexesOfNotUploadedDocuments?.length;
  }

  const uploadAttachments = async (e: ChangeEvent<HTMLInputElement>, kategoriIndex: number, lastOppVedleggUrl: string) => {
    const fileList = Array.from(e.target.files ?? []);
    const data = new FormData();

    fileList?.forEach((file) => data.append('file', file));

    try {
      const response = await upload(lastOppVedleggUrl, data);
      const replacement = dynamicDocs[kategoriIndex];

      if (replacement) {
        replacement.dokument = response;
        setDynamicDocs.replaceAt(kategoriIndex, replacement);
        validateDocuments(dynamicDocs);
        setError('');
      } else {
        setError('Visningen vet ikke hvor det nye dokumentet skal ligge');
      }
    } catch (error) {
      setError('Klarte ikke laste opp filen');
    }
  };

  const handleRemoveAttachment = async (kategoriIndex: number, id?: string) => {
    if (!id) return;
    try {
      await post(`${slettVedleggUrl}?filId=${id}`);
      const replacement = dynamicDocs[kategoriIndex];

      if (replacement) {
        replacement.dokument = {
          kategorinavn: replacement.kategoriNavn,
          lastOppVedleggUrl: replacement.lastOppVedleggUrl
        };

        setDynamicDocs.replaceAt(kategoriIndex, replacement);
        setError('');
      } else {
        setError('Visningen vet ikke hvilken fil som skal slettes');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submitAttachments = async (url) => {
    if (!url) return;
    try {
      if (!validateDocuments(dynamicDocs)) return;
      
      const committedAttachments = await post(url, { LeveranseFristUtc: date });
      if(!committedAttachments){
        throw new Error();
      }

      if(varsleMarkedsAktorUrl){
        const updatesForMarkedsAktor = await post(varsleMarkedsAktorUrl);
        if(!updatesForMarkedsAktor){
          throw new Error();
        }
      }

      setShowDocsPublishedModal(false);
      setDokumenterErPublisert(true);
    } catch (error) {
      console.error(error);
      setShowDocsPublishedModal(false);
      setDokumenterErPublisert(false);
      setError('Publisering feilet');
    }
  };

  const regretSubmitAttachments = async (url) => {
    if (!url) return;
    try {
      await post(url);
      setDokumenterErPublisert(false);
    } catch (error) {
      console.error(error);
      setError('Angring feilet');
    }
  };

  if (dokumenterPublisert && rolle == 'OG') {
    return (
      <div className="document-delivery-page">
        <Text component="h1" variant="list-title">
          {title}
        </Text>
        {pageLinks && <PageLinkMenu pageLinks={pageLinks} styles="-bottom-margin" />}
        <div className="document-delivery-page-list">
          {dynamicDocs.map((x) => (
            <div key={x.dokument?.visningsnavn} className="dokument">
              <Text variant="meny-hovedpunkt" component="h3">
                {x.dokument?.kategorinavn}
              </Text>
              <UploadedFileLabel file={x.dokument} icon={<IconReactIcons type={IconTypes.upload} size={3} />} />
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="document-delivery-page">
        <Modal
          title={'publiser dokumenter for seriøsitetssjekk?'}
          open={showDocsPublishedModal}
          clickOutsideToClose={true}
          onClose={() => setShowDocsPublishedModal(false)}
        >
          <p>
            Markedsaktører vil nå varsles om at seriøsitetsjekken er klar til nedlasting og <br></br> innsending av dokumenter og
            besvarelser. Du vil få en e-post når markedsaktører har sendt inn sine svar.
          </p>

          <p>
            OBS! De ulike aktørene vil motta automatisk e-post som forklarer om de er med videre eller ikke.
          </p>
          <div className="ModalButtons">
            <Button onClick={() => submitAttachments(publiserDokumenterUrl)}>Ok</Button>
            <Button
              secondary
              onClick={() =>
                setShowDocsPublishedModal(false)
              }
            >
              Avbryt
            </Button>
          </div>
        </Modal>
        <Text component="h1" variant="list-title">
          {title}
        </Text>
        {pageLinks && <PageLinkMenu pageLinks={pageLinks} styles="-bottom-margin" />}
        <div className="document-delivery-page-list">
          <Text variant="overskrift-mengdetekst" component="h2">
            Seriøsitet
          </Text>
          {dynamicDocs.map((x) => (
            <div key={x.kategoriNavn} className="dokument">
              <Text variant="meny-hovedpunkt" component="h3">
                {x.kategoriNavn}
                <span className={'muted-label'}>* KREVER BESVARELSE</span>
              </Text>
              {x.dokument?.nedlastingslenke ? (
                <UploadedFileLabel
                  file={x.dokument}
                  icon={<IconReactIcons type={IconTypes.trash} size={4} />}
                  onRemove={(id) => {
                    handleRemoveAttachment(x.kategoriIndex, id);
                  }}
                />
              ) : (
                <AdvancedFileSelector
                  multiple={false}
                  name="attachments"
                  invalid={invalidDocs.includes(x.kategoriIndex)}
                  accept={acceptedFileTypes.AllTypes}
                  onChange={(e) => {
                    uploadAttachments(e, x.kategoriIndex, x.dokument.lastOppVedleggUrl ?? '/error/');
                  }}
                />
              )}
            </div>
          ))}
        </div>
        <Text variant="paragraph">
          OBS!: Skatt, Mva- og firma-attest blir etterspurt automatisk ved etterspørring av seriøsitetsdokumentasjon.
        </Text>
        <FormSubmitBox
          heading="Publiser dokumenter for seriøsitetssjekk"
          bodyText="Oppdragsgiver har gitt sin godkjennelse, du kan nå publisere dokumentene som da blir distribuert til markedsaktørene som er invitert til konkurransen."
          buttonText={'Publiser dokumenter'}
          regretButtonText={'Angre'}
          dokumenterPublisert={dokumenterErPublisert}
          disabled={!date}
          submitUrl={dokumenterErPublisert ? angrePubliserDokumenterUrl ?? '/error' : publiserDokumenterUrl ?? '/error'}
          submitFunc={(url) => {
            setShowDocsPublishedModal(true)
          }}
          regretFunc={(url) => {
            regretSubmitAttachments(url);
          }}
        >
          <SingleDatePicker
            initialDate={leveranseFristUtc && dokumenterPublisert ? new Date(leveranseFristUtc) : undefined}
            disabled={dokumenterErPublisert}
            label={'Leveransefrist'}
            placeholder={'dd.mm.åååå'}
            onDateChange={(e) => setDate(e)}
          />
        </FormSubmitBox>
        {error && (
          <Modal title={'En feil oppstod'} open={error != ''} onClose={() => setError('')}>
            {error}
          </Modal>
        )}
      </div>
    );
  }
};

export default DokumentAvklaringSide;
