import { DokumentSkjemadel } from 'types/DokumentSkjemadel';
import { InvalidDocStructure } from 'types/InvalidDocStructure';

export function disableRemovableDoc(docs: DokumentSkjemadel[]) {
  docs.forEach((doc) => doc.underDeler.forEach((subDoc) => subDoc.vedlegg?.forEach((file) => (file.slettbar = false))));
}

export function getInvalidDocStructure(docs: DokumentSkjemadel[]) {
  let result: Array<InvalidDocStructure> = [];

  docs.forEach((part) => {
    const topPos = part.posisjon;
    const invalidIdxList = part.underDeler.map((doc) => {
      if ((!doc.vedlegg || doc.vedlegg.length == 0) && doc.requiresUpload) return doc.posisjon;
      return undefined;
    });

    result.push({
      topPos: topPos,
      invalidDocIdx: invalidIdxList
    });
  });

  return result.filter((x) => x.invalidDocIdx.some((idx) => idx != undefined));
}
