import { useCallback, useState } from 'react';
import { ValidationError } from '../api-helper';

export interface ValidationEntry {
  readonly validationState: null | 'error';
  readonly feedback: string;
  readonly feedbackMargin: boolean;
}

export type Fields = Record<string, any>;
export type Validations<T> = Record<keyof T, ValidationEntry>;

export default function useValidation<T extends Fields>(fields: T) {
  const [state, setState] = useState(validEntry(fields));

  const validate = useCallback((error: ValidationError) => {
    setState(objMap((old, name) => invalidEntry(error[name]) || old));
  }, []);

  const resetValidation = useCallback(() => {
    setState(validEntry);
  }, []);

  return [state, validate, resetValidation] as const;
}

const objMap = (
  map: (value: ValidationEntry, name: string, index: number) => ValidationEntry
) => <T extends Fields>(obj: T) =>
  Object.fromEntries(
    Object.entries(obj).map(([name, value], index) => [
      name,
      map(value, name, index)
    ])
  ) as Validations<T>;

const validEntry = objMap(
  (): ValidationEntry => ({
    validationState: null,
    feedback: '',
    feedbackMargin: false
  })
);

const invalidEntry = (entry: string | undefined): ValidationEntry | undefined =>
  entry
    ? { validationState: 'error', feedback: entry, feedbackMargin: true }
    : undefined;
