import { Fildata } from 'types/Fildata';

export function toFile(file: Fildata | undefined) {
  if (!file) return undefined;
  return toCamelCase(file);
}

export function toCamelCase(file: Fildata) {
  return {
    id: file.id,
    nedlastingslenke: file.nedlastingslenke,
    visningsnavn: file.visningsnavn
  };
}
