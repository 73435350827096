import { Validations, ValidationEntry } from 'js/hooks/useValidation';
import style from '../SvarRediger.module.css';
import React, { FC } from 'react';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

const keyMap = {
  utleier: 'Utleier',
  utleiemegler: 'Utleiemegler',
  generellInfoStandard: 'Generell info',
  generellInfoFasiliteter: 'Generell info - fasiliteter',
  generellInfoLedigFraUtc: 'Generell info - dato',
  hovedMatrikkelIdentitet: 'Hovedmatrikkel - identitet',
  hovedMatrikkelAdressetekst: 'Hovedmatrikkel - adressetekst',
  tilleggsMatrikler: 'Tilleggsmatrikler',
  avstandKollektivTransport: 'Avstand kollektivtransport',
  sykkelparkeringsplasser: 'Sykkelparkeringsplasser',
  parkeringsplasser: 'Parkeringsplasser',
  ladepunkter: 'Ladepunkter',
  bygningensStand: 'Bygningens stand',
  reguleringsstatus: 'Reguleringsstatus',
  energimerke: 'Energimerke',
  energimerkeFarge: 'Energimerke farge',
  energiattestField: 'Energiattest',
  planlagtEnergimerke: 'Planlagt energimerke',
  kWh: 'KWh',
  energieffektivitetTiltak: 'Energieffektivitet tiltak',
  miljosertifisert: 'Miljøsertifisert',
  miljosertifisertDetalj: 'Miljøsertifisert - Detalj',
  totaltAreal: 'Totalt areal',
  ledigAreal: 'Ledig areal',
  arealPerPlan: 'Areal per plan',
  universellUtforming: 'Universell utforming',
  universellUtformingDetalj: 'Universell utforming - Detalj',
  prisKvmUtenTiltak: 'Priv Kvm uten tiltak',
  felleskostKvm: 'Felleskost pr. kvm ',
  prisKvmMedTiltak: 'Pris pr. kvm',
  mvaKompensasjonKvm: 'Mva-kompensasjon',
  parkeringsplasskost: 'Parkerinsplass kost',
  sykkelplasskost: 'Sykkeplass kost',
  kostKantineBidrag: 'Kost kantinebidrag',
  bildeEksteriorField: 'Eksteriør',
  kommentarer: 'Øvrige kommentarer',

  // Nye fra tomtesok
  ledigFraUtc: 'Generell info - dato',
  naeringsmegler: 'Næringsmegler',
  kommuneplanStatus: 'Kommuneplanstatus',
  beliggenhet: 'Beliggenhet',
  grunnforhold: 'Grunnforhold',
  infrastruktur: 'Infrastruktur',
  gjennomforingstidTilByggeklar: 'Gjennomforingstid til byggeklar',
  bebygdStatus: 'Bebygd status',
  tilbudtAreal: 'Tilbudt areal',
  utbyggingspotensialeBTA: 'Utbyggingspotensiale BTA',
  prisKvmTilbudtAreal: 'Pris KVM tilbudt areal',
  kartutsnittField: 'Kartutsnitt med tomtegrense'
};
export interface PresenterFeil {
  readonly validation: Validations<Record<string, ValidationEntry>>;
}

const PresenterFeil: FC<PresenterFeil> = ({ validation }) => {
  const validationErrorList = React.useMemo(() => {
    function scrollToElementByText(index: number) {
      if (window) {
        const elements = window.document.getElementsByTagName(`span`);
        const filtered = Array.from(elements).filter((span) => span.className.includes('feedback'));
        const found = filtered[index];
        if (found)
          found.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
          });
      }
    }

    return Object.entries(validation)
      .filter((entry) => entry[1]?.validationState === 'error')
      .map(([key, val], index) => {
        return {
          key,
          ...val,
          feedback: (
            <span>
              <b>{keyMap[key]}: </b>
              {val.feedback}
              <button onClick={() => scrollToElementByText(index)}>Gå til feil</button>
            </span>
          )
        };
      });
  }, [validation]);

  if (!validationErrorList?.length) return null;
  return (
    <ul className={style.errorList}>
      <h3>{`Det er ${validationErrorList.length} feil eller ${
        validationErrorList.length === 1 ? 'mangel' : 'mangler'
      } i skjema`}</h3>
      {validationErrorList?.map((v) => (
        <li key={v.key}>
          <ErrorMessage feedback={v.feedback} feedbackMargin={v.feedbackMargin} />
        </li>
      ))}
      <aside>* Må besvares </aside>
    </ul>
  );
};

export default PresenterFeil;
