import cn from 'classnames';
import React, { useMemo } from 'react';
import style from './IconButton.module.css';
import { getIcon, IconTypes } from 'components/Icon/IconReactIcons';

interface CommonProps {
  size?: 'small' | 'normal' | 'large';
  title?: string;
  iconType?: IconTypes;
  color?: string;
  text?: string;
  children?: React.ReactNode;
}
export interface ButtonProps extends CommonProps {
  type?: 'normal' | 'warning' | 'danger' | 'noBorder' | 'rounded';
  onClick: () => void;
  href?: never;
}
export interface LinkProps extends CommonProps {
  type?: 'normal' | 'warning' | 'danger';
  href: string;
  onClick?: never;
}

export type Props = ButtonProps | LinkProps;

export default function IconButton({
  children,
  title,
  type = 'normal',
  iconType,
  color,
  href,
  text,
  size = 'normal',
  onClick,
  ...rest
}: Props) {
  const { _title, Component } = useMemo(() => {
    if (iconType) {
      const { alt, Component } = getIcon(iconType);
      return { _title: title || alt, Component };
    } else {
      return { _title: title, Component: children };
    }
  }, []);

  if (href) {
    return (
      <a
        {...rest}
        className={cn(style.IconButton, color && style[color], type && style[type], size && style[size])}
        title={_title}
        href={href}
      >
        {Component}
      </a>
    );
  }

  return (
    <button
      {...rest}
      title={_title}
      className={cn(style.IconButton, type && style[type], color && style[color], size && style[size])}
      onClick={onClick}
    >
      {text ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: 10 }}>{Component}</span>
          {text}
        </div>
      ) : (
        Component
      )}
    </button>
  );
}

IconButton.defaultProps = {
  type: 'normal',
  size: 'normal'
};
