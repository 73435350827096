import React, { FC, useState } from 'react';
import { BefaringListItem } from 'types/BefaringListItem';
import SvarInnkomneTitle from '../../Svar/SvarInnkomne/components/SvarInnkomneTitle';
import style from './BefaringInnkomneSide.module.css';
import FullforBefaringSkjema from './FullforBefaringSkjema/FullforBefaringSkjema';
import { LinkItem } from 'types/LinkItem';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import {SortLeieObjekter} from 'js/SortLeieObjekter';


import { TilbudtObjekt } from 'types/TilbudtObjekt';
import { TilbudtObjektListe } from 'types/TilbudtObjektListe';
import TilbudtObjektCardList, { TilbudtObjektCardListHeadings } from 'components/TilbudtObjektCardList/TilbudtObjektCardList';


export interface BefaringInnkomneSide {
  readonly title: string;
  readonly befaringer: TilbudtObjekt[];
  readonly annonseUrl: string;
  readonly lagreBefaringsRekkefolgeUrl: string;
  readonly lastNedZipUrl?: string;
  readonly listeLevert: boolean;
  readonly submitUrl: string;
  readonly undoUrl?: string;
  readonly pageLinks?: LinkItem[];
}

export enum ListeStatus {
  Levert,
  Vurdert,
  IkkeVurdert,
  Tom
}

const BefaringInnkomneSide: FC<BefaringInnkomneSide> = ({
  title,
  befaringer,
  lagreBefaringsRekkefolgeUrl,
  annonseUrl,
  lastNedZipUrl,
  listeLevert,
  submitUrl,
  undoUrl,
  pageLinks
}: BefaringInnkomneSide) => {


  const [sortedList, setSortedList] = useState<TilbudtObjektListe>(SortLeieObjekter(befaringer))
  const {NotEvaluated, Recommended, Rejected, Canceled} = sortedList
  

  const now = React.useMemo(() => {
    const d = new Date();
    return `${d.getUTCDate()}/${d.getUTCMonth() + 1}/${d.getUTCFullYear()}`;
  }, []);  


  const getListeStatus =() => {
    
    if(Recommended.length === 0 && NotEvaluated.length === 0 && Rejected.length === 0 && Canceled.length === 0){
      return ListeStatus.Tom
    }
    else if(listeLevert){
      return ListeStatus.Levert
    }
    else if (NotEvaluated.length === 0){
      return ListeStatus.Vurdert
    }
    else if(NotEvaluated.length !== 0){
      return ListeStatus.IkkeVurdert
    }
    return ListeStatus.IkkeVurdert
  }

  const ListHeadings: TilbudtObjektCardListHeadings= {
    recommended: "Anbefales å vurdere disse lokalene for deltakelse i konkurransen",
    notEvaluated: "Ikke vurdert",
    rejected: "Anbefales å utelukke fra konkurransen",
    canceled: "Trukket av aktør"
  }

  return (
    <div className={style.BefaringInnkomneSide}>
      <SvarInnkomneTitle
        title={title}
        lastNedZipUrl={lastNedZipUrl}
        annonseUrl={annonseUrl}
      />
       {pageLinks && pageLinks?.length > 0 && (
        <PageLinkMenu pageLinks={pageLinks} />
      )}
      
      <TilbudtObjektCardList list={sortedList} headings={ListHeadings}/> 
        
          <FullforBefaringSkjema
            submitUrl={submitUrl}
            undoUrl={undoUrl}
            skjemaStatus={getListeStatus()}
          />
      </div>
    
  );
};

export default BefaringInnkomneSide;
