import cn from 'classnames';
import ArcGisLoader from 'components/ArcGisKart/ArcGisLoader';
import IconButton from 'components/IconButton/IconButton';
import KontaktpersonPanel from 'components/Kontaktperson/Kontaktperson';
import Paragraphs from 'components/Paragraphs/Paragraphs';
import Popup from 'components/Popup/Popup';
import Button from 'components/Button/Button';
import Panel from 'components/Panel/Panel';
import Text from 'components/Text/Text';
import { dateString, shortDateString } from 'js/date-helper';
import React, { FC, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { BsLightningFill, BsPencil } from 'react-icons/bs';
import { FaBus, FaCity, FaGlobeAfrica, FaParking, FaPlane, FaShip } from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';
import { GiHouseKeys } from 'react-icons/gi';
import { GrBike, GrBus, GrTrain } from 'react-icons/gr';
import { IoIosPeople } from 'react-icons/io';
import { MdSubway, MdTram } from 'react-icons/md';
import { Fildata } from 'types/Fildata';
import { Kart } from 'types/Kart';
import { Kontaktperson } from 'types/Kontaktperson';
import { SingelKollektivTransportEntry } from 'types/SingelKollektivTransportEntry';
import styles from '../Annonse.module.css';
import FileList from '../components/FileList';
import PanelEntry from '../components/PanelEntry';
import EmbeddedButton from '../../../pages/Svar/SvarDetalj/components/EmbeddedButton';

const Kollektivtyper = {
  Kollektivtransport: <FaBus />,
  Sykkelparkeringsplasser: <GrBike />,
  Parkeringsplasser: <FaParking />,
  Ladepunkter: <BsLightningFill />,
  Buss: <GrBus />,
  Tog: <GrTrain />,
  'T-bane': <MdSubway />,
  Trikk: <MdTram />,
  Ferge: <FaShip />,
  Flytog: <GrTrain />,
  Fly: <FaPlane />,
  Metrobuss: <GrBus />,
  Flybuss: <GrBus />
} as const;

export interface AnnonseTomteSide {
  readonly prosjektId: string;
  readonly annonsePublisert: boolean;
  readonly kundelogo: string;
  readonly kartBakgrunn: string;
  readonly annonseTittel: string;
  readonly annonseIngress: string;
  readonly annonseInnhold: string;
  readonly annonsePubliseringUTC: string;
  readonly annonseSvarfristUTC: string;
  readonly kontaktperson: Kontaktperson;
  readonly some: {
    readonly mailUrl: string;
    readonly facebookUrl: string;
    readonly linkedinUrl: string;
    readonly twitterUrl: string;
    readonly annonseHtmlSnippet?: string;
  };
  readonly fylkeKommuner: {
    readonly fylke: string;
    readonly fylkeId: string;
    readonly kommune: string;
    readonly kommuneId: string;
  }[];
  readonly kart?: Kart;
  readonly ekstraDokumenter: Fildata[];
  readonly kartSokeomradeBilde?: Fildata;
  readonly kartSokeomradeDokument?: Fildata;
  readonly fylkeNavn: string;
  readonly kommuneNavn: string;
  readonly onsketOvertakelseFraUtc: string;
  readonly onsketOvertakelseTilUtc: string;
  readonly onsketLeieperiode: number;
  readonly arealFra: number;
  readonly arealTil: number;
  readonly avstandKollektivTransport: SingelKollektivTransportEntry[];
  readonly sykkelparkeringsplasser: number;
  readonly parkeringsplasser: number;
  readonly ladepunkter: number;
  readonly svarUrl?: string;
  readonly innkomneSvarUrl?: string;
  readonly redigerUrl?: string;
}

const AnnonseTomteSide: FC<AnnonseTomteSide> = ({ svarUrl, innkomneSvarUrl, redigerUrl, fylkeKommuner, ...props }) => {
  const [mapPopupOpen, setMapPopupOpen] = useState<boolean>(false);

  const renderMapImageOrArcgis = () => {
    if (props.kartSokeomradeBilde)
      return (
        <a href={`${props.kartSokeomradeBilde?.nedlastingslenke}?download=false`} target="_blank" rel="noreferrer">
          <img src={props.kartSokeomradeBilde?.nedlastingslenke} />
        </a>
      );
    else if (props.kart) {
      if (mapPopupOpen) return <div style={{ height: 600 }} />;
      else
        return (
          <div style={{ height: 600 }}>
            <ArcGisLoader toggleFullscreen={() => setMapPopupOpen(!mapPopupOpen)} {...props.kart} />
          </div>
        );
    } else return null;
  };

  return (
    <div className={styles.StickyContainer}>
      <div className={styles.HeadingContainer}>
        <Text variant="mono-16">
          Svarfrist: <b>{dateString(props.annonseSvarfristUTC)}</b>
        </Text>

        <Text variant="news-title">{props.annonseTittel}</Text>
        {!props.annonsePublisert && <p className={styles.UnPublishedMessage}>Annonsen er ikke publisert</p>}

        <Paragraphs textVariant="news-ingress">{props.annonseIngress}</Paragraphs>
        <div style={{ height: 30 }} />
        <Text variant="mono-16">
          Publisert: <b>{dateString(props.annonsePubliseringUTC)}</b>
        </Text>
      </div>

      {/* <div
        className={styles.Header}
        style={{ backgroundImage: `url(${props.kartBakgrunn})` }}
      > */}
      {/* <img className={styles.HeaderLogo} src={props.kundelogo} /> */}
      {/* </div> */}

      <div className={styles.Article}>
        {(props.kart || props.kartSokeomradeBilde) && <div className={styles.sokeomrade}>{renderMapImageOrArcgis()}</div>}

        <KontaktpersonPanel panelType="border" {...props.kontaktperson} />

        <div className={styles.ArticleBody}>
          <Paragraphs>{props.annonseInnhold}</Paragraphs>
        </div>
        <div className={styles.Files}>
          <Text variant="annonse-grid-title" component="h3">
            Tilleggsinformasjon
          </Text>
          <FileList files={props.ekstraDokumenter} />
        </div>
        <Panel type="blue" className={cn(styles.panel, styles.location)}>
          <Text variant="annonse-grid-title" component="h3">
            Beliggenhet
          </Text>

          {fylkeKommuner?.map(({ fylke, kommune, fylkeId, kommuneId }) => (
            <div className={styles.FylkeKommune} key={fylkeId + kommuneId}>
              <PanelEntry icon={<FaGlobeAfrica />} label={'Fylke'} value={fylke} />
              <PanelEntry icon={<FaCity />} label={'Kommune'} value={kommune} />
            </div>
          ))}

          {props.kartSokeomradeDokument?.nedlastingslenke && (
            <>
              <span>Søkeområde:</span>
              <a href={props.kartSokeomradeDokument?.nedlastingslenke}>
                Last ned kart <FiExternalLink />
              </a>
            </>
          )}
        </Panel>

        <Panel type="blue" className={cn(styles.panel, styles.area)}>
          <Text variant="annonse-grid-title" component="h3">
            Tid og areal
          </Text>
          <PanelEntry
            icon={<GiHouseKeys />}
            label={'Overtakelse'}
            value={`${shortDateString(props.onsketOvertakelseFraUtc)} -
            ${shortDateString(props.onsketOvertakelseTilUtc)}`}
          />

          <PanelEntry icon={<IoIosPeople />} label={'Areal'} value={`${props.arealFra}kvm - ${props.arealTil}kvm`} />
        </Panel>

        <Panel type="blue" className={cn(styles.panel, styles.transport)}>
          <Text variant="annonse-grid-title" component="h3">
            Transport
          </Text>

          {props.avstandKollektivTransport.map((t) => (
            <PanelEntry
              key={t.kollektivtype}
              icon={Kollektivtyper[t.kollektivtype]}
              label={t.kollektivtype}
              value={`${t.avstandIMeter}m`}
            />
          ))}
          <PanelEntry
            key={'sykkelP'}
            icon={Kollektivtyper.Sykkelparkeringsplasser}
            label={'P-plasser, sykkel'}
            value={`${props.sykkelparkeringsplasser} stk`}
          />
          <PanelEntry
            key={'p-plasser'}
            icon={Kollektivtyper.Parkeringsplasser}
            label={'P-plasser, bil'}
            value={`${props.parkeringsplasser} stk`}
          />
          <PanelEntry
            key={'ladepnkt'}
            icon={Kollektivtyper.Ladepunkter}
            label={'Ladepunkter'}
            value={`${props.ladepunkter} stk`}
          />
        </Panel>

        <Popup close={() => setMapPopupOpen(false)} isOpen={mapPopupOpen}>
          <ArcGisLoader initialZoomDisabled toggleFullscreen={() => setMapPopupOpen(!mapPopupOpen)} {...props.kart} />
        </Popup>
      </div>

      <EmbeddedButton />

      <div className={styles.svar}>
        {svarUrl && <Button onClick={() => (document.location.href = svarUrl)}>Svar på søk</Button>}
        {innkomneSvarUrl && (
          <IconButton type="normal" href={innkomneSvarUrl} title="Se innkomne svar">
            <AiOutlineArrowRight />
          </IconButton>
        )}
        {redigerUrl && (
          <IconButton type="normal" href={redigerUrl} title="Rediger prosjekt">
            <BsPencil />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default AnnonseTomteSide;
