import React, { InputHTMLAttributes, ChangeEvent, DragEvent } from 'react';

import './AdvancedFileSelector.scss';

import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import cn from 'classnames';

export const acceptedFileTypes = {
  AllTypes: '.pdf, .docx, .xls, .xlsx, .png, .jpg,',
  DocAndPdf: '.docx, .pdf'
};

export interface AdvancedFileSelectorProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  highlightLabel?: string;
  instruction?: string;
  invalid?: boolean;
  autoUpload?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const AdvancedFileSelector = ({
  name,
  label,
  highlightLabel,
  instruction,
  onChange,
  invalid,
  autoUpload,
  ...rest
}: AdvancedFileSelectorProps) => {
  const dropHandler = (event: DragEvent<HTMLLabelElement>) => {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      let convertedEvent = event as unknown as ChangeEvent<HTMLInputElement>;
      convertedEvent.target.files = event.dataTransfer.files;
      onChange(convertedEvent);
    } else {
      console.log('error!');
    }
  };

  return (
    <label
      onDrop={dropHandler}
      onDragOver={(event) => event.preventDefault()}
      className={cn('advanced-file-selector', invalid && '-invalid', autoUpload && '-auto-upload', rest.disabled && '-disabled')}
    >
      {!autoUpload ? (
        <>
          <i className="advanced-file-selector__icon">
            <IconReactIcons type={IconTypes.upload} size={2} />
          </i>

          <span className="advanced-file-selector__text">
            <span>
              {`${label ? label : 'Last opp dokumenter ved å dra de inn i feltet, eller '}`}
              <strong>{`${highlightLabel ? highlightLabel : 'finn frem på maskinen'}`}</strong>
            </span>
            <span>
              {`${
                instruction ? instruction : 'Tillatte filer er: .PDF, .DOCX, .XLS, .XLSX, .PNG og .JPG, maks filstørrelse er 32MB'
              }`}
            </span>
          </span>
          <input type="file" name={name} onChange={(e) => onChange(e)} multiple {...rest} />
        </>
      ) : (
        <>
          <span className="advanced-file-selector__text">
            <span>{`${label ? label : 'Innhentes automatisk av markedsaktør'}`}</span>
            <span>
              {`${
                instruction ? instruction : 'Tillatte filer er: .PDF, .DOCX, .XLS, .XLSX, .PNG og .JPG, maks filstørrelse er 32MB'
              }`}
            </span>
          </span>
        </>
      )}
    </label>
  );
};

export default AdvancedFileSelector;
