import TextField from 'components/TextField/TextField';
import { post } from 'js/api-helper';
import { usePostback } from 'js/hooks';
import React, { useCallback, useState } from 'react';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import style from './HjelpeteksterSide.module.css';

export interface EditHjelpetekstProps {
  readonly initialTittel: string;
  readonly initialInnhold?: string;
  readonly lagreUrl: string;
  readonly slettUrl?: string;
  onSave(tittel: string, innhold: string, slettUrl: string): void;
  onDelete(): void;
  stopEditing(): void;
}

export default function EditHjelpetekst({
  initialTittel,
  initialInnhold,
  lagreUrl,
  slettUrl,
  onSave: onChange,
  stopEditing,
  onDelete
}: EditHjelpetekstProps) {
  const [tittel, setTittel] = useState(initialTittel);
  const [innhold, setInnhold] = useState(initialInnhold);

  const { postback, validation, canSubmit } = usePostback(lagreUrl, {
    tittel,
    innhold
  });

  const saveChanges = useCallback(async () => {
    const { slettUrl } = await postback();
    onChange(tittel, innhold ?? '', slettUrl);
  }, [tittel, innhold]);

  const deleteEntry = useCallback(async () => {
    if (!slettUrl) return;
    await post(slettUrl);
    onDelete();
  }, []);

  return (
    <div className={style.edit}>
      <Field autoFocus label="Tittel" value={tittel} onChange={(e) => setTittel(e.currentTarget.value)} {...validation.tittel} />
      <TextField
        rows={5}
        type="textarea"
        label="Innhold"
        maxCharacters={1500}
        value={innhold}
        onChange={(e) => setInnhold(e.currentTarget.value)}
        {...validation.innhold}
      />
      <div className={style.footer}>
        <Button onClick={saveChanges} disabled={!canSubmit}>
          Lagre
        </Button>
        <Button onClick={stopEditing}>Avbryt</Button>
        {slettUrl && <Button onClick={deleteEntry}>Slett</Button>}
      </div>
    </div>
  );
}
