import { shortDateString } from 'js/date-helper';
import React from 'react';
import Text from 'components/Text/Text';

export interface Props {
  readonly generellInfoLedigFraUtc?: string;
  readonly gjennomforingstidTilByggeklar?: string;
}

export default function OvertakelsePopup({ generellInfoLedigFraUtc, gjennomforingstidTilByggeklar }: Props) {
  return (
    <>
      <Text variant="paragraph-header" component="h3">
        Overtakelse
      </Text>

      {!!generellInfoLedigFraUtc && (
        <div>
          <Text variant="list-item">
            Ledig fra: <b style={{ color: 'var(link-color)' }}>{shortDateString(generellInfoLedigFraUtc)}</b>
          </Text>
        </div>
      )}

      {!!gjennomforingstidTilByggeklar && (
        <div>
          <Text variant="list-item">
            Gjennomføringstid til tom er byggeklar: <b style={{ color: 'var(link-color)' }}>{gjennomforingstidTilByggeklar}</b>
          </Text>
        </div>
      )}
    </>
  );
}
