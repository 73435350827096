//TODO: validation

import SingleDatePicker from 'components/DateRangePicker/SingleDatePicker';
import LabeledField from 'components/LabeledField/LabeledField';
import SelectField, { Choice } from 'components/SelectField/SelectField';
import Field from 'components/Field/Field';
import Label from 'components/Label/Label';
import TimePicker from 'components/TimePicker/TimePicker';
import UploadMultipleField from 'components/UploadMultipleField/UploadMultipleField';

import { Validations } from 'js/hooks/useValidation';
import { toCamelCase } from 'js/upload-files';
import React, { FC, useEffect } from 'react';
import { useState } from 'react';
import { LogEntryForm } from '../LogEntryForm';

export interface GenericForm extends LogEntryForm {
  activeChoice: Choice<number>;
  validation: Validations<any>;
  setActiveChoice: (choice: Choice<number>) => void;
  onChange: (event: any) => void;
}

const GenericForm: FC<GenericForm> = ({ ...props }) => {
  const [formValidation, setFormValidation] = useState(props.validation);

  useEffect(() => {
    setFormValidation(props.validation);
  }, [props.validation]);

  return (
    <div className="LogEntryForm--generic">
      <div className="span-half">
        <Label>Type loggføring</Label>
        <SelectField
          onChange={(value) => {
            props.setActiveChoice(value);
          }}
          className="picker"
          choices={props.choices}
          selectedValue={props.activeChoice}
        />
      </div>
      <div className="span-whole">
        <SingleDatePicker
          className="picker"
          label={'Dato for hendelse'}
          placeholder={'Velg dato'}
          styleException={'half-width'}
          onDateChange={(val) => {
            props.onChange({ handlingUtc: val });
            setFormValidation({
              ...formValidation,
              handlingUtc: {
                validationState: null,
                feedback: '',
                feedbackMargin: false
              }
            });
          }}
          {...formValidation.handlingUtc}
        />
      </div>
      <div className="span-whole">
        <Label>Estimert tidspunkt</Label>

        <TimePicker onChange={(val) => props.onChange({ handlingKlokkeslett: val })} />
      </div>
      <div className="span-whole">
        <Label>Tittel</Label>
        <Field
          onChange={(val) => {
            props.onChange({ handlingTittel: val.target.value });
            setFormValidation({
              ...formValidation,
              handlingTittel: {
                validationState: null,
                feedback: '',
                feedbackMargin: false
              }
            });
          }}
          {...formValidation.handlingTittel}
        />
      </div>
      <div className="span-whole">
        <Label>Innhold</Label>
        <Field
          type="textarea"
          onChange={(event) => {
            props.onChange({ handlingTekst: event.target.value });
            setFormValidation({
              ...formValidation,
              handlingTekst: {
                validationState: null,
                feedback: '',
                feedbackMargin: false
              }
            });
          }}
          {...formValidation.handlingTekst}
        />
      </div>
      <div className="span-whole">
        <LabeledField label="Ekstra Dokumenter">
          <UploadMultipleField
            onChangeGetFiles={(files) => props.onChange({ vedlegg: files })}
            initialFiles={props.vedlegg?.map(toCamelCase) ?? []}
            destinationUrl={props.leggTilVedleggUrl}
            deleteUrl={props.slettFilUrl}
            fileType="documentAndImages"
          />
        </LabeledField>
      </div>
    </div>
  );
};

export default GenericForm;
