import TextField from 'components/TextField/TextField';
import { usePostback } from 'js/hooks';
import React, { FC, useState } from 'react';
import Panel from 'components/Panel/Panel';
import { Annonse } from 'types/Annonse';
import { Prosjekt } from 'types/Prosjekt';
import ProsjektRedigerContainer from '../ProsjektRedigerContainer';

export interface AnnonseRedigerSide {
  readonly headerText: string;
  readonly prosjektData: Prosjekt;
  readonly annonseData: Annonse;
  readonly annonsedataLagreApi: string;
  readonly avsluttUrl: string;
  readonly forrigeUrl: string;
  readonly nesteUrl: string;
  readonly deleteUrl?: string;
  readonly slettProsjektBekreftTekst: string;
  readonly archiveProsjektUrl?: string;
}

const AnnonseRedigerSide: FC<AnnonseRedigerSide> = ({
  headerText,
  annonseData: data,
  annonsedataLagreApi,
  avsluttUrl,
  nesteUrl,
  forrigeUrl,
  deleteUrl,
  slettProsjektBekreftTekst,
  archiveProsjektUrl
}) => {
  const [annonseTittel, setAnnonseTittel] = useState(data.annonseTittel);
  const [annonseIngress, setAnnonseIngress] = useState(data.annonseIngress);
  const [annonseInnhold, setAnnonseInnhold] = useState(data.annonseInnhold);

  const { postback, isDirty, validation, isBusy } = usePostback(annonsedataLagreApi, {
    annonseTittel,
    annonseIngress,
    annonseInnhold
  });

  return (
    <ProsjektRedigerContainer
      headerText={headerText}
      isDirty={isDirty}
      isBusy={isBusy}
      currentStep={4}
      quitUrl={avsluttUrl}
      nextPageUrl={nesteUrl}
      previousPageUrl={forrigeUrl}
      save={postback}
      deleteUrl={deleteUrl}
      slettProsjektBekreftTekst={slettProsjektBekreftTekst}
      archiveProsjektUrl={archiveProsjektUrl}
    >
      <Panel heading="Annonsetekst" className="AnnonseRedigerSide">
        <TextField
          label="Tittel*"
          value={annonseTittel}
          maxCharacters={150}
          onChange={(e) => setAnnonseTittel(e.target.value)}
          {...validation.annonseTittel}
        />

        <TextField
          label="Ingress*"
          placeholder="Skriv her"
          large={true}
          rows={3}
          type="textarea"
          maxCharacters={350}
          value={annonseIngress}
          onChange={(e) => setAnnonseIngress(e.target.value)}
          {...validation.annonseIngress}
        />

        <TextField
          label="Brødtekst/Innhold*"
          placeholder="Skriv her"
          large={true}
          rows={9}
          type="textarea"
          maxCharacters={1200}
          value={annonseInnhold}
          onChange={(e) => setAnnonseInnhold(e.target.value)}
          {...validation.annonseInnhold}
        />
      </Panel>
    </ProsjektRedigerContainer>
  );
};

export default AnnonseRedigerSide;
