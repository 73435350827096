import cn from 'classnames';
import React, { FC } from 'react';
import { createCookie, getCookie } from 'js/cookie-helper';
import { ifWindow } from 'js/checkWindow';
import Text from 'components/Text/Text';

export interface Footer {
  readonly questionsText: string;
  readonly privacyUrl: string;
  readonly contactPerson: {
    readonly name: string;
    readonly phone: string;
    readonly email: string;
  };
  readonly copyText: string;
  readonly loginUrl: string;
  readonly environment?: string;
  readonly allowEnvironmentChange?: boolean;
}

const COOKIE_NAME = 'devsite',
  TOMTESOK = 'tomt',
  INNLEIE = 'leie';

const Footer: FC<Footer> = ({ questionsText, allowEnvironmentChange, copyText, environment, contactPerson }) => {
  const handleChangeSite = () => {
    const c = getCookie(COOKIE_NAME);
    ifWindow(() => {
      if (c === TOMTESOK) {
        const yes = confirm('Vil du bytte til innleie?');
        if (yes) {
          createCookie(COOKIE_NAME, INNLEIE, 1);
          ifWindow(() => window.location.reload());
        }
      } else {
        const yes = confirm('Vil du bytte til tomtesøk?');
        if (yes) {
          createCookie(COOKIE_NAME, TOMTESOK, 1);
          ifWindow(() => window.location.reload());
        }
      }
    });
  };

  return (
    <footer className="footer">
      <div className="footer__container">
        <Text className="footer__info-border">INFO</Text>

        <div className="footer__content">
          <div>
            {questionsText}

            <Text variant="list-item">
              <b>KONTAKT</b>
            </Text>
            {contactPerson && (
              <Text variant="list-item" component="a" href={`tel:${contactPerson.phone}`}>
                Tlf: {contactPerson.phone}
              </Text>
            )}
            {contactPerson && (
              <Text variant="list-item" component="a" href={`mailto:${contactPerson.email}`}>
                Epost: {contactPerson.email}
              </Text>
            )}
          </div>
          <div>
            {copyText}

            <Text variant="list-item">
              <b>POSTADRESSE</b>
            </Text>
            <Text variant="list-item">Postboks 232 Sentrum</Text>
            <Text variant="list-item">0103 Oslo</Text>
          </div>
        </div>
      </div>
      {environment && (
        <div className={cn('environment', environment)} onClick={allowEnvironmentChange ? handleChangeSite : () => {}} />
      )}
    </footer>
  );
};

export default Footer;
