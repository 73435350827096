/* eslint-disable react/display-name */
import React from 'react';
import Text from 'components/Text/Text';
import style from '../SvarDetalj.module.css';
import { MatrikkelInfo } from 'types/MatrikkelInfo';

export interface SvarDetaljHeading {
  readonly matrikkelInfo?: MatrikkelInfo;
  readonly naeringsmegler?: string;
  readonly OfferRecinded?: boolean;
}

const SvarDetaljHeading = ({ matrikkelInfo, naeringsmegler, OfferRecinded }: SvarDetaljHeading) => {
  return (
    <div className={style.Heading}>
      <Text noMargin variant="news-title">
        {matrikkelInfo?.hovedMatrikkel?.adressetekst}
      </Text>
      <div className={style.recindLabelWrapper}>{OfferRecinded && <span className={style.recindLabel}>Trukket</span>}</div>

      <div className={style.HeadingInfo}>
        {matrikkelInfo?.hovedMatrikkel?.matrikkelIdentitet && (
          <Text variant="tag">
            Kommunenr - Gnr - Bnr: <b>{matrikkelInfo?.hovedMatrikkel?.matrikkelIdentitet}</b>
          </Text>
        )}
      </div>
      {matrikkelInfo?.tilleggsMatrikler?.map((matrikkel, index) => (
        <div className={style.TilleggsMatrikkel}>
          <Text noMargin variant="news-title">
            {matrikkel.adressetekst}
          </Text>
          <Text variant="tag">
            Kommunenr - Gnr - Bnr:<b>{matrikkel.matrikkelIdentitet}</b>
          </Text>
        </div>
      ))}
      {naeringsmegler && (
        <Text variant="tag">
          Næringsmegler/Eier/Holdingselskap: <b>{naeringsmegler}</b>
        </Text>
      )}
    </div>
  );
};

export default SvarDetaljHeading;
