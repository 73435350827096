import React, { FC, useState } from 'react';
import { Befaring } from 'types/Befaring';
import ScoreRow from 'components/ScoreRow/ScoreRow';
import BefaringHeader from './BefaringHeader/BefaringHeader';
import BefaringKalkulator from './BefaringKalkulator/BefaringKalkulator';
import BefaringKommentarBoks from './BefaringKommentarBoks/BefaringKommentarBoks';
import style from './BefaringSide.module.css';
import ImageUploadGrid from './ImageUploadGrid/ImageUploadGrid';
import Button from 'components/Button/Button';
import { post } from 'js/api-helper';
import { AiOutlineSync } from 'react-icons/ai';
import BefaringAnbefalingBoks from './BefaringKommentarBoks/BefaringAnbefalingBoks';
import { ProsjektKriterier } from 'types/ProsjektKriterier';
import { LinkItem } from 'types/LinkItem';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
export interface BefaringSide {
  readonly befaring: Befaring;
  readonly oppdaterBefaringUrl: string;
  readonly overstyrVurderingUrl: string;
  readonly slettBefaringBildeApi: string;
  readonly bildeOpplastingsUrl: string;
  readonly visKalkulator: boolean;
  readonly readOnly?: boolean;
  readonly prosjektKriterier: ProsjektKriterier;
  readonly pageLinks?: LinkItem[];
  readonly erTrukket?: boolean;
}

export enum BefaringsVurdering {
  UNSET = -1,
  ANBEFALES = 0,
  ANBEFALES_MED_ANMERKNING = 1,
  ANBEFALES_IKKE = 2
}

const BefaringSide: FC<BefaringSide> = ({
  visKalkulator,
  befaring,
  overstyrVurderingUrl,
  oppdaterBefaringUrl,
  bildeOpplastingsUrl,
  readOnly,
  prosjektKriterier,
  slettBefaringBildeApi,
  pageLinks,
  erTrukket
}) => {
  const {
    tittel,
    befaringKommentarer,
    ledigAreal,
    kvmPris,
    mvaKompensasjonKvm,
    felleskostKvm,
    estimertTotalpris,
    utleiemegler,
    utleier,
    fremutleier,
    datoForBefaring,
    beliggenhet,
    tilstede,
    bildeListe,
    erAnbefalt,
    erVurdert,
    erAnbefaltMedAnmerkning,
    befaringNr,
    oppsummeringsVerdi
  } = befaring;

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [saveCompleted, setSaveCompleted] = useState(false);

  const [editBefaring, setEditBefaring] = useState({
    tittel,
    kvmPris,
    mvaKompensasjonKvm,
    ledigAreal,
    felleskostKvm,
    oppsummeringsVerdi,
    fremutleier,
    datoForBefaring,
    beliggenhet,
    tilstede,
    befaringKommentarer,
    erAnbefalt,
    erVurdert,
    erAnbefaltMedAnmerkning
  });

  const handleBefaringChange = (id, value) => {
    setSaveCompleted(false);

    setEditBefaring({
      ...editBefaring,
      [id]: value
    });
  };

  const handleBefaringVerdiChange = (id, value) => {
    setSaveCompleted(false);

    setEditBefaring({
      ...editBefaring,
      befaringKommentarer: editBefaring.befaringKommentarer.map((befaringKommentar) => {
        if (befaringKommentar.id === id) {
          return {
            ...befaringKommentar,
            verdi: value
          };
        } else return befaringKommentar;
      })
    });
  };

  const handleBefaringKommentarVerdiChange = (id, value) => {
    setSaveCompleted(false);

    setEditBefaring({
      ...editBefaring,
      befaringKommentarer: editBefaring.befaringKommentarer.map((befaringKommentar) => {
        if (befaringKommentar.id === id) {
          return {
            ...befaringKommentar,
            kommentarVerdi: value
          };
        } else return befaringKommentar;
      })
    });
  };

  const submitBefaring = async () => {
    setIsLoading(true);
    try {
      await post(oppdaterBefaringUrl, editBefaring);
      setSaveCompleted(true);
      setHasError(false);
    } catch (error) {
      setHasError(true);
      setSaveCompleted(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAnbefalingKommentarChange = (id, value) => {
    setEditBefaring({
      ...editBefaring,
      oppsummeringsVerdi: value
    });
  };

  const handleAnbefaling = (vurdering: BefaringsVurdering) => {
    setEditBefaring({
      ...editBefaring,
      erVurdert: true,
      erAnbefalt: vurdering == BefaringsVurdering.ANBEFALES,
      erAnbefaltMedAnmerkning: vurdering == BefaringsVurdering.ANBEFALES_MED_ANMERKNING
    });
  };

  return (
    <div className={style.wrapper}>
      <b>Befaring nr: {befaringNr}</b>
      {tittel && (
        <div className={style.titleWrapper}>
          <h1 className={style.title}>{editBefaring.tittel}</h1>
          {erTrukket && <span className={style.recindLabel}>Trukket</span>}
        </div>
      )}
      <span className={style.margin} />
      {readOnly && (
        <section className={style.forbeholdBox}>
          <div className={style.forbeholdContent}>
            <h2>Forbehold</h2>
            <p>
              Vi fremhever at rapporten er basert på de opplysningene som tilbyderne har gitt om sine respektive leieobjekter, og
              at leiepriser inkl. mva-kompensasjon må betraktes som uforpliktende prisantydninger.
            </p>
          </div>
        </section>
      )}
      {pageLinks && pageLinks?.length > 0 && <PageLinkMenu pageLinks={pageLinks} />}
      <span className={style.margin}>
        <BefaringHeader
          befaringNr={befaringNr}
          handleBefaringChange={handleBefaringChange}
          readOnly={!!readOnly}
          utleiemegler={utleiemegler}
          utleier={utleier}
          fremutleier={editBefaring.fremutleier}
          datoForBefaring={editBefaring.datoForBefaring}
          beliggenhet={editBefaring.beliggenhet}
          tilstede={editBefaring.tilstede}
        />
      </span>

      <span className={style.margin}>
        <ImageUploadGrid
          slettBefaringBildeApi={slettBefaringBildeApi}
          readOnly={readOnly}
          bildeListe={bildeListe}
          imageUploadUrl={bildeOpplastingsUrl}
        />
      </span>
      <span className={style.margin}>
        <div className={style.maxPrintWidth}>
          <ScoreRow
            isLeiesok={true}
            hideExpand={readOnly}
            visVurdering
            overstyrVurderingUrl={overstyrVurderingUrl}
            {...befaring}
          />
        </div>
      </span>

      {!!prosjektKriterier?.malbilde && <h2 className={style.befaringH2}>&quot;{prosjektKriterier.malbilde}&quot;</h2>}

      {befaringKommentarer?.map((kommentar) => (
        <BefaringKommentarBoks
          maxCharacters={500}
          handleBefaringKommentarVerdiChange={handleBefaringKommentarVerdiChange}
          handleBefaringVerdiChange={handleBefaringVerdiChange}
          readOnly={readOnly}
          key={kommentar?.id}
          {...kommentar}
        />
      ))}
      <BefaringAnbefalingBoks
        handleAnbefalingKommentarChange={handleAnbefalingKommentarChange}
        readOnly={readOnly || erTrukket}
        id={'oppsummeringsBoks'}
        tittel={'Oppsummering og anbefaling'}
        verdi={editBefaring?.oppsummeringsVerdi || ''}
        handleAnbefaling={handleAnbefaling}
        vurdering={
          !editBefaring.erVurdert
            ? BefaringsVurdering.UNSET
            : editBefaring.erVurdert && editBefaring.erAnbefalt
            ? BefaringsVurdering.ANBEFALES
            : editBefaring.erVurdert && editBefaring.erAnbefaltMedAnmerkning
            ? BefaringsVurdering.ANBEFALES_MED_ANMERKNING
            : BefaringsVurdering.ANBEFALES_IKKE
        }
      />

      <div className={style.actionsBar}>
        {hasError && !isLoading && <p style={{ marginRight: '1em' }}>Noe gikk galt</p>}

        {saveCompleted && !isLoading && <p style={{ marginRight: '1em' }}>Lagring fullført</p>}

        {!!isLoading && <AiOutlineSync className="spin" />}
        <Button disabled={erTrukket} onClick={submitBefaring}>
          Lagre
        </Button>
      </div>

      {erAnbefalt && (
        <div style={!readOnly ? { display: 'none' } : {}} className={style.recommendedBox}>
          Anbefales til konkurranse
        </div>
      )}
      <span style={{ marginBottom: '-2em' }}>
        {!!(visKalkulator && prosjektKriterier.totalLeiepris) && (
          <BefaringKalkulator
            handleBefaringChange={handleBefaringChange}
            readOnly={readOnly}
            mvaKompensasjonKvm={mvaKompensasjonKvm}
            ledigAreal={ledigAreal}
            kvmPris={kvmPris}
            felleskostKvm={felleskostKvm}
            estimertTotalpris={estimertTotalpris}
            totalLeieprisIdag={prosjektKriterier.totalLeiepris}
          />
        )}
      </span>
    </div>
  );
};

export default BefaringSide;
