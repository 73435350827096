import { numberWithSpaces } from 'js/format-helper';
import React from 'react';
import Text from 'components/Text/Text';

export interface Props {
  readonly prisKvmUtenTiltak?: number;
  readonly prisKvmMedTiltak?: number;
  readonly felleskostKvm?: number;
  readonly parkeringsplasskost?: number;
  readonly sykkelplasskost?: number;
  readonly kostKantineBidrag?: number;
  readonly mvaKompensasjonKvm?: number;
  readonly paslagFellesarealiProsent?: number;
}

export default function LeieprisPopup({
  prisKvmUtenTiltak,
  prisKvmMedTiltak,
  felleskostKvm,
  parkeringsplasskost,
  sykkelplasskost,
  kostKantineBidrag,
  mvaKompensasjonKvm,
  paslagFellesarealiProsent
}: Props) {
  return (
    <>
      <Text component="h3" variant="paragraph-header">
        Leiepris
      </Text>

      <Text variant="list-item">
        Pris med tiltak:
        <b style={{ color: 'var(--linkColor)' }}>{' ' + numberWithSpaces(prisKvmMedTiltak)} kr</b>
      </Text>
      <Text variant="list-item">
        Pris uten tiltak:
        <b style={{ color: 'var(--linkColor)' }}>{' ' + numberWithSpaces(prisKvmUtenTiltak)} kr</b>
      </Text>

      <div>
        <Text variant={'list-item'}>
          Felleskost per m<sup>2</sup> BTA: <b style={{ color: 'var(--linkColor)' }}>{numberWithSpaces(felleskostKvm)} kr</b>
        </Text>
      </div>

      <div>
        <Text variant={'list-item'}>
          Pr P-plass: <b style={{ color: 'var(--linkColor)' }}>{numberWithSpaces(parkeringsplasskost)} kr</b>
        </Text>
      </div>

      <div>
        <Text variant={'list-item'}>
          Pr sykkelplass: <b style={{ color: 'var(--linkColor)' }}>{numberWithSpaces(sykkelplasskost)} kr</b>
        </Text>
      </div>

      <div>
        <Text variant={'list-item'}>
          Kantinebidrag pr ansatt:
          <b style={{ color: 'var(--linkColor)' }}>{numberWithSpaces(kostKantineBidrag)} kr</b>
        </Text>
      </div>

      <div>
        <Text variant={'list-item'}>
          MVA kompensasjon per m<sup>2</sup> BTA:{' '}
          <b style={{ color: 'var(--linkColor)' }}>{numberWithSpaces(mvaKompensasjonKvm)} kr</b>
        </Text>
      </div>
      <div>
        <Text variant={'list-item'}>
          Påslag for fellesareal i prosent: <b style={{ color: 'var(--linkColor)' }}>{paslagFellesarealiProsent} %</b>
        </Text>
      </div>
    </>
  );
}
