import UploadButton, { AcceptedFileTypes, File, fileTypes } from 'components/UploadButton/UploadButton';
import { post } from 'js/api-helper';
import { useOnChange } from 'js/hooks';
import React, { useCallback, useState } from 'react';
import { AiOutlineDelete, AiOutlineSync } from 'react-icons/ai';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';

export { File, AcceptedFileTypes };

export interface Props {
  readonly fileType: AcceptedFileTypes;
  readonly initialFile?: File;
  readonly destinationUrl: string;
  readonly deleteUrl: string;
  readonly feedback?: string;
  readonly validationState?: 'error' | null;
  readonly feedbackMargin?: boolean;
  readonly onChange?: (file: File | undefined) => void;
}

export default function UploadField({
  fileType,
  destinationUrl,
  deleteUrl,
  initialFile,
  feedback,
  validationState,
  feedbackMargin,
  onChange
}: Props) {
  const [file, setFile] = useState<File | undefined>(initialFile);
  const showError = feedback && validationState === 'error';
  useOnChange(onChange, [file]);
  return file ? (
    <DeleteFile file={file} deleteUrl={deleteUrl} onDelete={() => setFile(undefined)} />
  ) : (
    <div>
      <UploadFile fileType={fileType} destinationUrl={destinationUrl} onUpload={setFile} />
      {showError && <ErrorMessage feedback={feedback} feedbackMargin={feedbackMargin} />}
    </div>
  );
}

interface DeleteFileProps {
  readonly file: File;
  readonly deleteUrl: string;
  readonly onDelete?: (file: File) => void;
  readonly multiple?: boolean;
}

export function DeleteFile({ file, deleteUrl, onDelete, multiple }: DeleteFileProps) {
  const [isBusy, setBusy] = useState(false);

  const deleteFile = useCallback(async () => {
    if (!file) return;
    setBusy(true);
    await post(`${deleteUrl}?filId=${file.id}`);
    setBusy(false);
    onDelete?.(file);
  }, [file]);
  return (
    <div className={`UploadField ${multiple ? 'UploadFieldMultiple' : ''}`}>
      <div className="UploadField__label">
        <a href={file.nedlastingslenke} target="_blank" rel="noopener noreferrer" className="UploadField__link">
          {file.visningsnavn}
        </a>
      </div>
      <Button icon onClick={deleteFile} disabled={isBusy}>
        {isBusy ? <AiOutlineSync className="spin" /> : <IconReactIcons type={IconTypes.remove} />}
      </Button>
    </div>
  );
}

export interface UploadFileProps {
  readonly multiple?: boolean;
  readonly fileType: AcceptedFileTypes;
  readonly destinationUrl: string;
  readonly onUpload?: (file: File) => void;
}

export function UploadFile({ multiple, fileType, destinationUrl, onUpload }: UploadFileProps) {
  return (
    <Text variant="mono-16" component="label" className={`UploadField ${multiple ? 'UploadFieldMultiple' : ''}`}>
      <div className="UploadField__label">
        <span>{fileTypes(fileType)}</span>
      </div>
      <UploadButton multiple={multiple} fileType={fileType} destinationUrl={destinationUrl} onUploadComplete={onUpload} />
    </Text>
  );
}
