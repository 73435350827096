import React from 'react';

export interface Props {
  readonly kalkulert: number;
  readonly overstyrt?: number;
  readonly visIndre?: boolean;
  readonly visJustert?: boolean;
}

const Arc = ({
  radius,
  width,
  from,
  to
}: {
  radius: number;
  width: number;
  from: number;
  to: number;
}) => {
  const toRad = Math.PI / 180;
  const inner = radius - width;
  const fx = Math.cos(from * toRad - Math.PI / 2);
  const fy = Math.sin(from * toRad - Math.PI / 2);
  const tx = Math.cos(to * toRad - Math.PI / 2);
  const ty = Math.sin(to * toRad - Math.PI / 2);
  return (
    <path
      d={[
        `M${inner * fx},${inner * fy}`,
        `L${radius * fx},${radius * fy}`,
        //`L${radius * tx},${radius * ty}`,
        `A${radius},${radius} 1 0,1 ${radius * tx},${radius * ty}`,
        `L${inner * tx},${inner * ty}`,
        `A${inner},${inner} 1 0,0 ${inner * fx},${inner * fy}`
        //'z'
      ].join(' ')}
    ></path>
  );
};

const color = {
  0: '#949494',
  1: '#cf4f13',
  2: '#f9b100',
  3: '#5c9424'
};

export default function TriPie({
  kalkulert,
  overstyrt = 0,
  visIndre = false,
  visJustert = false,
  ...rest
}: Props) {
  const ytre = overstyrt || kalkulert;
  const indre = overstyrt === 0 ? 0 : kalkulert;

  return (
    <svg {...rest} viewBox="-100 -100 200 200" stroke="white" strokeWidth="3">
      {visJustert && overstyrt !== 0 && overstyrt !== kalkulert && (
        <text stroke="#005763" strokeWidth="0" x={0} y={0} textAnchor="middle">
          Justert
        </text>
      )}
      <g fill={color[ytre]}>
        {(ytre === 0 || ytre >= 1) && (
          <Arc radius={100} width={40} from={0} to={120} />
        )}
        {(ytre === 0 || ytre >= 2) && (
          <Arc radius={100} width={40} from={120} to={240} />
        )}
        {(ytre === 0 || ytre >= 3) && (
          <Arc radius={100} width={40} from={240} to={360} />
        )}
      </g>
      {visIndre && (
        <g fill={color[indre]}>
          {indre >= 1 && <Arc radius={55} width={35} from={0} to={120} />}
          {indre >= 2 && <Arc radius={55} width={35} from={120} to={240} />}
          {indre >= 3 && <Arc radius={55} width={35} from={240} to={360} />}
        </g>
      )}
    </svg>
  );
}
