import SelectField from 'components/SelectField/SelectField';
import Popup from 'components/Popup/Popup';
import { post } from 'js/api-helper';
import React, { useState } from 'react';
import Panel from 'components/Panel/Panel';
import style from '../SvarRediger.module.css';
import { AiOutlineSync } from 'react-icons/ai';
import Button from 'components/Button/Button';

export interface Props {
  readonly kommune: string;
  setPopupOpen(number): void;
  readonly popupOpen: number;
  readonly relevanteTidligereSvar: {
    readonly url: string;
    readonly status?: string;

    readonly tittel: string;
    readonly erKladd?: boolean;
    readonly erIProsjekt?: boolean;
  }[];
}

export function KopierSvar({ relevanteTidligereSvar, kommune, setPopupOpen, popupOpen }: Props) {
  const [url, setUrl] = useState<string>();
  const [busy, setBusy] = useState(false);

  const { hasSvarIProsjekt, svarIProsjekt, tidligereSvar } = React.useMemo(() => {
    const list = relevanteTidligereSvar.filter((svar) => svar.erIProsjekt);
    const mineObjekterList = relevanteTidligereSvar.filter((svar) => !svar.erKladd);
    const hasSvarIProsjekt = list.length > 0;
    return {
      hasSvarIProsjekt,
      svarIProsjekt: list,
      mineObjekterList,
      tidligereSvar: popupOpen === 2 ? mineObjekterList : hasSvarIProsjekt ? list : relevanteTidligereSvar
    };
  }, [relevanteTidligereSvar, popupOpen]);

  const submit = async () => {
    if (!url) return;
    setBusy(true);
    const found = tidligereSvar.find((svar) => svar.url === url);
    if (found?.erIProsjekt) {
      document.location.href = found.url;
    } else {
      const redirectUrl = await post<string>(url);
      document.location.href = redirectUrl;
    }
    setBusy(false);
  };

  return (
    <Popup clickOutsideToClose={false} isOpen={!!popupOpen} close={() => setPopupOpen(0)}>
      <Panel className={style.kopier}>
        <h3>
          {hasSvarIProsjekt && popupOpen !== 2
            ? `Du har allerede ${
                svarIProsjekt?.length === 1 ? 'et ' : 'tidligere '
              } svar på denne annonsen. Hva ønsker du å gjøre?`
            : `Du har tidligere tilbudt et objekt i ${kommune}. Hva ønsker du å gjøre?`}
        </h3>
        <div>
          <SelectField
            label={'Velg her:'}
            choices={tidligereSvar.map(({ url, tittel, erKladd }) => ({
              label: `${tittel} (${erKladd ? 'Kladd' : 'Levert'})`,
              value: url
            }))}
            placeholder={
              hasSvarIProsjekt && popupOpen !== 2
                ? 'Oppdater et tidligere levert svar eller en kladd'
                : 'Kopier fra et tidligere levert svar'
            }
            onChange={({ value }) => setUrl(value)}
          />
        </div>
        <div className={style.popupButtons}>
          {busy && <AiOutlineSync className="spin" />}
          <Button secondary onClick={() => setPopupOpen(0)}>
            {popupOpen === 1 ? 'Lag nytt svar' : 'Avbryt'}
          </Button>
          <Button disabled={!url || busy} data-busy={busy || undefined} onClick={submit}>
            Kopier svar
          </Button>
        </div>
      </Panel>
    </Popup>
  );
}
