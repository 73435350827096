/* eslint-disable react/display-name */
import { MarkerT } from 'components/ArcGisKart/ArcGis';
import { getMarker } from 'components/ArcGisKart/arcGis.helper';
import ArcGisLoader from 'components/ArcGisKart/ArcGisLoader';
import Popup from 'components/Popup/Popup';
import React, { FC, useState } from 'react';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { ArcgisServiceArea } from 'types/ArcgisServiceArea';
import { ArcgisSketch } from 'types/ArcgisSketch';
import style from '../SvarDetalj.module.css';
import { Location } from 'types/Location';
import { MatrikkelInfo } from 'types/MatrikkelInfo';
import { Matrikkel } from 'types/Matrikkel';
import { Fildata } from 'types/Fildata';

export interface SvarDetaljImages {
  readonly sketches?: ArcgisSketch[];
  readonly serviceAreas?: ArcgisServiceArea[];
  readonly matrikkelInfo?: MatrikkelInfo;
  readonly overstyrtScoreHelhetsvurdering?: number;
  readonly bilder?: Fildata[];
}

const SvarDetaljImages: FC<SvarDetaljImages> = ({
  sketches,
  serviceAreas,
  matrikkelInfo,
  overstyrtScoreHelhetsvurdering,
  bilder
}) => {
  const [mapPopup, setMapPopup] = useState(false);

  const commonArcGisProps = {
    readOnly: true,
    initialZoomDisabled: false,
    center: [
      Number(matrikkelInfo?.hovedMatrikkel?.location?.longitude),
      Number(matrikkelInfo?.hovedMatrikkel?.location?.latitude)
    ],
    markers: makeLabeledMarkers({
      location: matrikkelInfo?.hovedMatrikkel?.location,
      tilleggsMatrikler: matrikkelInfo?.tilleggsMatrikler,
      hovedMatrikkelAdresse: matrikkelInfo?.hovedMatrikkel?.adressetekst,
      overstyrtScoreHelhetsvurdering,
      withLabels: true
    }),
    sketches: sketches,
    serviceAreas: serviceAreas,
    zoom: 12,
    toggleFullscreen: () => setMapPopup(!mapPopup)
  };
  return (
    <div className={style.Images}>
      <Popup isOpen={mapPopup} close={() => setMapPopup(false)}>
        <div style={{ width: '90vw', height: '90vh' }}>
          {mapPopup && matrikkelInfo?.hovedMatrikkel?.location ? (
            <ArcGisLoader {...commonArcGisProps} />
          ) : null}
        </div>
      </Popup>
      {/* <button onClick={() => setPopup('Kart')}> */}
      {mapPopup ? (
        <div />
      ) : matrikkelInfo?.hovedMatrikkel?.location ? (
        <ArcGisLoader noPadding hideCustomToolbar {...commonArcGisProps} />
      ) : null}

      {/* <Kart static>{makeMarkers(svar)}</Kart> */}
      {/* <BsBoxArrowUpRight className={style.expandIcon} />
    </button> */}
      {bilder?.map((filData) => (
        <a
          key={filData?.id}
          href={`${filData?.nedlastingslenke}?download=false`}
          target="_blank"
          style={{ overflow: 'hidden', position: 'relative' }}
          rel="noopener noreferrer"
        >
          <img
            src={`${filData?.nedlastingslenke}?maxWidth=600&maxHeight=300&download=false`}
          />
          <span className={style.expandButton}>
            <BsBoxArrowUpRight />
          </span>
        </a>
      ))}
    </div>
  );
};

function makeLabeledMarkers({
  location,
  hovedMatrikkelAdresse,
  overstyrtScoreHelhetsvurdering,
  tilleggsMatrikler,
  withLabels
}: {
  location?: Location;
  hovedMatrikkelAdresse?: string;
  overstyrtScoreHelhetsvurdering?: number;
  tilleggsMatrikler?: Matrikkel[];
  withLabels: boolean;
}): MarkerT[] {
  let markers: MarkerT[] = [];
  if (!location || !hovedMatrikkelAdresse) return markers;
  markers = [
    {
      latitude: Number(location?.latitude),
      longitude: Number(location?.longitude),
      markerColor: getMarker(overstyrtScoreHelhetsvurdering),
      popupTemplate: {
        title: hovedMatrikkelAdresse,
        element: <div />
      }
    }
  ] as MarkerT[];

  if (!tilleggsMatrikler?.length) return markers;
  const withTilleggsMatrikler = Array.isArray(tilleggsMatrikler);
  if (withTilleggsMatrikler) {
    markers.concat(
      tilleggsMatrikler
        .filter(({ location }) => location)
        .map(({ location, adressetekst }) => ({
          latitude: Number(location?.latitude),
          longitude: Number(location?.longitude),
          markerColor: getMarker(overstyrtScoreHelhetsvurdering),
          popupTemplate: withLabels
            ? {
                title: adressetekst,
                element: <div />
              }
            : undefined
        }))
    );
  }
  return markers;
}

export default SvarDetaljImages;
