import React from 'react';
import classnames from 'classnames';
import Input from 'components/Input/Input';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import styles from './Field.module.css';
import Text from 'components/Text/Text';

interface IField {
  name?: string;
  value?: number | string;
  className?: string;
  feedback?: string;
  error?: string;
  label?: string;
  type?: string;
  lg?: boolean;
  tall?: boolean;
  horizontal?: boolean;
  feedbackMargin?: boolean;
  tabNumber?: number;
  required?: boolean;
  children?: React.ReactNode;
  readOnly?: boolean;
  showCharCount?: boolean;
  maxCharacters?: number;
  validationState?: 'success' | 'error' | 'warning' | false | null;
}
const Field = ({
  required,
  name,
  label,
  validationState,
  tabNumber,
  feedback,
  error,
  type,
  className,
  lg,
  tall,
  horizontal,
  feedbackMargin,
  readOnly,
  maxCharacters: maxLength,
  ...props
}: IField) => {
  const inputProps = {
    id: name,
    tabIndex: tabNumber,
    name,
    type,
    lg,
    tall,
    maxLength,
    validationState,
    ...props
  };
  const classes = classnames(className, {
    [styles.time]: type === 'time',
    [styles.tall]: tall,
    [styles.horizontal]: horizontal,
    [styles.vertical]: !horizontal,
    [styles.feedbackMargin]: feedbackMargin && !(validationState === 'error' && feedback),
    [styles.relative]: !feedbackMargin
  });

  return (
    <div className={classes}>
      {label && (
        <Text variant="mono-16" component="label" htmlFor={inputProps.id}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </Text>
      )}
      {!readOnly && <Input aria-label={label} {...inputProps} />}
      {readOnly && (props.value ? <p className={styles.readOnly}>{props.value}</p> : props.children)}
      {feedback && validationState === 'error' && <ErrorMessage feedback={feedback} feedbackMargin={feedbackMargin} />}
      {error && (
        <div id={`${name}Error`} role="alert">
          {error}
        </div>
      )}
    </div>
  );
};

Field.defaultProps = {
  type: 'text'
};

export default Field;
