import React from 'react';
import classnames from 'classnames';
import styles from './Checkbox.module.css';
import IconReactIcons, { IconTypes } from '../Icon/IconReactIcons';

type CheckboxProps = {
  label: string;
  className?: string;
  inverted?: boolean;
  checked?: boolean;
  name?: string;
};

const Checkbox = ({
  label,
  className,
  inverted,
  name,
  checked,
  ...props
}: CheckboxProps) => {
  const id = `${label.replace(/ /g, '_')}-checkbox`;
  return (
    <label
      htmlFor={id}
      tabIndex={-1}
      className={classnames(
        styles.label,
        inverted && styles.inverted,
        className
      )}
    >
      {label}
      <input
        id={id}
        name={name ?? id}
        className={styles.input}
        type="checkbox"
        tabIndex={0}
        {...props}
      />
      <div className={styles.fakeBox} tabIndex={-1}>
        {checked ? <IconReactIcons type={IconTypes.checkMark}/> : void(0)}
      </div>
    </label>
  );
};

export default Checkbox;
