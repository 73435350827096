import { ValidationEntry, Validations } from 'js/hooks/useValidation';
import React, { useCallback } from 'react';
import { FaPlus } from 'react-icons/fa';
import Button from 'components/Button/Button';
import Panel from 'components/Panel/Panel';
import { Matrikkel } from 'types/Matrikkel';
import style from '../SvarRediger.module.css';
import MatrikkelPanel from './MatrikkelPanel';
import Text from 'components/Text/Text';

interface Fields {
  readonly hovedMatrikkelIdentitet: string;
  readonly hovedMatrikkelAdressetekst: string;
}
interface ValidationFields {
  readonly hovedMatrikkelIdentitet: string;
  readonly hovedMatrikkelAdressetekst: string;
  readonly tilleggsMatrikler: string;
}

export interface Props extends Fields {
  readonly matrikler: Matrikkel[];
  addMatrikkel(value: Matrikkel): void;
  removeMatrikkel(index: number): void;
  editMatrikkel(index: number, edit: (old: Matrikkel) => Matrikkel): void;
  readonly validation: Validations<ValidationFields>;
  readonly setHovedMatrikkelIdentitet?: (value: string) => void;
  readonly setHovedMatrikkelAdressetekst?: (value: string) => void;
}

export default function RedigerMatrikler({
  matrikler,
  addMatrikkel,
  removeMatrikkel,
  editMatrikkel,
  hovedMatrikkelAdressetekst,
  hovedMatrikkelIdentitet,
  validation,
  setHovedMatrikkelAdressetekst,
  setHovedMatrikkelIdentitet
}: Props) {
  const createMatrikkel = useCallback(() => {
    addMatrikkel({
      matrikkelIdentitet: '',
      adressetekst: ''
    } as Matrikkel);
  }, []);

  const setMatrikkelIdentitet = useCallback(
    (index: number, matrikkelIdentitet: string) =>
      editMatrikkel(index, (matrikkel) => ({
        ...matrikkel,
        matrikkelIdentitet
      })),
    []
  );

  const setAddresse = useCallback(
    (index: number, adressetekst: string) =>
      editMatrikkel(index, (matrikkel) => ({
        ...matrikkel,
        adressetekst
      })),
    []
  );

  return (
    <div className={style.matrikkel}>
      <MatrikkelPanel
        adressetekst={hovedMatrikkelAdressetekst}
        matrikkelIdentitet={hovedMatrikkelIdentitet}
        validation={{
          matrikkelIdentitet: validation.hovedMatrikkelIdentitet,
          adressetekst: validation.hovedMatrikkelAdressetekst
        }}
        setAdressetekst={setHovedMatrikkelAdressetekst}
        setMatrikkelIdentitet={setHovedMatrikkelIdentitet}
        label="Hovedmatrikkel"
      />
      {matrikler.map((matrikkel, index) => (
        <MatrikkelPanel
          key={index}
          {...matrikkel}
          setMatrikkelIdentitet={(v) => setMatrikkelIdentitet(index, v)}
          setAdressetekst={(v) => setAddresse(index, v)}
          validation={{
            matrikkelIdentitet: validation.tilleggsMatrikler,
            adressetekst: validation.tilleggsMatrikler
          }}
          removeMe={() => removeMatrikkel(index)}
          label={`Tilleggsmatrikkel ${index + 1}`}
        />
      ))}
      <Panel className={style.addMatrikkel}>
        <Text noMargin variant="panel-heading">
          Legg til ny matrikkelinformasjon
        </Text>
        <Button onClick={createMatrikkel}>
          <FaPlus />
        </Button>
      </Panel>
    </div>
  );
}
