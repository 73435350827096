import useAction from 'js/hooks/useAction';
import usePostback, { delay, Fields, Validations } from 'js/hooks/usePostback';
import { ValidationEntry } from 'js/hooks/useValidation';
import { useMemo } from 'react';

export function useDraftAndFinalPostback<T extends Fields>(
  svarLagreKladdApi: string,
  svarLeverTilbudApi: string,
  fields: T
) {
  const draft = usePostback(svarLagreKladdApi, fields);

  const final = usePostback(svarLeverTilbudApi, fields);

  const saveDraft = useAction(async () => {
    const result = await draft.postback();
    final.resetDirty();
    await delay(1);
    return result;
  }, [draft.postback]);

  const saveFinal = useAction(async () => {
    const result = await final.postback();
    draft.resetDirty();
    await delay(1);
    return result;
  }, [final.postback]);

  return {
    saveDraft,
    saveFinal,
    canSubmit: draft.canSubmit && final.canSubmit,
    isBusy: draft.isBusy || final.isBusy,
    isDirty: draft.isDirty || final.isDirty,
    validation: combine(draft.validation, final.validation)
  };
}

function combine<T>(
  draft: Validations<T>,
  final: Validations<T>
): Validations<T> {
  return Object.fromEntries(
    Object.entries<ValidationEntry>(draft).map(([name, value]) => [
      name,
      value.validationState === 'error' ? value : final[name]
    ])
  ) as Validations<T>;
}
