/* eslint-disable react/display-name */
import { Vurdering } from 'components/ScoreRow/Popups/Vurdering';
import { post } from 'js/api-helper';
import { useOnChange } from 'js/hooks';
import useBooleanState from 'js/hooks/useBooleanState';
import React, { FC, useCallback, useState } from 'react';
import style from '../SvarDetalj.module.css';
import { shortDateTimeString } from 'js/date-helper';
import Text from 'components/Text/Text';
import CommentBox from 'components/CommentBox/CommentBox';

export interface RecommendationToolbar {
  readonly lastNedZipUrl?: string;
  readonly overstyrVurderingUrl?: string;
  readonly settEllerFjernTilBefaringUrl?: string;
  readonly visKommentar?: boolean;
  readonly visRedigerKommentar?: boolean;
  readonly kommenterUrl?: string;
  readonly overstyrtScoreHelhetsvurdering?: number;
  readonly internKommentar?: string;
  readonly tilBefaring?: boolean;
  readonly levertDatoUtc?: string;
  readonly tilbudTrukket?: boolean;
}

const RecommendationToolbar: FC<RecommendationToolbar> = ({
  overstyrtScoreHelhetsvurdering,
  tilBefaring,
  levertDatoUtc,
  overstyrVurderingUrl,
  settEllerFjernTilBefaringUrl,
  lastNedZipUrl,
  tilbudTrukket
}) => {
  const overstyrVurdering = useCallback(
    async (type: string, vurdering: number) => {
      await post(overstyrVurderingUrl as string, { type, vurdering });
    },
    [overstyrVurderingUrl]
  );

  const [_overstyrtScoreHelhetsvurdering, setOverstyrtScoreHelhetsvurdering] = useState(overstyrtScoreHelhetsvurdering ?? 0);

  useOnChange(overstyrVurdering, ['Helhetsvurdering', _overstyrtScoreHelhetsvurdering]);

  const settEllerFjernTilBefaring = useCallback(
    async (value: boolean) => {
      await post(settEllerFjernTilBefaringUrl as string, { value });
    },
    [settEllerFjernTilBefaringUrl]
  );

  const [_tilBefaring, , toggleTilBefaring] = useBooleanState(tilBefaring ?? false);

  useOnChange(settEllerFjernTilBefaring, [_tilBefaring]);

  return (
    <div className={style.toolbar}>
      <div className={style.kommentarWrapper}>
        <div className={style.ekstraKommentarInfo}>
          <div className={style.Innsendt}>
            <Text variant="tag">
              Innsendt: <b>{shortDateTimeString(levertDatoUtc, 'Ikke innsendt ennå')} </b>
              {!!lastNedZipUrl && <a href={lastNedZipUrl}>Last ned</a>}
            </Text>
          </div>
          {!!overstyrVurderingUrl && (
            <Vurdering
              label="Totalvurdering"
              onChange={setOverstyrtScoreHelhetsvurdering}
              score={_overstyrtScoreHelhetsvurdering}
            />
          )}
          {!!settEllerFjernTilBefaringUrl && !tilbudTrukket && (
            <label className={style.tilBefaring} htmlFor={'tilBefaringCheckbox'}>
              <input id={'tilBefaringCheckbox'} type="checkbox" checked={_tilBefaring} onChange={toggleTilBefaring} />
              <Text noMargin variant="paragraph">
                Til befaring?
              </Text>
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecommendationToolbar;
