import type { LinkItem } from 'types/LinkItem';
import React, { FC, useEffect, useState } from 'react';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import Heading from 'components/Heading/Heading';
import { IconTypes } from 'components/Icon/IconReactIcons';
import IconButton from 'components/IconButton/IconButton';
import type { TilbudtObjekt } from 'types/TilbudtObjekt';
import { shortDateString } from 'js/date-helper';
import { TilbudtObjektListe } from 'types/TilbudtObjektListe';
import TilbudtObjektCard from 'components/TilbudtObjektCard/TilbudtObjektCard';
import { SortLeieObjekter } from 'js/SortLeieObjekter';
import TilbudtObjektCardList, { TilbudtObjektCardListHeadings } from 'components/TilbudtObjektCardList/TilbudtObjektCardList';

export interface KonkurranseInnkomneSide {
  readonly title: string;
  readonly svarFristUtc: string;
  readonly pageLinks: LinkItem[];
  readonly arkiverProsjektHref: string;
  readonly objekter: TilbudtObjekt[];
  readonly anbefalingerGodkjent: boolean; //lager tentativ liste hvis seriøsitetsmodulen ikke er godkjent av OG.
}

const getTentativeState = (entries: TilbudtObjekt[], anbefalingerGodkjent: boolean): TilbudtObjektListe | TilbudtObjekt[] => {
  if (!anbefalingerGodkjent) {
    return entries;
  }

  return SortLeieObjekter(entries);
};

const listHeadings: TilbudtObjektCardListHeadings = {
  tentative: 'Tentativ liste, mangler godkjenning fra oppdragsgiver',
  recommended: 'Anbefales å vurdere disse lokalene for deltakelse i konkurransen',
  notEvaluated: 'Ikke vurdert',
  rejected: 'Anbefales å utelukke fra konkurransen',
  canceled: 'Tilbud er trukket'
};

const KonkurranseInnkomneSide: FC<KonkurranseInnkomneSide> = ({
  title,
  svarFristUtc,
  pageLinks,
  arkiverProsjektHref,
  objekter,
  anbefalingerGodkjent
}) => {
  const [sortedList, setSortedList] = useState<TilbudtObjektListe | TilbudtObjekt[]>(
    getTentativeState(objekter, anbefalingerGodkjent)
  );

  return (
    <div className="KonkurranseInnkomneSide">
      <div className="heading">
        <Heading>{title}</Heading>
        <IconButton color="#147e87" type="normal" title="Arkiver" href={arkiverProsjektHref} iconType={IconTypes.fiArchive} />
      </div>
      <PageLinkMenu pageLinks={pageLinks} />
      {anbefalingerGodkjent ? (
        <TilbudtObjektCardList
          list={sortedList as TilbudtObjektListe}
          headings={listHeadings}
          svarFristUtc={svarFristUtc && shortDateString(svarFristUtc)}
        />
      ) : (
        <div className="TilbudteObjekter">
          <h2>{listHeadings.tentative}</h2>
          <ul>
            {(sortedList as TilbudtObjekt[]).map((objekt) => (
              <li>
                <TilbudtObjektCard {...objekt} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default KonkurranseInnkomneSide;
