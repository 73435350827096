import React, { useCallback, useState } from 'react';
import Field from 'components/Field/Field';
import Text from 'components/Text/Text';

export interface Props extends Record<string, unknown> {
  readonly value?: string;
  readonly onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readonly maxCharacters: number;
  readonly threshold?: number;
  readonly className?: string;
}

export default function TextField({ maxCharacters, threshold = 50, className, onChange, ...props }: Props) {
  const [count, setCount] = useState(props.value?.length ?? 0);

  const onLengthChange = useCallback(
    (e) => {
      setCount(e.target?.value?.length ?? 0);
      onChange?.(e);
    },
    [onChange]
  );

  return (
    <div className={className} style={{ display: 'flex', flexDirection: 'column' }}>
      <Field {...props} maxCharacters={maxCharacters} onChange={onLengthChange} />
      <div
        style={{
          marginLeft: 'auto',
          fontSize: 12,
          color: '#ABABAB'
        }}
      >
        {count}/{maxCharacters}
      </div>
    </div>
  );
}
