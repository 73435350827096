import { post } from 'js/api-helper';
import React, { useState } from 'react';
import { AiOutlineSync } from 'react-icons/ai';
import Button from 'components/Button/Button';
import { ProsjektKriterier } from 'types/ProsjektKriterier';
import style from './BefaringProsjektKriterier.module.css';
import SvarInnkomneTitle from '../../Svar/SvarInnkomne/components/SvarInnkomneTitle';
import { LinkItem } from 'types/LinkItem';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';

export interface BefaringProsjektKriterierSide {
  readonly prosjektKriterier: ProsjektKriterier;
  readonly title: string;
  readonly lastNedZipUrl?: string;
  readonly annonseUrl?: string;
  readonly pageLinks?: LinkItem[];
}

const makeNumber = (v: string | number) => (typeof v === 'string' ? Number(v.replace(/\D/g, '')) : v);

const makeKroner = (v: number) => {
  return v.toLocaleString('fr');
};

const BefaringProsjektKriterierSide = ({
  prosjektKriterier,
  title,
  lastNedZipUrl,
  annonseUrl,
  pageLinks
}: BefaringProsjektKriterierSide) => {
  const [isLoading, setIsLoading] = useState(false);
  const [saveCompleted, setSaveCompleted] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [currentProsjektKriterier, setProsjektKriterier] = useState<ProsjektKriterier>({
    malbilde: prosjektKriterier.malbilde,
    totalLeiepris: prosjektKriterier.totalLeiepris
  });

  const handleChange = (e) => {
    setSaveCompleted(false);
    const { id, value } = e.target;
    setProsjektKriterier((_prosjektKriterier) => ({
      ..._prosjektKriterier,
      [id]: value
    }));
  };
  const handleChangeTotalLeiepris = (e) => {
    setSaveCompleted(false);
    const { value } = e.target;

    setProsjektKriterier((_prosjektKriterier) => ({
      ..._prosjektKriterier,
      totalLeiepris: makeNumber(value)
    }));
  };

  const saveProsjektKriterier = async () => {
    setIsLoading(true);
    if (!prosjektKriterier.saveEndpoint) return setIsLoading(false);
    try {
      await post(prosjektKriterier.saveEndpoint, {
        malbilde: currentProsjektKriterier.malbilde,
        totalLeiepris: makeNumber(currentProsjektKriterier.totalLeiepris)
      });
      setSaveCompleted(true);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className={style.befaringProsjektKriterier}>
      <SvarInnkomneTitle title={title} lastNedZipUrl={lastNedZipUrl} annonseUrl={annonseUrl ?? ''} />
      {pageLinks && pageLinks?.length > 0 && <PageLinkMenu pageLinks={pageLinks} />}
      <div style={{ marginBottom: '5em' }}>
        <div className={style.InputContainer}>
          <label htmlFor={'totalLeieprisIdag'}>
            Total leiepris i dag
            <span className={style.AdditionalLabel}>inkl felleskostnader (i kr)</span>
          </label>
          <input
            className={style.Input}
            onChange={handleChangeTotalLeiepris}
            id={'totalLeieprisIdag'}
            value={makeKroner(currentProsjektKriterier.totalLeiepris)}
          />
        </div>
        <div className={style.InputContainer}>
          <label htmlFor={'malbilde'}>Målbilde</label>
          <textarea
            rows={8}
            className={style.Input}
            onChange={handleChange}
            id={'malbilde'}
            value={currentProsjektKriterier.malbilde}
          />
        </div>
        <div className={style.actionsBar}>
          {hasError && !isLoading && <p style={{ marginRight: '1em' }}>Noe gikk galt</p>}
          {saveCompleted && !isLoading && <p style={{ marginRight: '1em' }}>Lagring fullført</p>}
          {!!isLoading && <AiOutlineSync className="spin" />}
          <Button onClick={saveProsjektKriterier}>Lagre</Button>
        </div>
      </div>
    </div>
  );
};

export default BefaringProsjektKriterierSide;
