import cn from 'classnames';
import Heading from 'components/Heading/Heading';
import { shortDateTimeString } from 'js/date-helper';
import * as FormatHelper from 'js/format-helper';
import React, { FC, useEffect, useState, useRef } from 'react';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import Icon from 'components/Icon/Icon';
import Panel from 'components/Panel/Panel';
import { HiChevronUp, HiChevronDown } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import SearchField from 'components/SearchField/SearchField';
import styles from 'components/Button/Button.module.css';
import { useWindowSize } from 'js/hooks/useWindowSize';

export interface RollerOgBrukereSide {
  readonly brukere: {
    readonly lenkeUrl: string;
    readonly oppmerksomhetIkon: boolean; // Angir at brukeren krever admins oppmerksomhet.
    readonly statsbyggIkon: boolean; // Statsbygg ikon som i prototypen. Angir at brukeren er AD bruker fra Statsbygg.
    readonly fulltNavn: string;
    readonly organisasjonsnavn: string;
    readonly rollenavn: string;
    readonly sistInnlogget: string;
    readonly antallSvar: number;
    readonly telefon: string;
    readonly epost: string;
  }[];
  readonly orderBy: string; // Navnet på egenskapen i BrukereTableItem det er sortert på
  readonly orderDesc?: boolean; // True hvis det er sortert motsatt, dvs. Å-A
  readonly page: number; // Gjeldende side man er på, første side = 1
  readonly numberOfPages: number; // Antall sider i gjeldende søk
  readonly pageSize: number; // Antall brukere per side
  readonly totalItems: number; // Totalt antall brukere i gjeldende søk
  readonly query: string; // Gjeldende søkestreng
}

const RollerOgBrukereSide: FC<RollerOgBrukereSide> = ({
  brukere,
  orderBy,
  orderDesc,
  page,
  numberOfPages,
  pageSize,
  totalItems,
  query
}) => {
  const [searchString, setSearchString] = useState<string>(query);

  useEffect(() => {
    const parameters = new URLSearchParams(window.location.search);

    // store search string to memory at runtime
    const queryParam = parameters.get('query');
    if (queryParam) setSearchString(queryParam);
  }, []);

  function getArrowIcon(sortKey: string) {
    return sortKey == orderBy && !orderDesc ? <HiChevronDown /> : sortKey == orderBy && orderDesc ? <HiChevronUp /> : null;
  }

  function orderHandler(sortKey: string) {
    return sortKey === orderBy && !orderDesc ? true : sortKey === orderBy && orderDesc ? false : false;
  }

  function urlToPage(page, pageSize, orderBy, orderDesc, query) {
    return (
      '?page=' +
      page +
      (pageSize > 0 ? '&pageSize=' + pageSize : '') +
      (orderBy != null ? '&orderBy=' + orderBy : '') +
      (orderDesc ? '&orderDesc=true' : '') +
      (query != null ? '&query=' + query : '')
    );
  }
  const activePageElem = useRef<HTMLAnchorElement>(null);
  const resized = useWindowSize();

  useEffect(() => {
    const activePage = activePageElem?.current;
    if (activePage) activePage.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
  }, [resized]);

  return (
    <div className="RollerOgBrukereSide">
      <Heading>Roller og brukere</Heading>
      <Panel className={cn('BrukereSide__Panel', 'side-panel')}>
        <div>
          <SearchField>
            <Field
              label="Filtrer brukere"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              onKeyPress={(e) =>
                e.key === 'Enter' ? (window.location.href = urlToPage(page, pageSize, orderBy, orderDesc, searchString)) : null
              }
            />
            {searchString && searchString != '' && <IoMdClose className="close" onClick={() => setSearchString('')} />}
            <Button onClick={() => (window.location.href = urlToPage(page, pageSize, orderBy, orderDesc, searchString))}>
              Søk
            </Button>
          </SearchField>
        </div>
        <table>
          <thead>
            <tr>
              <th className="indikator"></th>
              <th>
                <a href={urlToPage(1, pageSize, 'FulltNavn', orderHandler('FulltNavn'), query)}>
                  Navn
                  {getArrowIcon('FulltNavn')}
                </a>
              </th>
              <th>
                <a href={urlToPage(1, pageSize, 'Organisasjonsnavn', orderHandler('Organisasjonsnavn'), query)}>
                  Dep./Virksomhet
                  {getArrowIcon('Organisasjonsnavn')}
                </a>
              </th>
              <th>
                <a href={urlToPage(1, pageSize, 'Rollenavn', orderHandler('Rollenavn'), query)}>
                  Rolle
                  {getArrowIcon('Rollenavn')}
                </a>
              </th>
              <th>Sist innlogget</th>
              <th>Antall svar</th>
              <th>
                <a href={urlToPage(1, pageSize, 'Epost', orderHandler('Epost'), query)}>
                  Epost
                  {getArrowIcon('Epost')}
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            {brukere.map(
              (
                {
                  epost,
                  oppmerksomhetIkon,
                  statsbyggIkon,
                  lenkeUrl,
                  fulltNavn,
                  organisasjonsnavn,
                  rollenavn,
                  sistInnlogget,
                  antallSvar
                },
                idx
              ) => {
                return (
                  <tr key={lenkeUrl + idx}>
                    <td className="indikator">
                      {oppmerksomhetIkon && <Icon icon="info-alt" />}
                      {statsbyggIkon && (
                        <img className="statsbygg-ikon" src="/assets/svg/Statsbygg_logo_sort_frittstaaende_ikon.svg" />
                      )}
                    </td>
                    <td>
                      <a href={lenkeUrl}>{fulltNavn}</a>
                    </td>
                    <td>{FormatHelper.shortenString(organisasjonsnavn, 24)}</td>
                    <td>{rollenavn}</td>
                    <td>{shortDateTimeString(sistInnlogget)}</td>
                    <td>{antallSvar}</td>
                    <td>
                      <a href={'mailto:' + epost}>{epost}</a>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
        <div className="RollerOgBrukereSide__pages">
          <div>
            {page > 1 ? (
              <a className={cn(styles.LinkAsButton)} href={urlToPage(page - 1, pageSize, orderBy, orderDesc, query)}>
                Forrige
              </a>
            ) : (
              <span></span>
            )}
          </div>
          <div className="RollerOgBrukereSide__pages__page-selection">
            {[...Array(numberOfPages)].map((i, idx) => (
              <a
                ref={page == idx + 1 ? activePageElem : undefined}
                key={'page ' + idx + 1}
                className={cn(styles.button, styles.secondary, page != idx + 1 && styles.borderless)}
                href={urlToPage(idx + 1, pageSize, orderBy, orderDesc, query)}
              >
                {' '}
                {idx + 1}
              </a>
            ))}
          </div>
          <div>
            {page < numberOfPages ? (
              <a className={cn(styles.LinkAsButton)} href={urlToPage(page + 1, pageSize, orderBy, orderDesc, query)}>
                Neste
              </a>
            ) : (
              <span></span>
            )}
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default RollerOgBrukereSide;
