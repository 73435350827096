import IconButton from 'components/IconButton/IconButton';
import React from 'react';
import { FaCloudDownloadAlt, FaRegEye } from 'react-icons/fa';
import style from '../SvarInnkomne.module.css';
import Text from 'components/Text/Text';

interface Props {
  title: string;
  annonseUrl: string;
  lastNedZipUrl?: string;
}
export default function SvarInnkomneTitle({ annonseUrl, lastNedZipUrl, title }: Props) {
  return (
    <div className={style.visningBar}>
      <Text noMargin variant="project-h1">
        {title}
      </Text>
      <div className={style.titleIcons}>
        <IconButton size="small" href={annonseUrl} type="normal" title="Se annonsen">
          <FaRegEye />
        </IconButton>
        {!!lastNedZipUrl && (
          <IconButton size="small" href={lastNedZipUrl} type="normal" title="Last ned zip">
            <FaCloudDownloadAlt />
          </IconButton>
        )}
      </div>
    </div>
  );
}
