import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import { post } from 'js/api-helper';
import React, { useState } from 'react';
import { FC } from 'react';
import { SvarListeSide } from 'source/pages/Svar/SvarListe/SvarListeSide/SvarListeSide';
import AnchoredErrorMessage, { ErrorProps } from 'components/AnchoredErrorMessage/AnchoredErrorMessage';

interface SvarItemModalProps {
  tableItems: SvarListeSide;
  setSvarTableItems: (...args: any[]) => any;
  setSvarItemModalData: (...args: any[]) => any;
  modalProps: ModalProps;
}

export interface ModalProps {
  isOpen: boolean;
  targetUrl?: string;
  tableItemId?: string;
  operation: ModalOperations;
}

export enum ModalOperations {
  'Delete',
  'Recind',
  'None'
}

const SvarItemModal: FC<SvarItemModalProps> = ({ tableItems, setSvarTableItems, setSvarItemModalData, modalProps }) => {
  const [error, setError] = useState<ErrorProps | undefined>();

  function updateTableItemById(id, operation) {
    Object.keys(tableItems).forEach((elem) => {
      const target = tableItems[elem].find((x) => x.id === id);

      if (target && operation == ModalOperations.Delete) {
        const itemsFiltered = tableItems[elem].filter((x) => x !== target);
        const newItems = (tableItems[elem] = itemsFiltered);
        setSvarTableItems((prevState) => ({
          ...prevState,
          [elem]: newItems
        }));
      }

      if (target && operation == ModalOperations.Recind) {
        target.status = 'Trukket';
        setSvarTableItems((prevState) => ({
          ...prevState
        }));
      }
    });
  }

  async function removeOrRecind(cb: () => void) {
    if (modalProps.targetUrl && modalProps.tableItemId) {
      try {
        const res = await post(modalProps.targetUrl);
        if (res) {
          setSvarItemModalData((prevState) => ({
            ...prevState,
            isOpen: false
          }));

          setError(undefined);

          cb();
        }
      } catch (error) {
        setError({
          msg: error.message
        });
      }
    }
  }

  const removeDraft = () => updateTableItemById(modalProps.tableItemId, ModalOperations.Delete);
  const recindOffer = () => updateTableItemById(modalProps.tableItemId, ModalOperations.Recind);

  return (
    <Modal
      id="dropdown-menu-item-popup"
      title={'Er du sikker?'}
      open={modalProps.isOpen}
      onClose={() =>
        setSvarItemModalData((prevState) => ({
          ...prevState,
          isOpen: false
        }))
      }
    >
      {modalProps.operation === ModalOperations.Delete && (
        <>
          <p>Er du sikker på at du vil slette tilbudet? </p>
          <div className="ModalButtons">
            <Button onClick={() => removeOrRecind(removeDraft)}>Slett</Button>
            <Button
              secondary
              onClick={() =>
                setSvarItemModalData(
                  (prevState) => ({
                    ...prevState,
                    isOpen: false
                  }),
                  setError(undefined)
                )
              }
            >
              Avbryt
            </Button>
          </div>
        </>
      )}

      {modalProps.operation === ModalOperations.Recind && (
        <>
          <p>Er du sikker på at du trekke tilbudet? </p>
          <div className="ModalButtons">
            <Button onClick={() => removeOrRecind(recindOffer)}>Trekk tilbud</Button>
            <Button
              secondary
              onClick={() =>
                setSvarItemModalData(
                  (prevState) => ({
                    ...prevState,
                    isOpen: false
                  }),
                  setError(undefined)
                )
              }
            >
              Avbryt
            </Button>
          </div>
        </>
      )}
      {error && <AnchoredErrorMessage percentageTopPos={91} {...error} />}
    </Modal>
  );
};

export default SvarItemModal;
