import React from 'react';
import styles from '../Annonse.module.css';
import Text from 'components/Text/Text';

interface Props {
  label: string;
  value: string;
  icon?: React.ReactNode;
}
const PanelEntry = ({ label, value }: Props) => {
  return (
    <div key={label} className={styles.PanelEntry}>
      {/* {icon} TODO: Skal denne fjernes? */}
      <Text variant="list-item">
        {label?.toUpperCase() || ''}: <b>{value}</b>
      </Text>
    </div>
  );
};
export default PanelEntry;
