import type { LinkItem } from 'types/LinkItem';
import React, { FC, useState } from 'react';

import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import Heading from 'components/Heading/Heading';
import { IconTypes } from 'components/Icon/IconReactIcons';
import IconButton from 'components/IconButton/IconButton';
import { shortDateString } from 'js/date-helper';
import { TilbudtObjekt } from 'types/TilbudtObjekt';
import { TilbudtObjektListe } from 'types/TilbudtObjektListe';
import { SortLeieObjekter } from 'js/SortLeieObjekter';
import TilbudtObjektCardList, { TilbudtObjektCardListHeadings } from 'components/TilbudtObjektCardList/TilbudtObjektCardList';

export interface ForhandlingerInnkomneSide {
  readonly title: string;
  readonly svarFristUtc: string;
  readonly pageLinks: LinkItem[];
  readonly arkiverProsjektHref: string;
  readonly objekter: TilbudtObjekt[];
}

const ForhandlingerInnkomneSide: FC<ForhandlingerInnkomneSide> = ({
  title,
  svarFristUtc,
  pageLinks,
  arkiverProsjektHref,
  objekter
}) => {
  const [sortedList, setSortedList] = useState<TilbudtObjektListe>(SortLeieObjekter(objekter));

  const listHeadings: TilbudtObjektCardListHeadings = {
    tentative: 'Tentativ liste, mangler godkjenning fra oppdragsgiver',
    recommended: 'Anbefales å vurdere disse lokalene for deltakelse i forhandlinger',
    notEvaluated: 'Ikke evaluert',
    rejected: 'Anbefales å utelukke fra forhandlinger',
    canceled: 'Tilbud er trukket'
  };

  return (
    <div className="KonkurranseInnkomneSide">
      <div className="heading">
        <Heading>{title}</Heading>
        <IconButton color="#147e87" type="normal" title="Arkiver" href={arkiverProsjektHref} iconType={IconTypes.fiArchive} />
      </div>
      <PageLinkMenu pageLinks={pageLinks} />

      <TilbudtObjektCardList list={sortedList as TilbudtObjektListe} headings={listHeadings} />
    </div>
  );
};

export default ForhandlingerInnkomneSide;
