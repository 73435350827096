import { post } from 'js/api-helper';
import React, { useCallback, useState } from 'react';
import IconReactIcons, { IconTypes, getIcon } from 'components/Icon/IconReactIcons';

export interface Props {
  readonly slettUrl: string;
  onDelete(res: Record<any, any>): void;
}

export default function DeleteButton({ slettUrl, onDelete }: Props) {
  const [isBusy, setBusy] = useState(false);
  const [deleteFailedMessage, setdeleteFailedMessage] = useState<string>();

  const deleteEntry = useCallback(async () => {
    if (!slettUrl) return;
    setBusy(true);
    try {
      const result = await post(slettUrl);
      onDelete(result);
    } catch (error) {
      setdeleteFailedMessage('Ooops! vi klarte ikke slette bildet.<br> Vennligst prøv igjen senere.');
      return;
    } finally {
      setBusy(false);
    }
  }, []);

  return (
    <label className="DeleteButtonWrapper">
      {deleteFailedMessage && (
        <div className="DeleteButtonWrapper-failed">
          <p dangerouslySetInnerHTML={{ __html: deleteFailedMessage }}></p>
        </div>
      )}
      {isBusy ? (
        getIcon(IconTypes.spinner).Component
      ) : (
        <>
          <button className="DeleteButton" type="submit" onClick={(e) => deleteEntry()}>
            {' '}
            Fjern bilde{' '}
          </button>
        </>
      )}
    </label>
  );
}
