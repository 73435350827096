import ErrorMessage, { IErrorMessage } from 'components/ErrorMessage/ErrorMessage';
import Icon from 'components/Icon/Icon';
import React, { FC } from 'react';
import AnchoredErrorMessage, { ErrorProps } from '../AnchoredErrorMessage/AnchoredErrorMessage';

export interface FormSubmitBoxProps {
  heading: string;
  bodyText?: string;
  disabled: boolean;
  buttonText: string;
  regretButtonText?: string;
  submitUrl?: string;
  submitFunc: (url: string) => void;
  regretFunc?: (url: string) => void;
  dokumenterPublisert?: boolean;
  error?: ErrorProps;

  children?: React.ReactNode;
}

const FormSubmitBox: FC<FormSubmitBoxProps> = ({
  heading,
  bodyText,
  disabled,
  buttonText,
  regretButtonText,
  submitUrl,
  submitFunc,
  regretFunc,
  dokumenterPublisert,
  error,
  children
}) => {
  return (
    <div className="complete">
      <div className="font-paragraph-header">{heading}</div>
      {children && <div className="complete__content">{children}</div>}
      <div className="complete__bottom-row">
        {bodyText && <p className="font-paragraph text">{bodyText}</p>}
        {dokumenterPublisert && regretFunc ? (
          <button
            disabled={disabled}
            onClick={() => {
              if (submitUrl) regretFunc(submitUrl);
            }}
          >
            <Icon icon="bookmark"></Icon>
            {regretButtonText}
          </button>
        ) : (
          <button
            disabled={disabled}
            onClick={() => {
              if (submitUrl) submitFunc(submitUrl);
            }}
          >
            <Icon icon="bookmark"></Icon>
            {buttonText}
          </button>
        )}
        {error && <AnchoredErrorMessage percentageTopPos={118} {...error} />}
      </div>
    </div>
  );
};

export default FormSubmitBox;
