import cs from 'classnames';
import React, { PropsWithChildren } from 'react';
import Panel from 'components/Panel/Panel';
import style from './DoublePanel.module.css';
import Text from 'components/Text/Text';

export interface Props {
  readonly label?: string;
  readonly className?: string;
}
export default function DoublePanel({ label, children, className }: PropsWithChildren<Props>) {
  return (
    <Panel className={cs(style.DoublePanel, className)}>
      {label && (
        <Text noMargin variant="panel-heading" component="h2" className={style.Label}>
          {label}
        </Text>
      )}
      {children}
    </Panel>
  );
}
