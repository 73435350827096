import React from 'react';
import { ArealEtPlan } from 'types/ArealEtPlan';
import style from './Popup.module.css';
import Text from 'components/Text/Text';

export interface Props {
  readonly totaltAreal?: number;
  readonly ledigAreal?: number;
  readonly arealPerPlan?: ArealEtPlan[];
  readonly universellUtforming?: string;
  readonly bebygdStatus?: string;
  readonly tilbudtAreal?: number;
  readonly utbyggingspotensialeBTA?: number;
}

export default function FleksibilitetPopup({
  totaltAreal,
  ledigAreal,
  arealPerPlan,
  universellUtforming,
  bebygdStatus,
  tilbudtAreal,
  utbyggingspotensialeBTA
}: Props) {
  return (
    <>
      <Text variant="paragraph-header" component="h3">
        Fleksibilitet
      </Text>

      {!!totaltAreal && (
        <div>
          <Text variant="list-item">
            Totalt areal:{' '}
            <b style={{ color: 'var(--linkColor)' }}>
              {totaltAreal} m<sup>2</sup>
            </b>
          </Text>
        </div>
      )}
      {!!ledigAreal && (
        <div>
          <Text variant="list-item">
            Ledig areal:{' '}
            <b style={{ color: 'var(--linkColor)' }}>
              {ledigAreal} m<sup>2</sup>
            </b>
          </Text>
        </div>
      )}

      <div className={style.Avstander}>
        {arealPerPlan?.map(({ areal, plan }, index) => (
          <div key={index}>
            <Text variant="list-item">
              {plan} etasje:{' '}
              <b style={{ color: 'var(--linkColor)' }}>
                {areal} m<sup>2</sup>
              </b>
            </Text>
          </div>
        ))}
      </div>

      {!!universellUtforming && (
        <div>
          <Text variant="list-item">
            Universell Utforming: <b style={{ color: 'var(--linkColor)' }}>{universellUtforming}</b>
          </Text>
        </div>
      )}

      {!!tilbudtAreal && (
        <div>
          <Text variant="list-item">
            Tilbudt areal:
            <b style={{ color: 'var(--linkColor)' }}>{tilbudtAreal}</b>
          </Text>
        </div>
      )}

      {!!utbyggingspotensialeBTA && (
        <div>
          <Text variant="list-item">
            Utbyggingspotensiale:
            <b style={{ color: 'var(--linkColor)' }}>{utbyggingspotensialeBTA}</b>
          </Text>
        </div>
      )}

      {!!bebygdStatus && (
        <div>
          <Text variant="list-item">
            Bebygd/ubebygd:
            <b style={{ color: 'var(--linkColor)' }}>{bebygdStatus}</b>
          </Text>
        </div>
      )}
    </>
  );
}
