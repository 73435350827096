import SingleDatePicker from 'components/DateRangePicker/SingleDatePicker';
import Organisasjonsvelger from 'components/Organisasjonsvelger/Organisasjonsvelger';
import SelectField from 'components/SelectField/SelectField';
import TextField from 'components/TextField/TextField';
import { parseISO8601String as parseDate } from 'js/date-helper';
import { usePostback } from 'js/hooks';
import React, { FC, useEffect, useMemo, useState } from 'react';
import Panel from 'components/Panel/Panel';
import { Prosjekt } from 'types/Prosjekt';
import ProsjektRedigerContainer from '../ProsjektRedigerContainer';
import { mapPersoner, diffArrayOfObjects } from '../utils';
import Field from 'components/Field/Field';
import FagRessursSelector from 'components/FagressursSelector/FagRessursSelector';

export interface ProsjektRedigerSide {
  readonly headerText: string;
  readonly prosjektData: Prosjekt;
  readonly prosjektLederListe: {
    readonly id: string;
    readonly fulltNavn: string;
  }[];
  readonly fagressursListe: {
    readonly id: string;
    readonly fulltNavn: string;
  }[];
  readonly erTomteSide?: boolean;
  readonly prosjektLagreApi: string;
  readonly avsluttUrl: string;
  readonly nesteUrl: string;
  readonly slettProsjektBekreftTekst?: string;
  readonly deleteUrl?: string;
  readonly archiveProsjektUrl?: string;
}

const ProsjektRedigerSide: FC<ProsjektRedigerSide> = ({
  headerText,
  prosjektData: data,
  erTomteSide,
  prosjektLederListe,
  fagressursListe,
  prosjektLagreApi,
  avsluttUrl,
  nesteUrl,
  slettProsjektBekreftTekst,
  deleteUrl,
  archiveProsjektUrl
}) => {
  const prosjektledere = useMemo(() => mapPersoner(prosjektLederListe), []);
  const [prosjektnummer, setProsjektnummer] = useState(data.prosjektnummer);
  const [prosjektnavn, setProsjektnavn] = useState(data.prosjektnavn);
  const [prosjektEier, setProsjektEier] = useState(data.prosjektEier || '');
  const [arkivreferanse, setArkivreferanse] = useState(data.arkivreferanse);
  const [prosjektleder, setProsjektleder] = useState(data.prosjektleder);
  const [muligFagRessurs, setMuligFagRessurs] = useState(mapPersoner(fagressursListe));
  const [selectedFagRessurser, setSelectedFagRessurser] = useState(mapPersoner(data.fagressurser));
  const [oppdragsgiver, setOppdragsgiver] = useState(data.oppdragsgiver);
  const oppdragsgiverNavn = data.oppdragsgiverNavn;
  const [startDatoUtc, setStartDatoUtc] = useState(() => parseDate(data.startDatoUtc));
  const [beskrivelse, setBeskrivelse] = useState(data.beskrivelse);

  const { postback, isDirty, isBusy, validation } = usePostback(prosjektLagreApi, {
    prosjektnummer,
    prosjektnavn,
    arkivreferanse,
    prosjektleder,
    selectedFagRessurser,
    oppdragsgiver,
    startDatoUtc,
    beskrivelse
  });

  useEffect(() => {
    setMuligFagRessurs(diffArrayOfObjects(mapPersoner(fagressursListe), selectedFagRessurser));
  }, [selectedFagRessurser]);

  return (
    <ProsjektRedigerContainer
      headerText={headerText}
      isDirty={isDirty}
      isBusy={isBusy}
      currentStep={1}
      quitUrl={avsluttUrl}
      nextPageUrl={nesteUrl}
      save={postback}
      slettProsjektBekreftTekst={slettProsjektBekreftTekst}
      deleteUrl={deleteUrl}
      archiveProsjektUrl={archiveProsjektUrl}
    >
      <Panel className="ProsjektRedigerSide">
        <Field
          label="Prosjektnummer*"
          type="text"
          defaultValue={prosjektnummer}
          onChange={(e) => setProsjektnummer(e.target.value)}
          {...validation.prosjektnummer}
        />

        <TextField
          label="Prosjektnavn*"
          type="text"
          rows={1}
          maxCharacters={50}
          value={prosjektnavn}
          onChange={(e) => setProsjektnavn(e.target.value)}
          {...validation.prosjektnavn}
        />

        <Field
          label="Arkivreferanse*"
          type="text"
          defaultValue={arkivreferanse}
          onChange={(e) => setArkivreferanse(e.target.value)}
          {...validation.arkivreferanse}
        />

        <SingleDatePicker
          label="Startdato*"
          placeholder="Startdato"
          initialDate={startDatoUtc}
          onDateChange={(value) => setStartDatoUtc(value)}
          styleException={'half-width'}
          {...validation.startDatoUtc}
        />

        <Organisasjonsvelger
          spanFull={!erTomteSide}
          label="Oppdragsgiver*"
          initialValue={oppdragsgiverNavn}
          onChange={(value) => setOppdragsgiver(value)}
          {...validation.oppdragsgiver}
        />

        {erTomteSide && (
          <TextField
            label="Prosjekteier*"
            type="text"
            rows={1}
            maxCharacters={50}
            value={prosjektEier}
            onChange={(e) => setProsjektEier(e.target.value)}
            // {...validation.prosjektEier}
          />
        )}
      </Panel>
      <Panel className="ProsjektRedigerSide">
        <SelectField
          label="Prosjektleder*"
          choices={prosjektledere}
          selectedValue={prosjektledere.find((p) => p.value === prosjektleder)}
          onChange={(e) => setProsjektleder(e.value)}
          {...validation.prosjektleder}
        />
        <FagRessursSelector
          label="Fagressurser"
          dropdownOptions={muligFagRessurs}
          selectedFagRessurser={selectedFagRessurser}
          setSelectedFagRessurser={setSelectedFagRessurser}
        />
      </Panel>
      <Panel className="ProsjektRedigerSide">
        <TextField
          className="ProsjektRedigerSide__Beskrivelse"
          label="Beskrivelse"
          placeholder="Skriv her"
          large={true}
          type="textarea"
          rows={5}
          value={beskrivelse}
          maxCharacters={500}
          onChange={(e) => setBeskrivelse(e.target.value)}
          {...validation.beskrivelse}
        />
      </Panel>
    </ProsjektRedigerContainer>
  );
};

export default ProsjektRedigerSide;
