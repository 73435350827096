import { Color, Letter, Merke } from 'components/Energimerke/Energimerke';
import React from 'react';
import { Fildata } from 'types/Fildata';
import Text from 'components/Text/Text';

export interface Props {
  readonly energimerke: string;
  readonly energimerkeFarge: string;
  readonly planlagtEnergimerke?: string;
  readonly planlagtEnergimerkeFarge?: string;
  readonly energiattest?: Fildata;
  readonly miljosertifisert: string;
  readonly kwh?: number;
}

export default function MiljoPopup({
  energimerke,
  energimerkeFarge,
  planlagtEnergimerke,
  planlagtEnergimerkeFarge,
  energiattest,
  miljosertifisert,
  kwh
}: Props) {
  return (
    <>
      <Text component="h3" variant="paragraph-header">
        Miljø
      </Text>

      <div>
        <Text variant="list-item">
          Energimerke: <Merke color={energimerkeFarge as Color} letter={energimerke as Letter} />
        </Text>
      </div>

      {planlagtEnergimerke && planlagtEnergimerkeFarge && (
        <div>
          <Text variant="list-item">
            Planlagt energimerke: <Merke color={planlagtEnergimerkeFarge as Color} letter={planlagtEnergimerke as Letter} />{' '}
          </Text>
        </div>
      )}

      <div>
        <Text variant="list-item">
          Energiattest:{' '}
          {energiattest !== null ? (
            <b style={{ color: 'var(--linkColor)' }}>
              <a href={energiattest?.nedlastingslenke}>Vedlagt</a>
            </b>
          ) : (
            <b style={{ color: 'var(--linkColor)' }}>Ikke vedlagt</b>
          )}
        </Text>
      </div>

      <div>
        <Text variant="list-item">
          Miljøsertifisert: <b style={{ color: 'var(--linkColor)' }}>{miljosertifisert}</b>
        </Text>
      </div>

      {!!kwh && (
        <div>
          <Text variant="list-item">
            Kwh: <b style={{ color: 'var(--linkColor)' }}>{kwh} kwh</b>
          </Text>
        </div>
      )}
    </>
  );
}
