import LabeledField from 'components/LabeledField/LabeledField';
import React from 'react';
import RadioButton from './RadioButton';

export interface Choice<T> {
  readonly label: string;
  readonly value: T;
}

export interface Props<T> {
  readonly label: string;
  readonly name: string;
  readonly values: Choice<T>[];
  readonly currentValue?: Choice<T>;
  readonly onChange?: (v: string) => void;
  readonly feedback?: string;
  readonly validationState?: 'error' | null;
  readonly feedbackMargin?: boolean;
}
export default function RadioField<T>({
  label,
  name,
  values,
  currentValue,
  onChange,
  feedback,
  feedbackMargin,
  validationState
}: Props<T>) {
  return (
    <LabeledField
      label={label}
      feedback={feedback}
      feedbackMargin={feedbackMargin}
      validationState={validationState}
    >
      {values.map((value) => (
        <RadioButton
          key={value.label}
          name={name}
          value={value.label}
          currentValue={currentValue?.label}
          onChange={onChange}
        />
      ))}
    </LabeledField>
  );
}
