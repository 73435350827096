import React, { FC } from 'react';
import type { LinkItem } from 'types/LinkItem';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import type { DokumentSkjemadel } from 'types/DokumentSkjemadel';
import Text from 'components/Text/Text';
import useArrayState from '@clave/use-array-state';
import DokumentSkjema from 'components/DokumentSkjema/DokumentSkjema';
import ProjectManagerArea from '../../KonkurransegrunnlagSide/ProjectManagerArea/ProjectManagerArea';

export interface PubliserKonkurransegrunnlagSide {
  readonly tittel: string;
  readonly sideLenker?: LinkItem[];
  readonly sendTilGodkjenningUrl: string;
  readonly godkjennUrl: string;
  readonly publiserGrunnlagUrl: string;
  readonly avsluttKonkurranseUrl: string;
  readonly editVedleggUrl: string;
  readonly slettVedleggUrl: string;
  readonly grunnlagPublisert: boolean;
  readonly leveranseFristUtc?: string;
  readonly oppdaterFilStatusUrl: string;

  readonly skjemaDeler: DokumentSkjemadel[];
}

const PubliserKonkurransegrunnlagSide: FC<PubliserKonkurransegrunnlagSide> = (props: PubliserKonkurransegrunnlagSide) => {
  const [dynamicDocs, setDynamicDocs] = useArrayState<DokumentSkjemadel>(props.skjemaDeler);

  return (
    <div className={'PubliserKonkurransegrunnlagSide'}>
      <Text component="h1" variant="list-title">
        {props.tittel}
      </Text>

      {props.sideLenker && <PageLinkMenu pageLinks={props.sideLenker} />}
      <DokumentSkjema
        oppdaterFilStatusUrl={props.oppdaterFilStatusUrl}
        skjemaDeler={dynamicDocs}
        slettVedleggUrl={props.slettVedleggUrl}
        editVedleggUrl={props.editVedleggUrl}
        readonly={false}
      />

      <ProjectManagerArea
        {...props}
        rolle="PL"
        anbefalingerSendt={true}
        anbefalingerGodkjent={true}
        oppdragsGiverKommentar={''}
      />
    </div>
  );
};

export default PubliserKonkurransegrunnlagSide;
