import { useRef, useState, useEffect } from 'react';

export const useParentSize = () => {
  const [sizes, setSizes] = useState<{ height: number; width: number }>({
    height: 0,
    width: 0
  });
  const parentRef = useRef<HTMLDivElement>(null);
  // useEffect will run on stageCanvasRef value assignment
  useEffect(() => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (parentRef?.current) {
      const height = parentRef?.current?.offsetHeight || 0;
      const width = parentRef?.current?.offsetWidth || 0;
      setSizes({ height, width });
    }
  }, [parentRef]);

  return { parentRef, sizes };
};
