export function mapPersoner(list: { fulltNavn: string; id: string }[]) {
  return list?.map((e) => ({ label: e.fulltNavn, value: e.id })) ?? [];
}

export function mapFylker(list: { navn: string; id: string }[]) {
  return list?.map(({ id, navn }) => ({ label: navn, value: id })) ?? [];
}

export function mapKommuner(
  fylke: string,
  list: { fylkeId: string; navn: string; id: string }[]
) {
  return (
    list
      ?.filter(({ fylkeId }) => fylkeId === fylke)
      .map(({ id, navn }) => ({ label: navn, value: id })) ?? []
  );
}

export const diffArrayOfObjects =(baseArr, arrToCompare) => {
  return [...baseArr.filter(({ value: id1 }) => !arrToCompare.some(({ value: id2 }) => id2 === id1))]
}
