import { IArcGisKart } from 'components/ArcGisKart/ArcGis';
import { getMarker } from 'components/ArcGisKart/arcGis.helper';
import ArcGisLoader from 'components/ArcGisKart/ArcGisLoader';
import Popup from 'components/Popup/Popup';
import Text from 'components/Text/Text';
import { shortDateString } from 'js/date-helper';
import { numberWithSpaces } from 'js/format-helper';
import React, { FC, useCallback } from 'react';
import { BiCommentDots } from 'react-icons/bi';
import { ArcgisServiceArea } from 'types/ArcgisServiceArea';
import { ArcgisSketch } from 'types/ArcgisSketch';
import { SvarListItem } from 'types/SvarListItem';
import InnkomneItem from 'components/InnkomneItem/InnkomneItem';
import SvarInnkomneHeader from '../components/SvarInnkomneHeader';
import SvarInnkomneTitle from '../components/SvarInnkomneTitle';
import SvarPopup from '../components/SvarPopup';
import style from '../SvarInnkomne.module.css';
import useSvarInnkomneListOperations from '../useSvarInnkomneListOperations';
export interface SvarInnkomneSide {
  readonly title: string;
  readonly innkomneSvar: SvarListItem[];
  readonly annonseUrl: string;
  readonly lastNedZipUrl?: string;
  readonly sketches?: ArcgisSketch[];
  readonly serviceAreas?: ArcgisServiceArea[];
}

const SvarInnkomneSide: FC<SvarInnkomneSide> = ({
  title,
  innkomneSvar,
  annonseUrl,
  lastNedZipUrl,
  sketches,
  serviceAreas
}: SvarInnkomneSide) => {
  const { sortedSvar, now, mapPopup, setMapPopup, viewMode, setViewMode, toggleBareTilBefaring, setCurrentInnkomneSvar } =
    useSvarInnkomneListOperations<SvarListItem>(innkomneSvar);

  const getDetailsList = useCallback(
    (svar) => [
      {
        label: 'Pris per kvm med tiltak: ',
        value: numberWithSpaces(svar.prisKvmMedTiltak) + ' kr'
      },
      {
        label: 'Pris per kvm uten tiltak: ',
        value: numberWithSpaces(svar.prisKvmUtenTiltak) + ' kr'
      },
      {
        label: 'Felleskostnader: ',
        value: numberWithSpaces(svar.felleskostKvm) + ' kr'
      },
      {
        label: 'Mva-kompensasjon per kvm: ',
        value: numberWithSpaces(svar.mvaKompensasjonKvm) + ' kr'
      },
      {
        label: 'Ledig areal: ',
        value: numberWithSpaces(svar.ledigAreal) + ' kvm'
      },
      {
        label: 'Ledig fra: ',
        value: shortDateString(svar.ledigFraDato)
      }
    ],
    []
  );

  const getSubtitle = useCallback(
    (svar) => ({
      label: 'Utleier: ',
      value: svar.utleier
    }),
    []
  );

  const getMapsProps = (): IArcGisKart => ({
    readOnly: true,
    zoom: 8,
    center: sortedSvar[0] ? [Number(sortedSvar[0]?.location?.longitude), Number(sortedSvar[0]?.location?.latitude)] : undefined,
    sketches: sketches,
    serviceAreas: serviceAreas,
    markers: sortedSvar.map(({ location, overstyrtScoreHelhetsvurdering, ...svar }) => {
      return {
        latitude: Number(location?.latitude),
        longitude: Number(location?.longitude),
        markerColor: getMarker(overstyrtScoreHelhetsvurdering),
        popupTemplate: {
          title: svar.address,
          element: (
            <SvarPopup
              heading={
                <>
                  {svar.address}
                  {svar.internKommentar && <BiCommentDots />}
                </>
              }
              url={svar.url}
              overstyrtScoreHelhetsvurdering={overstyrtScoreHelhetsvurdering}
              scores={svar.scores}
              subtitle={getSubtitle(svar)}
              location={location}
              detailsList={getDetailsList(svar)}
            />
          )
        }
      };
    })
  });

  return (
    <div className={style.SvarInnkomneSide}>
      <SvarInnkomneTitle title={title} annonseUrl={annonseUrl} lastNedZipUrl={lastNedZipUrl} />
      <SvarInnkomneHeader viewMode={viewMode} setViewMode={setViewMode} toggleBareTilBefaring={toggleBareTilBefaring} />

      {viewMode === 'table' &&
        (sortedSvar?.length === 0 ? (
          <Text variant={'tag-bold'} className={style.empty}>
            Ingen innkomne svar
          </Text>
        ) : (
          sortedSvar.map((svar) => (
            <InnkomneItem
              heading={
                <>
                  {svar.address}
                  {svar.internKommentar && <BiCommentDots />}
                </>
              }
              subtitle={getSubtitle(svar)}
              detailsList={getDetailsList(svar)}
              id={svar.id}
              url={svar.url}
              image={svar.image}
              key={svar.id}
              scores={svar.scores}
              befaring={svar.befaring}
              overstyrtScoreHelhetsvurdering={svar.overstyrtScoreHelhetsvurdering}
              bygningensStand={svar.bygningensStand}
              setCurrentInnkomneSvar={setCurrentInnkomneSvar}
            />
          ))
        ))}

      {viewMode === 'map' && (
        <div className={style.map}>
          <Popup close={() => setMapPopup(false)} isOpen={mapPopup}>
            <ArcGisLoader toggleFullscreen={() => setMapPopup(!mapPopup)} {...getMapsProps()} />
          </Popup>
          {ArcGisLoader !== null && mapPopup ? (
            <div style={{ height: 600 }} />
          ) : (
            <ArcGisLoader toggleFullscreen={() => setMapPopup(!mapPopup)} {...getMapsProps()} />
          )}
        </div>
      )}
      <div className={style.printDate}>{now}</div>
    </div>
  );
};

export default SvarInnkomneSide;
