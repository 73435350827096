import Heading from 'components/Heading/Heading';
import Organisasjonsvelger from 'components/Organisasjonsvelger/Organisasjonsvelger';
import ProfilBildePanel from 'components/ProfilBildePanel/ProfilBildePanel';
import SelectField from 'components/SelectField/SelectField';
import * as api from 'js/api-helper';
import { dateTimeString } from 'js/date-helper';
import { usePostback } from 'js/hooks';
import React, { FC, useCallback, useState } from 'react';
import { AiOutlineSync } from 'react-icons/ai';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import Modal from 'components/Modal/Modal';
import type { LinkItemChild } from 'types/LinkItemChild';

// This is here to make the c# generator pick up LinkItemChild
const x: LinkItemChild = {
  url: '',
  text: ''
};

export interface BrukerSide {
  readonly id: string;
  readonly fulltNavn: string;
  readonly oppgittOrganisasjon: string;
  readonly organisasjonsnavn: string;
  readonly organisasjonsnummer: string;
  readonly epostRedigerbar: boolean;
  readonly epost: string;
  readonly telefonRedigerbar: boolean;
  readonly telefon: string;
  readonly rolle: string;
  readonly rollenavn: string;
  readonly sistInnloggetUtc: string;
  readonly antallInnlogginger: number;
  readonly rollevalg: {
    readonly rolle: string;
    readonly rollenavn: string;
  }[];
  readonly slettBrukerBekreftTekst: string;
  readonly redigerBrukerApiUrl: string;
  readonly slettBrukerApiUrl: string;
  readonly lagreProfilBildeUrl: string;
  readonly profilBildeUrl: string;
  readonly slettProfilBildeUrl: string;
}

const BrukerSide: FC<BrukerSide> = (props) => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const [rolle, setRolle] = useState(props.rolle);
  const [epost, setEpost] = useState(props.epost);
  const [telefon, setTelefon] = useState(props.telefon);
  const [organisasjonsnummer, setOrganisasjonsnummer] = useState(props.organisasjonsnummer);

  const rolleListe = props.rollevalg.map((r) => ({
    label: r.rollenavn,
    value: r.rolle
  }));
  const rolleSelected = rolleListe.find((r) => r.value === rolle);

  const { postback, validation, canSubmit, isBusy } = usePostback(props.redigerBrukerApiUrl, {
    rolle,
    epost,
    telefon,
    organisasjonsnummer
  });

  const lagreBruker = useCallback(async () => {
    await postback();
    window.location.href = '/admin/rollerbrukere';
  }, [postback]);

  const hidePopup = useCallback(() => setPopupOpen(false), []);
  const showPopup = useCallback(() => setPopupOpen(true), []);

  const slettBruker = useCallback(async () => {
    await api.post(props.slettBrukerApiUrl);
    window.location.href = '/admin/rollerbrukere';
  }, [props.slettBrukerApiUrl]);

  return (
    <>
      <Heading>Rediger bruker</Heading>
      <div className="ProfilSide">
        <div className="ProfilSide__backdrop"></div>
        <div className="ProfilSide__info">
          <h5>Navn</h5>

          <div>{props.fulltNavn}</div>

          {props.oppgittOrganisasjon && <h5>Organisasjon oppgitt av brukeren</h5>}
          {props.oppgittOrganisasjon && <div>{props.oppgittOrganisasjon}</div>}

          <h5>Departement / Virksomhet</h5>
          <Organisasjonsvelger
            initialValue={props.organisasjonsnavn}
            onChange={(orgNummer) => setOrganisasjonsnummer(orgNummer)}
            {...validation.organisasjonsnummer}
          />

          <h5>Epost</h5>
          {props.epostRedigerbar ? (
            <Field
              name="Epost"
              label=""
              defaultValue={props.epost}
              onChange={(e) => setEpost(e.target.value)}
              {...validation.epost}
            />
          ) : (
            <div>{props.epost}</div>
          )}

          <h5>Telefon</h5>
          {props.telefonRedigerbar ? (
            <Field
              onChange={(e) => setTelefon(e.target.value)}
              name="Telefon"
              label=""
              defaultValue={props.telefon}
              {...validation.telefon}
            />
          ) : (
            <div>{props.telefon}</div>
          )}

          <h5>Rolle</h5>
          <SelectField
            choices={rolleListe}
            selectedValue={rolleSelected}
            onChange={(selected) => setRolle(selected.value)}
            {...validation.rolle}
          />

          <Modal open={isPopupOpen} title="Bekreft sletting" clickOutsideToClose={true} onClose={hidePopup}>
            {props.slettBrukerBekreftTekst.split('\n').map((line, key) => (
              <p key={key}>{line}</p>
            ))}
            <div className="BrukerSide__popup-buttons">
              <Button secondary onClick={hidePopup}>
                Avbryt
              </Button>
              <Button onClick={slettBruker}>Slett bruker</Button>
            </div>
          </Modal>
        </div>

        <ProfilBildePanel
          url={props.profilBildeUrl}
          uploadUrl={props.lagreProfilBildeUrl}
          deleteUrl={props.slettProfilBildeUrl}
        />
        <div className="ProfilSide__info__buttons">
          {isBusy && <AiOutlineSync className="spin" />}

          <Button onClick={showPopup} secondary>
            Slett bruker
          </Button>

          <Button disabled={!canSubmit} onClick={lagreBruker}>
            Lagre
          </Button>
        </div>
      </div>
      <div className="loginDataFooter">
        <div>
          <h5>Sist innlogget: </h5>
          <div>{dateTimeString(props.sistInnloggetUtc)}</div>
        </div>
        <div>
          <h5>Antall innlogginger: </h5>
          <div>{props.antallInnlogginger}</div>
        </div>
      </div>
    </>
  );
};

export default BrukerSide;
