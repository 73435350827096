import React from 'react';

interface ISearchField {
  children?: React.ReactNode;
}
const SearchField = ({...props}: ISearchField) => {

  return (
    <div className="SearchField">
        {props.children && 
            props.children
        }
    </div>
  );
};

export default SearchField;
