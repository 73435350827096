import useArrayState from '@clave/use-array-state';
import NumberField from 'components/NumberField/NumberField';
import SelectField from 'components/SelectField/SelectField';
import useOnChange from 'js/hooks/useOnChange';
import React, { useCallback, useState } from 'react';
import Button from 'components/Button/Button';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { SingelKollektivTransportEntry } from 'types/SingelKollektivTransportEntry';
import Text from 'components/Text/Text';
import { getIcon, IconTypes } from 'components/Icon/IconReactIcons';

export interface Props {
  avstandKollektivtransport: SingelKollektivTransportEntry[];
  typerKollektivtransport?: string[];
  label?: string;
  onChange?: (transList: SingelKollektivTransportEntry[]) => void;
  readonly feedback?: string;
  readonly validationState?: 'error' | null;
  readonly feedbackMargin?: boolean;
}

export default function KollektivTransport({
  avstandKollektivtransport = [],
  typerKollektivtransport = ['Buss', 'Trikk', 'Tog', 'T-bane'],
  label = 'Kollektivtransport',
  feedback,
  validationState,
  feedbackMargin,
  onChange
}: Props) {
  const [transList, setTransList] = useArrayState(avstandKollektivtransport);
  const typerTilListe = typerKollektivtransport.filter((t) => !transList.find((x) => x.kollektivtype === t));
  const [kollektivtype, setKollektivtype] = useState('');
  const [avstandIMeter, setAvstandIMeter] = useState(0);

  const addToList = useCallback(() => {
    setTransList.append({
      kollektivtype,
      avstandIMeter: avstandIMeter
    });
  }, [kollektivtype, avstandIMeter]);
  const handleAddToList = () => {
    addToList();
    // setTransList((_tL) => _tL.filter((t) => t.kollektivtype !== kollektivtype));
    // setAvstandIMeter(0);
  };

  const showError = feedback && validationState === 'error';

  useOnChange(onChange, [transList]);

  const listToOptions = (list: string[]) => list.map((s) => ({ label: s, value: s }));

  return (
    <div className="KollektivTransport">
      <div className="KollektivTransport__Label">
        <Text variant="mono-16" component="label">
          {label}
        </Text>
      </div>
      {!!typerTilListe?.length && (
        <>
          <div className="KollektivTransport__entry__type">
            <SelectField
              placeholder="Type transport"
              selectedValue={kollektivtype ? { value: kollektivtype, label: kollektivtype } : undefined}
              choices={[{ label: 'Type', value: '' }, ...listToOptions(typerTilListe)]}
              onChange={(e) => setKollektivtype(e.value)}
            />
          </div>
          <div className="KollektivTransport__entry__avstand">
            <NumberField
              type="number"
              suffix="m"
              value={avstandIMeter}
              placeholder="Avstand I Meter"
              onChange={(val) => setAvstandIMeter(val)}
            />
          </div>
          <div className="KollektivTransport__entry__delete">
            <Button
              icon
              title={getIcon(IconTypes.plus).alt}
              onClick={handleAddToList}
              disabled={!kollektivtype || !avstandIMeter}
            >
              {getIcon(IconTypes.plus).Component}
            </Button>
          </div>
        </>
      )}

      {transList.map(({ kollektivtype, avstandIMeter }, index) => (
        <React.Fragment key={index}>
          <div className="KollektivTransport__entry__type" style={{ marginLeft: 5 }}>
            {kollektivtype}
          </div>
          <div className="KollektivTransport__entry__avstand">
            <NumberField
              type="number"
              suffix="m"
              value={avstandIMeter}
              placeholder="Avstand I Meter"
              onChange={(avstandIMeter) => setTransList.replaceAt(index, { kollektivtype, avstandIMeter })}
            />
          </div>
          <Button title={getIcon(IconTypes.remove).alt} icon onClick={() => setTransList.removeAt(index)}>
            {getIcon(IconTypes.remove).Component}
          </Button>
        </React.Fragment>
      ))}
      <div className="KollektivTransport__entry__errorMessage">
        {showError && <ErrorMessage feedback={feedback} feedbackMargin={feedbackMargin} />}
      </div>
    </div>
  );
}
