import React from 'react';
import classnames from 'classnames';
import styles from './Panel.module.css';
import Text from '../Text/Text';

interface IPanel {
  heading?: string | React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  type?: 'default' | 'border' | 'blue';
}
const Panel = ({ type = 'default', heading, ...rest }: IPanel) => {
  const classes = classnames(styles[type ?? 'default'], rest.className);
  const panelHeading = heading ? (
    <Text noMargin variant="panel-heading" component="h2">
      {heading}
    </Text>
  ) : (
    ''
  );
  return (
    <div className={classes}>
      {panelHeading}
      {rest.children}
    </div>
  );
};

export default Panel;
