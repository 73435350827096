import TilbudtObjektCard from "components/TilbudtObjektCard/TilbudtObjektCard"
import React from "react"
import { FC } from "react"
import { TilbudtObjektListe } from "types/TilbudtObjektListe"

export interface TilbudtObjektCardListHeadings{
 tentative?: string;
 notEvaluated: string;
 recommended: string; 
 rejected: string; 
 canceled?: string;
}

interface TilbudtObjektCardListProps{
  list: TilbudtObjektListe;
  headings: TilbudtObjektCardListHeadings;
  svarFristUtc?: string; 
}

const TilbudtObjektCardList: FC<TilbudtObjektCardListProps> = ({list, headings, svarFristUtc = null}) => {
    const {NotEvaluated, Recommended, Rejected, Canceled} = list
  
    return (
      
    <div className='TilbudteObjekter'>
      
      {NotEvaluated.length > 0 &&
      <> 
      {svarFristUtc 
      ? <div className="-with-deadline">
         <h2>{headings.notEvaluated}</h2>
         <h2>{svarFristUtc}</h2>
        </div>
      : <h2>{headings.notEvaluated}</h2>
      }
      <ul>
        {NotEvaluated?.map((objekt) => (
          <li>
            <TilbudtObjektCard {...objekt}/>
          </li>
        ))}
      </ul>
      </>
      }
      
      {Recommended.length > 0 &&
      <> 
      <h2>{headings.recommended}</h2>
      <ul>
        {Recommended?.map((objekt) => (
          <li>
            <TilbudtObjektCard {...objekt}/>
          </li>
        ))}
      </ul>
      </>
      }
      
      {Rejected.length > 0 &&
      <>
      <h2>{headings.rejected}</h2>
      <ul>
        {Rejected?.map((objekt) => (
          <li>
            <TilbudtObjektCard {...objekt}/>
          </li>
        ))}
      </ul>
      </>
      }

      {Canceled.length > 0 &&
      <>
      <h2>{headings.canceled}</h2>
      <ul>
        {Canceled?.map((objekt) => (
          <li>
            <TilbudtObjektCard {...objekt}/>
          </li>
        ))}
      </ul>
      </>
      }
    </div>
    )
  }
export default TilbudtObjektCardList