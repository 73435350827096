import React, { FC, useState } from 'react';
import type { LinkItem } from 'types/LinkItem';
import type { DokumentSkjemadel } from 'types/DokumentSkjemadel';
import AdvancedFileSelector from 'components/AdvancedFileSelector/AdvancedFileSelector';
import Accordion from 'components/Accordion/Accordion';
import useArrayState from '@clave/use-array-state';
import { cleanPost, post, upload } from 'js/api-helper';
import UploadedFileLabel from 'components/UploadedFileLabel/UploadedFileLabel';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import Modal from 'components/Modal/Modal';
import RadioBox from 'components/RadioBox/RadioBox';
import DynamicFileList, { SortColumns } from 'components/DynamicFileList/DynamicFileList';
import { filterDocById, findAttachmentById } from 'js/document-filter-helper';
import AnchoredErrorMessage, { ErrorProps } from 'components/AnchoredErrorMessage/AnchoredErrorMessage';

export interface DokumentSkjema {
  readonly sideLenker?: LinkItem[];
  readonly skjemaDeler: DokumentSkjemadel[];
  readonly slettVedleggUrl: string;
  readonly oppdaterFilStatusUrl: string;
  readonly editVedleggUrl: string;
  readonly readonly?: boolean;
}

interface DocumentPayload {
  data: FileList | undefined;
  skjemadel: number;
  prosjektFilType: string;
  skjemaUnderdel: number;
  url: string;
  underDelTittel: string;
}

enum ModalOperation {
  Post = 0,
  Put = 1
}

interface UploadModal {
  isRequired: boolean;
  isOpen: boolean;
  docToBeHandled: DocumentPayload | undefined;
  operation: ModalOperation;
}

const defaultModal: UploadModal = {
  isRequired: true,
  isOpen: false,
  docToBeHandled: undefined,
  operation: ModalOperation.Post
};

const DokumentSkjema: FC<DokumentSkjema> = (props: DokumentSkjema) => {
  const [modal, setModal] = useState<UploadModal>(defaultModal);
  const [error, setError] = useState<ErrorProps | undefined>();

  const [dynamicDocs, setDynamicDocs] = useArrayState<DokumentSkjemadel>(props.skjemaDeler);

  const onDocChange = (doc: DocumentPayload, op: ModalOperation) => {
    setModal((prevState) => ({
      ...prevState,
      docToBeHandled: doc,
      isOpen: true,
      operation: op
    }));
  };

  const handleUploadAttachment = async (args: DocumentPayload) => {
    setModal((prevState) => ({
      ...prevState,
      isOpen: false
    }));

    const fileList = Array.from(args.data ?? []);

    const data = new FormData();
    data.append('prosjektFilType', args.prosjektFilType ?? '');
    data.append('required', modal.isRequired ? 'true' : 'false');
    fileList?.forEach((file) => data.append('file', file));

    try {
      const res = await cleanPost(args.url, data);

      if (res) {
        res.slettbar = true;
        res.required = modal.isRequired;
        const newObj = dynamicDocs[args.skjemadel];
        newObj.underDeler[args.skjemaUnderdel].vedlegg
          ? newObj.underDeler[args.skjemaUnderdel].vedlegg?.push(res)
          : (newObj.underDeler[args.skjemaUnderdel].vedlegg = [res]);

        setDynamicDocs.replaceAt(args.skjemadel, newObj);
      }
    } catch (error) {
      setError({
        msg: error.message,
        targetKey: args.underDelTittel
      });
    }
  };

  const handleEditAttachment = async (doc: any) => {
    try {
      const res = await post(`${doc.url}?filId=${doc.data.id}`, { required: modal.isRequired });
      if (res) {
        const targetDoc = findAttachmentById(dynamicDocs, doc.data.id);
        targetDoc.required = modal.isRequired;
        setModal((prevState) => ({
          ...prevState,
          isOpen: false
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveAttachment = async (id: string) => {
    try {
      await post(`${props.slettVedleggUrl}?filId=${id}`);
      const copy = filterDocById(dynamicDocs, id);
      if (copy) setDynamicDocs(copy);
    } catch (error) {
      console.log(error);
    }
  };

  function defaultSort(p: DokumentSkjemadel, pp: DokumentSkjemadel) {
    return p.posisjon - pp.posisjon;
  }

  return (
    <div className={'DokumentSkjema'}>
      <Modal
        title={'Er opplastning obligatorisk'}
        clickOutsideToClose={true}
        open={modal.isOpen}
        onClose={() =>
          setModal((prevState) => ({
            ...prevState,
            isOpen: false,
            docToBeHandled: undefined
          }))
        }
      >
        <CheckBoxes currentState={modal.isRequired} setModal={setModal} />
        {modal.operation == ModalOperation.Post && (
          <button
            className="submit-btn"
            onClick={() => {
              modal.docToBeHandled ? handleUploadAttachment(modal.docToBeHandled) : void 0;
            }}
          >
            Lagre
          </button>
        )}

        {modal.operation == ModalOperation.Put && (
          <button
            className="submit-btn"
            onClick={() => {
              modal.docToBeHandled ? handleEditAttachment(modal.docToBeHandled) : void 0;
            }}
          >
            Endre
          </button>
        )}
      </Modal>
      {dynamicDocs &&
        dynamicDocs.sort(defaultSort).map((del) => (
          <UnderDelWrapper tittel={del.tittel}>
            {del.underDeler?.sort(defaultSort).map((underDel) => (
              <div className={'Underdel'}>
                <h3>{underDel.tittel}</h3>

                {error?.msg && error?.targetKey == underDel.tittel && <AnchoredErrorMessage {...error} percentageTopPos={5} />}

                {underDel.grunnlagsDokument && (
                  <UploadedFileLabel
                    file={underDel.grunnlagsDokument}
                    icon={<IconReactIcons type={IconTypes.download} size={4} />}
                  />
                )}

                {underDel.lastOppVedleggUrl && (
                  <AdvancedFileSelector
                    onChange={(event) =>
                      onDocChange(
                        {
                          data: event?.target?.files ?? undefined,
                          skjemadel: del.posisjon,
                          skjemaUnderdel: underDel.posisjon,
                          prosjektFilType: underDel.prosjektFilType ?? '/error/',
                          url: underDel.lastOppVedleggUrl ?? '/error/',
                          underDelTittel: underDel.tittel
                        },
                        ModalOperation.Post
                      )
                    }
                    name={underDel.tittel}
                    autoUpload={underDel.automatisk}
                  />
                )}

                {underDel.vedlegg && underDel.vedlegg.length > 0 && (
                  <DynamicFileList
                    oppdaterFilStatusUrl={props.oppdaterFilStatusUrl}
                    readOnly={props.readonly}
                    bottomMargin={true}
                    files={underDel.vedlegg ?? []}
                    columns={[
                      SortColumns.date,
                      SortColumns.document,
                      SortColumns.status,
                      SortColumns.version,
                      SortColumns.required
                    ]}
                    onRemove={(id) => {
                      handleRemoveAttachment(id);
                    }}
                    onEdit={(id) => {
                      onDocChange(
                        {
                          data: findAttachmentById(dynamicDocs, id),
                          skjemadel: del.posisjon,
                          skjemaUnderdel: underDel.posisjon,
                          prosjektFilType: underDel.prosjektFilType ?? '/error/',
                          url: props.editVedleggUrl,
                          underDelTittel: underDel.tittel
                        },
                        ModalOperation.Put
                      );
                    }}
                  />
                )}
              </div>
            ))}
          </UnderDelWrapper>
        ))}
    </div>
  );
};

const UnderDelWrapper = (props: { tittel?: string; children: React.ReactNode[] }) => {
  if (!props.tittel) return <section>{props.children}</section>;
  return (
    <Accordion title={props.tittel} defaultOpen={true}>
      {props.children}
    </Accordion>
  );
};

const CheckBoxes = (props) => {
  return (
    <>
      <RadioBox
        disabled={false}
        className="Evaluation-checkbox font-paragraph"
        name={'Ja'}
        label={'Ja'}
        checked={props.currentState == true}
        onChange={() =>
          props.setModal((prevState) => ({
            ...prevState,
            isRequired: true
          }))
        }
      />

      <RadioBox
        disabled={false}
        className="Evaluation-checkbox font-paragraph"
        name={'Nei'}
        label={'Nei'}
        checked={props.currentState == false}
        onChange={() =>
          props.setModal((prevState) => ({
            ...prevState,
            isRequired: false
          }))
        }
      />
    </>
  );
};
export default DokumentSkjema;
