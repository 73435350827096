import React from 'react';
import Text from 'components/Text/Text';

export interface Props {
  readonly value: string;
  readonly name: string;
  readonly currentValue?: string;
  readonly onChange?: (v: string) => void;
}

export default function RadioButton({ value, name, currentValue, onChange }: Props) {
  return (
    <Text htmlFor={name} variant="mono-16" component="label">
      <input
        style={{ marginRight: 5 }}
        id={name}
        type="radio"
        value={value}
        name={name}
        checked={currentValue === value}
        onChange={(e) => onChange?.(e.target.value)}
      />
      {value}
    </Text>
  );
}
