import React, { FC, useState } from 'react';
import style from './BefaringKalkulator.module.css';
import cn from 'classnames';
export interface IBefaringKalkulator {
  ledigAreal: number;
  kvmPris: number;
  mvaKompensasjonKvm: number;
  felleskostKvm: number;
  estimertTotalpris: number;
  totalLeieprisIdag: number;
  readOnly?: boolean;
  handleBefaringChange: (id: string, value: string) => void;
}

const makeNumber = (v) =>
  typeof v === 'string' ? Number(v.replace(/\D/g, '')) : v;

const BefaringKalkulator: FC<IBefaringKalkulator> = ({
  ledigAreal,
  handleBefaringChange,
  kvmPris,
  felleskostKvm,
  mvaKompensasjonKvm,
  totalLeieprisIdag,
  readOnly
}) => {
  const calculateEstimertTotalpris = (ar, mvaK, kvmP, fk) =>
    makeNumber(ar) * makeNumber(kvmP) + makeNumber(mvaK) + makeNumber(fk);

  const [calculator, setCalculator] = useState({
    ledigAreal: ledigAreal,
    kvmPris: kvmPris,
    mvaKompensasjonKvm: mvaKompensasjonKvm,
    felleskostKvm: felleskostKvm,
    estimertTotalpris: calculateEstimertTotalpris(
      ledigAreal,
      mvaKompensasjonKvm,
      kvmPris,
      felleskostKvm
    ),
    totalLeieprisIdag: totalLeieprisIdag
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    const val = makeNumber(value);
    const ar = id === 'ledigAreal' ? val : calculator.ledigAreal;
    const kvmP = id === 'kvmPris' ? val : calculator.kvmPris;
    const mvaK =
      id === 'mvaKompensasjonKvm' ? val : calculator.mvaKompensasjonKvm;
    const fk = id === 'felleskostKvm' ? val : calculator.felleskostKvm;
    const estimertTotalpris = calculateEstimertTotalpris(ar, mvaK, kvmP, fk);
    setCalculator((c) => ({ ...c, [id]: val, estimertTotalpris }));
    handleBefaringChange(id, val);
  };

  const renderInputOrText = ({ id, value, inputDisabled }) => {
    if (readOnly) {
      return (
        <span className={style.readOnlyText}>
          {value.toLocaleString().replace(/,/g, ' ')}
        </span>
      );
    } else
      return (
        <input
          onChange={handleChange}
          disabled={inputDisabled}
          id={id}
          value={calculator[id].toLocaleString().replace(/,/g, ' ')}
        />
      );
  };
  return (
    <div className={style.kalkulatorWrapper}>
      <div className={style.kalkulatorContainer}>
        <h2 style={{ margin: 0 }}>Kalkulator</h2>
        <form className={style.kalkulatorItemsWrapper}>
          <div className={style.kalkulatorRow}>
            <div
              className={cn(
                style.kalkulatorItemContainer,
                style.kalkulatorItemContainerSmall
              )}
            >
              <label htmlFor="ledigAreal">Areal (kvm)</label>
              {renderInputOrText({
                id: 'ledigAreal',
                value: calculator.ledigAreal,
                inputDisabled: false
              })}
            </div>

            <div
              className={cn(
                style.kalkulatorItemContainer,
                style.kalkulatorItemContainerSmall
              )}
            >
              {' '}
              <label htmlFor={'kvmPris'}>KVM-pris inkl. tiltak</label>
              {renderInputOrText({
                id: 'kvmPris',
                value: calculator.kvmPris,
                inputDisabled: false
              })}
            </div>

            <div
              className={cn(
                style.kalkulatorItemContainer,
                style.kalkulatorItemContainerSmall
              )}
            >
              {' '}
              <label htmlFor={'mvaKompensasjonKvm'}>MVA kompensasjon</label>
              {renderInputOrText({
                id: 'mvaKompensasjonKvm',
                value: calculator.mvaKompensasjonKvm,
                inputDisabled: false
              })}
            </div>

            <div
              className={cn(
                style.kalkulatorItemContainer,
                style.kalkulatorItemContainerSmall
              )}
            >
              {' '}
              <label htmlFor={'felleskostKvm'}>Felleskostnader</label>
              {renderInputOrText({
                id: 'felleskostKvm',
                value: calculator.felleskostKvm,
                inputDisabled: false
              })}
            </div>
          </div>
          <div className={style.kalkulatorRow}>
            <div
              className={cn(
                style.kalkulatorItemContainer,
                style.kalkulatorItemContainerLarge
              )}
            >
              {' '}
              <label htmlFor={'estimertTotalpris'}>Estimert totalpris</label>
              {renderInputOrText({
                id: 'estimertTotalpris',
                value: calculator.estimertTotalpris,
                inputDisabled: true
              })}
            </div>

            <div
              className={cn(
                style.kalkulatorItemContainer,
                style.kalkulatorItemContainerLarge
              )}
            >
              {' '}
              <label htmlFor={'totalLeieprisIdag'}>Total leiepris i dag</label>
              {renderInputOrText({
                id: 'totalLeieprisIdag',
                value: calculator.totalLeieprisIdag,
                inputDisabled: true
              })}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default BefaringKalkulator;
