import { Dispatch, SetStateAction, useRef, useState } from 'react';

export default function useBooleanState(
  initial: boolean | (() => boolean)
): [boolean, Dispatch<SetStateAction<boolean>>, () => void] {
  const [state, setState] = useState<boolean>(initial);

  const toggleState = useRef(() => setState((x) => !x));

  return [state, setState, toggleState.current];
}
