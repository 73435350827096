import React, { FC, useState } from 'react';
import style from './BefaringKommentarBoks.module.css';
import { BsPencil } from 'react-icons/bs';
import IconButton from 'components/IconButton/IconButton';
import { AiFillQuestionCircle } from 'react-icons/ai';
import Popup from 'components/Popup/Popup';
import Button from 'components/Button/Button';
import Panel from 'components/Panel/Panel';
import { BefaringKommentar } from 'types/BefaringKommentar';

export interface IBefaringKommentarBoks extends BefaringKommentar {
  onChange?: () => void;
  readOnly?: boolean;
  maxCharacters?: number;
  handleBefaringKommentarVerdiChange: (id: string, value: string) => void;
  handleBefaringVerdiChange: (id: string, value: string) => void;
}
const BefaringKommentarBoks: FC<IBefaringKommentarBoks> = ({
  tittel,
  id,
  verdi,
  kommentarVerdi,
  hjelpetekst,
  maxCharacters,
  handleBefaringKommentarVerdiChange,
  handleBefaringVerdiChange,
  readOnly
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isEditingComment, setIsEditingComment] = useState(false);
  const [updatedCommentValue, setUpdatedCommentValue] = useState(kommentarVerdi);
  const [currentValue, setCurrentValue] = useState(verdi);

  const handleParentChangeOnBlur = () => {
    handleBefaringVerdiChange(id, currentValue);
  };
  const handleSaveComment = () => {
    handleBefaringKommentarVerdiChange(id, updatedCommentValue);
  };

  const handleChange = (e) => {
    setCurrentValue(e.target.value);
  };

  const handleCommentChange = (e) => {
    setUpdatedCommentValue(e.target.value);
  };

  const closePopup = () => {
    setIsEditingComment(false);
  };
  const saveAndClosePopup = () => {
    handleSaveComment();
    setIsEditingComment(false);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.leftSideContainer}>
        <label className={style.labelTitle} htmlFor={id}>
          {tittel}
        </label>
        <div className={style.editButtonContainer}>
          {!readOnly && (
            <button className={style.editButton} onClick={() => setIsEditingComment(!isEditingComment)}>
              <BsPencil />
            </button>
          )}
          <span className={style.commentText}>
            {updatedCommentValue?.length ? (
              updatedCommentValue
            ) : readOnly ? null : (
              <span className={style.emptyAddComment}>Legg til kommentar</span>
            )}
          </span>
        </div>
      </div>

      <div className={style.inputContainer}>
        {readOnly ? (
          currentValue ? (
            <div className={style.input}>{currentValue}</div>
          ) : (
            <p className={style.emptyNote}>Ingen notater</p>
          )
        ) : (
          <>
            <p className={style.printInput}>{currentValue}</p>
            <textarea
              maxLength={maxCharacters}
              placeholder="Fyll ut her"
              className={style.input}
              value={currentValue}
              id={id}
              onBlur={handleParentChangeOnBlur}
              onChange={handleChange}
            />
            <div
              style={{
                marginLeft: 'auto',
                fontSize: 12,
                color: '#ABABAB',
                textAlign: 'right'
              }}
            >
              {currentValue?.length ?? 0}/{maxCharacters}
            </div>
          </>
        )}
      </div>
      <div className={style.tooltipContainer}>
        {Boolean(hjelpetekst?.length) && (
          <IconButton onClick={() => setShowTooltip(!showTooltip)} type="noBorder" title="Se info">
            <AiFillQuestionCircle />
          </IconButton>
        )}
      </div>
      <Popup isOpen={showTooltip} close={() => setShowTooltip(false)} clickOutsideToClose>
        <Panel>
          <div style={{ width: 500 }}>
            <h3 className={style.labelTitle}>{tittel}</h3>
            {hjelpetekst?.length && (
              <ul className={style.helptextList}>
                {hjelpetekst.map((text) => (
                  <li className={style.helptextItem} key={text}>
                    {text}
                  </li>
                ))}
              </ul>
            )}
            <div className={style.popupActionContainer}>
              <Button onClick={() => setShowTooltip(false)}>Lukk hjelpetekst</Button>
            </div>
          </div>
        </Panel>
      </Popup>

      {!readOnly && (
        <Popup isOpen={isEditingComment} close={() => setIsEditingComment(false)} clickOutsideToClose>
          <Panel>
            <label className={style.labelTitle} htmlFor={id}>
              {tittel}
            </label>

            <textarea
              placeholder="Skriv en kommentar"
              className={style.commentInput}
              id={id}
              value={updatedCommentValue}
              onChange={handleCommentChange}
            />
            <div className={style.popupActionContainer}>
              <Button onClick={saveAndClosePopup}>Lagre kommentar</Button>
              <Button onClick={closePopup}>Avbryt</Button>
            </div>
          </Panel>
        </Popup>
      )}
    </div>
  );
};
export default BefaringKommentarBoks;
