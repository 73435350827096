import React, { useEffect, useState } from 'react';
import { IArcGisKart } from './ArcGis';
import ArcGisKart from './ArcGisKart';
import { useLoadModules } from './useLoadLib';

const ArcGisLoader = (props: IArcGisKart) => {
  const [isMountedOnClient, setIsMountedOnClient] = useState(false);
  const modules = useLoadModules();

  useEffect(() => {
    setIsMountedOnClient(true);
  }, []);
  if (isMountedOnClient && modules) {
    return <ArcGisKart {...props} lib={{ ...modules }} />;
  } else return <div style={{ width: '100%', height: '100%' }} />;
};

export default ArcGisLoader;
