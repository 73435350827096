import React from 'react';
import classnames from 'classnames';
import styles from './Button.module.css';

interface IButton {
  secondary?: boolean;
  textButton?: boolean;
  disabled?: boolean;
  borderless?: boolean;
  minWidth?: boolean;
  submit?: boolean;
  active?: boolean;
  children?: React.ReactNode;
  className?: string;
  icon?: boolean;
  type?: 'submit' | 'button' | 'reset' | undefined;
  loading?: boolean;
  title?: string;
}

const Button = ({
  secondary,
  textButton,
  disabled,
  borderless,
  minWidth,
  submit,
  active,
  className,
  icon,
  children,
  loading,
  title,
  ...props
}: IButton) => {
  const classes = classnames(styles.button, className, {
    [styles.primary]: !secondary && !textButton,
    [styles.secondary]: secondary,
    [styles.textButton]: textButton,
    [styles.disabled]: disabled,
    [styles.borderless]: borderless,
    [styles.minWidth]: minWidth,
    [styles.active]: active,
    [styles.icon]: icon,
    [styles.loading]: loading
  });

  const type = props.type || (submit ? 'submit' : 'button');

  // *** To be added:
  // Unless the disabled state is explicitly set, the button is disabled when loading.
  // const disabled = (props.disabled !== undefined ? props.disabled : loading) || false;
  return (
    <button
      className={classes}
      type={type}
      title={loading ? 'Jobber...' : title}
      disabled={disabled ?? loading}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
