import React, { forwardRef, LegacyRef } from 'react';
import CustomIconButton from './CustomIconButton';
/* eslint-disable @typescript-eslint/no-explicit-any */

type ArcGisLayerToolbarProps = {
  layers: {
    icon: JSX.Element | string | null;
    layer: any;
    title: string;
  }[];
};
const ArcGisLayerToolbar = forwardRef(
  ({ layers }: ArcGisLayerToolbarProps, ref: LegacyRef<HTMLDivElement>) => {
    return (
      <div ref={ref} className={'esri-custom-toolbar'}>
        <div className={'esri-custom-panel'}>
          <div className={'esri-custom-section'}>
            {layers.map(({ icon, layer, title }) => (
              <CustomIconButton
                title={title}
                key={layer.id}
                onClick={() => {
                  layer.visible = !layer.visible;
                }}
                isToggled={layer?.visible}
              >
                {icon}
              </CustomIconButton>
            ))}
          </div>
        </div>
      </div>
    );
  }
);

ArcGisLayerToolbar.displayName = 'ArcGisLayerToolbar';

export default ArcGisLayerToolbar;
