import mapMarkerBadUrl from '../../assets/svg/map-marker-bad.svg';
import mapMarkerGoodUrl from '../../assets/svg/map-marker-good.svg';
import mapMarkerMediumUrl from '../../assets/svg/map-marker-medium.svg';
import mapMarkerNoneUrl from '../../assets/svg/map-marker-none.svg';
import walkSvg from '../../assets/svg/Walk_icon.svg';
import driveSvg from '../../assets/svg/Drive_icon.svg';

export const serviceAreaColors = {
  orange: 'rgba(227, 139, 79, 0.4)',
  green: 'rgba(139,227,  79, 0.4)',
  blue: 'rgba(139, 79, 227, 0.4)'
};

export const getWalkDriveIcon = (type: number) => {
  if (type === 1) {
    return driveSvg;
  } else if (type === 3) {
    return walkSvg;
  } else return '';
};

export const getIconUrl = (status: string): string => {
  switch (status) {
    case 'none': {
      return mapMarkerNoneUrl;
    }
    case 'bad': {
      return mapMarkerBadUrl;
    }
    case 'medium': {
      return mapMarkerMediumUrl;
    }
    case 'good': {
      return mapMarkerGoodUrl;
    }
    default: {
      return mapMarkerNoneUrl;
    }
  }
};
export function getMarker(v: number | undefined): string {
  switch (v) {
    case 1:
      return 'bad';
    case 2:
      return 'medium';
    case 3:
      return 'good';
    default:
      return 'none';
  }
}

export const handleTakeScreenshot = async (view) => {
  if (view) {
    const data = await view.takeScreenshot();
    // img.src = data.dataUrl;
    // img.alt = 'Screenshot';
    const anchor = document.createElement('a');
    anchor.href = data.dataUrl;
    anchor.target = '_blank';
    anchor.download =
      'Screenshot-' + Math.floor(Math.random() * Math.floor(9999));
    anchor.click();
    // const parent = document.getElementById('screenshots');
    // parent?.appendChild(img);
  }
};

// const walkTimeArcgisDotCom = {
//   attributeParameterValues: [
//     {
//       attributeName: 'Avoid Private Roads',
//       parameterName: 'Restriction Usage',
//       value: 'AVOID_MEDIUM'
//     },
//     {
//       attributeName: 'Walking',
//       parameterName: 'Restriction Usage',
//       value: 'PROHIBITED'
//     },
//     {
//       attributeName: 'Preferred for Pedestrians',
//       parameterName: 'Restriction Usage',
//       value: 'PREFER_LOW'
//     },
//     {
//       attributeName: 'WalkTime',
//       parameterName: 'Walking Speed (km/h)',
//       value: 5
//     },
//     {
//       attributeName: 'Avoid Roads Unsuitable for Pedestrians',
//       parameterName: 'Restriction Usage',
//       value: 'AVOID_HIGH'
//     }
//   ],
//   description:
//     'Follows paths and roads that allow pedestrian traffic and finds solutions that optimize travel time. The walking speed is set to 5 kilometers per hour.',
//   distanceAttributeName: 'Kilometers',
//   id: 'caFAgoThrvUpkFBW',
//   impedanceAttributeName: 'WalkTime',
//   name: 'Walking Time',
//   restrictionAttributeNames: [
//     'Avoid Private Roads',
//     'Avoid Roads Unsuitable for Pedestrians',
//     'Preferred for Pedestrians',
//     'Walking'
//   ],
//   simplificationTolerance: 2,
//   simplificationToleranceUnits: 'esriMeters',
//   timeAttributeName: 'WalkTime',
//   type: 'WALK',
//   useHierarchy: false,
//   uturnAtJunctions: 'esriNFSBAllowBacktrack'
// };

// export const fetchWalkTime = async () => {
//     try {
//       const res = await fetch(
//         'https://route.arcgis.com/arcgis/rest/services/World/Route/NAServer/Route_World/retrieveTravelModes?f=json&token=' +
//           config.apiKey
//       )
//         .then((res) => res.json())
//         .then((data) => {
//           console.log(data);
//           const travelModeWalk = data?.supportedTravelModes?.filter(
//             (travelModes) => travelModes?.impedanceAttributeName === 'WalkTime'
//           )[0];
//           console.log(travelModeWalk);
//           if (!travelModeWalk) throw new Error(data);
//           return travelModeWalk;
//         })
//         .then((mode) => JSON.stringify(mode)); // requires stringified
//       return res;
//     } catch (err) {
//       console.log(err);
//       throw err;
//     }
//   };
