/* eslint-disable react/display-name */
import Paragraphs from 'components/Paragraphs/Paragraphs';
import Text from 'components/Text/Text';
import React from 'react';

export const SvarDetaljHeaderValue = ({ header, value }) => {
  if (!value) return null;
  return (
    <>
      <Text variant="annonse-grid-title" component="h2">
        {header}
      </Text>
      <Paragraphs textVariant="paragraph">{value}</Paragraphs>
    </>
  );
};
