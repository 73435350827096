import React from 'react';
import styles from './Label.module.css';
import Text from '../Text/Text';

interface ILabel {
  id?: string;
  custom?: boolean;
  label?: string;
  children?: React.ReactNode;
}
/**
 * Label component
 */
const Label = ({ children, ...props }: ILabel) => (
  <Text
    variant="table-header"
    component="label"
    className={styles.label}
    {...props}
  >
    {children}
  </Text>
);

export default Label;
