import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import KollektivTransport from 'components/KollektivTransport/KollektivTransport';
import LabeledField from 'components/LabeledField/LabeledField';
import NumberField from 'components/NumberField/NumberField';
import NumberPicker from 'components/NumberPicker/NumberPicker';
import SelectField from 'components/SelectField/SelectField';
import TextField from 'components/TextField/TextField';
import UploadField from 'components/UploadField/UploadField';
import UploadMultipleField from 'components/UploadMultipleField/UploadMultipleField';
import { parseISO8601String as parseDate } from 'js/date-helper';
import { usePostback } from 'js/hooks';
import React, { FC, useMemo, useState } from 'react';
import Panel from 'components/Panel/Panel';
import { Grunnlagsdata } from 'types/Grunnlagsdata';
import { Prosjekt } from 'types/Prosjekt';
import ProsjektRedigerContainer from '../ProsjektRedigerContainer';
import { mapFylker, mapKommuner, mapPersoner } from '../utils';
import styles from './GrunnlagsdataRedigerSide.module.css';
import { toCamelCase, toFile } from 'js/upload-files';

export interface GrunnlagsdataRedigerSide {
  readonly headerText: string;
  readonly prosjektData: Prosjekt;
  readonly grunnlagsdata: Grunnlagsdata;
  readonly fylkeListe: {
    readonly id: string;
    readonly navn: string;
  }[];
  readonly kommuneListe: {
    readonly id: string;
    readonly fylkeId: string;
    readonly navn: string;
  }[];
  readonly kontaktpersonListe: {
    readonly id: string;
    readonly fulltNavn: string;
  }[];
  readonly kollektivTransportTyperListe: string[];
  readonly grunnlagsdataLagreApi: string;
  readonly lastOppEkstraDokumentUrl: string;
  readonly lastOppKartSokeomradeBildeUrl: string;
  readonly lastOppKartSokeomradeDokumentUrl: string;
  readonly lastOppKundelogoUrl: string;
  readonly slettFilUrl: string;
  readonly avsluttUrl: string;
  readonly forrigeUrl: string;
  readonly nesteUrl: string;
  readonly deleteUrl?: string;
  readonly slettProsjektBekreftTekst: string;
  readonly archiveProsjektUrl?: string;
}

const GrunnlagsdataRedigerSide: FC<GrunnlagsdataRedigerSide> = ({
  headerText,
  grunnlagsdata: data,
  kollektivTransportTyperListe,
  kontaktpersonListe,
  fylkeListe,
  kommuneListe,
  lastOppEkstraDokumentUrl,
  lastOppKartSokeomradeBildeUrl,
  lastOppKartSokeomradeDokumentUrl,
  lastOppKundelogoUrl,
  slettFilUrl,
  grunnlagsdataLagreApi,
  avsluttUrl,
  forrigeUrl,
  nesteUrl,
  deleteUrl,
  slettProsjektBekreftTekst,
  archiveProsjektUrl
}) => {
  const [fylke, setFylke] = useState(data.fylke);
  const [kommune, setKommune] = useState(data.kommune);
  const [onsketOvertakelseFraUtc, setOnsketOvertakelseFraUtc] = useState(() => parseDate(data.onsketOvertakelseFraUtc));
  const [onsketOvertakelseTilUtc, setOnsketOvertakelseTilUtc] = useState(() => parseDate(data.onsketOvertakelseTilUtc));
  const [arealFra, setArealFra] = useState(data.arealFra || undefined);
  const [arealTil, setArealTil] = useState(data.arealTil || undefined);
  const [onsketLeieperiode, setOnsketLeieperiode] = useState(data.onsketLeieperiode || undefined);
  const [kontaktperson, setKontaktperson] = useState(data.kontaktperson);

  const [fraPrisKvmMedTiltak, setFraPrisKvmMedTiltak] = useState(data.fraPrisKvmMedTiltak || undefined);
  const [tilPrisKvmMedTiltak, setTilPrisKvmMedTiltak] = useState(data.tilPrisKvmMedTiltak || undefined);
  const [fraPrisKvmUtenTiltak, setFraPrisKvmUtenTiltak] = useState(data.fraPrisKvmUtenTiltak || undefined);
  const [tilPrisKvmUtenTiltak, setTilPrisKvmUtenTiltak] = useState(data.tilPrisKvmUtenTiltak || undefined);
  const [felleskostKvm, setFelleskostKvm] = useState(data.felleskostKvm || undefined);
  const [sykkelplasskost, setSykkelplasskost] = useState(data.sykkelplasskost || undefined);
  const [parkeringsplasskost, setParkeringsplasskost] = useState(data.parkeringsplasskost || undefined);
  const [kostKantineBidrag, setKostKantineBidrag] = useState(data.kostKantineBidrag || undefined);

  const [avstandKollektivtransport, setAvstandKollektivTransport] = useState(
    data.avstandKollektivTransport ? data.avstandKollektivTransport : []
  );

  const [sarskilteTiltak, setSarskilteTiltak] = useState(data.sarskilteTiltak);

  const [sykkelparkeringsplasser, setSykkelparkeringsplasser] = useState(data.sykkelparkeringsplasser || undefined);
  const [parkeringsplasser, setParkeringsplasser] = useState(data.parkeringsplasser || undefined);
  const [ladepunkter, setLadepunkter] = useState(data.ladepunkter || undefined);

  const prosjektledere = useMemo(() => mapPersoner(kontaktpersonListe), []);
  const fylker = useMemo(() => mapFylker(fylkeListe), []);
  const kommuner = useMemo(() => mapKommuner(fylke, kommuneListe), [fylke]);

  const { postback, isDirty, isBusy, validation } = usePostback(grunnlagsdataLagreApi, {
    fylke,
    kommune,
    onsketOvertakelseFraUtc,
    onsketOvertakelseTilUtc,
    arealFra,
    arealTil,
    onsketLeieperiode,
    kontaktperson,

    fraPrisKvmMedTiltak,
    tilPrisKvmMedTiltak,
    fraPrisKvmUtenTiltak,
    tilPrisKvmUtenTiltak,
    felleskostKvm,
    sykkelplasskost,
    parkeringsplasskost,
    kostKantineBidrag,

    sarskilteTiltak,
    avstandKollektivtransport,

    sykkelparkeringsplasser,
    parkeringsplasser,
    ladepunkter
  });

  return (
    <ProsjektRedigerContainer
      headerText={headerText}
      isDirty={isDirty}
      isBusy={isBusy}
      currentStep={2}
      quitUrl={avsluttUrl}
      nextPageUrl={nesteUrl}
      previousPageUrl={forrigeUrl}
      save={postback}
      slettProsjektBekreftTekst={slettProsjektBekreftTekst}
      deleteUrl={deleteUrl}
      archiveProsjektUrl={archiveProsjektUrl}
    >
      <div className={styles.GrunnlagsdataRedigerSide}>
        <Panel heading="Generell informasjon" className={styles.GenerellArea}>
          <SelectField
            label="Fylke*"
            choices={fylker}
            selectedValue={fylker.find((p) => p.value === fylke)}
            onChange={({ value }) => setFylke(value)}
            {...validation.fylke}
          />

          <SelectField
            label="Kommune*"
            choices={kommuner}
            selectedValue={kommuner.find((p) => p.value === kommune)}
            onChange={({ value }) => setKommune(value)}
            {...validation.kommune}
          />

          <DateRangePicker
            label="Ønsket overtakelse*"
            fromDateInitialValue={onsketOvertakelseFraUtc}
            toDateInitialValue={onsketOvertakelseTilUtc}
            onFromDateChanged={setOnsketOvertakelseFraUtc}
            onToDateChanged={setOnsketOvertakelseTilUtc}
            toDateValidation={validation.onsketOvertakelseTilUtc}
            fromDateValidation={validation.onsketOvertakelseFraUtc}
          />

          <LabeledField label="Areal*">
            <NumberField
              placeholder="Fra"
              suffix="kvm"
              type="number"
              min="0"
              value={arealFra}
              onChange={setArealFra}
              {...validation.arealFra}
            />
            <NumberField
              placeholder="Til"
              suffix="kvm"
              type="number"
              min="0"
              value={arealTil}
              onChange={setArealTil}
              {...validation.arealTil}
            />
          </LabeledField>

          <NumberPicker
            label="Ønsket leieperiode*"
            unit="år"
            selectedValue={onsketLeieperiode}
            onChange={({ value }) => setOnsketLeieperiode(value)}
            {...validation.onsketLeieperiode}
          />
          <SelectField
            label="Kontaktperson*"
            choices={prosjektledere}
            selectedValue={prosjektledere.find((p) => p.value === kontaktperson)}
            onChange={(e) => setKontaktperson(e.value)}
            {...validation.kontaktperson}
          />
        </Panel>

        <Panel heading="Filer" className={styles.FilerArea}>
          <LabeledField vertical label="Legg til kart over søkeområde">
            <UploadField
              initialFile={toFile(data.kartSokeomradeBilde)}
              fileType="image"
              destinationUrl={lastOppKartSokeomradeBildeUrl}
              deleteUrl={slettFilUrl}
            />
            <UploadField
              initialFile={toFile(data.kartSokeomradeDokument)}
              fileType="document"
              destinationUrl={lastOppKartSokeomradeDokumentUrl}
              deleteUrl={slettFilUrl}
            />
          </LabeledField>

          <LabeledField label="Ekstra Dokumenter">
            <UploadMultipleField
              initialFiles={data.ekstraDokumenter?.map(toCamelCase) ?? []}
              destinationUrl={lastOppEkstraDokumentUrl}
              deleteUrl={slettFilUrl}
              fileType="document"
            />
          </LabeledField>

          <LabeledField label="Legg til kundens logo">
            <UploadField
              fileType="image"
              destinationUrl={lastOppKundelogoUrl}
              deleteUrl={slettFilUrl}
              initialFile={toFile(data.kundelogo)}
            />
          </LabeledField>
        </Panel>
        <Panel heading="Pris kriterier" className={styles.PrisArea}>
          <LabeledField label="Pris pr.kvm BTA med planlagte tiltak*">
            <NumberField
              placeholder="Fra"
              suffix="kr"
              type="number"
              min="0"
              value={fraPrisKvmMedTiltak}
              onChange={setFraPrisKvmMedTiltak}
              {...validation.fraPrisKvmMedTiltak}
            />
            <NumberField
              placeholder="Til"
              suffix="kr"
              type="number"
              min="0"
              value={tilPrisKvmMedTiltak}
              onChange={setTilPrisKvmMedTiltak}
              {...validation.tilPrisKvmMedTiltak}
            />
          </LabeledField>

          <LabeledField label="Pris pr.kvm BTA uten planlagte tiltak*">
            <NumberField
              placeholder="Fra"
              suffix="kr"
              type="number"
              min="0"
              value={fraPrisKvmUtenTiltak}
              onChange={setFraPrisKvmUtenTiltak}
              {...validation.fraPrisKvmUtenTiltak}
            />
            <NumberField
              placeholder="Til"
              suffix="kr"
              type="number"
              min="0"
              value={tilPrisKvmUtenTiltak}
              onChange={setTilPrisKvmUtenTiltak}
              {...validation.tilPrisKvmUtenTiltak}
            />
          </LabeledField>

          <NumberField
            label="Felleskost pr. kvm BTA"
            suffix="kr"
            type="number"
            min="0"
            value={felleskostKvm}
            onChange={setFelleskostKvm}
            {...validation.felleskostKvm}
          />

          <NumberField
            label="Kost pr. sykkelplass"
            suffix="kr"
            type="number"
            min="0"
            value={sykkelplasskost}
            onChange={setSykkelplasskost}
            {...validation.sykkelplasskost}
          />

          <NumberField
            label="Kost pr. parkeringsplass"
            suffix="kr"
            type="number"
            min="0"
            value={parkeringsplasskost}
            onChange={setParkeringsplasskost}
            {...validation.parkeringsplasskost}
          />

          <NumberField
            label="Kost pr. ansatt i kantinebidrag"
            suffix="kr"
            type="number"
            min="0"
            value={kostKantineBidrag}
            onChange={setKostKantineBidrag}
            {...validation.kostKantineBidrag}
          />
        </Panel>

        <Panel heading="Annet" className={styles.AnnetArea}>
          <TextField
            label="Eventuelle særskilte krav"
            placeholder="Skriv her"
            type="textarea"
            maxCharacters={350}
            value={sarskilteTiltak}
            onChange={(e) => setSarskilteTiltak(e.target.value)}
            {...validation.sarskilteTiltak}
          />
        </Panel>

        <Panel heading="Transport" className={styles.TransportArea}>
          <KollektivTransport
            typerKollektivtransport={kollektivTransportTyperListe}
            avstandKollektivtransport={avstandKollektivtransport}
            onChange={setAvstandKollektivTransport}
          />
          <NumberField
            label="Sykkelparkeringsplasser"
            suffix="stk"
            placeholder="Antall"
            type="number"
            min="0"
            value={sykkelparkeringsplasser}
            onChange={(e) => setSykkelparkeringsplasser(e)}
            {...validation.sykkelparkeringsplasser}
          />
          <NumberField
            label="Parkeringsplasser"
            suffix="stk"
            placeholder="Antall"
            type="number"
            value={parkeringsplasser}
            min="0"
            onChange={(e) => setParkeringsplasser(e)}
            {...validation.parkeringsplasser}
          />
          <NumberField
            label="Ladepunkter"
            suffix="stk"
            placeholder="Antall"
            value={ladepunkter}
            min="0"
            onChange={(e) => setLadepunkter(e)}
            {...validation.ladepunkter}
          />
        </Panel>
      </div>
    </ProsjektRedigerContainer>
  );
};

export default GrunnlagsdataRedigerSide;
