export function dateTimeString(utcString: string) {
  if (!utcString) return 'Ugyldig datoformat';
  return new Date(Date.parse(utcString)).toLocaleTimeString('nb-NO', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
}

export function dateString(utcString: string) {
  if (!utcString) return 'Ugyldig datoformat';
  return new Date(Date.parse(utcString)).toLocaleDateString('nb-NO', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}

export function shortDateString(
  utcString?: string,
  fallback = 'Ugyldig datoformat'
) {
  if (!utcString) return fallback;
  return new Date(Date.parse(utcString)).toLocaleDateString('nb-NO', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
}

export function shortDateTimeString(
  utcString?: string,
  fallback = 'Ugyldig datoformat'
) {
  if (!utcString) return fallback;
  return new Date(Date.parse(utcString)).toLocaleDateString('nb-NO', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });
}

export function parseISO8601String(utcString: string) {
  if (!utcString) return undefined;
  return new Date(Date.parse(utcString));
}

export function dateToISOString(dateObject: Date) {
  return dateObject.toISOString();
}