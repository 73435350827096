import React from 'react';
import { BsCalendar } from 'react-icons/bs';
import Input from 'components/Input/Input';

export interface Props {
  readonly placeholder?: string;
  readonly validationState?: null | 'error';
  onClick?: () => void;
  onFocus?: () => void;
  readonly value?: string;
}

export default function CustomDateRangePickerInput({ validationState, placeholder, value, onClick, onFocus }: Props) {
  return (
    <div className="DateRangePicker__InputField">
      <BsCalendar />
      <Input
        onFocus={onFocus}
        validationState={validationState}
        type="text"
        value={value}
        onClick={onClick}
        readOnly
        placeholder={placeholder ?? 'Klikk her'}
      />
    </div>
  );
}
