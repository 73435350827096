import InfoLabel from 'components/InfoLabel/InfoLabel';
import { useOnChange } from 'js/hooks';
import React, { ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Input from 'components/Input/Input';

export interface Props {
  readonly label?: string;
  readonly tooltip?: ReactNode;
  readonly suffix: string;
  readonly placeholder?: string;
  readonly min?: string | number;
  readonly value?: number;
  readonly onChange?: (vaule: number) => void;
  readonly feedback?: string;
  readonly validationState?: 'error' | null;
  readonly feedbackMargin?: boolean;
}

export default function NumberField({
  label,
  tooltip,
  value: initialValue,
  feedback,
  feedbackMargin,
  suffix,
  onChange,
  ...props
}: Props) {
  const [value, setValue] = useState(initialValue === undefined ? undefined : String(initialValue));

  const update = useCallback((e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value), []);

  useEffect(() => {
    setValue(initialValue === undefined ? undefined : String(initialValue));
  }, [initialValue]);

  useOnChange((v) => onChange?.(Number(v)), [value]);

  return (
    <div className="NumberField">
      <InfoLabel label={label} tooltip={tooltip} />

      <div className="NumberInput">
        <Input type="number" onChange={update} onWheel={blockScrollChangingValue} value={value} {...props} />
        {value !== undefined && value !== '' && <span data-value={value}>{suffix}</span>}
      </div>
      {feedback && props.validationState === 'error' && <ErrorMessage feedback={feedback} feedbackMargin={feedbackMargin} />}
    </div>
  );
}

function blockScrollChangingValue(e: React.WheelEvent<HTMLInputElement>) {
  // Firefox check
  if ('InstallTrigger' in window) {
    e.currentTarget.blur();
  }
}
