import { useMemo, useState } from 'react';
import { spatialReferenceWGS84 } from '../ArcGisKart';
import { getWalkDriveIcon } from '../arcGis.helper';

export type TravelModeStateType = {
  type?: number;
  walkMeters?: number;
  driveMinutes?: number;
};

export const useServiceArea = (lib) => {
  const {
    Point,
    Graphic,
    ServiceAreaParameters,
    ServiceAreaTask,
    FeatureSet,
    Color,
    GraphicsLayer
  } = lib;
  type UndoRedoListType = {
    layer: typeof GraphicsLayer;
    allServiceAreaGraphicsList: typeof Graphic[];
  };
  const serviceAreaTask = useMemo(() => {
    if (!sessionStorage.getItem('geodata_token')) return null;
    return new ServiceAreaTask({
      url: `https://services.geodataonline.no/arcgis/rest/services/Geosok/GeosokRute3/NAServer/Service%20Area?`
    });
  }, []);
  const [travelModeState, setTravelModeState] = useState<TravelModeStateType>({
    type: 0,
    walkMeters: 1000,
    driveMinutes: 5
  });

  const [saRedoStack, setSaRedoList] = useState<UndoRedoListType[]>([]);
  const [saUndoStack, setSaUndoStack] = useState<UndoRedoListType[]>([]);

  // 0 = disabled, 1 = drive, 2 = truck, 3 = walk
  const handleSetTravelMode = (updateTravelMode: TravelModeStateType) => {
    setTravelModeState({
      type:
        typeof updateTravelMode?.type !== 'undefined'
          ? updateTravelMode.type
          : travelModeState.type,
      walkMeters:
        typeof updateTravelMode?.walkMeters !== 'undefined'
          ? updateTravelMode.walkMeters
          : travelModeState.walkMeters,
      driveMinutes:
        typeof updateTravelMode?.driveMinutes !== 'undefined'
          ? updateTravelMode.driveMinutes
          : travelModeState.driveMinutes
    });
  };

  function redoLastServiceArea() {
    const mutatableSaRedoStack: UndoRedoListType[] = [...saRedoStack];
    if (!mutatableSaRedoStack.length) return;
    const firstItemInStack = mutatableSaRedoStack.shift();
    if (typeof firstItemInStack !== 'undefined') {
      const storedLayer = firstItemInStack.layer;
      firstItemInStack.allServiceAreaGraphicsList.forEach((graphic) => {
        storedLayer.add(graphic);
      });

      setSaUndoStack((_saUndoStack: UndoRedoListType[]) => {
        return [firstItemInStack, ..._saUndoStack];
      });
      setSaRedoList(mutatableSaRedoStack);
    }
  }
  function undoLastServiceArea() {
    const mutatableSaUndoStack: UndoRedoListType[] = [...saUndoStack];
    if (!mutatableSaUndoStack.length) return;
    const firstItemInList = mutatableSaUndoStack.shift();

    if (typeof firstItemInList !== 'undefined') {
      const storedLayer = firstItemInList.layer;
      firstItemInList.allServiceAreaGraphicsList.forEach((graphic) => {
        storedLayer.remove(graphic);
      });

      setSaRedoList((_saRedoStack: UndoRedoListType[]) => {
        return [firstItemInList, ..._saRedoStack];
      });
      setSaUndoStack(mutatableSaUndoStack);
    }
  }

  function createServiceAreaParams({
    locationGraphic,
    defaultBreaks,
    travelMode
  }: {
    travelMode: {
      distance: number;
      type: number;
    };
    locationGraphic: typeof Graphic;
    defaultBreaks: number[];
  }) {
    // Create one or more locations (facilities) to solve for
    const featureSet = new FeatureSet({
      features: [locationGraphic]
    });

    // Set all of the input parameters for the service
    const taskParameters = new ServiceAreaParameters({
      facilities: featureSet,
      defaultBreaks,
      outSpatialReference: spatialReferenceWGS84,
      travelMode: travelMode.type + ''
      //   ,outputPolygons: 'detailed'
    });
    return taskParameters;
  }

  function executeServiceAreaTask({
    serviceAreaParams,
    locationGraphic,
    layer
  }) {
    function getColorOutline(ringAmount, index) {
      if (ringAmount === 1) {
        if (index === 0) return 'green';
      } else if (ringAmount === 2) {
        if (index === 0) return 'yellow';
        else if (index === 1) return 'green';
      } else return index === 0 ? 'red' : index === 1 ? 'yellow' : 'green';
    }
    serviceAreaTask.solve(serviceAreaParams).then(
      function (result) {
        if (result.serviceAreaPolygons.length) {
          // Draw each service area polygon
          const ringsGraphicList = result.serviceAreaPolygons.map(function (
            graphic,
            i
          ) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            graphic.symbol = {
              type: 'simple-fill',
              color: new Color('transparent'),
              outline: {
                width: 1,
                color: getColorOutline(result.serviceAreaPolygons?.length, i)
              }
            };

            return graphic;
          });
          ringsGraphicList.forEach((ringGraphic) => {
            layer.add(ringGraphic);
          });
          layer.add(locationGraphic);

          setSaUndoStack((_serviceAreaStack: UndoRedoListType[]) => {
            return [
              {
                layer,
                allServiceAreaGraphicsList: [
                  ...ringsGraphicList,
                  locationGraphic
                ]
              },
              ..._serviceAreaStack
            ];
          });
        }
      },
      function () {}
    );
  }
  const setServiceAreaPoint = ({
    point,
    travelMode = {
      type: 1, // 1 = drive, 2 = utrykking, 3 = walk
      distance: 10
    },
    layer
  }) => {
    if (!serviceAreaTask) return;

    const defaultBreaks = [
      Math.round(travelMode.distance * (1 / 3)),
      Math.round(travelMode.distance * (2 / 3)),
      travelMode.distance
    ];
    const _point = new Point(point);
    const popupTemplate = {
      title:
        travelMode.type === 1
          ? 'Kjøreavstand i minutter'
          : 'Gangavstand i meter',
      content: [
        {
          type: 'fields',
          fieldInfos: defaultBreaks.map((breakMin, i) => ({
            fieldName: 'Sirkel #' + (i + 1),
            label:
              'Sirkel #' +
              (i + 1) +
              ': ' +
              breakMin +
              (travelMode.type === 1 ? ' minutter' : ' meter')
          }))
        }
      ]
    };
    const locationGraphic = createServiceAreaGraphicPoint({
      point: _point,
      popupTemplate,
      travelMode: {
        type: travelMode.type,
        distance: travelMode.distance
      }
    });

    const serviceAreaParams = createServiceAreaParams({
      locationGraphic,
      defaultBreaks,
      travelMode
    });

    executeServiceAreaTask({
      serviceAreaParams,
      locationGraphic,
      layer
    });
  };

  function deleteAllServiceAreas(layer) {
    layer.graphics.removeAll();
  }
  function createServiceAreaGraphicPoint({ point, popupTemplate, travelMode }) {
    const graphic = new Graphic({
      geometry: point,
      popupTemplate: popupTemplate,
      symbol: {
        url: getWalkDriveIcon(Number(travelMode?.type)),
        type: 'picture-marker',
        width: 20,
        height: 20
      },
      id: JSON.stringify(travelMode) + '//' + Math.floor(Math.random() * 9999)
    });

    return graphic;
  }

  return {
    setServiceAreaPoint,
    deleteAllServiceAreas,
    undoLastServiceArea,
    redoLastServiceArea,
    travelModeState,
    handleSetTravelMode
  };
};
