import { post } from 'js/api-helper';
import React, { FC, useMemo, useState } from 'react';
import { Fildata } from 'types/Fildata';
import ImageUploadBox from './ImageUploadBox';
import style from './ImageUploadGrid.module.css';
export interface IImageUploadGrid {
  bildeListe: Fildata[];
  imageUploadUrl: string;
  readOnly?: boolean;
  readonly slettBefaringBildeApi: string;
}

const ImageUploadGrid: FC<IImageUploadGrid> = ({
  bildeListe,
  imageUploadUrl,
  readOnly,
  slettBefaringBildeApi
}) => {
  const [currentBildeListe, setCurrentBildeListe] = useState<Fildata[]>(
    bildeListe
  );
  const handleFile = (file: Fildata, index: number): void => {
    /* eslint-disable no-console */
    const newBildeListe = [...currentBildeListe];
    newBildeListe[index] = file;
    setCurrentBildeListe(newBildeListe);
  };
  const imageRowClass = useMemo(() => {
    const images = currentBildeListe?.filter((bilde) => bilde?.nedlastingslenke)
      .length;
    if (!readOnly) return '';
    else if (images === 0) return style.noRows;
    else if (images === 1) return style.oneRow;
    else if (images === 2 || images === 3) return style.twoRows;
    else return '';
    // if (!hasImage && readOnly) return true;
    // else return false;
  }, [currentBildeListe]);

  const handleDeleteImg = async (id) => {
    try {
      await post(`${slettBefaringBildeApi}?bildeId=${id}`);
      const updatedBildeListe = currentBildeListe.map((bilde) =>
        bilde.id === id
          ? {
              id: '',
              nedlastingslenke: '',
              visningsnavn: ''
            }
          : bilde
      );
      setCurrentBildeListe(updatedBildeListe);
    } catch (error) {
      console.log(error);
    }
  };

  // if (stopRender) return null;
  return (
    <div className={`${style.ImageUploadGrid} ${imageRowClass}`}>
      {currentBildeListe?.map((bilde, index) => {
        if (readOnly && !bilde?.nedlastingslenke) return null;
        else
          return (
            <ImageUploadBox
              index={index}
              key={index}
              readOnly={readOnly}
              bildeFil={bilde}
              onUploadComplete={handleFile}
              destinationUrl={imageUploadUrl}
              handleDeleteImg={handleDeleteImg}
            />
          );
      })}
    </div>
  );
};

ImageUploadGrid.defaultProps = {
  bildeListe: [
    {
      id: '',
      nedlastingslenke: '',
      visningsnavn: ''
    },
    {
      id: '',
      nedlastingslenke: '',
      visningsnavn: ''
    },
    {
      id: '',
      nedlastingslenke: '',
      visningsnavn: ''
    },
    {
      id: '',
      nedlastingslenke: '',
      visningsnavn: ''
    },
    {
      id: '',
      nedlastingslenke: '',
      visningsnavn: ''
    },
    {
      id: '',
      nedlastingslenke: '',
      visningsnavn: ''
    }
  ]
};
export default ImageUploadGrid;
