import useArrayState from '@clave/use-array-state';
import ArealPerPlan from 'components/ArealPerPlan/ArealPerPlan';
import SingleDatePicker from 'components/DateRangePicker/SingleDatePicker';
import DoublePanel from 'components/DoublePanel/DoublePanel';
import Energimerke, { EnergiLetterColor } from 'components/Energimerke/Energimerke';
import LabeledField from 'components/LabeledField/LabeledField';
import NumberField from 'components/NumberField/NumberField';
import NumberPicker from 'components/NumberPicker/NumberPicker';
import Organisasjonsvelger from 'components/Organisasjonsvelger/Organisasjonsvelger';
import RadioField from 'components/RadioField/RadioField';
import SelectField from 'components/SelectField/SelectField';
import TextField from 'components/TextField/TextField';
import UploadField from 'components/UploadField/UploadField';
import { parseISO8601String as parseDate, shortDateString } from 'js/date-helper';
import { numberWithSpaces } from 'js/format-helper';
import React, { FC, useMemo, useState } from 'react';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Label from 'components/Label/Label';
import Panel from 'components/Panel/Panel';
import { Kontaktperson } from 'types/Kontaktperson';
import { Matrikkel } from 'types/Matrikkel';
import { SingelKollektivTransportEntry } from 'types/SingelKollektivTransportEntry';
import { Svar } from 'types/Svar';
import PresenterFeil from '../components/PresenterFeil';
import SvarRedigerContainer from './SvarRedigerContainer';
import style from './SvarRedigerSide.module.css';
import { mapBygningsStandAlternativer, mapMiljosertifisertAlternativer, mapUniversellUtformingAlternativer } from '../../utils';
import { KopierSvar } from '../components/KopierSvar';
import RedigerMatrikler from '../components/RedigerMatrikkeler';
import { useDraftAndFinalPostback } from '../useDraftAndFinalPostback';
import UploadMultipleField from 'components/UploadMultipleField/UploadMultipleField';
import { toCamelCase } from 'js/upload-files';
import useBeforeUnload from 'js/hooks/use-before-unload';

export interface SvarRedigerSide {
  readonly svarData: Svar;
  readonly annonseinformasjon: {
    readonly tittel: string;
    readonly svarFrist: string;
    readonly onsketOvertakelseFraUtc: string;
    readonly onsketOvertakelseTilUtc: string;
    readonly avstandKollektivTransport: SingelKollektivTransportEntry[];
    readonly arealFra: number;
    readonly arealTil: number;
    readonly kommune: string;
  };
  readonly kontaktperson: Kontaktperson;
  readonly bygningensStandAlternativer: {
    readonly alternativ: string;
  }[];
  readonly miljosertifisertAlternativer: {
    readonly alternativ: string;
  }[];
  readonly universellUtformingAlternativer: {
    readonly alternativ: string;
  }[];
  readonly lastOppEnergiattestUrl: string;
  readonly lastOppBildePlantegningUrl: string;
  readonly lastOppBildeInteriorUrl: string;
  readonly lastOppBildeEksteriorUrl: string;
  readonly slettFilUrl: string;
  readonly lastOppEkstraDokumentUrl: string;
  readonly kollektivTransportTyperListe: string[];
  readonly svarLagreKladdApi: string;
  readonly svarLeverTilbudApi: string;
  readonly relevanteTidligereSvar: {
    readonly url: string;
    readonly tittel: string;
    readonly erKladd?: boolean;
    readonly erIProsjekt?: boolean;
  }[];
  readonly slettSvarBekreftTekst?: string;
  readonly deleteUrl?: string;
}

const SvarRedigerSide: FC<SvarRedigerSide> = ({
  svarData: data,
  annonseinformasjon,
  kontaktperson,
  bygningensStandAlternativer,
  miljosertifisertAlternativer,
  universellUtformingAlternativer,
  kollektivTransportTyperListe,
  lastOppEnergiattestUrl,
  lastOppBildePlantegningUrl,
  lastOppBildeInteriorUrl,
  lastOppBildeEksteriorUrl,
  slettFilUrl,
  lastOppEkstraDokumentUrl,
  svarLagreKladdApi,
  svarLeverTilbudApi,
  relevanteTidligereSvar = [],
  slettSvarBekreftTekst,
  deleteUrl
}) => {
  const [utleier, setUtleier] = useState(data.utleier);
  const [utleiemegler, setUtleiemegler] = useState(data.utleiemegler);

  const [hovedMatrikkelIdentitet, setHovedMatrikkelIdentitet] = useState(data?.matrikkelInfo?.hovedMatrikkel?.matrikkelIdentitet);
  const [hovedMatrikkelAdressetekst, setHovedMatrikkelAdressetekst] = useState(data?.matrikkelInfo?.hovedMatrikkel?.adressetekst);

  const [tilleggsMatrikler, setTilleggsMatrikler] = useArrayState<Matrikkel>(data?.matrikkelInfo?.tilleggsMatrikler ?? []);

  const [generellInfoStandard, setGenerellInfoStandard] = useState(data.generellInfoStandard);
  const [generellInfoFasiliteter, setGenerellInfoFasiliteter] = useState(data.generellInfoFasiliteter);
  const [generellInfoLedigFraUtc, setGenerellInfoLedigFraUtc] = useState(() => parseDate(data.generellInfoLedigFraUtc));
  const [avstandKollektivTransport, setAvstandKollektivTransport] = useState(
    data.avstandKollektivTransport?.length
      ? data.avstandKollektivTransport
      : kollektivTransportTyperListe.map((kollektivtype) => ({
          avstandIMeter: undefined as unknown as number,
          kollektivtype
        }))
  );
  const [sykkelparkeringsplasser, setSykkelparkeringsplasser] = useState(data?.parkeringsData?.sykkelparkeringsplasser);
  const [parkeringsplasser, setParkeringsplasser] = useState(data?.parkeringsData?.parkeringsplasser);
  const [ladepunkter, setLadepunkter] = useState(data?.parkeringsData?.ladepunkter);
  const [bygningensStand, setBygningensStand] = useState(data.bygningensStand);
  const [reguleringsstatus, setReguleringsstatus] = useState(data.reguleringsstatus);
  const [energimerke, setEnergimerke] = useState(data?.miljoData?.energimerke);
  const [energimerkeFarge, setEnergimerkeFarge] = useState(data?.miljoData?.energimerkeFarge);
  const [planlagtEnergimerke, setPlanlagtEnergimerke] = useState(data?.miljoData?.planlagtEnergimerke);
  const [planlagtEnergimerkeFarge, setPlanlagtEnergimerkeFarge] = useState(data?.miljoData?.planlagtEnergimerkeFarge);
  const [kWh, setKWh] = useState(data?.miljoData?.kwh || undefined);
  const [energieffektivitetTiltak, setEnergieffektivitetTiltak] = useState(data.energieffektivitetTiltak || undefined);
  const [miljosertifisert, setMiljosertifisert] = useState(data?.miljoData?.miljosertifisert);
  const [miljosertifisertDetalj, setMiljosertifisertDetalj] = useState(data?.miljoData?.miljosertifisertDetalj);
  const [universellUtforming, setUniversellUtforming] = useState(data.universellUtforming);
  const [universellUtformingDetalj, setUniversellUtformingDetalj] = useState(data.universellUtformingDetalj);
  const [totaltAreal, setTotaltAreal] = useState(data.totaltAreal || undefined);
  const [ledigAreal, setLedigAreal] = useState(data.ledigAreal || undefined);
  const [arealPerPlan, setArealPerPlan] = useState(data.arealPerPlan ? data.arealPerPlan : []);
  const [prisKvmMedTiltak, setPrisKvmMedTiltak] = useState(data.prisKvmMedTiltak || undefined);
  const [prisKvmUtenTiltak, setPrisKvmUtenTiltak] = useState(data.prisKvmUtenTiltak || undefined);
  const [felleskostKvm, setFelleskostKvm] = useState(data.felleskostKvm || undefined);
  const [mvaKompensasjonKvm, setMvaKompensasjonKvm] = useState(data.mvaKompensasjonKvm || undefined);
  const [paslagFellesarealiProsent, setPaslagFellesarealiProsent] = useState(data.paslagFellesarealiProsent || undefined);
  const [sykkelplasskost, setSykkelplasskost] = useState(data?.parkeringsData?.sykkelplasskost || undefined);
  const [parkeringsplasskost, setParkeringsplasskost] = useState(data?.parkeringsData?.parkeringsplasskost || undefined);
  const [kostKantineBidrag, setKostKantineBidrag] = useState(data.kostKantineBidrag || undefined);
  const [kommentarer, setKommentarer] = useState(data.kommentarer);

  const utleierNavn = data.utleierNavn;
  const utleiemeglerNavn = data.utleiemeglerNavn;

  const bygningsStandChoices = useMemo(() => mapBygningsStandAlternativer(bygningensStandAlternativer), [bygningensStand]);

  const miljosertifisertChoices = useMemo(
    () => mapMiljosertifisertAlternativer(miljosertifisertAlternativer),
    [miljosertifisert]
  );
  const universellUtformingChoices = useMemo(
    () => mapUniversellUtformingAlternativer(universellUtformingAlternativer),
    [universellUtforming]
  );

  const [bildeEksteriorField, setBildeEksteriorField] = useState(data?.bildeData?.bildeEksterior);
  const [energiattestField, setEnergiattestField] = useState(data?.miljoData?.energiattest);

  const { saveDraft, saveFinal, isBusy, isDirty, validation, canSubmit } = useDraftAndFinalPostback(
    svarLagreKladdApi,
    svarLeverTilbudApi,
    {
      utleier,
      utleiemegler,
      hovedMatrikkelIdentitet,
      hovedMatrikkelAdressetekst,
      tilleggsMatrikler,
      generellInfoStandard,
      generellInfoFasiliteter,
      generellInfoLedigFraUtc,
      bygningensStand,
      reguleringsstatus,
      avstandKollektivTransport,
      sykkelparkeringsplasser,
      parkeringsplasser,
      ladepunkter,
      energimerke,
      energimerkeFarge,
      planlagtEnergimerke,
      planlagtEnergimerkeFarge,
      kWh,
      energieffektivitetTiltak,
      miljosertifisert,
      miljosertifisertDetalj,
      universellUtforming,
      universellUtformingDetalj,
      totaltAreal,
      ledigAreal,
      arealPerPlan,
      prisKvmMedTiltak,
      prisKvmUtenTiltak,
      felleskostKvm,
      mvaKompensasjonKvm,
      paslagFellesarealiProsent,
      sykkelplasskost,
      parkeringsplasskost,
      kostKantineBidrag,
      kommentarer,
      bildeEksteriorField: bildeEksteriorField?.id,
      energiattestField: energiattestField?.id
    }
  );

  useBeforeUnload(isDirty);

  const [popupOpen, setPopupOpen] = useState(0);
  const { hasRelevanteSvar, hasRelevanteSvarWithoutKladd } = React.useMemo(() => {
    return {
      hasRelevanteSvar: !!relevanteTidligereSvar?.length,
      hasRelevanteSvarWithoutKladd: !!relevanteTidligereSvar?.filter((svar) => !svar.erKladd).length
    };
  }, [relevanteTidligereSvar]);

  React.useEffect(() => {
    if (hasRelevanteSvar) {
      setPopupOpen(1);
    }
  }, [hasRelevanteSvar]);

  return (
    <>
      <SvarRedigerContainer
        headline={annonseinformasjon.tittel}
        subtitle={shortDateString(annonseinformasjon.svarFrist)}
        kontaktperson={kontaktperson}
        showOpenRelevanteSvar={hasRelevanteSvarWithoutKladd}
        openRelevanteSvar={() => setPopupOpen(2)}
        submitDraft={saveDraft}
        submitFinal={saveFinal}
        slettSvarBekreftTekst={slettSvarBekreftTekst}
        deleteUrl={deleteUrl}
        isBusy={isBusy}
        canSubmit={canSubmit}
      >
        {!!relevanteTidligereSvar?.length && !isDirty && (
          <KopierSvar
            setPopupOpen={setPopupOpen}
            popupOpen={popupOpen}
            kommune={annonseinformasjon.kommune}
            relevanteTidligereSvar={relevanteTidligereSvar}
          />
        )}
        <DoublePanel label="Kontaktinformasjon" className={style.kontaktinfo}>
          <Organisasjonsvelger
            label="Utleier*"
            initialValue={utleierNavn}
            onChange={(value) => setUtleier(value)}
            placeholder="Tast inn org.nr eller navn og velg utleier"
            {...validation.utleier}
          />
          <Organisasjonsvelger
            label="Utleiemegler*"
            initialValue={utleiemeglerNavn}
            onChange={(value) => setUtleiemegler(value)}
            placeholder="Tast inn org.nr eller navn og velg utleiemegler"
            {...validation.utleiemegler}
          />
        </DoublePanel>
        <RedigerMatrikler
          hovedMatrikkelAdressetekst={hovedMatrikkelAdressetekst}
          hovedMatrikkelIdentitet={hovedMatrikkelIdentitet}
          validation={validation}
          setHovedMatrikkelAdressetekst={setHovedMatrikkelAdressetekst}
          setHovedMatrikkelIdentitet={setHovedMatrikkelIdentitet}
          matrikler={tilleggsMatrikler}
          addMatrikkel={setTilleggsMatrikler.append}
          removeMatrikkel={setTilleggsMatrikler.removeAt}
          editMatrikkel={setTilleggsMatrikler.editAt}
        />
        <DoublePanel label="Generell info" className={style.generell}>
          <TextField
            label="Standard*"
            placeholder="Skriv om utleieobjektet her"
            large={true}
            type="textarea"
            rows={10}
            value={generellInfoStandard}
            maxCharacters={500}
            onChange={(e) => setGenerellInfoStandard(e.target.value)}
            {...validation.generellInfoStandard}
          />

          <TextField
            label="Fasiliteter*"
            placeholder="Skriv om fasilitetene til utleieobjektet her"
            large={true}
            type="textarea"
            rows={10}
            value={generellInfoFasiliteter}
            maxCharacters={500}
            onChange={(e) => setGenerellInfoFasiliteter(e.target.value)}
            {...validation.generellInfoFasiliteter}
          />

          <SingleDatePicker
            label="Ledig fra*"
            tooltip={
              <>
                <h4>Ønsket overtakelse</h4>
                <p>
                  {shortDateString(annonseinformasjon.onsketOvertakelseFraUtc)} -{' '}
                  {shortDateString(annonseinformasjon.onsketOvertakelseTilUtc)}
                </p>
              </>
            }
            placeholder="Velg tidligste ledig dato"
            initialDate={generellInfoLedigFraUtc}
            onDateChange={(value) => setGenerellInfoLedigFraUtc(value)}
            {...validation.generellInfoLedigFraUtc}
          />
        </DoublePanel>
        {!!avstandKollektivTransport?.length && (
          <DoublePanel className={style.kollektivTransport} label="Avstand til kollektivtransport">
            {avstandKollektivTransport.map((k, i) => (
              <NumberField
                placeholder="Avstand i meter"
                tooltip={
                  <>
                    <h4>Ønsket avstand til {k.kollektivtype}</h4>
                    <p>
                      {
                        annonseinformasjon.avstandKollektivTransport.find((a) => a.kollektivtype === k.kollektivtype)
                          ?.avstandIMeter
                      }
                      m
                    </p>
                  </>
                }
                key={i}
                label={k.kollektivtype + "*"}
                suffix="m"
                value={k.avstandIMeter}
                onChange={(avstandIMeter) =>
                  setAvstandKollektivTransport((liste) => liste.map((o) => (o === k ? { ...o, avstandIMeter } : o)))
                }
              />
            ))}
            {validation.avstandKollektivTransport.feedback &&
              validation.avstandKollektivTransport.validationState === 'error' && (
                <ErrorMessage feedback={validation.avstandKollektivTransport.feedback} feedbackMargin />
              )}
          </DoublePanel>
        )}
        <Panel heading={'Transport'} className={style.transport}>
          <NumberField
            label="Sykkelparkeringsplasser"
            suffix="stk"
            placeholder="Antall"
            type="number"
            min="0"
            value={sykkelparkeringsplasser}
            onChange={(e) => setSykkelparkeringsplasser(e)}
            {...validation.sykkelparkeringsplasser}
          />
          <NumberField
            label="Parkeringsplasser"
            suffix="stk"
            placeholder="Antall"
            type="number"
            min="0"
            value={parkeringsplasser}
            onChange={(e) => setParkeringsplasser(e)}
            {...validation.parkeringsplasser}
          />
          <NumberField
            label="Ladepunkter"
            suffix="stk"
            placeholder="Antall"
            type="number"
            min="0"
            value={ladepunkter}
            onChange={(e) => setLadepunkter(e)}
            {...validation.ladepunkter}
          />
        </Panel>
        <Panel heading={'Bygningens stand'} className={style.bygg}>
          <SelectField
            label="Bygningens Stand*"
            placeholder="Velg byggningens stand"
            choices={bygningsStandChoices}
            selectedValue={bygningsStandChoices.find((p) => p.value === bygningensStand)}
            onChange={(e) => setBygningensStand(e.value)}
            {...validation.bygningensStand}
          />

          <TextField
            label="Reguleringsstatus*"
            placeholder="Skriv her"
            large={true}
            type="textarea"
            style={{ resize: 'vertical' }}
            rows={10}
            value={reguleringsstatus}
            maxCharacters={400}
            onChange={(e) => setReguleringsstatus(e.target.value)}
            {...validation.reguleringsstatus}
          />
        </Panel>
        <DoublePanel label="Energi og miljø" className={style.energi}>
          <LabeledField vertical label={`Energimerke* (legg ved energiattest om bygningen er oppført) `}>
            <Energimerke
              value={
                {
                  letter: energimerke,
                  color: energimerkeFarge
                } as EnergiLetterColor
              }
              onChange={(value) => {
                if (!value) return;
                setEnergimerke(value.letter);
                setEnergimerkeFarge(value.color);
              }}
              {...validation.energimerke}
            />
            <UploadField
              initialFile={data.miljoData?.energiattest}
              fileType="document"
              destinationUrl={lastOppEnergiattestUrl}
              deleteUrl={slettFilUrl}
              onChange={(x) => setEnergiattestField(x)}
              {...validation.energiattestField}
            />
          </LabeledField>

          <LabeledField vertical label={`Planlagt Energimerke (legg ved energiattest om bygningen er oppført)`}>
            <Energimerke
              value={
                planlagtEnergimerkeFarge && planlagtEnergimerke
                  ? ({
                      letter: planlagtEnergimerke,
                      color: planlagtEnergimerkeFarge
                    } as EnergiLetterColor)
                  : undefined
              }
              onChange={(value) => {
                setPlanlagtEnergimerke(value?.letter);
                setPlanlagtEnergimerkeFarge(value?.color);
              }}
              isClearable
              {...validation.planlagtEnergimerke}
            />

            <NumberField
              placeholder="kWh per kvm"
              suffix="kWh"
              type="number"
              min="0"
              value={kWh}
              onChange={setKWh}
              {...validation.kWh}
            />
          </LabeledField>

          <TextField
            className={style.fullSpan}
            label="Tiltak for å forbedre energieffektiviteten?*"
            placeholder="Beskriv hvilke tiltak (tekniske- og/eller bygningsmessige og forventet revidert energimerke.)"
            large={true}
            type="textarea"
            rows={5}
            value={energieffektivitetTiltak}
            maxCharacters={400}
            onChange={(e) => setEnergieffektivitetTiltak(e.target.value)}
            {...validation.energieffektivitetTiltak}
          />

          <RadioField
            label="Er lokalet/utleier miljøsertifisert?*"
            name="miljo"
            currentValue={miljosertifisertChoices.find((p) => p.value === miljosertifisert)}
            onChange={setMiljosertifisert}
            values={miljosertifisertChoices}
            {...validation.miljosertifisert}
          />

          <TextField
            className={style.fullSpan}
            placeholder="Hvis ja, spesifiser hvilke(t)"
            large={true}
            type="textarea"
            rows={8}
            value={miljosertifisertDetalj}
            maxCharacters={400}
            onChange={(e) => setMiljosertifisertDetalj(e.target.value)}
            {...validation.miljosertifisertDetalj}
          />
        </DoublePanel>
        <Panel heading={'Størrelse'} className={style.areal}>
          <NumberField
            placeholder="Areal"
            suffix="kvm"
            label="Totalt areal i bygget (BTA)*"
            min="0"
            value={totaltAreal}
            tooltip={
              <>
                <h4>Ønsket areal</h4>
                <p>
                  {numberWithSpaces(annonseinformasjon.arealFra)}kvm - {numberWithSpaces(annonseinformasjon.arealTil)}kvm
                </p>
              </>
            }
            onChange={setTotaltAreal}
            {...validation.totaltAreal}
          />
          <NumberField
            placeholder="Areal"
            suffix="kvm"
            label="Ledig areal i bygget (BTA)*"
            min="0"
            value={ledigAreal}
            onChange={setLedigAreal}
            {...validation.ledigAreal}
          />
          <ArealPerPlan
            label="Spesifiser areal for hvert plan"
            arealPerPlan={arealPerPlan}
            onChange={setArealPerPlan}
            {...validation.arealPerPlan}
          />
          <RadioField
            label="Universell utforming?*"
            name="utforming"
            currentValue={universellUtformingChoices.find((p) => p.value === universellUtforming)}
            onChange={setUniversellUtforming}
            values={universellUtformingChoices}
            {...validation.universellUtforming}
          />
          <TextField
            placeholder="Hvis under arbeid, spesifisert hvilke(t) endring(er)"
            large={true}
            type="textarea"
            rows={8}
            style={{ resize: 'vertical' }}
            value={universellUtformingDetalj}
            maxCharacters={400}
            onChange={(e) => setUniversellUtformingDetalj(e.target.value)}
            {...validation.universellUtformingDetalj}
          />
        </Panel>
        <DoublePanel label="Prisestimater" className={style.pris}>
          <NumberField
            placeholder="Pris"
            suffix="kr"
            label="Pris pr. kvm BTA uten planlagte tiltak*"
            type="number"
            min="0"
            value={prisKvmUtenTiltak}
            onChange={setPrisKvmUtenTiltak}
            {...validation.prisKvmUtenTiltak}
          />
          <NumberField
            placeholder="Pris"
            suffix="kr"
            label="Felleskost pr. kvm BTA"
            type="number"
            min="0"
            value={felleskostKvm}
            onChange={setFelleskostKvm}
            {...validation.felleskostKvm}
          />
          <NumberField
            placeholder="Pris"
            suffix="kr"
            label="Pris pr. kvm BTA med planlagte tiltak*"
            type="number"
            min="0"
            value={prisKvmMedTiltak}
            onChange={setPrisKvmMedTiltak}
            {...validation.prisKvmMedTiltak}
          />
          <NumberField
            placeholder="Pris"
            suffix="kr"
            label="Mva-kompensasjon pr. kvm BTA"
            type="number"
            min="0"
            value={mvaKompensasjonKvm}
            onChange={setMvaKompensasjonKvm}
            {...validation.mvaKompensasjonKvm}
          />
          <NumberField
            placeholder="Pris"
            suffix="kr"
            label="Kost pr. parkeringsplass"
            type="number"
            min="0"
            value={parkeringsplasskost}
            onChange={setParkeringsplasskost}
            {...validation.parkeringsplasskost}
          />
          <NumberField
            placeholder="Prosent"
            suffix="%"
            label="Påslag for fellesareal i prosent"
            type="number"
            min="0"
            value={paslagFellesarealiProsent}
            onChange={setPaslagFellesarealiProsent}
            {...validation.paslagFellesarealiProsent}
          />
          <NumberField
            placeholder="Pris"
            suffix="kr"
            label="Kost pr. ansatt i kantinebidrag"
            type="number"
            min="0"
            value={kostKantineBidrag}
            onChange={setKostKantineBidrag}
            {...validation.kostKantineBidrag}
          />
          <NumberField
            placeholder="Pris"
            suffix="kr"
            label="Kost pr. sykkelplass"
            type="number"
            min="0"
            value={sykkelplasskost}
            onChange={setSykkelplasskost}
            {...validation.sykkelplasskost}
          />
        </DoublePanel>
        <Panel heading={'Bilder'} className={style.bilde}>
          <h4>Laste opp bilder (jpg, png)</h4>
          <Label>Plantegning</Label>
          <UploadField
            initialFile={data.bildeData?.bildePlantegning}
            fileType="image"
            destinationUrl={lastOppBildePlantegningUrl}
            deleteUrl={slettFilUrl}
          />
          <Label>Interiør</Label>
          <UploadField
            initialFile={data.bildeData?.bildeInterior}
            fileType="image"
            destinationUrl={lastOppBildeInteriorUrl}
            deleteUrl={slettFilUrl}
          />
          <Label>Eksteriør*</Label>
          <UploadField
            initialFile={data.bildeData?.bildeEksterior}
            fileType="image"
            destinationUrl={lastOppBildeEksteriorUrl}
            deleteUrl={slettFilUrl}
            onChange={(x) => setBildeEksteriorField(x)}
            {...validation.bildeEksteriorField}
          />
          <LabeledField vertical label="Tilleggsinformasjon">
            <UploadMultipleField
              initialFiles={data.ekstraDokumenter?.map(toCamelCase) ?? []}
              destinationUrl={lastOppEkstraDokumentUrl}
              deleteUrl={slettFilUrl}
              fileType="document"
            />
          </LabeledField>
        </Panel>
        <Panel heading={'Øvrige kommentarer'} className={style.kommentar}>
          <TextField
            placeholder="Skriv her"
            large={true}
            type="textarea"
            rows={7}
            value={kommentarer}
            maxCharacters={500}
            onChange={(e) => setKommentarer(e.target.value)}
            {...validation.kommentarer}
          />
        </Panel>
        <PresenterFeil validation={validation} />
      </SvarRedigerContainer>
    </>
  );
};

export default SvarRedigerSide;
