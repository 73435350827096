import classnames from 'classnames';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import { recommendationFlagsToEnumValue } from 'js/SortLeieObjekter';
import React from 'react';
import { ObjektVurdering } from 'types/ObjektVurdering';

interface ReccomendationLabel {
  anbefalingsEnum: SeriositetsLabels | BefaringLabels;
  vurdering: ObjektVurdering;
}

export enum SeriositetsLabels {
  'Ikke vurdert' = -1,
  'Anbefales' = 0,
  'Anbefales med anmerkninger' = 1,
  'Anbefales ikke' = 2,
  'TRUKKET' = 3
}

export enum BefaringLabels {
  'Ikke vurdert' = -1,
  'Anbefales å vurdere for deltakelse i konkurransen' = 0,
  'Anbefalt av Oppdragsgiver' = 1,
  'Anbefales å utelukke fra konkurransen' = 2,
  'TRUKKET' = 3
}

const ReccomendationLabel = ({ anbefalingsEnum, vurdering }) => {
  const value = recommendationFlagsToEnumValue(
    true,
    vurdering.erAnbefalt,
    vurdering.erAnbefaltMedAnmerkninger,
    vurdering.erTrukket
  );

  const classes = classnames('reccomendation-label', {
    '-green': value === 0,
    '-yellow': value === 1,
    '-red': value === 2 || value === 3
  });

  const renderIcon = (value) => {
    return (
      <span className="icon">
        {value === 0 || value == 3 ? (
          false
        ) : value === 1 ? (
          <IconReactIcons type={IconTypes.exclamationCircle} />
        ) : (
          <IconReactIcons type={IconTypes.exclamationTriangle} />
        )}
      </span>
    );
  };

  return (
    <span className={classes}>
      {renderIcon(value)}
      {anbefalingsEnum[value]}
    </span>
  );
};

export default ReccomendationLabel;
