import Heading from 'components/Heading/Heading';
import Organisasjonsvelger from 'components/Organisasjonsvelger/Organisasjonsvelger';
import { usePostback } from 'js/hooks';
import React, { FC, useCallback, useState } from 'react';
import { AiOutlineSync } from 'react-icons/ai';

import Button from 'components/Button/Button';
import Panel from 'components/Panel/Panel';
import Field from 'components/Field/Field';

export interface FullforProfilSide {
  readonly fulltNavn: string;
  readonly organisasjonsnummer?: string;
  readonly organisasjonsnavn?: string;
  readonly epost?: string;
  readonly telefon?: string;
  readonly lagreProfilDataUrl: string;
  readonly videresendUrl: string;
}

const FullforProfilSide: FC<FullforProfilSide> = (props) => {
  const [organisasjonsnummer, setOrganisasjonsnummer] = useState(props.organisasjonsnummer);
  const [epost, setEpost] = useState(props.epost);
  const [telefon, setTelefon] = useState(props.telefon);
  const { postback, validation, canSubmit, isBusy } = usePostback(props.lagreProfilDataUrl, {
    organisasjonsnummer,
    epost,
    telefon
  });

  const save = useCallback(async () => {
    await postback();
    document.location.href = props.videresendUrl;
  }, [postback, props.videresendUrl]);

  return (
    <div className="FullforProfilSide">
      <Heading>Fullfør profilen din</Heading>

      <Panel className="FullforProfilSide__info">
        <h5>Navn</h5>
        <div>{props.fulltNavn}</div>

        <h5>Departement / Virksomhet</h5>
        <Organisasjonsvelger
          placeholder="Søk på navn eller org.nr"
          {...validation.organisasjonsnummer}
          onChange={setOrganisasjonsnummer}
          initialValue={props.organisasjonsnavn}
        />

        <h5>Epost</h5>
        <Field
          name="Epost"
          label=""
          defaultValue={props.epost}
          onChange={(e) => setEpost(e.target.value)}
          {...validation.epost}
        />

        <h5>Telefon</h5>
        <Field
          name="Telefon"
          label=""
          defaultValue={props.telefon}
          onChange={(e) => setTelefon(e.target.value)}
          {...validation.telefon}
        />

        <div className="FullforProfilSide__info__buttons">
          {isBusy && <AiOutlineSync className="spin" />}
          <Button onClick={save} disabled={!canSubmit}>
            Lagre og fortsett
          </Button>
        </div>
      </Panel>
    </div>
  );
};

export default FullforProfilSide;
