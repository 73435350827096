import Paragraph from 'components/Paragraph/Paragraph';
import Button from 'components/Button/Button';
import React, { FC, useCallback, useState } from 'react';
import { ListeStatus } from '../BefaringInnkomneSide';
import { post } from 'js/api-helper';
import IconButton from 'components/IconButton/IconButton';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';

export interface FullforBefaringSkjema {
  readonly submitUrl: string;
  readonly undoUrl?: string;
  skjemaStatus: ListeStatus;
}

const FullforBefaringSkjema: FC<FullforBefaringSkjema> = ({ submitUrl, undoUrl, skjemaStatus }: FullforBefaringSkjema) => {
  const [checkBoxState, setCheckBoxState] = useState(false);
  const [failedMessage, setFailedMessage] = useState<string>();

  const reloadPage = () => {
    window.location.reload();
  };

  const submitForm = useCallback(async (actionObj) => {
    //post submit
    try {
      await post(submitUrl, actionObj);
      reloadPage();
    } catch (error) {
      setFailedMessage('oops! noe gikk galt');
    }
  }, []);

  const undoForm = useCallback(async (actionObj) => {
    //post submit
    try {
      if (undoUrl) await post(undoUrl, actionObj);
      reloadPage();
    } catch (error) {
      setFailedMessage('oops! noe gikk galt');
    }
  }, []);

  if (skjemaStatus == ListeStatus.Vurdert) {
    return (
      <div className="FullforBefaringSkjema -vurdert">
        <Paragraph>{failedMessage ?? 'Alle objekter er vurdert, ønsker du å levere for oppragsgivers godkjennelse?'}</Paragraph>
        {!failedMessage ? (
          <>
            <div className="FullforBefaringSkjema__checkbox">
              <input
                type="checkbox"
                id="confirm"
                checked={checkBoxState}
                onChange={() => setCheckBoxState((prevState) => !prevState)}
              />
              <label htmlFor="confirm">Ja, lever vurderinger for oppdragsgivers godkjennelse</label>
            </div>
            <Button
              submit={true}
              disabled={!checkBoxState}
              onClick={() => submitForm({ userAction: ListeStatus[ListeStatus.Levert] })}
            >
              Bekreft
            </Button>
          </>
        ) : (
          <Button onClick={() => reloadPage()}>
            <IconReactIcons type={IconTypes.reload} />
          </Button>
        )}
      </div>
    );
  } else if (skjemaStatus == ListeStatus.IkkeVurdert) {
    return (
      <div className="FullforBefaringSkjema -ikke-vurdert">
        <Paragraph>Det er uvurderte objekter i listen.</Paragraph>
        <Button submit={true} disabled={true}>
          Bekreft
        </Button>
      </div>
    );
  } else if (skjemaStatus == ListeStatus.Levert && undoUrl) {
    //<---can be null if E.G user is OG
    return (
      <div className="FullforBefaringSkjema -levert">
        <Paragraph>{failedMessage ?? 'Anbefaling til konkurranse er nå tilgjengelig for oppdragsgiver'}</Paragraph>
        {!failedMessage ? (
          <Button
            className="undo"
            submit={true}
            secondary={true}
            disabled={false}
            onClick={() => undoForm({ userAction: ListeStatus[ListeStatus.Levert] })}
          >
            Angre
          </Button>
        ) : (
          <Button onClick={() => reloadPage()}>
            <IconReactIcons type={IconTypes.reload} />
          </Button>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default FullforBefaringSkjema;
