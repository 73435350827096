// consistently print phone numbers as 'nnn nn nnn' if 8 characters.
// handles +47 prefix if string is 11 characters once spaces are stripped out.
export function phoneNumberString(phone: string): string {
  let edit = phone.replace(/\s+/g, '');
  let prefix = '';

  if (edit.startsWith('+47') && edit.length == 11) {
    prefix = '+47 ';
    edit = edit.substring(3);
  }

  if (edit.length == 8) edit = edit.substring(0, 3) + ' ' + edit.substring(3, 5) + ' ' + edit.substring(5, 8);

  return prefix + edit;
}

export function shortenString(input: string, limit: number): string {
  return input.length > limit ? input.substring(0, limit - 1) + '…' : input;
}

export function numberWithSpaces(x?: number): string {
  if (typeof x === 'undefined') return '';
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
