import React, { useState } from 'react';
import Text from 'components/Text/Text';
import AutoCompleteField from 'components/AutoCompleteField/AutoCompleteField';
import * as api from 'js/api-helper';
import Field from 'components/Field/Field';
import { EierDataOrganisasjon } from 'types/EierDataOrganisasjon';
import RadioBox from 'components/RadioBox/RadioBox';
import { getBrregLink, getProffLink, OrgDTO, OrgNrSearchResponse } from '../common';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';

interface EierDataBlockWithOverrideProps {
  onSetOrg: (val: EierDataOrganisasjon) => void;
  oppdaterEierUrl?: string;
  organisasjon?: EierDataOrganisasjon;
}

const EierDataBlockWithOverride: React.FC<EierDataBlockWithOverrideProps> = ({ ...props }) => {
  const [searching, setSearching] = useState<boolean>(false);
  const [overriding, setOverriding] = useState<boolean>(false);
  const [orgIndex, setOrgIndex] = useState<Array<OrgDTO>>([]);
  const [org, setOrg] = useState<EierDataOrganisasjon>({
    ...props.organisasjon
  });

  async function handleSearch(value: string) {
    if (!value || value.length < 3) {
      setSearching(false);
      return [];
    }

    try {
      setSearching(true);
      const response: OrgNrSearchResponse = await api.get(`/api/v1/data/OrganisasjonsvelgerSok/${value}?withOrgnrInNavn=false`);
      setSearching(false);
      if (!response.organisasjonResponse) {
        setOrgIndex([]);
        return [];
      }
      setOrgIndex(response.organisasjonResponse);
      return response.organisasjonResponse.map((e) => ({
        label: e.navn,
        value: e.organisasjonsnummer
      }));
    } catch (e) {
      return [];
    }
  }

  function handleChange(orgNr?: string) {
    if (!orgNr) {
      setOrg(props.organisasjon ?? {});
      props.onSetOrg(props.organisasjon ?? {});
      return;
    }
    const org = orgIndex.find((p) => p.organisasjonsnummer == orgNr);
    if (!org) return;
    const newValue = {
      orgNr: org.organisasjonsnummer,
      navn: org.navn
    } as EierDataOrganisasjon;
    setOrg(newValue);
    props.onSetOrg(newValue);
  }

  return (
    <div className="EierDataBlockWithOverride">
      <Text className="EierLabel" variant="mono-16">
        Hjemmelshaver i følge matrikkelinformasjon:
      </Text>
      <div className="Org-data">
        <Text component="p" variant="paragraph-header">
          {props.organisasjon?.navn}
        </Text>
        <Text component="p">
          Org. nr: {props.organisasjon?.orgNr} <br />
        </Text>
      </div>
      <Text className="EierLabel" variant="mono-16">
        Er denne informasjonen korrekt?
      </Text>
      <div className="Override-radios">
        <RadioBox
          label={'Ja'}
          name={'yes'}
          checked={!overriding}
          onChange={() => {
            setOverriding(false);
            handleChange();
          }}
        />
        <RadioBox
          label={'Nei, oppgi ny eier'}
          name={'no'}
          checked={overriding}
          onChange={() => {
            setOverriding(true);
          }}
        />
      </div>
      {overriding && (
        <div className="Org-edit">
          <AutoCompleteField
            label="Organisasjonsnummer:"
            validationState={searching ? 'loading' : null}
            onSearch={handleSearch}
            onChange={handleChange}
            placeholder={'Søk på org.nr. eller navn'}
            initialValue={org?.orgNr ?? props.organisasjon?.orgNr}
            formatOptionLabel={(opt, context) => {
              return context.context === 'menu'
                ? `${opt.label} (${opt.value})`
                : orgIndex.find((o) => o.navn === opt.value)?.organisasjonsnummer ?? '';
            }}
          />
          <Field label="Hjemmelshaver:" readOnly={true} value={org?.navn ?? '-'} />
        </div>
      )}
      {((org?.orgNr ?? props.organisasjon?.orgNr)?.length ?? 0) > 0 && (
        <div className="Org-links">
          <Text
            component="a"
            target={'_blank'}
            variant="download-link"
            title={'Åpne hjemmelshaver brreg.no-side i en ny fane'}
            href={getBrregLink(org?.orgNr ?? props.organisasjon?.orgNr)}
          >
            Les mer på brreg.no <IconReactIcons type={IconTypes.openInNew} size={1} />
          </Text>
          <Text
            component="a"
            target={'_blank'}
            title={'Åpne hjemmelshaver proff.no-side i en ny fane'}
            variant="download-link"
            href={getProffLink(org?.orgNr ?? props.organisasjon?.orgNr)}
          >
            Les mer på proff.no <IconReactIcons type={IconTypes.openInNew} size={1} />
          </Text>
        </div>
      )}
    </div>
  );
};

export default EierDataBlockWithOverride;
