import { useState, useEffect } from 'react';

export const useMapLock = (view, initialZoomDisabled) => {
  const [mapIsLocked, setMapIsLocked] = useState(initialZoomDisabled);

  const toggleMapLock = () => {
    setMapIsLocked(!mapIsLocked);
  };

  // Map lock support
  useEffect(() => {
    let wheelHandler,
      dragHandler,
      doubleClickHandler,
      dragModHandler,
      dragShiftHandler,
      specificKeyDowns;

    const handleMapLock = (e) => e.stopPropagation();

    if (view) {
      if (mapIsLocked) {
        wheelHandler = view.on('mouse-wheel', handleMapLock);
        doubleClickHandler = view.on('double-click', handleMapLock);
        dragShiftHandler = view.on('drag', ['Shift'], handleMapLock);
        dragModHandler = view.on('drag', ['Shift', 'Control'], handleMapLock);
        dragHandler = view.on('drag', handleMapLock);
        specificKeyDowns = view.on('key-down', function (event) {
          const prohibitedKeys = ['+', '-', 'Shift', '_', '='];
          const keyPressed = event.key;
          if (prohibitedKeys.indexOf(keyPressed) !== -1) {
            event.stopPropagation();
          }
        });
      }
    }

    return () => {
      wheelHandler && wheelHandler.remove();
      dragHandler && dragHandler.remove();
      doubleClickHandler && doubleClickHandler.remove();
      dragShiftHandler && dragShiftHandler.remove();
      dragModHandler && dragModHandler.remove();
      specificKeyDowns && specificKeyDowns.remove();
    };
  }, [view, mapIsLocked, initialZoomDisabled]);

  return [mapIsLocked, toggleMapLock];
};
