import cn from 'classnames';
import Hjelpetekst from 'components/Hjelpetekst/Hjelpetekst';
import IconButton from 'components/IconButton/IconButton';
import SelectField from 'components/SelectField/SelectField';
import useBooleanState from 'js/hooks/useBooleanState';
import React, { FC } from 'react';
import menneske from '../../assets/images/menneske.png';
import loginArrow from '../../assets/svg/LoginArrow.svg';
import logo from '../../assets/svg/Statsbygg_logo_sort.svg';
import style from './Header.module.css';
import { useWindowSize } from 'js/hooks/useWindowSize';
import { HamburgerButton } from './HamburgerButton';
import Text from 'components/Text/Text';
import { ifWindow } from 'js/checkWindow';
import { LinkItem } from 'types/LinkItem';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';

type MenuItem = {
  label: string;
  value: string;
  isSelected?: boolean;
};
export interface Header {
  readonly fulltNavn?: string;
  readonly profilBildeUrl?: string;
  readonly erLoggetInn?: boolean;
  readonly menuItems: LinkItem[];
  readonly projectNavMenuItems: LinkItem[];
  readonly projectNavTitle: LinkItem;
  readonly largeSidebarItems: LinkItem[];
  readonly smallSidebarItems: LinkItem[];
  readonly loginUrl: string;
  readonly opprettProsjektUrl?: string;

  readonly hjelpetekst?: {
    readonly id: string;
    readonly sidenavn: string;
    readonly tittel: string;
    readonly innhold?: string;
    readonly lagreUrl?: string;
    readonly slettUrl?: string;
  };
}

const Header: FC<Header> = ({
  fulltNavn,
  profilBildeUrl,
  erLoggetInn = false,
  menuItems = [],
  projectNavMenuItems = [],
  projectNavTitle,
  loginUrl,
  opprettProsjektUrl,
  hjelpetekst,
  largeSidebarItems,
  smallSidebarItems
}: Header) => {
  const [showHelp, , toggleShowHelp] = useBooleanState(false);
  const { lessThan } = useWindowSize();

  const print = () => {
    ifWindow(() => window.print());
  };

  return (
    <header>
      <div className={style.top}>
        <div>
          <div className={style.headerFlexRow}>
            {!lessThan.tablet && (
              <div className={style.hamburgerButtonWrapper}>
                <HamburgerButton largeSidebarItems={largeSidebarItems} smallSidebarItems={smallSidebarItems} />
              </div>
            )}
            <a className={style.logo} href="/">
              <img src={logo} />
            </a>
          </div>
          <div className={style.headerFlexRow}>
            {erLoggetInn ? <User bilde={profilBildeUrl} navn={fulltNavn} /> : <Login loginUrl={loginUrl} />}
            {lessThan.tablet && (
              <div className={style.hamburgerButtonWrapper}>
                <HamburgerButton largeSidebarItems={largeSidebarItems} smallSidebarItems={smallSidebarItems} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={style.nav}>
        <nav>
          <DesktopNav menuItems={menuItems.map(toMenuItem)} />
          <MobileNav menuItems={menuItems.map(toMenuItem)} />
          <div className={style.icons}>
            {!!opprettProsjektUrl && (
              <IconButton
                color="white"
                type="normal"
                size="small"
                title="Opprett et nytt prosjekt"
                href={opprettProsjektUrl}
                iconType={IconTypes.plus}
              />
            )}
            {hjelpetekst && (
              <IconButton
                color="white"
                title="Spørsmål"
                size="large"
                iconType={showHelp ? IconTypes.close : IconTypes.help}
                onClick={toggleShowHelp}
                type="normal"
              />
            )}
          </div>
        </nav>
      </div>

      {projectNavTitle?.text && (
        <div className={style.projectNav}>
          <nav>
            <div className={style.projectNavContainer}>
              <Text
                component="a"
                variant="download-link"
                className={style.projectName}
                title={projectNavTitle.text}
                href={projectNavTitle.url}
              >
                {projectNavTitle.text.length > 70 ? projectNavTitle.text.substring(0, 70) + '...' : projectNavTitle.text}
              </Text>

              <PageLinkMenu pageLinks={projectNavMenuItems} styles="-is-global" />
              <MobileNav menuItems={projectNavMenuItems.map(toMenuItem)} />
            </div>
          </nav>
        </div>
      )}

      {hjelpetekst && <Hjelpetekst show={showHelp} {...hjelpetekst} close={toggleShowHelp} />}
    </header>
  );
};

function User({ bilde: bilde, navn: fulltNavn }: { bilde?: string; navn?: string }) {
  const [isOpen, , toggleIsOpen] = useBooleanState(false);

  return (
    <button onClick={toggleIsOpen} className={style.user}>
      <IconReactIcons type={IconTypes.userCircle} size={5} />
      <Text variant="mono-16" component="span" className={style.notMobile}>
        {fulltNavn}
      </Text>
      <img className={cn(style.notMobile, { [style.open]: isOpen })} src={loginArrow} />
      {isOpen && (
        <div className={style.dropdown}>
          <a href="/LoggUt">
            <Text variant="mono-16" component="span">
              Logg ut
            </Text>
          </a>
        </div>
      )}
    </button>
  );
}

function Login({ loginUrl }: { loginUrl: string }) {
  return (
    <a href={loginUrl} className={style.login}>
      <img src={menneske} height="22" alt="Logg inn ikon" />
      <span className={style.notMobile}>Logg inn</span>
    </a>
  );
}

function DesktopNav({ menuItems }: { menuItems: MenuItem[] }) {
  return (
    <div className={style.desktopNav}>
      <Text component={'span'} variant={'tag'} className={style.goToLabel}>
        Gå til:
      </Text>
      {menuItems.map(({ value, label, isSelected }, index) => (
        <Text
          component={'a'}
          variant={'tag'}
          key={index}
          className={cn({
            [style.selected]: isSelected
          })}
          href={value}
        >
          {label}
        </Text>
      ))}
    </div>
  );
}

function MobileNav({ menuItems }: { menuItems: MenuItem[] }) {
  return (
    <div className={style.mobileNav}>
      <SelectField
        choices={menuItems.filter((x) => x.value)}
        onChange={(selected) => {
          window.location.href = selected.value;
        }}
      />
    </div>
  );
}

function toMenuItem({ text, url, isSelected }: LinkItem): MenuItem {
  return {
    label: text,
    value: url,
    isSelected: isSelected
  };
}

export default Header;
