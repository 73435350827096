import React, { FC, useState, ChangeEvent } from 'react';
import { cleanPost, post, upload } from 'js/api-helper';
import Heading from '../../components/Heading/Heading';
import Accordion from 'components/Accordion/Accordion';
import Input from '../../components/Input/Input';
import Checkbox from 'components/Checkbox/Checkbox';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import { LinkItem } from 'types/LinkItem';
import AdvancedFileSelector, { acceptedFileTypes } from 'components/AdvancedFileSelector/AdvancedFileSelector';
import FilelistWithSort from 'components/FileListWithSort/FileListWithSort';
import { Fildata } from 'types/Fildata';
import FormSubmitBox from 'components/FormSubmitBox/FormSubmitBox';
import Modal from 'components/Modal/Modal';
import ReccomendationLabel, { BefaringLabels } from 'components/ReccomendationLabel/ReccomendationLabel';
import { ObjektVurdering } from 'types/ObjektVurdering';
import AnchoredErrorMessage, { ErrorProps } from 'components/AnchoredErrorMessage/AnchoredErrorMessage';
import RapportVisning from 'types/no-gen/RapportVisning';

export interface BefaringsRapportSide {
  title: string;
  summary: { title: string; text: string; anbefaling: ObjektVurdering }[];
  befaringFullfort: boolean;

  visning: number;
  lastOppVedleggUrl: string;
  slettVedleggUrl: string;

  pageLinks?: LinkItem[];
  sendAnbefalingerUrl?: string; // url hvor PL kan sende anbefaling
  vedlegg?: Fildata[]; //gjelder bare evt. vedlegg PL laster opp

  anbefalingerGodkjentKommentar?: string; //evt. kommentarer OG har gitt
  anbefalingerGodkjennUrl?: string;
  anbefalingerGodkjentDato?: string; //dato for godkjennelse fra OG
}

const BefaringsRapportSide: FC<BefaringsRapportSide> = ({
  title,
  pageLinks,
  summary,

  befaringFullfort,
  sendAnbefalingerUrl,
  vedlegg,
  lastOppVedleggUrl,
  slettVedleggUrl,

  anbefalingerGodkjentKommentar,
  anbefalingerGodkjennUrl,
  anbefalingerGodkjentDato,
  visning
}) => {
  const [formdata, setFormdata] = useState({
    anbefalingerGodkjentKommentar: anbefalingerGodkjentKommentar ?? '',
    betingelserGodtatt: visning == RapportVisning.godkjentRapportView ?? false,
    vedlegg: vedlegg ?? []
  });

  const [view, setView] = useState<RapportVisning>(visning);

  const [modal, setModal] = useState(false);
  const [error, setError] = useState<ErrorProps | undefined>();

  const reloadPage = () => {
    window.location.reload();
  };

  const submitReccomendations = async (url) => {
    try {
      await post(url, { anbefalingerSendt: true });
      reloadPage();
    } catch (error) {
      console.log('error!');
    }
  };

  const approveWithComments = async (url) => {
    //post submit, submit relevant comments if any
    try {
      await post(url, { kommentar: formdata.anbefalingerGodkjentKommentar });
      reloadPage();
    } catch (error) {
      console.log('error!');
    }
  };

  const submitAttachments = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = Array.from(e.target.files ?? []);
    const data = new FormData();

    try {
      for (const file of fileList) {
        data.append('file', file);
      }

      const res = await cleanPost(lastOppVedleggUrl, data);

      if (res) {
        const newList = formdata?.vedlegg;
        newList.push(res);

        setFormdata({
          ...formdata,
          vedlegg: newList
        });
      }
    } catch (error) {
      setError({
        msg: error.message
      });
    }
  };

  const handleRemoveAttachment = async (id: string) => {
    try {
      const res = await post(`${slettVedleggUrl}?filId=${id}`);

      setFormdata({
        ...formdata,
        vedlegg: formdata?.vedlegg?.filter((item) => item.id !== id)
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="rapport">
      <Heading>{title}</Heading>
      {pageLinks && pageLinks?.length > 0 && <PageLinkMenu pageLinks={pageLinks} />}
      <Accordion title="Evalueringer og anbefalinger" defaultOpen={summary.length > 0} borders={true}>
        <div className="summary">
          {summary &&
            befaringFullfort &&
            summary.map((item) => {
              return (
                <div className="summary__item" key={item.title}>
                  <div>
                    <h3>{item.title}</h3>
                    <ReccomendationLabel anbefalingsEnum={BefaringLabels} vurdering={item.anbefaling} />
                  </div>
                  <p>{item.text}</p>
                </div>
              );
            })}
        </div>
      </Accordion>
      {/* {!anbefalingerSendt && !anbefalingerGodkjent && allowEditRoles.includes(rolle) && ( */}
      {view === RapportVisning.redigerRapportView && (
        <>
          <div className="attachments">
            {error?.msg && <AnchoredErrorMessage {...error} percentageTopPos={10} />}
            <h3>Vedlagte filer</h3>
            <AdvancedFileSelector
              name="attachments"
              onChange={(e) => {
                submitAttachments(e);
              }}
              accept={acceptedFileTypes.DocAndPdf}
              instruction="Tillate filtyper er .PDF og .DOCX,"
            ></AdvancedFileSelector>

            {formdata?.vedlegg.length > 0 && (
              <FilelistWithSort
                interactionDisabled={false}
                readOnly={false}
                files={formdata.vedlegg}
                onRemove={(id) => {
                  handleRemoveAttachment(id);
                }}
              />
            )}
          </div>
          <FormSubmitBox
            heading={'Send til oppdragsgiver for godkjennelse'}
            bodyText={
              !befaringFullfort
                ? 'Befaring er ikke fullført. Fullfør befaring i liste-visningen først'
                : 'Konkurransegrunnlaget er ikke publisert, du må be om oppdragsgivers godkjennelse før du kan publisere.'
            }
            disabled={!befaringFullfort}
            buttonText={'Send til Oppdragsgiver'}
            submitUrl={sendAnbefalingerUrl ?? ''}
            submitFunc={(url) => {
              submitReccomendations(url);
              setModal(true);
            }}
          />
          <Modal
            title={'Befaringsrapport er nå sendt til oppdragsgiver'}
            clickOutsideToClose={true}
            open={modal}
            onClose={() => setModal(false)}
          >
            <p>Oppdragsgiver må nå godkjenne befaringsrapporten for å gå videre til seriøsitetssjekk.</p>
          </Modal>
        </>
      )}
      {/* {anbefalingerSendt && !anbefalingerGodkjent && allowEditRoles.includes(rolle) && ( */}
      {view === RapportVisning.levertRapportView && (
        <>
          <div className="attachments">
            <h3>Vedlagte filer</h3>
            <FilelistWithSort
              interactionDisabled={false}
              readOnly={false}
              files={formdata?.vedlegg ?? []}
              onRemove={(id) => {
                handleRemoveAttachment(id);
              }}
            ></FilelistWithSort>
          </div>
          <div className="complete">
            <div className="complete__text">
              <div className="font-paragraph-header">Anbefalinger har blitt levert til oppdragsgiver</div>
              <p className="font-paragraph">Prosessen avventer nå godkjenning.</p>
            </div>
          </div>
        </>
      )}
      {/* {anbefalingerSendt && !anbefalingerGodkjent && rolle == 'OG' && ( */}
      {view == RapportVisning.lesRapportView && (
        <>
          <div className="attachments">
            <h3>Vedlagte filer</h3>
            <FilelistWithSort
              interactionDisabled={false}
              readOnly={true}
              files={formdata?.vedlegg ?? []}
              onRemove={(id) => {
                handleRemoveAttachment(id);
              }}
            ></FilelistWithSort>
          </div>
          <div className="form">
            <div className="form__row">
              <div>
                <h3>Oppdragsgivers vurdering</h3>
              </div>
              <div>
                <div className="form__comment">
                  <Input
                    value={formdata.anbefalingerGodkjentKommentar}
                    onChange={(e) => {
                      setFormdata({
                        ...formdata,
                        [e.currentTarget.name]: e.currentTarget.value
                      });
                    }}
                    name="anbefalingerGodkjentKommentar"
                    type="textarea"
                  ></Input>
                </div>
              </div>
            </div>
            <div className="form__row">
              <div>
                <h3>Oppdragsgivers godkjenning</h3>
              </div>
              <div>
                <Checkbox
                  name="godkjent"
                  label="Oppsummeringer og anbefalinger er lest og godkjent"
                  checked={formdata.betingelserGodtatt}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      betingelserGodtatt: e.currentTarget.checked
                    });
                  }}
                ></Checkbox>
              </div>
            </div>
            <FormSubmitBox
              heading="Godkjenn og send til prosjektleder"
              bodyText="For å gå videre til konkurranse må du som oppdragsgiver bekrefte at du har lest og godkjent anbefalningene fra Statensinnleie."
              disabled={!formdata.betingelserGodtatt}
              buttonText={'Send til Prosjektleder'}
              submitUrl={anbefalingerGodkjennUrl ?? ''}
              submitFunc={(url) => {
                approveWithComments(url);
                setModal(true);
              }}
            />
            <Modal title={'Befaringsrapport godkjent'} open={modal} clickOutsideToClose={true} onClose={() => setModal(false)}>
              <p>Befaringsrapporten er nå godkjent og søket vil gå videre til seriøsitetssjekk.</p>
            </Modal>
          </div>
        </>
      )}
      {view == RapportVisning.godkjentRapportView && (
        <>
          <div className="attachments">
            <h3>Vedlagte filer</h3>
            <FilelistWithSort
              interactionDisabled={false}
              readOnly={true}
              files={formdata?.vedlegg ?? []}
              onRemove={(id) => {
                handleRemoveAttachment(id);
              }}
            ></FilelistWithSort>
          </div>
          {anbefalingerGodkjentKommentar && (
            <div className="form__row">
              <div>
                <h3>Oppdragsgivers kommentar</h3>
              </div>

              <div>
                <p>{anbefalingerGodkjentKommentar}</p>
              </div>
            </div>
          )}
          {anbefalingerGodkjentDato && (
            <div className="form__row">
              <div>
                <h3>Oppdragsgivers godkjenning</h3>
              </div>
              <div>
                <p>{anbefalingerGodkjentDato}</p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BefaringsRapportSide;
