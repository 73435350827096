import UploadButton, { Styles } from 'components/UploadButton/UploadButton';
import React, { useCallback, useState } from 'react';
import Paragraph from 'components/Paragraph/Paragraph';
import DeleteButton from 'components/DeleteButton/DeleteButton';

export interface Props {
  readonly url: string;
  readonly uploadUrl: string;
  readonly deleteUrl?: string;
}

export default function ProfilBildePanel(props: Props) {
  const [url, setUrl] = useState(props.url);

  const onDelete = useCallback((res) => {
    setUrl('');
  }, []);

  return (
    <div className="ProfilBildePanel">
      <div className="ProfilBildePanel-lastopp">
        <h5>Legg til et profilbilde: </h5>
        <Paragraph>
          Profilbildet brukes i hovedsak sammen med et innsendt svar, bildet er ellers unntatt offentligheten.
        </Paragraph>

        <DeleteButton slettUrl={props.deleteUrl ?? '/none'} onDelete={onDelete} />
      </div>

      {url ? (
        <img src={url} />
      ) : (
        <UploadButton
          styles={Styles.ButtonAsLink}
          fileType="image"
          destinationUrl={props.uploadUrl}
          onUploadComplete={(file) => setUrl(file.nedlastingslenke)}
        />
      )}
    </div>
  );
}
