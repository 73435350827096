import React, { FC, useMemo } from 'react';
import style from './BefaringHeader.module.css';
import SingleDatePicker from 'components/DateRangePicker/SingleDatePicker';

interface IBefaringHeader {
  utleiemegler: string;
  utleier: string;
  fremutleier: string;
  datoForBefaring: string;
  befaringNr: number;
  beliggenhet: string;
  tilstede: string;
  readOnly: boolean;
  handleBefaringChange: (id: string, value: string) => void;
}

const BefaringHeader: FC<IBefaringHeader> = ({
  utleiemegler,
  utleier,
  fremutleier,
  datoForBefaring,
  befaringNr,
  beliggenhet,
  tilstede,
  readOnly,
  handleBefaringChange
}) => {
  const handleChange = (e) => {
    const { id, value } = e.target;
    handleBefaringChange(id, value);
  };

  const initialDate = useMemo(() => {
    if (datoForBefaring) return new Date(datoForBefaring);
    else {
      return new Date();
    }
  }, [datoForBefaring]);

  return (
    <div className={style.headerWrapper}>
      <div className={style.headerContainer}>
        <div className={style.inputContainer}>
          <section className={style.inputSection}>
            <label className={style.headerLabel} htmlFor={'utleiemegler'}>
              Næringsmegler:
            </label>
            <input
              disabled
              onChange={handleChange}
              className={style.headerInput}
              id={'utleiemegler'}
              value={utleiemegler}
            />
          </section>

          <section className={style.inputSection}>
            <label className={style.headerLabel} htmlFor={'datoForBefaring'}>
              Dato for befaring:
            </label>
            <div className={style.dateInputWrapper}>
              <SingleDatePicker
                placeholder={'Dato for befaring'}
                disabled={readOnly}
                className={style.headerInput}
                id={'datoForBefaring'}
                initialDate={initialDate}
                onDateChange={(date) =>
                  handleChange({
                    target: { id: 'datoForBefaring', value: date }
                  })
                }
                label={''}
              />
            </div>
          </section>

          
          <section className={style.inputSection}>
            <label className={style.headerLabel} htmlFor={'utleier'}>
              Hjemmelshaver:
            </label>
            <input
              disabled
              onChange={handleChange}
              className={style.headerInput}
              id={'utleier'}
              value={utleier}
            />
          </section>
          <section className={style.inputSection}>
            <label className={style.headerLabel} htmlFor={'beliggenhet'}>
              Beliggenhet:
            </label>
            <input
              disabled={readOnly}
              onChange={handleChange}
              className={style.headerInput}
              id={'beliggenhet'}
              value={beliggenhet}
            />
          </section>
        

          <section className={style.inputSection}>
            <label className={style.headerLabel} htmlFor={'fremutleier'}>
              Fremutleier:
            </label>
            <input
              disabled={readOnly}
              onChange={handleChange}
              className={style.headerInput}
              id={'fremutleier'}
              value={fremutleier}
            />
          </section>

          <section className={style.inputSection}>
            <label className={style.headerLabel} htmlFor={'befaringsnummer'}>
              Befaring nr:
            </label>
            <input
              disabled
              onChange={handleChange}
              className={style.headerInput}
              id={'befaringsnummer'}
              value={befaringNr}
            />
          </section>

          

          <section style={{ width: '100%' }} className={style.inputSection}>
            <label className={style.headerLabel} htmlFor={'tilstede'}>
              Tilstede:
            </label>
            <input
              disabled={readOnly}
              onChange={handleChange}
              className={style.headerInput}
              id={'tilstede'}
              value={tilstede}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default BefaringHeader;
