import React, { FC, useState, ChangeEvent } from 'react';

import { cleanPost, post, upload } from 'js/api-helper';
import Heading from '../../../components/Heading/Heading';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import { LinkItem } from 'types/LinkItem';
import FormSubmitBox from 'components/FormSubmitBox/FormSubmitBox';
import Modal from 'components/Modal/Modal';
import Text from 'components/Text/Text';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import Checkbox from 'components/Checkbox/Checkbox';
import AndreDokumentSkjema from 'components/AndreDokumentSkjema/AndreDokumentSkjema';
import { KonkurranseGrunnlagFil } from 'types/KonkurranseGrunnlagFil';
import { SortColumns } from 'components/DynamicFileList/DynamicFileList';
import { ErrorProps } from 'components/AnchoredErrorMessage/AnchoredErrorMessage';

export interface ForhandlingsStrategiSide {
  title: string;
  pageLinks?: LinkItem[];

  forhandlingsStrategiSendt: boolean; //skal si noe om anbefaling + evt. vedlegg er sendt for oppdragsgivers godkjenning
  kommentarInnsendt: boolean;
  rolle: string; //enten OG eller PL
  oppdragsGiverKommentar?: string;

  sendStrategiUrl: string; // url hvor PL kan sende anbefaling
  angreStrategiUrl: string; // url hvor PL kan angre anbefalinger
  sendInnKommentarUrl: string; // url hvor OG kan godkjenne anbefaling

  vedlegg?: KonkurranseGrunnlagFil[];
  lastOppVedleggUrl: string;
  slettVedleggUrl: string;
  readonly oppdaterFilStatusUrl: string;
  lopeNr?: string;
}

interface ModalData {
  open: boolean;
  title: string;
  body: React.ReactNode;
}

const ForhandlingsStrategiSide: FC<ForhandlingsStrategiSide> = ({
  title,
  pageLinks,
  rolle,

  forhandlingsStrategiSendt,
  kommentarInnsendt,
  oppdragsGiverKommentar,

  sendStrategiUrl,
  angreStrategiUrl,
  sendInnKommentarUrl,

  vedlegg,
  lastOppVedleggUrl,
  slettVedleggUrl,
  oppdaterFilStatusUrl,
  lopeNr
}) => {
  const [formdata, setFormdata] = useState({
    forhandlingsStrategiSendt: forhandlingsStrategiSendt,
    kommentarInnsendt: kommentarInnsendt,
    oppdragsGiverKommentar: oppdragsGiverKommentar
  });

  const [modal, setModal] = useState<ModalData>({ open: false, title: '', body: '' });
  const [hasBeenRead, setHasBeenRead] = useState<boolean>(kommentarInnsendt);
  const [error, setError] = useState<ErrorProps | undefined>();

  interface Payload {
    url: string;
    formParams: object;
    modal?: ModalData;
  }

  const submit = async (p: Payload) => {
    try {
      const res = await cleanPost(p.url, { ...p.formParams });
      if (res) {
        setFormdata({
          ...formdata,
          ...p.formParams
        });

        if (p.modal) {
          setModal(p.modal);
        }
      }
    } catch (error) {
      setError({
        msg: error.message
      });
    }
  };

  const regretReccomendations = async (undoUrl) => {
    try {
      const res = await cleanPost(undoUrl);
      if (res) {
        setFormdata({
          ...formdata,
          forhandlingsStrategiSendt: false
        });
      }
    } catch (error) {
      console.log(error);
      setError({
        msg: error.message
      });
    }
  };

  const allowEditRoles = ['PL', 'FR'];

  return (
    <div className="rapport">
      <Heading>{title}</Heading>
      {pageLinks && pageLinks?.length > 0 && <PageLinkMenu pageLinks={pageLinks} />}

      <Modal
        title={modal.title}
        open={modal.open}
        onClose={() =>
          setModal({
            ...modal,
            open: false
          })
        }
      >
        <p>{modal.body}</p>
      </Modal>
      <AndreDokumentSkjema
        oppdaterFilStatusUrl={oppdaterFilStatusUrl}
        overrideHeading={<h3 className="-heading--big">Vedlegg</h3>}
        readOnly={true}
        removable={rolle == 'PL'}
        andreDokumenter={vedlegg ?? []}
        lastOppAndreDokumenterUrl={lastOppVedleggUrl}
        slettVedleggUrl={slettVedleggUrl}
        disableDocRemoval={formdata.forhandlingsStrategiSendt}
        uploadField={rolle == 'PL'}
        fileListCols={[SortColumns.date, SortColumns.document, SortColumns.status, SortColumns.version]}
      />

      {!formdata.forhandlingsStrategiSendt && allowEditRoles.includes(rolle) && (
        <FormSubmitBox
          heading={'Fullfør forhandlingsstrategi'}
          bodyText={
            'Når du fullfører forhandlingsstrategi, rulles det igang en ny forhandlingsrunde. Forhandlingsstrategien vil også bil tilgjengeliggjort for oppdragsgiver.'
          }
          disabled={false}
          buttonText={'Fullfør'}
          submitUrl={sendStrategiUrl ?? ''}
          submitFunc={(url) => {
            submit({
              url: url,
              formParams: {
                forhandlingsStrategiSendt: true
              },
              modal: {
                open: true,
                title: 'Forhandlingsstrategi er fullført',
                body: (
                  <p>
                    Forhandlingsrunde {lopeNr && parseInt(lopeNr) + 1} vil starte snarest. <br></br>Statsbygg vil sende ut
                    innkallinger med agenda til de aktuelle aktørene.
                  </p>
                )
              }
            });
          }}
          error={error}
        />
      )}
      {formdata.forhandlingsStrategiSendt && allowEditRoles.includes(rolle) && (
        <div className="complete">
          <div className="font-paragraph-header">Forhandlingsstrategi fullført</div>
          <div className="complete__bottom-row">
            <p className="font-paragraph text">Forhandlingsstrategien er nå tilgjengelig for oppdragsgiver.</p>
            <Button
              className="undo"
              submit={true}
              secondary={true}
              disabled={false}
              onClick={() => regretReccomendations(angreStrategiUrl)}
            >
              Angre
            </Button>
          </div>
        </div>
      )}
      {formdata.oppdragsGiverKommentar && formdata.kommentarInnsendt && allowEditRoles.includes(rolle) && (
        <div className="form__row">
          <div>
            <h3>Oppdragsgivers kommentar:</h3>
          </div>

          <div>
            <p>{formdata.oppdragsGiverKommentar}</p>
          </div>
        </div>
      )}
      {rolle == 'OG' && (
        <FormSubmitBox
          heading="Kommenter forhandlingsstrategi"
          bodyText="For å sende inn kommentar, må du bekrefte at du har lest strategien."
          buttonText={'Fullfør'}
          regretButtonText="Fullfør"
          dokumenterPublisert={formdata.kommentarInnsendt}
          submitUrl={sendInnKommentarUrl}
          submitFunc={(url) => {
            submit({
              url: url,
              formParams: {
                oppdragsGiverKommentar: formdata.oppdragsGiverKommentar,
                kommentarInnsendt: true
              }
            });
          }}
          regretFunc={() => {}}
          disabled={!hasBeenRead || formdata.kommentarInnsendt}
          error={error}
        >
          <div className="row">
            <Field
              maxCharacters={500}
              className="CommentArea"
              label="Oppdragsgivers kommentar"
              type="textarea"
              name="anbefalingerGodkjentKommentar"
              value={formdata.oppdragsGiverKommentar}
              disabled={formdata.kommentarInnsendt}
              onChange={(e) =>
                setFormdata((prevState) => ({
                  ...prevState,
                  oppdragsGiverKommentar: e.target.value
                }))
              }
            />
          </div>
          <div className="row">
            <Text className="checkbox-disclaimer" variant="mono-16">
              Send inn oppdragsgivers kommentar{' '}
            </Text>
            <Checkbox
              name="godkjent"
              label="Strategien er lest."
              checked={hasBeenRead || formdata.kommentarInnsendt}
              onChange={(e) => {
                setHasBeenRead(!hasBeenRead);
              }}
            ></Checkbox>
          </div>
        </FormSubmitBox>
      )}
    </div>
  );
};

export default ForhandlingsStrategiSide;
