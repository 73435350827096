/* eslint-disable react/display-name */
import KontaktpersonPanel from 'components/Kontaktperson/Kontaktperson';
import ScoreRow from 'components/ScoreRow/ScoreRow';
import React, { FC, useMemo, useState } from 'react';
import { ArcgisServiceArea } from 'types/ArcgisServiceArea';
import { ArcgisSketch } from 'types/ArcgisSketch';
import { Kontaktperson } from 'types/Kontaktperson';
import RecommendationToolbar from '../components/RecommendationToolbar';
import SvarDetaljHeading from '../components/SvarDetaljHeading';
import SvarDetaljImages from '../components/SvarDetaljImages';
import SvarDetaljUtleierPanel from '../components/SvarDetaljUtleierPanel';
import { SvarDetaljHeaderValue } from '../components/SvarDetaljHeaderValue';
import style from '../SvarDetalj.module.css';
import { Svar } from 'types/Svar';
import { Fildata } from 'types/Fildata';
import SvarDetaljDokumenterPanel from '../components/SvarDetaljDokumenterPanel';
import Paragraphs from 'components/Paragraphs/Paragraphs';
import { shortDateString } from 'js/date-helper';
import { customKeysForObjectData } from 'js/custom-key-mappings';
import CommentBox from 'components/CommentBox/CommentBox';
import { LinkItem } from 'types/LinkItem';
import PageLinkMenu, { Styles } from 'components/PageLinkMenu/PageLinkMenu';
import RecindOfferBox from 'components/RecindOfferBox/RecindOfferBox';
import { post } from 'js/api-helper';

export interface SvarDetaljSide {
  readonly svar: Svar;
  readonly kontaktperson: Kontaktperson;
  readonly visVurdering?: boolean;
  readonly overstyrVurderingUrl?: string;
  readonly settEllerFjernTilBefaringUrl?: string;
  readonly lastNedZipUrl?: string;
  readonly visKommentar?: boolean;
  readonly visRedigerKommentar?: boolean;
  readonly kommenterUrl?: string;
  readonly sketches?: ArcgisSketch[];
  readonly serviceAreas?: ArcgisServiceArea[];
  readonly pageLinks?: LinkItem[];
  readonly tilbudTrukket?: boolean;
  readonly trekkTilbudUrl?: string;
}

const SvarDetaljSide: FC<SvarDetaljSide> = ({
  svar,
  kontaktperson,
  visVurdering = false,
  overstyrVurderingUrl,
  settEllerFjernTilBefaringUrl,
  lastNedZipUrl,
  visKommentar,
  visRedigerKommentar,
  kommenterUrl,
  sketches,
  serviceAreas,
  pageLinks,
  tilbudTrukket,
  trekkTilbudUrl
}) => {
  const getBilder = useMemo(() => {
    const arr: Fildata[] = [];

    function addToArr(key) {
      if (svar?.bildeData && svar?.bildeData[key]) {
        arr.push(svar?.bildeData[key]);
      }
    }

    addToArr('bildePlantegning');
    addToArr('bildeEksterior');
    addToArr('bildeInterior');
    return arr;
  }, [svar?.bildeData]);

  const recindOffer = async (url) => {
    try {
      await post(url);
      setOfferRecinded(true);
    } catch (error) {
      console.error(error);
    }
  };

  const [OfferRecinded, setOfferRecinded] = useState<boolean>(tilbudTrukket ?? false);

  return (
    <div className={style.SvarDetaljSide}>
      <SvarDetaljHeading matrikkelInfo={svar.matrikkelInfo} OfferRecinded={OfferRecinded} />
      {!pageLinks && <div className="on-grid" />}
      {pageLinks && <PageLinkMenu styles={Styles.onGrid} pageLinks={pageLinks} />}
      <SvarDetaljImages
        matrikkelInfo={svar.matrikkelInfo}
        overstyrtScoreHelhetsvurdering={svar.overstyrtScoreHelhetsvurdering}
        bilder={getBilder}
        sketches={sketches}
        serviceAreas={serviceAreas}
      />
      <ScoreRow
        isLeiesok={true}
        visVurdering={visVurdering}
        overstyrVurderingUrl={overstyrVurderingUrl}
        scores={svar.scores}
        avstandKollektivTransport={svar.avstandKollektivTransport}
        parkeringsData={svar.parkeringsData}
        miljoData={svar.miljoData}
        generellInfoLedigFraUtc={svar.generellInfoLedigFraUtc}
        totaltAreal={svar.totaltAreal}
        prisKvmMedTiltak={svar.prisKvmMedTiltak}
        prisKvmUtenTiltak={svar.prisKvmUtenTiltak}
        ledigAreal={svar.ledigAreal}
        arealPerPlan={svar.arealPerPlan}
        universellUtforming={svar.universellUtforming}
        felleskostKvm={svar.felleskostKvm}
        kostKantineBidrag={svar.kostKantineBidrag}
        mvaKompensasjonKvm={svar.mvaKompensasjonKvm}
        paslagFellesarealiProsent={svar.paslagFellesarealiProsent}
      />
      <div className={style.Info}>
        <SvarDetaljHeaderValue header="Standard" value={svar.generellInfoStandard} />
        <SvarDetaljHeaderValue header="Fasiliteter" value={svar.generellInfoFasiliteter} />
        {/* kommer inn som yyyy-MM-ddTHH:mm:ss.000Z fra backend */}
        <Paragraphs textVariant="paragraph">{'Ledig fra: ' + shortDateString(svar.generellInfoLedigFraUtc)}</Paragraphs>
        <SvarDetaljHeaderValue
          header="Transport"
          value={customKeysForObjectData(svar.parkeringsData, {
            parkeringsplasser: 'Parkeringsplasser: ',
            sykkelparkeringsplasser: 'P-plasser for sykkel: ',
            ladepunkter: 'Ladepunkter: ',
            sykkelplasskost: 'Kostnader, P-plasser for sykkel: ',
            parkeringsplasskost: 'Kostnader, P-plasser: '
          })}
        />
        <SvarDetaljHeaderValue
          header="Bygningens stand"
          value={`Bygningens stand: ${svar.bygningensStand}\n 
            Reguleringsstatus: ${svar.reguleringsstatus}`}
        />
        <SvarDetaljHeaderValue
          header="Bygningens stand"
          value={customKeysForObjectData(svar.miljoData, {
            energimerke: 'Energimerke ',
            planlagtEnergimerke: 'Planlagt energimerke: '
          })}
        />
        <Paragraphs textVariant="paragraph">
          {'Tiltak for å forbedre energieffektiviteten: ' + svar.energieffektivitetTiltak}
        </Paragraphs>

        <Paragraphs textVariant="paragraph">
          {'Er lokalet/utleier miljøsertifisert:  ' + svar.miljoData.miljosertifisert}
        </Paragraphs>

        <Paragraphs textVariant="paragraph">{'Videre spesifisering:  ' + svar.miljoData.miljosertifisertDetalj}</Paragraphs>

        <SvarDetaljHeaderValue
          header="Størrelse"
          value={`Totalt areal i bygget (BTA): ${svar.totaltAreal}\n
          Ledig areal i bygget (BTA): ${svar.ledigAreal}`}
        />
        <SvarDetaljHeaderValue /* areal-data */
          header={null}
          value={'Spesifisert areal per plan: \n' + svar.arealPerPlan.map((x) => `Plan ${x.plan}: ${x.areal} KVM`).join('\n')}
        ></SvarDetaljHeaderValue>

        <SvarDetaljHeaderValue /* universel-data */
          header={null}
          value={`Universell utforming: ${svar.universellUtforming}\n
        Videre spesifisering: ${svar.universellUtformingDetalj}`}
        ></SvarDetaljHeaderValue>
        <div className={style.PrisEstimatListe}>
          <SvarDetaljHeaderValue
            header="Prisestimater"
            value={customKeysForObjectData(
              {
                prisKvmMedTiltak: svar.prisKvmMedTiltak,
                prisKvmUtenTiltak: svar.prisKvmUtenTiltak,
                felleskostKvm: svar.felleskostKvm,
                mvaKompensasjonKvm: svar.mvaKompensasjonKvm,
                parkeringsplasskost: svar.parkeringsData.parkeringsplasskost,
                paslagFellesarealiProsent: svar.paslagFellesarealiProsent,
                kostKantineBidrag: svar.kostKantineBidrag,
                sykkelplasskost: svar.parkeringsData.sykkelplasskost
              },
              {
                prisKvmMedTiltak: 'Pris pr. kvm med planlagte tiltak: ',
                prisKvmUtenTiltak: 'Pris pr. kvm uten planlagte tiltak: ',
                felleskostKvm: 'felleskost pr. kvm: ',
                mvaKompensasjonKvm: 'Mva-kompensansjon pr. kvm BTA: ',
                parkeringsplasskost: 'Kost pr. parkeringsplass: ',
                paslagFellesarealiProsent: 'Påslag på fellesareal i prosent: ',
                kostKantineBidrag: 'Kost pr. ansatt i kantinebidrag: ',
                sykkelplasskost: 'kost per sykkelplass: '
              }
            )}
          />
        </div>
        <SvarDetaljHeaderValue header="Øvrige kommentarer" value={svar.kommentarer} />
      </div>
      <div className={style.BorderedContainer}>
        <KontaktpersonPanel panelType="border" {...kontaktperson} />
        <SvarDetaljUtleierPanel
          utleierNavn={svar.utleierNavn}
          utleier={svar.utleier}
          utleiemeglerNavn={svar.utleiemeglerNavn}
          utleiemegler={svar.utleiemegler}
        />
        <SvarDetaljDokumenterPanel ekstraDokumenter={svar.ekstraDokumenter} />
      </div>

      <RecommendationToolbar
        tilbudTrukket={tilbudTrukket}
        lastNedZipUrl={lastNedZipUrl}
        overstyrtScoreHelhetsvurdering={svar.overstyrtScoreHelhetsvurdering}
        internKommentar={svar.internKommentar}
        tilBefaring={svar.tilBefaring}
        levertDatoUtc={svar.levertDatoUtc}
        overstyrVurderingUrl={overstyrVurderingUrl}
        settEllerFjernTilBefaringUrl={settEllerFjernTilBefaringUrl}
        visKommentar={visKommentar}
        visRedigerKommentar={visRedigerKommentar}
        kommenterUrl={kommenterUrl}
      />
      <CommentBox
        internKommentar={svar.internKommentar}
        visKommentar={visKommentar}
        visRedigerKommentar={visRedigerKommentar}
        kommenterUrl={kommenterUrl}
      />
      {trekkTilbudUrl && (
        <div className={style.recindBox}>
          <RecindOfferBox prohibitRecind={OfferRecinded} recindOffer={() => recindOffer(trekkTilbudUrl)} />
        </div>
      )}
    </div>
  );
};

export default SvarDetaljSide;
