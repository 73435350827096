import { useState, useEffect } from 'react';
import { ifWindow } from 'js/checkWindow';
// Define general type for useWindowSize hook, which includes width and height
interface Size {
  width: number | undefined;
  height: number | undefined;
}

export const BREAKPOINTS = {
  TABLET: 768,
  DESKTOP: 1024,
  HD: 1260
};

// Hook
export function useWindowSize(): {
  lessThan: { tablet: boolean; desktop: boolean; hd: boolean };
} & Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined
  });

  const [lessThan, setLessThan] = useState({
    tablet: false,
    desktop: false,
    hd: false
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      ifWindow(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
        setLessThan({
          tablet: window.innerWidth < BREAKPOINTS.TABLET,
          desktop: window.innerWidth < BREAKPOINTS.DESKTOP,
          hd: window.innerWidth < BREAKPOINTS.HD
        });
      });
    }
    // Add event listener
    ifWindow(() => window.addEventListener('resize', handleResize));
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () =>
      ifWindow(() => window.removeEventListener('resize', handleResize));
  }, []); // Empty array ensures that effect is only run on mount
  return { ...windowSize, lessThan };
}
