import Heading from 'components/Heading/Heading';
import ProfilBildePanel from 'components/ProfilBildePanel/ProfilBildePanel';
import { dateTimeString } from 'js/date-helper';
import * as FormatHelper from 'js/format-helper';
import { usePostback } from 'js/hooks';
import React, { FC, useState } from 'react';
import { AiOutlineSync } from 'react-icons/ai';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import Paragraph from 'components/Paragraph/Paragraph';

export interface ProfilSide {
  readonly fulltNavn: string;
  readonly organisasjonsnavn: string;
  readonly rollenavn: string;
  readonly epostRedigerbar: boolean;
  readonly epost: string;
  readonly telefonRedigerbar: boolean;
  readonly telefon: string;
  readonly sistInnloggetUtc: string;
  readonly antallInnlogginger: number;
  readonly profilBildeUrl: string;
  readonly lagreProfilDataUrl: string;
  readonly lagreProfilBildeUrl: string;
  readonly slettProfilBildeUrl: string;
  readonly varselLeiesok: boolean;
}

const ProfilSide: FC<ProfilSide> = (props) => {
  const [epost, setEpost] = useState(props.epost);
  const [telefon, setTelefon] = useState(props.telefon);
  const [varselLeiesok, setVarsel] = useState(props.varselLeiesok);
  const { postback, validation, canSubmit, isBusy } = usePostback(props.lagreProfilDataUrl, {
    epost,
    telefon,
    varselLeiesok
  });

  return (
    <>
      <Heading>Min profil</Heading>
      <div className="ProfilSide">
        <div className="ProfilSide__backdrop"></div>

        <div className="ProfilSide__info">
          <h5>Navn</h5>
          <div>{props.fulltNavn}</div>

          <h5>Departement / Virksomhet</h5>
          <div>{props.organisasjonsnavn}</div>

          <h5>Rolle</h5>
          <div>{props.rollenavn}</div>

          <h5>Epost</h5>
          {props.epostRedigerbar ? (
            <Field
              name="Epost"
              label=""
              defaultValue={props.epost}
              onChange={(e) => setEpost(e.target.value)}
              {...validation.epost}
            />
          ) : (
            <div>{props.epost}</div>
          )}

          <h5>Telefon</h5>
          {props.telefonRedigerbar ? (
            <Field
              name="Telefon"
              label=""
              defaultValue={props.telefon}
              onChange={(e) => setTelefon(e.target.value)}
              {...validation.telefon}
            />
          ) : (
            <div>{FormatHelper.phoneNumberString(props.telefon)}</div>
          )}
        </div>

        <ProfilBildePanel
          url={props.profilBildeUrl}
          uploadUrl={props.lagreProfilBildeUrl}
          deleteUrl={props.slettProfilBildeUrl}
        />

        <div className="ProfilSide__submitArea">
          <h5>Varslinger</h5>
          <label className="varselWrapper" htmlFor={'varselCheckBox'}>
            <input
              id={'varselCheckBox'}
              type="checkbox"
              checked={varselLeiesok}
              onChange={() => setVarsel((prevState) => !prevState)}
              {...validation.varselLeiesok}
            />
            <Paragraph>Jeg ønsker å motta varslinger på epost når Statensinnleie oppretter et nytt søk.</Paragraph>
          </label>
          {isBusy && <AiOutlineSync className="spin" />}
          <Button onClick={postback} disabled={!canSubmit}>
            Lagre
          </Button>
        </div>
      </div>
      <div className="loginDataFooter">
        <div>
          <h5>Sist innlogget: </h5>
          <div>{dateTimeString(props.sistInnloggetUtc)}</div>
        </div>
        <div>
          <h5>Antall innlogginger: </h5>
          <div>{props.antallInnlogginger}</div>
        </div>
      </div>
    </>
  );
};

export default ProfilSide;
