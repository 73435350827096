import React from 'react';
import IconButton from 'components/IconButton/IconButton';
import { IconTypes } from 'components/Icon/IconReactIcons';
import { ifWindow } from 'js/checkWindow';
import Text from 'components/Text/Text';
import style from '../SvarDetalj.module.css';

function fallbackCopyTextToClipboard(text) {
  ifWindow(() => {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  });
}
function copyTextToClipboard(text) {
  ifWindow(() => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log('Async: Copying to clipboard was successful!');
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      }
    );
  });
}

const EmbeddedButton = () => {
  const [copyComplete, setCopyComplete] = React.useState(false);
  function handleClick() {
    ifWindow(async () => {
      const url = window.location.href + '/blokk';
      await copyTextToClipboard(url);
      setCopyComplete(true);
    });
  }

  return (
    <div className={style.embeddedButton}>
      <IconButton iconType={IconTypes.embed} onClick={handleClick} />
      {copyComplete && <Text variant={'alt-text'}>URL kopiert</Text>}
    </div>
  );
};

export default EmbeddedButton;
