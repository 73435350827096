import React from 'react';
import classnames from 'classnames';
import styles from './Paragraph.module.css';

interface IParagraph {
  bold?: boolean;
  inline?: boolean;
  zeromargin?: boolean;
  spaced?: boolean;
  ingress?: boolean;
  children?: React.ReactNode;
  className?: boolean;
}
const Paragraph = ({
  bold,
  inline,
  zeromargin,
  spaced,
  ingress,
  children,
  className
}: IParagraph) => {
  const classes = classnames(className, styles.paragraph, {
    [styles.ingress]: ingress,
    [styles.bold]: bold,
    [styles.inline]: inline,
    [styles.spaced]: spaced,
    [styles.zeromargin]: zeromargin
  });
  return <p className={classes}>{children}</p>;
};

export default Paragraph;
