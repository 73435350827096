/* eslint-disable @typescript-eslint/ban-types */
import { shortDateString } from 'js/date-helper';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Accessor, Column, usePagination, useSortBy, useTable } from 'react-table';
import Text from 'components/Text/Text';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';

type CellAccessor<T extends object> = keyof T | Accessor<T>;

export interface Props<T extends object> {
  readonly data: T[];
  readonly paging?: boolean;
  readonly pageSize?: number;
  readonly columns: Record<string, CellAccessor<T>>;
  readonly iconsComponent: FC<T>;
  readonly noItemsLabel?: string;
}

export default function Table<T extends object>({
  data,
  pageSize = 12,
  paging = false,
  columns: columnMap,
  iconsComponent: IconsComponent,
  noItemsLabel
}: Props<T>) {
  const pageSizeIfDisabled = useMemo(() => (paging ? pageSize : 1000), [paging, pageSize]);
  const columns = useMemo(() => mapColumns(columnMap), [columnMap]);
  const [selectedProjectId, setSelectedProjectId] = useState('');

  useEffect(() => {
    setSelectedProjectId(window.location.hash.replace('#', ''));
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable<T>(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: pageSizeIfDisabled }
    },
    useSortBy,
    usePagination
  );

  return (
    <div className="Table">
      {pageCount > 0 ? (
        <table {...getTableProps()} className="Table__table">
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  // eslint-disable-next-line react/jsx-key
                  <th className="Table__headers" {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <Text className={'Table__table-header'} noMargin variant="table-header">
                      {column.render('Header')}
                      {column.isSorted && column.isSortedDesc ? (
                        <IconReactIcons size={1} type={IconTypes.arrowUp} />
                      ) : (
                        column.isSorted && <IconReactIcons size={1} type={IconTypes.arrowDown} />
                      )}
                    </Text>
                    {/* Add a sort direction indicator */}
                  </th>
                ))}
                <th key="icons">{/* The column for the icons in each row*/}</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...row.getRowProps()} id={row.original['id'] === selectedProjectId ? 'selected-row' : ''}>
                  {row.cells.map((cell) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td title={cell.value} className="Table__cell" {...cell.getCellProps()}>
                        <div>
                          <Text noMargin variant="list-item">
                            {cell.render('Cell')}
                          </Text>
                        </div>
                      </td>
                    );
                  })}
                  <td className="Table__cell Table__cell__icons">
                    <IconsComponent {...row.original} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="Table__empty">{noItemsLabel ?? 'Ingen elementer'}</div>
      )}

      {paging && pageCount > 1 && (
        <div className="Table__pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <IconReactIcons size={1} type={IconTypes.doubleArrowLeft} />
          </button>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            <IconReactIcons size={1} type={IconTypes.arrowLeft} />
          </button>
          {sequence(pageCount).map((index) => (
            <Text
              component="button"
              variant="tag"
              key={index}
              className={index === pageIndex ? 'active' : 'inactive'}
              onClick={() => gotoPage(index)}
            >
              {index + 1}
            </Text>
          ))}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            <IconReactIcons size={1} type={IconTypes.arrowRight} />
          </button>

          <span className="Table__pagination__text">
            <span>
              <Text noMargin variant="tag">
                Viser side{' '}
                <b>
                  {pageIndex + 1} av {pageOptions.length}
                </b>
              </Text>
            </span>
          </span>
        </div>
      )}
    </div>
  );
}

function sequence(count: number) {
  return Array(count)
    .fill(null)
    .map((_, i) => i);
}

function mapColumns<T extends object>(columnMap: Record<string, CellAccessor<T>>) {
  return Object.entries(columnMap).map(([Header, accessor]) => {
    return {
      Header,
      accessor,
      Cell: (props) => {
        const formatAsDate = typeof props.value === 'number' && props.value > 9999; // regular numbers in the table not proceed this
        return formatAsDate ? shortDateString(`${new Date(props.value)}`) : props.value;
      }
    } as Column<T>;
  });
}
