import React, { FC, useState, ChangeEvent } from 'react';

import { cleanPost, post, upload } from 'js/api-helper';

import Heading from '../../../components/Heading/Heading';
import Accordion from '../../../components/Accordion/Accordion';

import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import { LinkItem } from 'types/LinkItem';

import FormSubmitBox from '../../../components/FormSubmitBox/FormSubmitBox';
import Modal, { ModalProps } from '../../../components/Modal/Modal';
import ReccomendationLabel, { SeriositetsLabels } from '../../../components/ReccomendationLabel/ReccomendationLabel';

import Button from 'components/Button/Button';
import AndreDokumentSkjema from 'components/AndreDokumentSkjema/AndreDokumentSkjema';
import { KonkurranseGrunnlagFil } from 'types/KonkurranseGrunnlagFil';
import { SortColumns } from '../../../components/DynamicFileList/DynamicFileList';
import AnchoredErrorMessage, { ErrorProps } from '../../../components/AnchoredErrorMessage/AnchoredErrorMessage';
import { ObjektVurdering } from 'types/ObjektVurdering';
import RapportVisning from 'types/no-gen/RapportVisning';

export interface ForhandlingsRapportSide {
  title: string;
  summary: { title: string; text: string; anbefaling: ObjektVurdering }[];
  visning: number;
  pageLinks?: LinkItem[];
  sendAnbefalingerUrl?: string; // url hvor PL kan sende anbefaling
  angreAnbefalingerUrl?: string; // url hvor PL kan angre anbefalinger
  andreDokumenter: KonkurranseGrunnlagFil[];
  lastOppAndreDokumenterUrl: string;
  slettVedleggUrl: string;
  oppdaterFilStatusUrl: string;
}

const ForhandlingsRapportSide: FC<ForhandlingsRapportSide> = ({
  title,
  pageLinks,
  summary,

  sendAnbefalingerUrl,
  angreAnbefalingerUrl,

  andreDokumenter,
  lastOppAndreDokumenterUrl,
  slettVedleggUrl,
  oppdaterFilStatusUrl,

  visning
}) => {
  const [view, setView] = useState<RapportVisning>(visning);

  const [modal, setModal] = useState<ModalProps>({
    open: false,
    title: 'Evalueringsrapport er fullført',
    children: <p>Oppdragsgiver vil nå varsles om at evalueringsrapporten er tilgjengelig.</p>,
    onClose: () => setModal,
    clickOutsideToClose: true
  });

  const [error, setError] = useState<ErrorProps | undefined>();

  const submitReccomendations = async (url) => {
    try {
      const res = await cleanPost(url);
      if (res) {
        setModal((prevState) => ({
          ...prevState,
          open: true
        }));
        setView(RapportVisning.levertRapportView);
      }
    } catch (error) {
      setError({
        msg: error.message
      });
    }
  };

  const regretReccomendations = async (undoUrl) => {
    try {
      const res = await cleanPost(undoUrl);
      if (res) {
        setView(RapportVisning.redigerRapportView);
      }
    } catch (error) {
      console.log(error);
      setError({
        msg: error.message
      });
    }
  };

  return (
    <div className="rapport">
      <Heading>{title}</Heading>
      {pageLinks && pageLinks?.length > 0 && <PageLinkMenu pageLinks={pageLinks} />}
      <Accordion title="Evaluering av konkurransegrunnlag" defaultOpen={summary.length > 0}>
        <div className="summary">
          {summary &&
            summary.map((item) => {
              return (
                <div className="summary__item" key={item.title}>
                  <div>
                    <h3>{item.title}</h3>
                    <ReccomendationLabel anbefalingsEnum={SeriositetsLabels} vurdering={item.anbefaling} />
                  </div>
                  <p>{item.text}</p>
                </div>
              );
            })}
        </div>
      </Accordion>
      <Modal
        title={modal.title}
        open={modal.open}
        onClose={() =>
          setModal({
            ...modal,
            open: false
          })
        }
      >
        {modal.children}
      </Modal>
      {view === RapportVisning.redigerRapportView && (
        <>
          <AndreDokumentSkjema
            oppdaterFilStatusUrl={oppdaterFilStatusUrl}
            overrideHeading={<h3 className="-heading--big">Vedlegg</h3>}
            readOnly={true}
            andreDokumenter={andreDokumenter}
            lastOppAndreDokumenterUrl={lastOppAndreDokumenterUrl}
            slettVedleggUrl={slettVedleggUrl}
            uploadField={true}
            fileListCols={[SortColumns.date, SortColumns.document, SortColumns.status, SortColumns.version]}
            disableDocRemoval={false}
          />

          <FormSubmitBox
            heading={'Fullfør evalueringen'}
            bodyText={'Tilgjengeliggjør evalueringsrapporten for oppdragsgiver.'}
            disabled={false}
            buttonText={'Fullfør'}
            submitUrl={sendAnbefalingerUrl ?? ''}
            submitFunc={(url) => {
              submitReccomendations(url);
            }}
            error={error}
          />
        </>
      )}
      {view === RapportVisning.levertRapportView && (
        <>
          <AndreDokumentSkjema
            oppdaterFilStatusUrl={oppdaterFilStatusUrl}
            overrideHeading={<h3 className="-heading--big">Vedlegg</h3>}
            readOnly={true}
            andreDokumenter={andreDokumenter}
            lastOppAndreDokumenterUrl={lastOppAndreDokumenterUrl}
            slettVedleggUrl={slettVedleggUrl}
            uploadField={false}
            fileListCols={[SortColumns.date, SortColumns.document, SortColumns.status, SortColumns.version]}
            disableDocRemoval={true}
          />

          <div className="complete">
            <div className="font-paragraph-header">Fullfør evalueringen</div>
            <div className="complete__bottom-row">
              <p className="font-paragraph text">Evalueringsrapporten er fullført.</p>
              <Button
                className="undo"
                submit={true}
                secondary={true}
                disabled={false}
                onClick={() => regretReccomendations(angreAnbefalingerUrl)}
              >
                Angre
              </Button>
              {error && <AnchoredErrorMessage {...error} percentageTopPos={120} />}
            </div>
          </div>
        </>
      )}
      {view === RapportVisning.lesRapportView && (
        <AndreDokumentSkjema
          oppdaterFilStatusUrl={oppdaterFilStatusUrl}
          overrideHeading={<h3 className="-heading--big">Vedlegg</h3>}
          readOnly={true}
          andreDokumenter={andreDokumenter}
          lastOppAndreDokumenterUrl={lastOppAndreDokumenterUrl}
          slettVedleggUrl={slettVedleggUrl}
          uploadField={false}
          fileListCols={[SortColumns.date, SortColumns.document, SortColumns.status, SortColumns.version]}
          disableDocRemoval={true}
        />
      )}
    </div>
  );
};

export default ForhandlingsRapportSide;
