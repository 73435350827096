import cn from 'classnames';
import AutoCompleteField, {
  Props as AutoCompleteFieldProps
} from 'components/AutoCompleteField/AutoCompleteField';
import * as api from 'js/api-helper';
import React from 'react';

interface Response {
  readonly organisasjonResponse: {
    readonly organisasjonsnummer: string;
    readonly navn: string;
  }[];

  readonly status: string;
}

async function handleSearch(value: string) {
  if (!value || value.length < 3) return [];

  try {
    const response: Response = await api.get(
      `/api/v1/data/OrganisasjonsvelgerSok/${value}`
    );

    if (!response.organisasjonResponse) return [];

    return response.organisasjonResponse.map((e) => ({
      label: e.navn,
      value: e.organisasjonsnummer
    }));
  } catch (e) {
    return [];
  }
}

export type Props = Omit<AutoCompleteFieldProps, 'onSearch'>;

export default function Organisasjonsvelger(props: Props, spanFull?) {

  return (
    <div className={cn("Organisasjonsvelger", spanFull ? "span-full" : "")}>
      <AutoCompleteField {...props} onSearch={handleSearch} />
    </div>
  );
}
