import SelectField from 'components/SelectField/SelectField';
import React, { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import { getIcon, IconTypes } from 'components/Icon/IconReactIcons';

export interface FagRessursSelector {
  dropdownOptions: any;
  selectedFagRessurser: {
    label: any;
    value: any;
  }[];
  setSelectedFagRessurser: any;
  label: string | undefined;
}

const defaultData = { label: 'Fagressurs', value: '' };

export default function FagRessursSelector({ ...props }: FagRessursSelector) {
  const [selectedOption, setSelectedOption] = useState(defaultData);

  useEffect(() => {
    setSelectedOption(defaultData);
  }, [props.selectedFagRessurser]);

  return (
    <div className="FagRessursSelector">
      <SelectField
        label={props.label}
        placeholder="fagressurs"
        selectedValue={selectedOption}
        choices={[...props.dropdownOptions]}
        onChange={(e) => setSelectedOption(e)}
      />
      <div className="FagRessursSelector--add-lines">
        <Button
          disabled={selectedOption.value === ''}
          icon
          title={getIcon(IconTypes.plus).alt}
          onClick={() => props.setSelectedFagRessurser((prevState) => [...prevState, selectedOption])}
        >
          {getIcon(IconTypes.plus).Component}
        </Button>
      </div>
      {props.selectedFagRessurser.map(({ label, value }) => (
        <React.Fragment key={value}>
          <div style={{ marginLeft: 5 }}>{label}</div>
          <Button
            title={getIcon(IconTypes.remove).alt}
            icon
            onClick={() => props.setSelectedFagRessurser((prevState) => prevState.filter((x) => x.value != value))}
          >
            {getIcon(IconTypes.remove).Component}
          </Button>
        </React.Fragment>
      ))}
    </div>
  );
}
