import cn from 'classnames';
import React, { useCallback } from 'react';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import Button from 'components/Button/Button';
import style from './Popup.module.css';
import Text from 'components/Text/Text';

export interface Props {
  readonly score: number;
  readonly label?: string;
  readonly titleComponent?: React.ReactNode;
  readonly column?: boolean;
  onChange(score: number): void;
}

export function Vurdering({ label = 'Vurdering', titleComponent, score, onChange, column }: Props) {
  const unset = useCallback(() => onChange(0), [onChange]);
  const setBad = useCallback(() => onChange(1), [onChange]);
  const setMedium = useCallback(() => onChange(2), [onChange]);
  const setGood = useCallback(() => onChange(3), [onChange]);

  return (
    <div
      className={style.Vurdering}
      style={
        column
          ? {
              flexDirection: 'column',
              alignItems: 'flex-start'
            }
          : {}
      }
    >
      {titleComponent || (
        <Text variant="tag" noMargin>
          {label}:{' '}
        </Text>
      )}

      <div className={style.ButtonContainer}>
        {!!score && (
          <Button onClick={unset} className={style.Reset}>
            <BsArrowCounterclockwise />
          </Button>
        )}

        <Button secondary className={cn({ [style.Bad]: score === 1 })} onClick={setBad}>
          Dårlig
        </Button>

        <Button secondary className={cn({ [style.Medium]: score === 2 })} onClick={setMedium}>
          Middels
        </Button>

        <Button secondary className={cn({ [style.Good]: score === 3 })} onClick={setGood}>
          God
        </Button>
      </div>
    </div>
  );
}
