import React, { FC, useState } from 'react';
import style from './BefaringKommentarBoks.module.css';
import { BefaringsVurdering } from '../BefaringSide';

export interface IBefaringAnbefalingBoks {
  onChange?: () => void;
  readOnly?: boolean;
  tittel: string;
  id: string;
  verdi: string;
  vurdering: BefaringsVurdering;
  handleAnbefalingKommentarChange: (id: string, value: string) => void;
  handleAnbefaling: (boolean) => void;
}

const BefaringAnbefalingBoks: FC<IBefaringAnbefalingBoks> = ({
  tittel,
  id,
  verdi,
  vurdering,
  handleAnbefalingKommentarChange,
  handleAnbefaling,
  readOnly
}) => {
  const [currentValue, setCurrentValue] = useState(verdi);

  const handleParentChangeOnBlur = () => {
    handleAnbefalingKommentarChange(id, currentValue);
  };

  const handleChange = (e) => {
    setCurrentValue(e.target.value);
  };

  return (
    <div className={`${style.wrapper} ${readOnly && style.disabled}`}>
      <div className={style.leftSideContainer}>
        <label className={style.labelTitle} htmlFor={id}>
          {tittel}
        </label>
        <div className={style.anbefalingContainer}>
          <div className={style.radioContainer}>
            <input
              id="anbefalt"
              type="radio"
              value={vurdering}
              name="anbefalt"
              autoComplete="off"
              disabled={readOnly}
              onChange={() => {
                handleAnbefaling(BefaringsVurdering.ANBEFALES);
              }}
              checked={vurdering == BefaringsVurdering.ANBEFALES}
            />
            <label htmlFor="anbefalt" className={style.commentText}>
              Anbefales til konkurranse
            </label>
          </div>
          <div className={style.radioContainer}>
            <input
              id="ErAnbefaltMedAnmerkning"
              type="radio"
              name="ErAnbefaltMedAnmerkning"
              value={vurdering}
              autoComplete="off"
              disabled={readOnly}
              onChange={() => {
                handleAnbefaling(BefaringsVurdering.ANBEFALES_MED_ANMERKNING);
              }}
              checked={vurdering == BefaringsVurdering.ANBEFALES_MED_ANMERKNING}
            />
            <label htmlFor="ErAnbefaltMedAnmerkning" className={style.commentText}>
              Anbefales til konkurranse med anmerkninger
            </label>
          </div>
          <div className={style.radioContainer}>
            <input
              id="ikkeAnbefalt"
              type="radio"
              name="ikkeAnbefalt"
              value={vurdering}
              autoComplete="off"
              disabled={readOnly}
              onChange={() => {
                handleAnbefaling(BefaringsVurdering.ANBEFALES_IKKE);
              }}
              checked={vurdering == BefaringsVurdering.ANBEFALES_IKKE}
            />
            <label htmlFor="ikkeAnbefalt" className={style.commentText}>
              Anbefales ikke til konkurranse
            </label>
          </div>
        </div>
      </div>

      <div className={style.inputContainer}>
        {readOnly ? (
          currentValue ? (
            <div className={style.input}>{currentValue}</div>
          ) : (
            <p className={style.emptyNote}>Ingen notater</p>
          )
        ) : (
          <textarea
            placeholder="Fyll ut her"
            className={style.input}
            value={currentValue}
            id={id}
            onBlur={handleParentChangeOnBlur}
            onChange={handleChange}
          />
        )}
      </div>
      <div className={style.tooltipContainer} />
    </div>
  );
};
export default BefaringAnbefalingBoks;
