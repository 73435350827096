import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import React from 'react';

const DownloadAllFiles = ({ downloadUrl }: { downloadUrl: string }) => {
  return (
    <a className="download-btn" href={downloadUrl} download>
      Last ned alle dokumenter <IconReactIcons type={IconTypes.download} />
    </a>
  );
};

export default DownloadAllFiles;
