import React from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';

export const DropdownMenu = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Root>
>(({ children, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.Root className="DropdownMenu" {...props} ref={forwardedRef}>
      {children}
    </DropdownMenuPrimitive.Root>
  );
});

type DropdownMenuTriggerProps = {
  iconType?: IconTypes;
};

export const DropdownMenuTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Trigger> & DropdownMenuTriggerProps
>(({ children, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.Trigger asChild className="DropdownMenuTrigger" {...props} ref={forwardedRef}>
      {props.iconType ? (
        <button className="IconButton">
          {children}
          <IconReactIcons type={props.iconType} />
        </button>
      ) : (
        <button>{children}</button>
      )}
    </DropdownMenuPrimitive.Trigger>
  );
});

export const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ children, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content className="DropdownMenuContent" {...props} ref={forwardedRef}>
        {children}
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Portal>
  );
});

export const DropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label>
>(({ children, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.Label className="DropdownMenuLabel" {...props} ref={forwardedRef}>
      {children}
    </DropdownMenuPrimitive.Label>
  );
});

export const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>
>(({ children, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.Item className="DropdownMenuItem" {...props} ref={forwardedRef}>
      {children}
    </DropdownMenuPrimitive.Item>
  );
});

export const DropdownMenuGroup = DropdownMenuPrimitive.Group;

export const DropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ children, ...props }, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.Separator className="DropdownMenuSeparator" {...props} ref={forwardedRef}>
      {children}
    </DropdownMenuPrimitive.Separator>
  );
});
