import { useEffect, useState } from 'react';
import { post, ValidationError } from '../api-helper';
import useAction from './useAction';
import useDirtyTracking from './useDirtyTracking';
import useMutableRef from './useMutableRef';
import useValidation, { Fields, Validations } from './useValidation';

export type { Fields, Validations };

const forever = new Promise(() => {});
export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export default function usePostback<T extends Fields>(url: string, fields: T) {
  const fieldRef = useMutableRef(fields);
  const [validation, validate, resetValidation] = useValidation(fields);
  const [isDirty, resetDirty] = useDirtyTracking(fields);
  const [isBusy, setBusy] = useState(false);

  useEffect(() => {
    if (isDirty) {
      const listener = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = '';
      };
      addEventListener('beforeunload', listener);
      return () => removeEventListener('beforeunload', listener);
    }
  }, [isDirty]);

  const postback = useAction(async () => {
    resetValidation();
    setBusy(true);
    try {
      const res = await post(url, fieldRef.current);
      resetDirty();
      return res;
    } catch (error) {
      if (error instanceof ValidationError) {
        validate(error);
        setBusy(false);
        await forever;
      } else {
        throw error;
      }
    } finally {
      setBusy(false);
      await delay(1);
    }
  }, [url]);

  return {
    postback,
    validation,
    validate,
    resetValidation,
    isDirty,
    resetDirty,
    isBusy,
    canSubmit: isDirty && !isBusy
  };
}
