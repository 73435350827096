import classnames from 'classnames';
import React, { useState } from 'react';
import buttonStyles from '../Button/Button.module.css';
import styles from './Input.module.css';
import Text from '../Text/Text';

type InputElement = 'input' | 'textarea';

export type InputProps<E extends InputElement> = {
  multiline: E extends 'textarea' ? true : false;
  /* rest of props */
};

interface IInput {
  medium?: boolean;
  lg?: boolean;
  tall?: boolean;
  maxWide?: boolean;
  largeTextArea?: boolean;
  validationState?: 'success' | 'error' | 'warning' | false | null;
  disabled?: boolean;
  refFunc?: boolean;
  error?: boolean;
  orange?: boolean;
  autoFocus?: boolean;
  showCharCount?: boolean;
}

/**
 * Form input for text, textarea and file.
 */
const Input = React.forwardRef<
  HTMLInputElement, // | HTMLTextAreaElement,
  IInput & React.HTMLProps<HTMLInputElement>
>(({ type, medium, lg, tall, largeTextArea, validationState, disabled, error, orange, ...props }, ref) => {
  const enableAutoFocus = props.autoFocus ? { autoFocus: true } : '';
  const classes = classnames({
    [styles.text]: type === 'text' || type === 'number' || type === 'password',
    [styles.medium]: medium,
    [styles.lg]: lg,
    [styles.tall]: tall,
    [styles.textarea]: type === 'textarea',
    [styles.checkbox]: type === 'checkbox',
    [styles.largeTextArea]: largeTextArea,
    [styles.error]: validationState === 'error' || error,
    [styles.disabled]: disabled,
    [styles.orange]: orange
  });
  const [charCount, setCharCount] = useState<number>(props.value?.toString()?.length ?? 0);
  if (type === 'textarea') {
    return (
      <>
        <textarea
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: Unreachable code error
          ref={ref}
          {...props}
          disabled={disabled}
          className={classes}
          onInput={(e) => {
            // @ts-ignore
            setCharCount(e.target.value.length ?? 0);
          }}
        />
        {props.showCharCount && (
          <small style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {charCount}/{props.maxLength}
          </small>
        )}
      </>
    );
  }
  if (type === 'file') {
    return (
      <label className={[styles.file, buttonStyles.button, buttonStyles.primary].join(' ')}>
        <Text variant="mono-16" component="label" className={styles.fileText}>
          Velg fil
        </Text>
        <input ref={ref} {...props} {...enableAutoFocus} disabled={disabled} type={type} className={classes} />
      </label>
    );
  }
  return <input ref={ref} {...props} {...enableAutoFocus} disabled={disabled} type={type} className={classes} />;
});

Input.displayName = 'Input';
Input.defaultProps = {
  type: 'text'
};

export default Input;
