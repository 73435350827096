import TriPie from 'components/TriPie/TriPie';
import React, { PropsWithChildren } from 'react';
import Panel from 'components/Panel/Panel';
import style from './Popup.module.css';
import { Vurdering } from './Vurdering';

export interface Props {
  readonly kalkulert: number;
  readonly overstyrt: number;
  readonly visVurdering: boolean;
  readonly overstyrVurdering: boolean;
  onChange(value: number): void;
}

const VurderingsPopup = ({
  kalkulert,
  overstyrt,
  visVurdering,
  overstyrVurdering,
  onChange,
  children
}: PropsWithChildren<Props>) => {
  if (visVurdering) {
    return (
      <Panel className={style.VurderingsPopup}>
        <TriPie kalkulert={kalkulert} overstyrt={overstyrt} visIndre />

        <div className={style.Text}>
          {children}
          <div className={style.Spacer}></div>
          {overstyrVurdering && <Vurdering score={overstyrt} onChange={onChange} />}
        </div>
      </Panel>
    );
  } else {
    return (
      <Panel className={style.InfoPopup}>
        <div className={style.Text}>{children}</div>
      </Panel>
    );
  }
};

VurderingsPopup.displayName = 'VurderingsPopup';

export default VurderingsPopup;
