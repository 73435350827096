import React from 'react';
import Panel from 'components/Panel/Panel';
import { Kontaktperson as Props } from 'types/Kontaktperson';
import style from './Kontaktperson.module.css';
import Text from 'components/Text/Text';

interface IKontaktperson extends Props {
  children?: React.ReactNode;
  panelType?: 'default' | 'border';
}
export default function Kontaktperson(props: IKontaktperson) {
  return (
    <Panel type={props.panelType || 'default'} className={style.Kontaktperson}>
      <Text variant="annonse-grid-title" component="h3">
        Kontaktperson
      </Text>
      <img alt={props.fulltNavn} src={props.portrett?.nedlastingslenke ?? '/assets/images/profil-bilde-blank.png'} />
      <div className={style.Info}>
        <Text variant="panel-heading">{props.fulltNavn}</Text>
        <Text variant="table-header">{props.tittel}</Text>
        {props.telefon && (
          <Text variant="download-link">
            Mob:{' '}
            <a className={style.LinkColor} href={`tel:${props.telefon}`}>
              {props.telefon}
            </a>
          </Text>
        )}
        {props.epost && (
          <Text variant="download-link" component="a" className={style.LinkColor} href={`mailto:${props.epost}`}>
            Send E-post
          </Text>
        )}
      </div>

      <div className={style.Children}>{props.children}</div>
    </Panel>
  );
}
