import React, { FC, useEffect, useState } from 'react';
import type { DokumentSkjemaUnderdel } from 'types/DokumentSkjemaUnderdel';
import type { DokumentSkjemadel } from 'types/DokumentSkjemadel';
import AdvancedFileSelector from 'components/AdvancedFileSelector/AdvancedFileSelector';
import DynamicFileList, { SortColumns } from 'components/DynamicFileList/DynamicFileList';
import Accordion from 'components/Accordion/Accordion';
import { SetArrayState } from '@clave/use-array-state';
import { cleanPost, post } from 'js/api-helper';
import UploadedFileLabel from 'components/UploadedFileLabel/UploadedFileLabel';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import { DokumentSkjema } from '../DokumentSkjema/DokumentSkjema';
import { InvalidDocStructure } from 'types/InvalidDocStructure';
import { filterDocById } from 'js/document-filter-helper';
import AnchoredErrorMessage, { ErrorProps } from 'components/AnchoredErrorMessage/AnchoredErrorMessage';

interface DocumentPayload {
  data: FileList | undefined;
  skjemadel: number;
  skjemaUnderdel: number;
  svarFilType: string;
  skjemaUnderDelTittel?: string;
  url: string;
  underDelTittel: string;
}

enum ModalOperation {
  Post = 0,
  Put = 1
}
interface UploadModal {
  isRequired: boolean;
  isOpen: boolean;
  docToBeHandled: DocumentPayload | undefined;
  operation: ModalOperation;
}

const defaultModal: UploadModal = {
  isRequired: true,
  isOpen: false,
  docToBeHandled: undefined,
  operation: ModalOperation.Post
};

interface SvarDokumentSkjema extends DokumentSkjema {
  setDynamicDocs: SetArrayState<DokumentSkjemadel>;
  fileListCols?: Array<Object>;
  supportMultipleVedlegg?: boolean;
  invalidDocs: InvalidDocStructure[];
  offerRecinded?: boolean;
  readonly oppdaterFilStatusUrl: string;
}

const SvarDokumentSkjema: FC<SvarDokumentSkjema> = (props: SvarDokumentSkjema) => {
  const [modal, setModal] = useState<UploadModal>(defaultModal);
  const [error, setError] = useState<ErrorProps | undefined>();

  const handleUploadAttachment = async (args: DocumentPayload) => {
    //reset in case user tries again
    setError(undefined);

    const fileList = Array.from(args.data ?? []);

    const data = new FormData();
    data.append('svarFilType', args.svarFilType ?? '');
    fileList?.forEach((file) => data.append('file', file));

    try {
      const res = await cleanPost(args.url, data);

      if (res) {
        res.slettbar = true;
        res.required = modal.isRequired;
        const newObj = props.skjemaDeler[args.skjemadel];
        newObj.underDeler[args.skjemaUnderdel].vedlegg
          ? newObj.underDeler[args.skjemaUnderdel].vedlegg?.push(res)
          : (newObj.underDeler[args.skjemaUnderdel].vedlegg = [res]);

        props.setDynamicDocs.replaceAt(args.skjemadel, newObj);
      }
    } catch (error) {
      setError({
        msg: error.message,
        targetKey: args.underDelTittel
      });
    }
  };

  const handleRemoveAttachment = async (id: string) => {
    try {
      await post(`${props.slettVedleggUrl}?filId=${id}`);
      // Avklar med backend om det er trygt å filtrere på id.
      const copy = filterDocById(props.skjemaDeler, id);
      if (copy) props.setDynamicDocs(copy);
    } catch (error) {
      console.log(error);
    }
  };

  // This const is here to make the c# generator generate the DokumentSkjemaUnderdel model.
  const DONT_REMOVE: DokumentSkjemaUnderdel = {
    posisjon: 2,
    tittel: ''
  };

  function defaultSort(p: DokumentSkjemadel, pp: DokumentSkjemadel) {
    return p.posisjon - pp.posisjon;
  }

  return (
    <div className={'DokumentSkjema'}>
      {props.skjemaDeler &&
        props.skjemaDeler.sort(defaultSort).map((del) => (
          <Accordion title={del.tittel} defaultOpen={true}>
            {del.underDeler?.sort(defaultSort).map((underDel) => (
              <div className={'Underdel'}>
                <h3>
                  {underDel.tittel}
                  {underDel.requiresUpload && <span className="Underdel-required-flag">* Krever Besvarelse</span>}
                </h3>

                {error?.msg && error?.targetKey == underDel.tittel && <AnchoredErrorMessage {...error} percentageTopPos={5} />}
                {underDel.grunnlagsDokument && underDel.grunnlagsDokument.id && (
                  <UploadedFileLabel
                    file={underDel.grunnlagsDokument}
                    icon={<IconReactIcons type={IconTypes.download} size={4} />}
                  />
                )}

                {underDel.lastOppVedleggUrl &&
                  (props.supportMultipleVedlegg || !underDel.vedlegg || underDel.vedlegg.length == 0) && (
                    <AdvancedFileSelector
                      disabled={props.offerRecinded}
                      onChange={(event) =>
                        handleUploadAttachment({
                          data: event?.target?.files ?? undefined,
                          skjemadel: del.posisjon,
                          skjemaUnderdel: underDel.posisjon,
                          svarFilType: underDel.prosjektFilType ?? '',
                          url: underDel.lastOppVedleggUrl ?? '/error/',
                          underDelTittel: underDel.tittel
                        })
                      }
                      name={underDel.tittel}
                      autoUpload={underDel.automatisk}
                      invalid={
                        props.invalidDocs
                          .find((x) => x.topPos == del.posisjon)
                          ?.invalidDocIdx.filter((idx) => idx == underDel.posisjon).length
                          ? true
                          : false
                      }
                      columns={[SortColumns.date, SortColumns.document, SortColumns.status, SortColumns.version]}
                    />
                  )}

                {underDel.vedlegg && underDel.vedlegg.length > 0 && (
                  <DynamicFileList
                    oppdaterFilStatusUrl={props.oppdaterFilStatusUrl}
                    readOnly={true}
                    files={underDel.vedlegg ?? []}
                    onRemove={(id) => {
                      handleRemoveAttachment(id);
                    }}
                    columns={
                      props.fileListCols ?? [SortColumns.date, SortColumns.document, SortColumns.status, SortColumns.version]
                    }
                  />
                )}
              </div>
            ))}
          </Accordion>
        ))}
    </div>
  );
};
export default SvarDokumentSkjema;
