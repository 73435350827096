import Table from 'components/Table/Table';
import React, { FC } from 'react';
import { AiOutlineArrowRight, AiOutlineEye } from 'react-icons/ai';
import { BsPencil } from 'react-icons/bs';
import Panel from 'components/Panel/Panel';
import { ProsjektTableItem } from 'types/ProsjektTableItem';

export interface ProsjekterSide {
  readonly aktiveProsjekter: ProsjektTableItem[];
  readonly paabegynteProsjekter: ProsjektTableItem[];
  readonly historiskeProsjekter: ProsjektTableItem[];
}

const AktivtProsjektIkoner: FC<ProsjektTableItem> = ({ redigerUrl, detaljUrl }) => (
  <>
    {redigerUrl && (
      <a title="Rediger prosjektet" href={redigerUrl}>
        <BsPencil />
      </a>
    )}
    {detaljUrl && (
      <a title="Se svar" href={detaljUrl}>
        <AiOutlineArrowRight />
      </a>
    )}
  </>
);

const PaabegyntProsjektIkoner: FC<ProsjektTableItem> = ({ redigerUrl, annonseUrl }) => (
  <>
    {redigerUrl && (
      <a title="Rediger prosjektet" href={redigerUrl}>
        <BsPencil />
      </a>
    )}
    {annonseUrl && (
      <a title="Forhåndsvis annonsen" href={annonseUrl}>
        <AiOutlineEye />
      </a>
    )}
  </>
);

const HistoriskeProsjektIkoner: FC<ProsjektTableItem> = ({ annonseUrl, detaljUrl }) => (
  <>
    {annonseUrl && (
      <a title="Vis annonsen" href={annonseUrl}>
        <AiOutlineEye />
      </a>
    )}
    {detaljUrl && (
      <a title="Se svar" href={detaljUrl}>
        <AiOutlineArrowRight />
      </a>
    )}
  </>
);

const ProsjekterSide: FC<ProsjekterSide> = ({
  aktiveProsjekter: AktiveProsjekter,
  paabegynteProsjekter: PaabegynteProsjekter,
  historiskeProsjekter: HistoriskeProsjekter
}) => {
  return (
    <div className="ProsjekterSide">
      <h1>Aktive søk</h1>
      <Panel>
        <Table
          data={AktiveProsjekter}
          iconsComponent={AktivtProsjektIkoner}
          columns={{
            ['Prosjekt#']: 'prosjektnummer',
            ['Prosjektnavn']: 'prosjektnavn',
            ['Sted']: 'sted',
            ['Oppdragsgiver']: 'oppdragsgiverNavn',
            ['Svar']: 'antallSvar',
            ['Frist']: (x) => new Date(x.annonseringTilDatoUtc).getTime()
          }}
          noItemsLabel="Ingen aktive søk"
        />
      </Panel>

      <h1>Påbegynte</h1>
      <Panel>
        <Table
          data={PaabegynteProsjekter}
          iconsComponent={PaabegyntProsjektIkoner}
          columns={{
            ['Prosjekt#']: 'prosjektnummer',
            ['Prosjektnavn']: 'prosjektnavn',
            ['Sted']: 'sted',
            ['Oppdragsgiver']: 'oppdragsgiverNavn',
            ['Status']: 'status',
            ['Startdato']: (x) => new Date(x.startdatoUtc).getTime()
          }}
          noItemsLabel="Ingen påbegynte søk"
        />
      </Panel>

      <h1>Historiske</h1>
      <Panel>
        <Table
          data={HistoriskeProsjekter}
          iconsComponent={HistoriskeProsjektIkoner}
          columns={{
            ['Prosjekt#']: 'prosjektnummer',
            ['Prosjektnavn']: 'prosjektnavn',
            ['Sted']: 'sted',
            ['Oppdragsgiver']: 'oppdragsgiverNavn',
            ['Svar']: 'antallSvar',
            ['Avsluttet']: (x) => new Date(x.avsluttetdatoUtc).getTime()
          }}
          paging
          noItemsLabel="Ingen historiske søk"
        />
      </Panel>
    </div>
  );
};

export default ProsjekterSide;
