import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import './Modal.scss';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import { createPortal } from 'react-dom';

export type ModalProps = {
  title?: string;
  open: boolean;
  children: ReactNode;
  onClose: () => void;
  clickOutsideToClose?: boolean;
  showQuitButton?: boolean;
};

const Modal = ({ title, open, children, onClose, clickOutsideToClose = false, showQuitButton = true }: ModalProps) => {
  function handleClick({ target }: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (clickOutsideToClose && target instanceof HTMLElement && !target.closest('.modal__inner')) {
      onClose();
    }
  }

  return open ? (
    <Portal>
      <div className="modal" onClick={handleClick}>
        <div className="modal__inner">
          {showQuitButton && (
            <button onClick={() => onClose()} title={'Lukk vinduet'} className="modal__inner--close">
              <IconReactIcons type={IconTypes.close} />
            </button>
          )}
          {title && <div className="modal__inner--title">{title}</div>}
          {children}
        </div>
      </div>
    </Portal>
  ) : (
    <></>
  );
};

const Portal: FC = ({ children }) => {
  const el = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    document.body.appendChild(el);
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.removeChild(el);
      document.body.style.overflow = 'auto';
    };
  }, []);

  return createPortal(children, el);
};

export default Modal;
