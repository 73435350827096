import CustomDateRangePickerInput from 'components/DateRangePicker/CustomDateRangePickerInput';
import noNb from 'date-fns/locale/nb';
import { useOnChange } from 'js/hooks';
import { ValidationEntry } from 'js/hooks/useValidation';
import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Text from 'components/Text/Text';
registerLocale('no-nb', noNb);

export interface Props {
  label: string;
  fromDateInitialValue?: Date | undefined;
  toDateInitialValue?: Date | undefined;
  onFromDateChanged?: (date: Date) => void;
  onToDateChanged?: (date: Date) => void;
  toDateValidation?: ValidationEntry;
  fromDateValidation?: ValidationEntry;
}

const defaultValidationObject: ValidationEntry = {
  feedback: '',
  feedbackMargin: false,
  validationState: null
};

export default function DateRangePicker({
  label,
  fromDateInitialValue,
  toDateInitialValue,
  onFromDateChanged,
  onToDateChanged,
  toDateValidation = defaultValidationObject,
  fromDateValidation = defaultValidationObject
}: Props) {
  const [startDate, setStartDate] = useState(fromDateInitialValue);
  const [endDate, setEndDate] = useState(toDateInitialValue);

  useOnChange(
    (startDate) => {
      if (!endDate || (startDate && startDate > endDate)) {
        setEndDate(undefined);
      }
    },
    [startDate]
  );

  useOnChange(
    (endDate) => {
      if (!startDate || (endDate && endDate < startDate)) {
        setStartDate(undefined);
      }
    },
    [endDate]
  );

  useOnChange(onFromDateChanged, [startDate]);
  useOnChange(onToDateChanged, [endDate]);

  const toShowError = toDateValidation.feedback && toDateValidation.validationState === 'error';

  const fromShowError = fromDateValidation.feedback && fromDateValidation.validationState === 'error';

  return (
    <div className="DateRangePicker">
      <Text variant="mono-16" component="label">
        {' '}
        {label}{' '}
      </Text>

      <div className="DateRangePicker__CustomInput">
        <DatePicker
          locale="no-nb"
          selected={startDate}
          onChange={(date) => setStartDate((date as Date) ?? undefined)}
          customInput={<CustomDateRangePickerInput validationState={fromDateValidation.validationState} placeholder="Fra" />}
          selectsStart
          showYearDropdown
          showMonthDropdown
          startDate={startDate}
          dateFormat="dd-MM-yyyy"
          placeholderText="Fra"
        />
        {fromShowError && (
          <ErrorMessage feedback={fromDateValidation.feedback} feedbackMargin={fromDateValidation.feedbackMargin} />
        )}
      </div>

      <div className="DateRangePicker__CustomInput">
        <DatePicker
          locale="no-nb"
          selected={endDate}
          onChange={(date) => setEndDate((date as Date) ?? undefined)}
          selectsEnd
          showYearDropdown
          showMonthDropdown
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          customInput={<CustomDateRangePickerInput validationState={toDateValidation.validationState} placeholder="Til" />}
          dateFormat="dd-MM-yyyy"
          placeholderText="Til"
        />
        {toShowError && <ErrorMessage feedback={toDateValidation.feedback} feedbackMargin={toDateValidation.feedbackMargin} />}
      </div>
    </div>
  );
}
