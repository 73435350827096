import useArrayState from '@clave/use-array-state';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import AdvancedFileSelector, { acceptedFileTypes } from 'components/AdvancedFileSelector/AdvancedFileSelector';
import FormSubmitBox from 'components/FormSubmitBox/FormSubmitBox';
import Modal from 'components/Modal/Modal';
import UploadedFileLabel from 'components/UploadedFileLabel/UploadedFileLabel';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import { post, upload } from 'js/api-helper';
import React, { ChangeEvent, FC, useState } from 'react';
import { KategoriskDokument } from 'types/KategoriskDokument';
import { LinkItem } from 'types/LinkItem';
import Text from 'components/Text/Text';
import { Fildata } from 'types/Fildata';
import FileListWithSort from 'components/FileListWithSort/FileListWithSort';
import Checkbox from 'components/Checkbox/Checkbox';
import EierDataBlockWithOverride from 'components/EierDataBlock/WithOverride';
import type { EierDataOrganisasjon } from 'types/EierDataOrganisasjon';
import { EierDataSeriositet } from 'types/EierDataSeriositet';
import EierDataBlockDisplayOnly from 'components/EierDataBlock/DisplayOnly';
import RecindOfferBox from 'components/RecindOfferBox/RecindOfferBox';

export interface SvarSeriositetsSjekkSide {
  readonly title: string;
  readonly pageLinks?: LinkItem[];
  readonly tilbudTrukket: boolean;
  readonly dokumentListe: KategoriskDokument[];
  readonly svarSendt: boolean;
  readonly eierData: EierDataSeriositet;
  readonly nyEierData?: EierDataOrganisasjon;
  readonly slettVedleggUrl?: string;
  readonly sendSvarUrl: string;
  readonly trekkTilbudUrl: string;
  readonly leveranseFristDato: string;
}

interface KategoriskDokumentAdapter {
  kategoriNavn: string;
  kategoriIndex: number;
  lastOppVedleggUrl: string;
  dokument: KategoriskDokument;
  svarDokument?: Fildata;
}

// 1: create an indexed list of all documents, with respective category name
// 2: whenever a request is sent - the contextual index is read from the data structure itself
// 3: replace array item at the contextual index

function documentListTransformer(dokumentListe: KategoriskDokument[]): KategoriskDokumentAdapter[] {
  return dokumentListe.map((x, idx) => {
    return {
      kategoriNavn: x.kategorinavn,
      kategoriIndex: idx,
      lastOppVedleggUrl: x.lastOppVedleggUrl ?? '/error',
      dokument: x,
      svarDokument: x?.svarDokument
    };
  });
}

const SvarSeriositetsSjekkSide: FC<SvarSeriositetsSjekkSide> = ({
  title,
  pageLinks,
  dokumentListe,
  slettVedleggUrl,
  sendSvarUrl,
  svarSendt,
  trekkTilbudUrl,
  tilbudTrukket,
  leveranseFristDato,
  eierData,
  nyEierData
}) => {
  const [dynamicDocs, setDynamicDocs] = useArrayState<KategoriskDokumentAdapter>(documentListTransformer(dokumentListe));
  const [svarErSendt, setSvarErSendt] = useState<boolean>(svarSendt);
  const [betingelserErGodtatt, setBetingelserErGodtatt] = useState<boolean>(svarErSendt);
  const [OfferRecinded, setOfferRecinded] = useState<boolean>(tilbudTrukket);

  const [org, setOrg] = useState<EierDataOrganisasjon>({
    ...eierData.organisasjon
  });
  const [eierDataOverskrevet, setEierDataOverskrevet] = useState(nyEierData);

  const [error, setError] = useState<string>();
  const [invalidDocs, setInvalidDocs] = useArrayState<number>([]);
  const [submittedModal, setSubmittedModal] = useState(false);
  const [recindModal, setRecindModal] = useState(false);

  function validateDocuments(docs: KategoriskDokumentAdapter[]) {
    const result = docs.filter((x) => !x.svarDokument).map((y) => y.kategoriIndex);
    setInvalidDocs(result);
    return result.length == 0 ?? false;
  }

  async function uploadAttachments(changeEvent: ChangeEvent<HTMLInputElement>, kategoriIndex: number, lastOppVedleggUrl: string) {
    const fileList = Array.from(changeEvent.target.files ?? []);
    if ((fileList?.length ?? 0) < 1) return;
    const data = new FormData();
    fileList?.forEach((file) => data.append('file', file));
    try {
      const res = await upload(lastOppVedleggUrl, data);
      const replacement = dynamicDocs[kategoriIndex];

      if (replacement) {
        replacement.svarDokument = res;
        setInvalidDocs.removeWhere((x) => x === kategoriIndex);
        setDynamicDocs.replaceAt(kategoriIndex, replacement);
        setError('');
      } else {
        setError('Visningen vet ikke hvor det nye dokumentet skal ligge.');
      }
    } catch (error) {
      setError('Klarte ikke laste opp filen');
    }
  }

  async function handleRemoveAttachment(kategoriIndex: number, id?: string) {
    if (!id) return;
    try {
      await post(`${slettVedleggUrl}?filId=${id}`);
      const replacement = dynamicDocs[kategoriIndex];

      if (replacement) {
        replacement.svarDokument = undefined;
        setDynamicDocs.replaceAt(kategoriIndex, replacement);
        setError('');
      } else {
        setError('Visningen vet ikke hvilken fil som skal slettes');
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleFormSubmit(url) {
    try {
      setError('');
      const validated = validateDocuments(dynamicDocs);
      if (!validated) {
        setError('Ugyldig skjema');
        return;
      }
      await post(url);
      setSubmittedModal(true);
      setSvarErSendt(true);
      if (eierData.organisasjon.orgNr !== org.orgNr) {
        const updatedOrgData = await post(eierData.oppdaterEierUrl, {
          orgNr: org.orgNr,
          navn: org.navn
        });

        if (updatedOrgData && validated) {
          setEierDataOverskrevet({
            orgNr: org.orgNr,
            navn: org.navn,
            adresse: 'N/A'
          });
        }
      }
    } catch (error) {
      console.error(error);
      setError('Opplastning feilet');
    }
  }

  const recindOffer = async (url) => {
    try {
      await post(url);
      setOfferRecinded(true);
      setBetingelserErGodtatt(svarSendt);
      setRecindModal(false);
    } catch (error) {
      console.error(error);
      setError('Trekking feilet');
    }
  };

  return (
    <div className="document-delivery-page">
      {leveranseFristDato && (
        <span className="document-delivery-page-deadline">
          <b>Leveransefrist:</b> {leveranseFristDato}
        </span>
      )}
      {title && (
        <div className="document-delivery-page-title-wrapper">
          <Text component="h1" variant="list-title">
            Seriøsitetssjekk for: {title}
          </Text>
          {OfferRecinded && <span className="recind-label">Trukket</span>}
        </div>
      )}

      {pageLinks && <PageLinkMenu pageLinks={pageLinks} styles="-bottom-margin" />}
      <div className="document-delivery-page-preamble">
        <Text component="p" variant="article-ingress-1-2">
          Som kvalifikasjonskrav seriøsitet (Del 1), bes det om å laste opp følgende etterspurte dokumenter nedenfor:
        </Text>
      </div>
      {eierDataOverskrevet?.orgNr ? (
        <EierDataBlockDisplayOnly organisasjon={eierData.organisasjon} nyOrganisasjon={eierDataOverskrevet} />
      ) : (
        <EierDataBlockWithOverride
          onSetOrg={(org) => {
            setOrg(org ?? eierData.organisasjon);
          }}
          organisasjon={eierData.organisasjon}
          oppdaterEierUrl={eierData.oppdaterEierUrl}
        />
      )}
      <div className="document-delivery-page-list">
        {dynamicDocs.map((x) => (
          <div key={x.kategoriNavn} className="dokument">
            <Text className="dokument-title" variant="meny-hovedpunkt" component="h3">
              <span>{x.kategoriNavn}</span>
              <span>* KREVER BESVARELSE</span>
            </Text>
            {x.dokument.nedlastingslenke && x.dokument.id && (
              <UploadedFileLabel file={x.dokument} icon={<IconReactIcons type={IconTypes.download} size={4} />} />
            )}
            {!x.svarDokument ? (
              <AdvancedFileSelector
                disabled={svarErSendt || OfferRecinded}
                invalid={invalidDocs.includes(x.kategoriIndex)}
                multiple={false}
                name="attachments"
                onChange={(e) => {
                  uploadAttachments(e, x.kategoriIndex, x.dokument.lastOppVedleggUrl ?? '/error/');
                }}
                instruction="Tillatte filer er: .PDF, .DOCX. maks filstørrelse er 32MB"
                accept={acceptedFileTypes.DocAndPdf}
              />
            ) : (
              <FileListWithSort
                interactionDisabled={OfferRecinded}
                files={[x.svarDokument]}
                onRemove={(id) => handleRemoveAttachment(x.kategoriIndex, id)}
                readOnly={false}
              />
            )}
          </div>
        ))}
      </div>
      <div className="document-delivery-page-confirmation">
        <div className="check-guard">
          <Text variant="paragraph">For å gå videre med ditt tilbud må du godta følgende:</Text>
          <Checkbox
            checkMark={true}
            name="godkjent"
            label="Vi bekrefter at vi har lest og forstått dokumentene i seriøsitetssjekken og leverer tilbud iht. til dette."
            disabled={svarErSendt || OfferRecinded}
            checked={betingelserErGodtatt}
            onChange={(e) => {
              setBetingelserErGodtatt(e.currentTarget.checked);
            }}
          ></Checkbox>
        </div>
        <FormSubmitBox
          heading={svarErSendt ? 'Du har sendt inn svar' : 'Send inn svar på seriøsitetssjekk'}
          bodyText='Statsbygg vil gjennomgå de opplastede dokumentene. Ved kvalifisering vil konkurransegrunnlaget og tilhørende dokumentasjon bli tilgjengeliggjort i fanen "Konkurransedokumenter".'
          buttonText={svarErSendt ? 'Svar sendt' : 'Send inn svar'}
          dokumenterPublisert={svarErSendt}
          submitUrl={sendSvarUrl ?? '/error'}
          submitFunc={(url) => {
            handleFormSubmit(url);
          }}
          disabled={svarErSendt || !betingelserErGodtatt || OfferRecinded}
        ></FormSubmitBox>

        {error && (
          <Modal title={'En feil oppstod'} open={error != ''} onClose={() => setError('')}>
            {error}
          </Modal>
        )}
      </div>
      <Modal title={'Svar innsendt'} open={submittedModal} onClose={() => setSubmittedModal(false)}>
        <p>Du har nå besvart seriøsitetssjekken.</p>
      </Modal>
      <RecindOfferBox prohibitRecind={OfferRecinded} recindOffer={() => recindOffer(trekkTilbudUrl)} />
    </div>
  );
};

export default SvarSeriositetsSjekkSide;
