import React, { FC, useEffect, useState } from 'react';

import Heading from 'components/Heading/Heading';
import { LinkItem } from 'types/LinkItem';
import PageLinkMenu from 'components/PageLinkMenu/PageLinkMenu';
import { TilbudtObjektListe } from 'types/TilbudtObjektListe';
import { SortLeieObjekter } from 'js/SortLeieObjekter';
import { TilbudtObjekt } from 'types/TilbudtObjekt';
import TilbudtObjektCardList, { TilbudtObjektCardListHeadings } from 'components/TilbudtObjektCardList/TilbudtObjektCardList';

export interface SeriositetInkomneSide {
  readonly objekter: TilbudtObjekt[];
  pageLinks?: LinkItem[];
  title: string;
}

const SeriositetInkomneSide: FC<SeriositetInkomneSide> = ({ objekter, title, pageLinks }) => {
    
  const [sortedList, setSortedList] = useState<TilbudtObjektListe>(SortLeieObjekter(objekter))
 
  const ListHeadings: TilbudtObjektCardListHeadings= {
    recommended: "Anbefales",
    notEvaluated: "Seriøsitetssjekk ikke utført",
    rejected: "Anbefales å utelukke fra konkurransen",
    canceled: "Trukket av aktør"
  }

  return (
    <div className="tilbudte-objekter">
      <Heading>{title}</Heading>
      {pageLinks && pageLinks?.length > 0 && (
        <PageLinkMenu pageLinks={pageLinks} />
      )}
      <TilbudtObjektCardList list={sortedList} headings={ListHeadings}/> 
    </div>
  );
};

export default SeriositetInkomneSide;
