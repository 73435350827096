import cn from 'classnames';
import React from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { Score } from 'types/Score';
import style from '../SvarInnkomne.module.css';

interface Props {
  readonly heading: React.ReactNode;
  readonly detailsList: {
    value: string;
    label: string;
  }[];
  readonly subtitle: {
    value: string;
    label: string;
  };
  readonly url: string;
  readonly scores: Score[];
}

export default function SvarPopup({
  heading,
  url,
  detailsList,
  subtitle,
  scores
}: Props) {
  return (
    <div className={style.popup}>
      <h3>{heading}</h3>
      <a href={url} className={style.link}>
        Detaljer <FiChevronRight />
      </a>
      <div>
        {subtitle.label}: <strong>{subtitle.value}</strong>
        {detailsList.map((detail) => (
          <div key={detail.value}>
            {detail.label}
            <strong>{detail.value}</strong>
          </div>
        ))}
      </div>
      <div className={style.score}>
        <div className={style.score}>
          {scores?.map((score) => (
            <>
              <strong>{score.type}</strong>
              <Dot
                kalkulert={score.kalkulertScore}
                overstyrt={score.overstyrtScore}
              />
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

function Dot({
  kalkulert,
  overstyrt
}: {
  kalkulert: number;
  overstyrt: number;
}) {
  const scoreClass = getScore(overstyrt, kalkulert);

  return <div className={cn(style.dot, scoreClass)}></div>;
}
function getScore(overstyrt: number, kalkulert: number) {
  switch (overstyrt || kalkulert) {
    case 1:
      return style.dotBad;
    case 2:
      return style.dotMiddle;
    case 3:
      return style.dotGood;
  }
}
export function bygningstandToCSSClass(item: string) {
  switch (item) {
    case 'Ikke oppført':
      return style.ikkeoppfort;
    case 'Eksisterende':
      return style.eksisterende;
    case 'Total rehabilitering':
      return style.totalRehab;
  }
}
