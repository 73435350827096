import { dateString } from 'js/date-helper';
import React, { FC } from 'react';
import style from './AnnonseBlock.module.css';
import Text from 'components/Text/Text';

export interface AnnonseBlock {
  readonly title: string;
  readonly ingress: string;
  readonly deadline: string;
  readonly imageUrl: string;
  readonly logoUrl: string;
  readonly kommune: string;
  readonly annonseUrl: string;
}
const AnnonseBlock: FC<AnnonseBlock> = (props) => (
  <a href={props.annonseUrl} className={style.AnnonseBlock} target="_top">
    <div className={style.header} style={{ backgroundImage: `url(${props.imageUrl})` }}>
      <div className={style.headerOverlay} />
      <img src={props.logoUrl} alt="Logo" />
    </div>
    <Text className={style.AnnonseText} variant={'tag'}>
      {props.kommune}
    </Text>
    <Text className={style.AnnonseText} variant="annonse-grid-title" component={'h2'}>
      {props.title}
    </Text>
    <Text className={style.AnnonseText} variant={'news-paragraph'}>
      {props.ingress}
    </Text>
    <div className={style.footer}>
      <Text className={style.AnnonseText} variant={'tag'}>
        Frist: <b>{dateString(props.deadline)}</b>
      </Text>
    </div>
  </a>
);

export default AnnonseBlock;
