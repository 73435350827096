import { useEffect, useRef } from 'react';

export default function useOnChange<T extends any[]>(
  callback: undefined | ((...args: T) => void),
  deps: T
) {
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    callback?.(...deps);
  }, deps);
}
