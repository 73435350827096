import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

interface IHeading {
  styles?: string;
}

export default function Heading({ children, styles }: PropsWithChildren<IHeading>) {
  return children ? <h1 className={cn("heading", styles)}>{children}</h1> : null;
}

export const styles = {
  largeHead : "-large"
}
