import React from 'react';
import Text from 'components/Text/Text';
import { EierDataOrganisasjon } from 'types/EierDataOrganisasjon';
import cn from 'classnames';
import { getBrregLink, getProffLink } from 'components/EierDataBlock/common';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';

interface EierDataBlockDisplayOnlyProps {
  organisasjon?: EierDataOrganisasjon;
  nyOrganisasjon?: EierDataOrganisasjon;
  oppdaterEierUrl?: string;
}

const EierDataBlockDisplayOnly: React.FC<EierDataBlockDisplayOnlyProps> = ({ ...props }) => {
  const hasNew = (props.nyOrganisasjon?.orgNr?.length ?? 0) > 0;
  return (
    <div
      className={cn({
        EierDataBlockDisplayOnly: true,
        IsOld: hasNew
      })}
    >
      <div className={'Left'}>
        <Text className="EierLabel" variant="mono-16">
          Hjemmelshaver i følge matrikkelinformasjon:
        </Text>
        <div className="Org-data">
          <Text component="p" variant="paragraph-header">
            {props.organisasjon?.navn}
          </Text>
          <Text component="p">
            Org. nr: {props.organisasjon?.orgNr} <br />
          </Text>
          {(props.organisasjon?.orgNr?.length ?? 0) > 0 && (
            <div className="Org-links">
              <Text component="a" variant="download-link" target={'_blank'} href={getBrregLink(props.organisasjon?.orgNr)}>
                Les mer på brreg.no <IconReactIcons type={IconTypes.openInNew} size={1} />
              </Text>
              <Text component="a" variant="download-link" target={'_blank'} href={getProffLink(props.organisasjon?.orgNr)}>
                Les mer på proff.no <IconReactIcons type={IconTypes.openInNew} size={1} />
              </Text>
            </div>
          )}
        </div>
      </div>
      {hasNew && (
        <div className="Right">
          <Text className="EierLabel" variant="mono-16">
            Hjemmelshaver i følge markedsaktør:
          </Text>
          <div className="Org-data">
            <Text component="p" variant="paragraph-header">
              {props.nyOrganisasjon?.navn}
            </Text>
            <Text component="p">
              Org. nr: {props.nyOrganisasjon?.orgNr} <br />
            </Text>
          </div>
          {(props.nyOrganisasjon?.orgNr?.length ?? 0) > 0 && (
            <div className="Org-links">
              <Text component="a" variant="download-link" target={'_blank'} href={getBrregLink(props.nyOrganisasjon?.orgNr)}>
                Les mer på brreg.no <IconReactIcons type={IconTypes.openInNew} size={1} />
              </Text>
              <Text component="a" variant="download-link" target={'_blank'} href={getProffLink(props.nyOrganisasjon?.orgNr)}>
                Les mer på proff.no <IconReactIcons type={IconTypes.openInNew} size={1} />
              </Text>
            </div>
          )}

          {props.oppdaterEierUrl && (
            <Text
              component="a"
              title={'Gå til Økonomi og Eierskapsiden for å oppdatere verdier'}
              variant="download-link"
              href={props.oppdaterEierUrl}
            >
              Oppdater Økonomi og Eierskap her
            </Text>
          )}
        </div>
      )}
    </div>
  );
};

export default EierDataBlockDisplayOnly;
