import React from 'react';
import classnames from 'classnames';
import styles from './Icon.module.css';
import icons from './iconReferences';

interface IIcon {
  size: '1' | '2' | '3' | '4' | '5';
  icon: string;
  spaced?: boolean;
  button?: boolean;
  tabButton?: boolean;
  white?: boolean;
  green?: boolean;
  danger?: boolean;
  warning?: boolean;
  info?: boolean;
  read?: boolean;
  unread?: boolean;
  small?: boolean;
  red?: boolean;
  black?: boolean;
  noMargin?: boolean;
}
const Icon = ({
  size,
  icon,
  spaced,
  button,
  tabButton,
  white,
  green,
  danger,
  warning,
  info,
  read,
  unread,
  small,
  red,
  black,
  noMargin,
  ...props
}: IIcon) => {
  const iconClasses = classnames(styles.defaultSize, {
    [styles.small]: small,
    [styles.sizex1]: size === '1',
    [styles.sizex2]: size === '2',
    [styles.sizex3]: size === '3',
    [styles.sizex4]: size === '4',
    [styles.sizex5]: size === '5',
    [styles.spaced]: spaced,
    [styles.button]: button,
    [styles.tabButton]: tabButton,
    [styles.white]: white,
    [styles.green]: green,
    [styles.red]: red,
    [styles.black]: black,
    [styles.danger]: danger,
    [styles.warning]: warning,
    [styles.info]: info,
    [styles.read]: read,
    [styles.unread]: unread,
    [styles.noMargin]: noMargin
  });
  const svg = icons[icon];
  return (
    <span className={iconClasses} {...props}>
      <img src={svg} />
    </span>
  );
};

Icon.defaultProps = {
  size: '1'
};

export default Icon;
