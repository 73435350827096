import React, { FC } from 'react';

export interface FeilSide {
  readonly feilkode: number;
  readonly overskrift: string;
  readonly feilmelding: string;
}

const FeilSide: FC<FeilSide> = (props) => {
  return (
    <div className="FeilSide">
      <h1>{props.overskrift}</h1>
      <h2>Feilkode: {props.feilkode}</h2>
      <p>{props.feilmelding}</p>
      <p>
        <a href="/">Gå tilbake til forsiden</a>
      </p>
    </div>
  );
};

export default FeilSide;
