import classNames from 'classnames';
import IconButton from 'components/IconButton/IconButton';
import Paragraphs from 'components/Paragraphs/Paragraphs';
import Popup from 'components/Popup/Popup';
import Button from 'components/Button/Button';
import WizardSteps from 'components/WizardSteps/WizardSteps';
import Panel from 'components/Panel/Panel';
import { IconTypes } from 'components/Icon/IconReactIcons';
import Text from 'components/Text/Text';
import { post } from 'js/api-helper';
import useBeforeUnload from 'js/hooks/use-before-unload';
import React, { PropsWithChildren, useCallback, useState } from 'react';
import styles from './ProsjektRedigerContainer.module.css';
import { AiOutlineSync } from 'react-icons/ai';
export interface Props {
  readonly headerText: string;
  readonly currentStep: number;
  readonly isDirty: boolean;
  readonly isBusy: boolean;
  readonly quitUrl: string;
  readonly deleteUrl?: string;
  readonly slettProsjektBekreftTekst?: string;
  readonly nextPageUrl?: string;
  readonly previousPageUrl?: string;
  readonly archiveProsjektUrl?: string;
  save(): Promise<string>;
  disableSave?: boolean;
  hideRequiredText?: boolean;
  hasAbsoluteButtonRow?: boolean;
}

export default function ProsjektRedigerContainer({
  headerText,
  currentStep,
  isDirty,
  isBusy,
  quitUrl,
  nextPageUrl,
  previousPageUrl,
  hideRequiredText,
  save,
  children,
  deleteUrl,
  slettProsjektBekreftTekst,
  archiveProsjektUrl,
  disableSave,
  hasAbsoluteButtonRow
}: PropsWithChildren<Props>) {
  const stepLabels = ['Prosjekt', 'Grunnlagsdata', 'Kart', 'Annonsetekst', 'Publisering'];
  const [popup, setPopup] = useState<'delete' | 'archive' | undefined>();
  const hidePopup = useCallback(() => setPopup(undefined), []);
  const [nextUrl, setNextUrl] = useState(nextPageUrl);

  const isFirstPage = currentStep === 1;
  const isLastPage = stepLabels.length === currentStep;
  const canGoToPreviousPage = !!previousPageUrl;
  const canSave = isDirty && !disableSave;

  const canGoToNextPage = !!nextUrl;
  const goToNextPage = useCallback(() => {
    if (nextUrl) window.location.href = nextUrl;
  }, [nextUrl]);

  const goToPreviousPage = useCallback(async () => {
    if (previousPageUrl) window.location.href = previousPageUrl;
  }, [previousPageUrl]);

  const deleteProject = useCallback(async () => {
    if (deleteUrl) {
      await post(deleteUrl);
      window.location.href = '/';
    }
  }, [deleteUrl]);

  useBeforeUnload(isDirty);

  const handleSave = async () => {
    const url = await save();
    setNextUrl(url);
    return url;
  };

  const saveAndQuit = async () => {
    if (canSave) {
      await handleSave();
    }

    window.location.href = quitUrl;
  };

  const saveAndGoToNextPage = async () => {
    if (canSave) {
      const url = await handleSave();
      window.location.href = url;
    }
    if (nextPageUrl) {
      window.location.href = nextPageUrl;
    }
  };

  const archiveProsjekt = async () => {
    if (archiveProsjektUrl) {
      await post(archiveProsjektUrl);
      window.location.href = '/';
    }
  };

  const canSubmit = isDirty && !isBusy;

  return (
    <div className="ProsjektRedigerContainer">
      <Popup isOpen={popup === 'delete'} close={hidePopup}>
        <Panel>
          <Paragraphs>{slettProsjektBekreftTekst}</Paragraphs>
          <div className="BrukerSide__popup-buttons">
            <Button secondary onClick={hidePopup}>
              Avbryt
            </Button>
            <Button onClick={deleteProject}>Slett prosjekt</Button>
          </div>
        </Panel>
      </Popup>
      <Popup isOpen={popup === 'archive'} close={hidePopup}>
        <Panel>
          <p>Er du sikker på at du vil avslutte prosjektet?</p>
          <p>Det vil ikke være mulig å svare på prosjektet når det er avsluttet.</p>
          <p>Du vil finne det igjen i historiske prosjekter</p>
          <div className="BrukerSide__popup-buttons">
            <Button secondary onClick={hidePopup}>
              Avbryt
            </Button>
            <Button onClick={archiveProsjekt}>Avslutt prosjekt</Button>
          </div>
        </Panel>
      </Popup>

      <div className="ProsjektRedigerContainer__header">
        <Text variant="news-title" className="ProsjektRedigerContainer__prosjektnavn">
          {headerText || 'Prosjekt'}
        </Text>
        <div className="ProsjektRedigerContainer__iconButtons">
          {archiveProsjektUrl && (
            <IconButton onClick={() => setPopup('archive')} title="Avslutt prosjekt" iconType={IconTypes.archive} />
          )}
          {deleteUrl && <IconButton onClick={() => setPopup('delete')} title="Slett prosjekt" iconType={IconTypes.trash} />}
        </div>
      </div>
      <Panel>
        <WizardSteps currentStep={currentStep} steps={stepLabels.length} labels={stepLabels} />
      </Panel>

      {!hideRequiredText && <div className="ProsjektRedigerContainer__required">* Må besvares </div>}

      {children}

      <div
        className={classNames('ProsjektRedigerContainer__actions', {
          'ProsjektRedigerContainer__actions--absolute': hasAbsoluteButtonRow
        })}
      >
        <div className={styles.Toolbar}>
          <div className={styles.Buttons}>
            {isBusy && <AiOutlineSync className="spin" />}

            {!isFirstPage && (
              <Button secondary onClick={goToPreviousPage} disabled={!canGoToPreviousPage}>
                Forrige
              </Button>
            )}
            <Button secondary={!isLastPage} onClick={saveAndQuit}>
              Lagre og avslutt
            </Button>

            <Button onClick={saveAndGoToNextPage}>{isLastPage ? 'Lagre' : 'Lagre og neste'}</Button>
            {!isLastPage && (
              <Button secondary onClick={goToNextPage} disabled={!canGoToNextPage}>
                Neste
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ProsjektRedigerContainer.defaultProps = {
  slettProsjektBekreftTekst: 'Er du sikker på at du vil slette prosjektet?'
};
