import React, { FC, ReactElement, useEffect, useState } from 'react';
import cn from 'classnames';
import IconReactIcons, { IconTypes } from 'components/Icon/IconReactIcons';
import { post } from 'js/api-helper';

export interface DotsMenuProps {
  eventId: string;
  strikeUrl: string;
  updateStrikeThrough: (flag: boolean) => void;
  isStrikedThrough: boolean;
}

const DotsMenu: FC<DotsMenuProps> = ({ eventId, strikeUrl, updateStrikeThrough, isStrikedThrough }: DotsMenuProps) => {
  const strikeContent = async () => {
    //TODO endpoint for strikethrough
    try {
      const res = await post(`${strikeUrl}?hendelsesloggOppforingId=${eventId}`, { strikeThrough: isStrikedThrough });

      if (res != undefined) {
        updateStrikeThrough(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <button
      className="dots"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <IconReactIcons name="dots" type={IconTypes.dotsVertical} size={2} />

      <div className="dots-content">
        <div
          onClick={(e) => {
            strikeContent();
          }}
          className="stryk-hendelse"
        >
          {isStrikedThrough ? 'Angre stryking' : 'Stryk hendelse'}
        </div>
      </div>
    </button>
  );
};

export default DotsMenu;
