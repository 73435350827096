import NumberField from 'components/NumberField/NumberField';
import NumberPicker from 'components/NumberPicker/NumberPicker';
import * as FormatHelper from 'js/format-helper';
import useDirtyTracking from 'js/hooks/useDirtyTracking';
import useOnChange from 'js/hooks/useOnChange';
import React, { useState } from 'react';
import Button from 'components/Button/Button';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Icon from 'components/Icon/Icon';
import Label from 'components/Label/Label';
import { ArealEtPlan } from 'types/ArealEtPlan';

export interface Props {
  arealPerPlan: ArealEtPlan[];
  label?: string;
  onChange?: (transList: ArealEtPlan[]) => void;
  readonly feedback?: string;
  readonly validationState?: 'error' | null;
  readonly feedbackMargin?: boolean;
}

export default function ArealPerPlan({
  arealPerPlan = [],
  label = 'Areal per plan',
  feedback,
  validationState,
  feedbackMargin,
  onChange
}: Props) {
  const [arealPlanList, setArealPlanList] = useState(arealPerPlan);
  const [tmpPlan, setTmpPlan] = useState(0);
  const [tmpAreal, setTmpAreal] = useState(0);
  const [isDirty, resetDirty] = useDirtyTracking([tmpPlan, tmpAreal]);

  const addToArealPlanList = (arealPlanElm: ArealEtPlan) => {
    setArealPlanList([...arealPlanList, arealPlanElm]);
  };

  const resetInputFields = () => {
    setTmpPlan(0);
    setTmpAreal(0);
    resetDirty();
  };

  const addToList = () => {
    resetInputFields();
    addToArealPlanList({
      plan: tmpPlan,
      areal: tmpAreal
    });
  };

  const removeElm = (index: number) => {
    setArealPlanList(arealPlanList.filter((_, i) => i !== index));
  };

  const showError = feedback && validationState === 'error';

  useOnChange(onChange, [arealPlanList]);

  return (
    <div className="ArealPerPlan">
      <div className="ArealPerPlan__Label">
        <Label>{label}</Label>
      </div>
      <NumberPicker unit="" placeholder="Plan" choices={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} onChange={(e) => setTmpPlan(e.value)} />
      <NumberField type="number" suffix="kvm" value={tmpAreal} onChange={setTmpAreal} />
      <Button disabled={!isDirty} onClick={addToList}>
        +
      </Button>
      {arealPlanList.map((elm: ArealEtPlan, index: number) => (
        <React.Fragment key={index}>
          <div className="ArealPerPlan__entry__type">{elm.plan}</div>
          <div className="ArealPerPlan__entry__avstand">
            {!isNaN(elm.areal) ? FormatHelper.numberWithSpaces(elm.areal) + 'kvm' : 'NaN'}
          </div>
          <div className="ArealPerPlan__entry__delete">
            <Icon
              onClick={() => {
                removeElm(index);
              }}
              icon="close"
            />
          </div>
        </React.Fragment>
      ))}
      <div className="ArealPerPlan__entry__errorMessage">
        {showError && <ErrorMessage feedback={feedback} feedbackMargin={feedbackMargin} />}
      </div>
    </div>
  );
}
